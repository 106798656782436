import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import authService from "./Components/Authentication/authService";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import NotifiReducer from "./features/Notification";
import ErrorBoundary from "./ErrorBoundary";
import { ConfigProvider } from "antd";
import TimeAgo from "javascript-time-ago";
import { Provider as Jotai } from "jotai";

import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";




TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

window.addEventListener(
  "blur",
  () => (document.title = "Check for new updates*")
);
window.addEventListener("focus", () => (document.title = "Intelense - AI"));

if (!authService.isAuthenticateduser()) {
  authService.logout();
}

const store = configureStore({
  reducer: {
    count: NotifiReducer,
    cpu_usage: NotifiReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
  
    <Jotai>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerHeight: 45,
            },
          },
        }}
      >
        <ErrorBoundary fallback="Somthing Went Wrong">
          <App />
        </ErrorBoundary>
      </ConfigProvider>
    </Jotai>
 
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

export default store;
