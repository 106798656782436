// socket.js
import { io } from "socket.io-client";
import authService from "./Components/Authentication/authService";
const socket = io(process.env.REACT_APP_SOCKET_KEY, {
  transports: ["websocket"],
  query: {
    authorization: authService.getCurrentUser(),
  },
  reconnection: false,
});

socket.on("connect", () => {
});

export default socket;