import React, {useState, useEffect, createRef, useCallback, useRef} from "react";
import socket from "../../../Socket";
import { TbAlertOctagonFilled } from "react-icons/tb";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { MdNoteAdd } from "react-icons/md";
import {EyeOutlined, CloseCircleOutlined, DeleteOutlined} from "@ant-design/icons";
import {Input, Button, Radio, Select, Form, Checkbox, message, Typography, Alert, Skeleton, Tooltip, Badge, Modal, Tabs, TimePicker, Tag, Dropdown, Flex, Collapse, Empty, Drawer, Popover, Pagination, Row, Col, Card, DatePicker} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";
import moment from "moment/moment";
import ReactLasso from "react-lasso-select";
import { useScreenshot } from "use-react-screenshot";
import { MultiSelect } from "react-multi-select-component";
import ClipLoader from "react-spinners/ClipLoader";
import { Timeline } from "antd";
import {FaPlus } from "react-icons/fa6";
import { GrConfigure } from "react-icons/gr";
import { IoMdAdd } from "react-icons/io";
import {BiMinus } from "react-icons/bi";
import TabPane from "antd/lib/tabs/TabPane";
import { getCameraListApi1 } from "../Reports/Controller/ReportPageApicall";
import ImageMapper from "react-img-mapper";
import { PulseLoader } from "react-spinners";
import { useAtom } from "jotai";
import { LoadingComp } from "../Utils/StateExports";
import { RxDrawingPinFilled } from "react-icons/rx";
import dayjs from "dayjs";
import { WeekdaysInput } from "react-weekdays-input";
import TimeAgo from "javascript-time-ago";
const { RangePicker } = DatePicker;

const { Option } = Select;
const { Panel: CollapsePanel } = Collapse;
const { Title, Text, Link } = Typography;
let InitialData = {
  id: "", // to store event id
  // areaName: "",
  Marker: false, // To show link to open modal
  loading: false, //Loading
  baseimage: "", // Base image thats going to be added in payload
  ImagetoMark: "",
  coordinates: [], // single coordinates stored here before going to add in FinalCoordinates
  FinalCoordinates: [], // All/Multiple Coordinates to send in api
  OpenModalToMark: false, // To open modal
  FrameReceived: false, //
  ImageUpdated: false, //whether the image from base or screenshot
  DisableEvent: true, //Disable evet select till camera list selected
  DisableCheckbox: true, //Disable Checkbox untill event selected
  FinalCoordinatesEdit: [],
  SelectedEventid: null,
  SelectedCameraid: null,
};

const editData = {
  startedit: false,
  loading: false,
  Mapperformat: [],
  coords: [],
  baseimage: "",
  resData: null,
};

let CommonData = {
  SlectedEvent: null,
  SelectedCamera: null,
  DisableEvent: true,
};

let SchedulerDetails = {
  SchedluerType: 1,
};

let ScheduleDrawerDetails = {
  OpenDrawer: false,
  SchedulerSelected: false,
};

function EventSetup() {
  document.title = "Intelense - Event Setup";
  const [form] = Form.useForm();
  const [basicform] = Form.useForm();
  const [ScheduleForm] = Form.useForm();
  const [AddRefresh, setAddRefresh] = useState(0);
  const [events, setevents] = useState([]);
  const [img, setImg] = useState({ width: 100, height: 100 });
  // const [showTimeFields, setShowTimeFields] = useState(false);
  const [cameralist, setcameralist] = useState([]);
  // const disabledDate = (current) => {
  //   const createdDate = authService.getCreatedDate();
  //   const today = dayjs();

  //   return createdDate
  //     ? current < dayjs(createdDate).startOf("day") ||
  //         current > today.endOf("day")
  //     : false;
  // };
  const ref = createRef(null);
  const ref1 = createRef(null);
  const ref2 = createRef(null);
  const ref3 = createRef(null);
  const ref4 = createRef(null);
  const ref5 = createRef(null);

  const [Selectedevents, setSelectedevents] = useState([]);
  const allids = Selectedevents.map(({ value }) => value);
  const [EnvEvents, setEnvEvents] = useState([]);
  const [cameralistmultiselect, setcameralistmultiselect] = useState([]);
  const [selectedcameralist, setselectedcameralist] = useState([]);
  const selectedcamids = selectedcameralist.map(({ value }) => value);
  const [Screenshot, takeScreenshot] = useScreenshot([]);
  const [Pageload, setPageload] = useState(false);
  const [DataController, setDataController] = useState(InitialData);
  const [NewEventModalOpen, setNewEventModalOpen] = useState(false);
  const [NewENVevent, setNewENVevent] = useState(false);
  const [DwellController, setDwellController] = useState(InitialData);
  const [FootFallController, setFootFallController] = useState(InitialData);
  const [FootFallDirectionController, setFootFallDirectionController] = useState(InitialData);
  const [CommonEventController, setCommonEventController] = useState(CommonData);
  // const [currentPage, setCurrentPage] = useState(1);
  // const pageSize = 10;
  // const [data, setdata] = useState([]);
  const [selectedtimes, setselectedtimes] = useState({
    start_time: [],
    end_time: [],
    count: 1,
  });
  // const [editeventdata, setediteventdata] = useState([]);
  // const [editcamname, seteditcamname] = useState();
  const [CameralistBasic, setCameralistBasic] = useState([]);
  const [EditEnvController, setEditEnvController] = useState(editData);
  const [historycurrentpage, sethistorycurrentpage] = useState(1);
  const [historydata, sethistorydata] = useState([]);
  const [getserverdata, setgetserverdata] = useState([]);
  const [serverid, setserverid] = useState("");
  const [FootfallTypes, setFootfallTypes] = useState([]);
  const [FootfallTypesDirection, setFootfallTypesDirection] = useState([]);
  const [SchedulerController, setSchedulerController] = useState(SchedulerDetails);
  const [ScheduleDrawerContoller, setScheduleDrawerContoller] = useState(ScheduleDrawerDetails);
  const [daysAsString, setDaysAsString] = useState([0, 0, 0, 0, 0, 0, 0]); //Wednesday and saturday active
  const [PasserByCheck, setPasserByCheck] = useState(false);
  // const [getserverdata, setgetserverdata] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  // const [socketInterval, setSocketInterval] = useState(null);
  // const [socketTimer, setSocketTimer] = useState(null);
  // const [serverid, setserverid] = useState("");
  const [cpuUsage, setCpuUsage] = useState("");
  const [gpuUsage, setGpuUsage] = useState([]);
  // const [isMonthChanging, setIsMonthChanging] = useState(false);
  // const [PreviewDetails, setPreviewDetails] = useState({
  //   rtsp: null,
  //   image: null,
  //   openmodal: false,
  //   loading: false,
  //   LoadRTSP: null,
  //   Coordinates: [],
  // });
  const [OneTimeSChedule, setOneTimeSChedule] = useState({
    StartDate: "",
    EndDate: "",
    StartTime: "",
    EndTime: "",
  });
  const [EveryDaySChedule, setEveryDaySChedule] = useState({
    Day: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    StartTime: "",
    EndTime: "",
  });
  const [CustomSChedule, setCustomSChedule] = useState({
    Day: [],
    StartTime: "",
    EndTime: "",
  });
  const [SchedulerStatus, setSchedulerStatus] = useState(false);
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [SchedulerCount, setSchedulerCount] = useState([]);
  // const [SchedulerView, setSchedulerSchedulerView] = useState([]);
  // const [SchedulerModel, setSchedulerModel] = useState(false);
  // const [activeKey, setActiveKey] = useState(null);
  const [previewDetails, setpreviewDetails] = useState({
    loading: false,
    image: null,
    coordinates: [],
    openmodal: false,
    LoadRTSP: null,
    EventId: "",
  });
  const [showIntimeOuttimeDropdown, setShowIntimeOuttimeDropdown] = useState(false);
  const [showMarkAreaField, setShowMarkAreaField] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [scheduleData, setScheduleData] = useState({
    startTime: null,
    endTime: null,
    day: null,
    daystartTime: null,
    dayendTime: null,
  });
  // const [activeKey1, setActiveKey1] = useState({}); // Initially empty object for all cameras
  const [Month, setMonth] = useState(dayjs().format("YYYY-MM"));

  const historypageSize = 8;
  const timeAgo = new TimeAgo("en-US");

  // Calculate the current page's data
  const currentData = historydata.slice(
    (historycurrentpage - 1) * historypageSize,
    historycurrentpage * historypageSize
  );

  // Handle page change
  const onPageChange = (page) => {
    sethistorycurrentpage(page);
  };  

  const fetchHistoryData = (startDate, endDate, page = 1) => {
    // const pageSize = historypageSize;
    // const offset = (page - 1) * pageSize;
    const startDate1 = startDate ? startDate.format("YYYY-MM-DD") : "";
    const endDate1 = endDate ? endDate.format("YYYY-MM-DD") : "";

    axiosClient
      .get(
        `cameraEvents/geteventhistory?startdate=${startDate1}&enddate=${endDate1}`
      )

      .then((response) => {
        if (response.data.issuccess) {
          sethistorydata(response.data.data);
        } else {
          message.error("Failed to fetch data");
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;

      fetchHistoryData(startDate, endDate);
    } else {
      sethistorycurrentpage(1);
      fetchHistoryData(null, null, 1);
    }
  };

  const formatDate = (dateString) => {
    const eventDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const isToday = eventDate.toDateString() === today.toDateString();
    const isYesterday = eventDate.toDateString() === yesterday.toDateString();

    if (isToday) {
      return timeAgo.format(eventDate);
    } else if (isYesterday) {
      return "Yesterday";
    } else {
      return eventDate.toLocaleDateString();
    }
  };

  const items = [
    // {
    //   label: "Basic Events",
    //   key: "1",
    //   icon: <FaRegBookmark />,
    // },
    {
      label: "Environment Events",
      key: "2",
      icon: <RxDrawingPinFilled />,
    },
    authService.getCurrentUserRole() === "Admin" && {
      label: "Config",
      key: "3",
      icon: <GrConfigure />,
    },
  ];

  // Checkbox items
  const getImagewithRTSPTable = (items) => {
    setpreviewDetails((prev) => ({ ...prev, loading: true, LoadRTSP: items }));

    const { eventId, camEventId } = items;
    let apiUrl = "";
    // Determine the API endpoint based on eventId
    if (eventId === 13 || eventId === 14) {
      apiUrl = `/cameraEvents/getFootfallMarkRegion?id=${camEventId}`;
    } else {
      apiUrl = `/notification/getMarkedRegion?eventId=${eventId}&id=${camEventId}`;
    }
    axiosClient
      .get(apiUrl)
      .then((response) => {
        if (response.data.issuccess) {
          const data = response.data.data;
          if (data) {
            const markedRegionDetails = data.markedRegionDetails
              ? data.markedRegionDetails[0]
              : null;

            // Extracting image and coordinates based on availability

            const image = markedRegionDetails
              ? markedRegionDetails.cam_image
              : data.cam_images;

            // const coordinates = markedRegionDetails
            //   ? markedRegionDetails.coordinates
            //   : eventId === 13
            //   ? data.footfallCoordinates
            //   : eventId === 14
            //   ? data.footfallDirectionCoordinates
            //   : [];
            const coordinates = markedRegionDetails
              ? markedRegionDetails.coordinates
              : eventId === 14 // Change condition to differentiate
              ? data.footfallDirectionCoordinates
              : [];

            const DwellCoordinates = markedRegionDetails
              ? markedRegionDetails.coordinates
              : eventId === 13
              ? data.dwellStoreCoordinates.length > 0
                ? data.dwellStoreCoordinates
                : [] // Return an empty array if no dwell coordinates
              : []; // Return empty array for other cases

            const footfallCoordinates = markedRegionDetails
              ? markedRegionDetails.coordinates
              : eventId === 13
              ? data.footfallCoordinates
              : eventId === 14
              ? data.footfallDirectionCoordinates
              : [];

            if (image) {
              setpreviewDetails((prev) => ({
                ...prev,
                image: image,
                coordinates: coordinates,
                DwellCoordinates: DwellCoordinates,
                footfallCoordinates: footfallCoordinates,
                loading: false,
                rtsp: items,
                openmodal: true,
                LoadRTSP: null,
              }));
            } else {
              message.error("Image data is not available");
              setpreviewDetails((prev) => ({ ...prev, loading: false }));
            }
          } else {
            message.error("Image data is not available");
            setpreviewDetails((prev) => ({ ...prev, loading: false }));
          }
        } else {
          message.error("Image unavailable");
          setpreviewDetails((prev) => ({ ...prev, loading: false }));
        }
      })
      .catch((error) => {
        message.error("Image unavailable");
        setpreviewDetails((prev) => ({ ...prev, loading: false }));
      });
  };

  // const getImagewithRTSPTable = (items) => {
  //   setpreviewDetails((prev) => ({ ...prev, loading: true, LoadRTSP: items }));

  //   const { eventId, camEventId } = items;
  //   let apiUrl = "";
  //   // Determine the API endpoint based on eventId
  //   if (eventId === 13 || eventId === 14) {
  //     apiUrl = `/cameraEvents/getFootfallMarkRegion?id=${camEventId}`;
  //   } else {
  //     apiUrl = `/notification/getMarkedRegion?eventId=${eventId}&id=${camEventId}`;
  //   }
  //   axiosClient
  //     .get(apiUrl)
  //     .then((response) => {
  //       if (response.data.issuccess) {
  //         const data = response.data.data;
  //         if (data) {
  //           const markedRegionDetails = data.markedRegionDetails
  //             ? data.markedRegionDetails[0]
  //             : null;

  //           // Extracting image and coordinates based on availability

  //           const image = markedRegionDetails
  //             ? markedRegionDetails.cam_image
  //             : data.cam_images;

  //           const coordinates = markedRegionDetails
  //             ? markedRegionDetails.coordinates
  //             : eventId === 13
  //             ? data.footfallCoordinates
  //             : eventId === 14
  //             ? data.footfallDirectionCoordinates
  //             : [];

  //           const DwellCoordinates = markedRegionDetails
  //             ? markedRegionDetails.coordinates
  //             : eventId === 13
  //             ? data.dwellStoreCoordinates.length > 0
  //               ? data.dwellStoreCoordinates
  //               : [] // Return an empty array if no dwell coordinates
  //             : []; // Return empty array for other cases

  //           const footfallCoordinates = markedRegionDetails
  //             ? markedRegionDetails.coordinates
  //             : eventId === 13
  //             ? data.footfallCoordinates
  //             : eventId === 14
  //             ? data.footfallDirectionCoordinates
  //             : [];

  //           if (image) {
  //             setpreviewDetails((prev) => ({
  //               ...prev,
  //               image: image,
  //               coordinates: coordinates,
  //               DwellCoordinates: DwellCoordinates,
  //               footfallCoordinates: footfallCoordinates,
  //               loading: false,
  //               rtsp: items,
  //               openmodal: true,
  //               LoadRTSP: null,
  //             }));
  //           } else {
  //             message.error("Image data is not available");
  //             setpreviewDetails((prev) => ({ ...prev, loading: false }));
  //           }
  //         } else {
  //           message.error("Image data is not available");
  //           setpreviewDetails((prev) => ({ ...prev, loading: false }));
  //         }
  //       } else {
  //         message.error("Image unavailable");
  //         setpreviewDetails((prev) => ({ ...prev, loading: false }));
  //       }
  //     })
  //     .catch((error) => {
  //       message.error("Image unavailable");
  //       setpreviewDetails((prev) => ({ ...prev, loading: false }));
  //     });
  // };

  // const getImagewithRTSPTable = (items) => {
  //   setpreviewDetails((prev) => ({ ...prev, loading: true, LoadRTSP: items }));

  //   const { eventId, camEventId } = items;
  //   let apiUrl = "";

  //   // Determine the API endpoint based on eventId
  //   if (eventId === 13 || eventId === 14) {
  //       apiUrl = `/cameraEvents/getFootfallMarkRegion?id=${camEventId}`;
  //   } else {
  //       apiUrl = `/notification/getMarkedRegion?eventId=${eventId}&id=${camEventId}`;
  //   }

  //   axiosClient.get(apiUrl)
  //       .then((response) => {
  //           if (response.data.issuccess) {
  //               const data = response.data.data;
  //               if (data) {
  //                   const markedRegionDetails = data.markedRegionDetails ? data.markedRegionDetails[0] : null;

  //                   // Extract image buffer
  //                   const imageBuffer = markedRegionDetails ? markedRegionDetails.cam_image : data.cam_images1.data;

  //                   // Convert Buffer to base64 string using Uint8Array
  //                   let str = '';
  // for (let i = 0; i < imageBuffer.length; i++) {
  //     str += String.fromCharCode(imageBuffer[i]);
  // }
  //                   const base64String = btoa(str);

  //                   const coordinates = markedRegionDetails
  //                       ? markedRegionDetails.coordinates
  //                       : (eventId === 13 ? data.footfallCoordinates : (eventId === 14 ? data.footfallDirectionCoordinates : []));

  //                   const DwellCoordinates = markedRegionDetails
  //                       ? markedRegionDetails.coordinates
  //                       : (eventId === 13 ? (data.dwellStoreCoordinates.length > 0 ? data.dwellStoreCoordinates : []) : []);

  //                   if (base64String) {
  //                       setpreviewDetails((prev) => ({
  //                           ...prev,
  //                           image: `data:image/png;base64,${base64String}`,
  //                           coordinates,
  //                           DwellCoordinates,
  //                           loading: false,
  //                           rtsp: items,
  //                           openmodal: true,
  //                           LoadRTSP: null,
  //                       }));
  //                   } else {
  //                       message.error("Image data is not available");
  //                       setpreviewDetails((prev) => ({ ...prev, loading: false }));
  //                   }
  //               } else {
  //                   message.error("Image data is not available");
  //                   setpreviewDetails((prev) => ({ ...prev, loading: false }));
  //               }
  //           } else {
  //               message.error("Image unavailable");
  //               setpreviewDetails((prev) => ({ ...prev, loading: false }));
  //           }
  //       })
  //       .catch((error) => {
  //           message.error("Image unavailable");
  //           setpreviewDetails((prev) => ({ ...prev, loading: false }));
  //       });
  // };

  const getImagewithRTSPTableSchduler = async (record, option) => {
    let sId = option.scheduleId ? option.scheduleId : option.days[0][1];
    setpreviewDetails((prev) => ({ ...prev, loading: true, LoadRTSP: option }));
    await axiosClient
      .get(
        "/notification/getMarkedRegion?eventId=" +
          record.eventId +
          "&id=" +
          record.camEventId +
          "&sid=" +
          sId
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const { cam_image, coordinates } =
            response.data.data.markedRegionDetails[0];
          setpreviewDetails((prev) => ({
            ...prev,
            image: cam_image,
            loading: false,
            openmodal: true,
            LoadRTSP: null,
            Coordinates: coordinates ? coordinates : [0],
          }));
        } else {
          message.error("image unavailable");
          setpreviewDetails((prev) => ({ ...prev, loading: false }));

          //FormWithoutRTSP.resetFields();
        }
      })
      .catch((rr) => {
        message.error("image unavailable");
        setpreviewDetails((prev) => ({ ...prev, loading: false }));
        // form.resetFields();
      });
  };

  const imageWidth = 1000;
  const imageHeight = 700;

  const scalePoints = (points, Width, Height) => {
    return points
      .reduce((acc, coord, index) => {
        if (index % 2 === 0) {
          const scaledX = (coord / Width) * imageWidth;
          const scaledY = (points[index + 1] / Height) * imageHeight;
          acc.push(`${scaledX},${scaledY}`);
        }
        return acc;
      }, [])
      .join(" ");
  };

  // const ImageWithCoordinates = ({ image, coordinates }) => {
  //   const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  //   const [pointsString, setPointsString] = useState("");

  //   const handleImageLoad = (event) => {
  //     const { naturalWidth, naturalHeight } = event.target;
  //     setImageSize({ width: naturalWidth, height: naturalHeight });
  //   };

  //   useEffect(() => {
  //     if (imageSize.width > 0 && imageSize.height > 0) {
  //       const scaledPoints = scalePoints(
  //         coordinates,
  //         imageSize.width,
  //         imageSize.height
  //       );
  //       setPointsString(scaledPoints);
  //     }
  //   }, [imageSize, coordinates]);

  //   return (
  //     <div style={{ position: "relative", display: "inline-block" }}>
  //       <img
  //         src={image}
  //         alt="Camera View"
  //         style={{ width: imageWidth, height: imageHeight }}
  //         onLoad={handleImageLoad}
  //       />
  //       <svg
  //         style={{
  //           position: "absolute",
  //           top: 0,
  //           left: 0,
  //           width: "100%",
  //           height: "100%",
  //           pointerEvents: "none",
  //         }}
  //       >
  //         <polygon
  //           points={pointsString}
  //           fill="rgba(255, 0, 0, 0.5)"
  //           stroke="red"
  //           strokeWidth="2"
  //         />
  //       </svg>
  //     </div>
  //   );
  // };

  // const ImageWithCoordinates = ({
  //   image,
  //   footfallCoordinates,
  //   Dwell,
  //   coordinates,
  // }) => {
  //   const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  //   const [coordinatesPointsArray, setcoordinatesPointsArray] = useState([]);
  //   const [coordinatesPointsString, setcoordinatesPointsString] = useState("");
  //   const [footfallPointsArray, setFootfallPointsArray] = useState([]);
  //   const [dwellPointsArray, setDwellPointsArray] = useState([]);
  //   const [footfallPointsString, setFootfallPointsString] = useState("");
  //   const [dwellPointsString, setDwellPointsString] = useState("");

  //   const handleImageLoad = (event) => {
  //     const { naturalWidth, naturalHeight } = event.target;
  //     setImageSize({ width: naturalWidth, height: naturalHeight });
  //   };

  //   useEffect(() => {
  //     if (Array.isArray(footfallCoordinates[0])) {
  //       const scaledFootfallPoints = footfallCoordinates.map((coords) =>
  //         scalePoints(coords, imageSize.width, imageSize.height)
  //       );
  //       setFootfallPointsArray(scaledFootfallPoints);
  //     } else {
  //       const scaledFootfallPoints = scalePoints(
  //         footfallCoordinates,
  //         imageSize.width,
  //         imageSize.height
  //       );
  //       setFootfallPointsString(scaledFootfallPoints);
  //     }
  //     if (Array.isArray(coordinates[0])) {
  //       const scaledcoordinatesPoints = coordinates.map((coords) =>
  //         scalePoints(coords, imageSize.width, imageSize.height)
  //       );
  //       setcoordinatesPointsArray(scaledcoordinatesPoints);
  //     } else {
  //       const scaledcoordinatesPoints = scalePoints(
  //         coordinates,
  //         imageSize.width,
  //         imageSize.height
  //       );
  //       setcoordinatesPointsString(scaledcoordinatesPoints);
  //     }
  //     if (Array.isArray(Dwell[0])) {
  //       const scaledDwellPoints = Dwell.map((coords) =>
  //         scalePoints(coords, imageSize.width, imageSize.height)
  //       );
  //       setDwellPointsArray(scaledDwellPoints);
  //     } else {
  //       const scaledDwellPoints = scalePoints(
  //         Dwell,
  //         imageSize.width,
  //         imageSize.height
  //       );
  //       setDwellPointsString(scaledDwellPoints);
  //     }
  //   }, [imageSize, coordinates, footfallCoordinates, Dwell]);

  //   return (
  //     <div style={{ position: "relative", display: "inline-block" }}>
  //       <img
  //         src={image}
  //         alt="Camera View"
  //         style={{ width: imageWidth, height: imageHeight }}
  //         onLoad={handleImageLoad}
  //       />
  //       <svg
  //         style={{
  //           position: "absolute",
  //           top: 0,
  //           left: 0,
  //           width: "100%",
  //           height: "100%",
  //           pointerEvents: "none",
  //         }}
  //       >
  //         {Array.isArray(footfallCoordinates[0]) ? (
  //           footfallPointsArray.map((points, index) => (
  //             <polygon
  //               key={`footfall-${index}`}
  //               points={points}
  //               fill="rgba(255, 0, 0, 0.5)"
  //               stroke="red"
  //               strokeWidth="2"
  //             />
  //           ))
  //         ) : (
  //           <polygon
  //             points={footfallPointsString}
  //             fill="rgba(255, 0, 0, 0.5)"
  //             stroke="red"
  //             strokeWidth="2"
  //           />
  //         )}

  //         {Array.isArray(Dwell[0]) ? (
  //           dwellPointsArray.map((points, index) => (
  //             <polygon
  //               key={`dwell-${index}`}
  //               points={points}
  //               fill="rgba(0, 0, 255, 0.5)"
  //               stroke="blue"
  //               strokeWidth="2"
  //             />
  //           ))
  //         ) : (
  //           <polygon
  //             points={dwellPointsString}
  //             fill="rgba(0, 0, 255, 0.5)"
  //             stroke="blue"
  //             strokeWidth="2"
  //           />
  //         )}
  //       </svg>

  //       {/* Label box in the top-right corner */}
  //       {Dwell.length > 0 || footfallCoordinates.length > 0 ? (
  //         <div
  //           style={{
  //             position: "absolute",
  //             top: 10,
  //             right: 10,
  //             backgroundColor: "rgba(255, 255, 255, 0.8)",
  //             padding: "5px 10px",
  //             borderRadius: "5px",
  //             fontSize: "14px",
  //             color: "#333",
  //           }}
  //         >
  //           {Dwell.length > 0 || footfallCoordinates.length > 0 ? (
  //             <>
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   marginBottom: 4,
  //                 }}
  //               >
  //                 <span
  //                   style={{
  //                     display: "inline-block",
  //                     width: 10,
  //                     height: 10,
  //                     backgroundColor: "red",
  //                   }}
  //                 ></span>
  //                 <span>Ingress/Egress</span>
  //               </div>
  //               <div style={{ display: "flex", alignItems: "center" }}>
  //                 <span
  //                   style={{
  //                     display: "inline-block",
  //                     width: 10,
  //                     height: 10,
  //                     backgroundColor: "blue",
  //                     marginRight: 6,
  //                   }}
  //                 ></span>
  //                 <span>Dwell</span>
  //               </div>
  //             </>
  //           ) : null}
  //         </div>
  //       ) : null}
  //     </div>
  //   );
  // };

  const ImageWithCoordinates = ({
    image,
    footfallCoordinates,
    Dwell,
    coordinates,
  }) => {
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [coordinatesPointsArray, setcoordinatesPointsArray] = useState([]);
    const [coordinatesPointsString, setcoordinatesPointsString] = useState("");
    const [footfallPointsArray, setFootfallPointsArray] = useState([]);
    const [dwellPointsArray, setDwellPointsArray] = useState([]);
    const [footfallPointsString, setFootfallPointsString] = useState("");
    const [dwellPointsString, setDwellPointsString] = useState("");

    const handleImageLoad = (event) => {
      const { naturalWidth, naturalHeight } = event.target;
      setImageSize({ width: naturalWidth, height: naturalHeight });
    };

    useEffect(() => {
      if (Array.isArray(footfallCoordinates[0])) {
        const scaledFootfallPoints = footfallCoordinates.map((coords) =>
          scalePoints(coords, imageSize.width, imageSize.height)
        );
        setFootfallPointsArray(scaledFootfallPoints);
      } else {
        const scaledFootfallPoints = scalePoints(
          footfallCoordinates,
          imageSize.width,
          imageSize.height
        );
        setFootfallPointsString(scaledFootfallPoints);
      }
      // if (Array.isArray(coordinates[0])) {
      //   const scaledcoordinatesPoints = coordinates.map((coordss) =>
      //     scalePoints(coordss, imageSize.width, imageSize.height)
      //   );

      //   setcoordinatesPointsArray(scaledcoordinatesPoints);
      // } else {
      //   const scaledcoordinatesPoints = scalePoints(
      //     coordinates,
      //     imageSize.width,
      //     imageSize.height
      //   );
      //   setcoordinatesPointsString(scaledcoordinatesPoints);
      // }
      if (Array.isArray(coordinates)) {
        const scaledCoordinatesPoints = scalePoints(
          coordinates,
          imageSize.width,
          imageSize.height
        );
        setcoordinatesPointsArray([scaledCoordinatesPoints]); // Single polygon
      }
      if (Array.isArray(Dwell[0])) {
        const scaledDwellPoints = Dwell.map((coords) =>
          scalePoints(coords, imageSize.width, imageSize.height)
        );
        setDwellPointsArray(scaledDwellPoints);
      } else {
        const scaledDwellPoints = scalePoints(
          Dwell,
          imageSize.width,
          imageSize.height
        );
        setDwellPointsString(scaledDwellPoints);
      }
    }, [imageSize, coordinates, footfallCoordinates, Dwell]);

    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        <img
          src={image}
          alt="Camera View"
          style={{ width: imageWidth, height: imageHeight }}
          onLoad={handleImageLoad}
        />
        <svg
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
          }}
        >
          {Array.isArray(footfallCoordinates[0]) ? (
            footfallPointsArray.map((points, index) => (
              <polygon
                key={`footfall-${index}`}
                points={points}
                fill="rgba(255, 0, 0, 0.5)"
                stroke="red"
                strokeWidth="2"
              />
            ))
          ) : (
            <polygon
              points={footfallPointsString}
              fill="rgba(255, 0, 0, 0.5)"
              stroke="red"
              strokeWidth="2"
            />
          )}

          {/* {coordinatesPointsArray.length > 0 && (
          <polygon
            points={coordinatesPointsArray[0]} // Use the first element
            fill="rgba(255, 0, 0, 0.5)"
            stroke="red"
            strokeWidth="2"
          />
        )} */}

          {Array.isArray(coordinates) && coordinates.length > 0 ? (
            // Check if the first element is an array
            Array.isArray(coordinates[0]) ? (
              coordinatesPointsArray.map((points, index) => (
                <polygon
                  key={`coordinated-${index}`}
                  points={points}
                  fill="rgba(255, 0, 0, 0.5)"
                  stroke="red"
                  strokeWidth="2"
                />
              ))
            ) : (
              // If it's a flat array, directly use it
              <polygon
                points={coordinatesPointsString}
                fill="rgba(255, 0, 0, 0.5)"
                stroke="red"
                strokeWidth="2"
              />
            )
          ) : null}

          {Array.isArray(Dwell[0]) ? (
            dwellPointsArray.map((points, index) => (
              <polygon
                key={`dwell-${index}`}
                points={points}
                fill="rgba(0, 0, 255, 0.5)"
                stroke="blue"
                strokeWidth="2"
              />
            ))
          ) : (
            <polygon
              points={dwellPointsString}
              fill="rgba(0, 0, 255, 0.5)"
              stroke="blue"
              strokeWidth="2"
            />
          )}
        </svg>
        {(Dwell.length > 0 || footfallCoordinates.length > 0) &&
          coordinates.length === 0 && (
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "14px",
                color: "#333",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: 10,
                    height: 10,
                    backgroundColor: "red",
                  }}
                ></span>
                <span>Ingress/Egress</span>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: 10,
                    height: 10,
                    backgroundColor: "blue",
                    marginRight: 6,
                  }}
                ></span>
                <span>Dwell</span>
              </div>
            </div>
          )}
        {/* Label box in the top-right corner */}
        {/* {(Dwell.length > 0 || footfallCoordinates.length > 0) &&
          coordinates.length === 0 && (
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "14px",
                color: "#333",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: 10,
                    height: 10,
                    backgroundColor: "red",
                  }}
                ></span>
                <span>Ingress/Egress</span>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: 10,
                    height: 10,
                    backgroundColor: "blue",
                    marginRight: 6,
                  }}
                ></span>
                <span>Dwell</span>
              </div>
            </div>
          )} */}
      </div>
    );
  };

  // screenshot after marking area
  const getImage = async () => {
    if (CommonEventController.SlectedEvent === 14) {
      setFootFallDirectionController((prev_values) => ({
        ...prev_values,
        loading: true,
      }));

      await takeScreenshot(ref.current);

      setFootFallDirectionController((prev_values) => ({
        ...prev_values,
        loading: false,
        ImageUpdated: true,
        coordinates: [],
      }));
    }
    if (CommonEventController.SlectedEvent === 13) {
      setFootFallController((prev_values) => ({
        ...prev_values,
        loading: true,
      }));

      await takeScreenshot(ref.current);
      setFootFallController((prev_values) => ({
        ...prev_values,
        loading: false,
        ImageUpdated: true,
        coordinates: [],
      }));
      setDataController((prev_values) => ({
        ...prev_values,
        loading: false,
        ImageUpdated: true,
        coordinates: [],
      }));
    }
  };

  // screenshot after marking area
  const getImage1 = async () => {
    if (CommonEventController.SlectedEvent === 14) {
      setFootFallDirectionController((prev_values) => ({
        ...prev_values,
        loading: true,
      }));

      await takeScreenshot(ref1.current);

      setFootFallDirectionController((prev_values) => ({
        ...prev_values,
        loading: false,
        ImageUpdated: true,
        coordinates: [],
      }));
    }
    if (CommonEventController.SlectedEvent === 13) {
      setFootFallController((prev_values) => ({
        ...prev_values,
        loading: false,
      }));

      await takeScreenshot(ref1.current);

      setFootFallController((prev_values) => ({
        ...prev_values,
        loading: false,
        ImageUpdated: true,
        coordinates: [],
      }));
    }
  };

  // screenshot after marking area
  const getImage2 = async () => {
    setDwellController((prev_values) => ({
      ...prev_values,
      loading: true,
    }));

    await takeScreenshot(ref2.current);

    setDwellController((prev_values) => ({
      ...prev_values,
      loading: false,
      ImageUpdated: true,
      coordinates: [],
    }));
  };

  // screenshot after marking area
  const getImage3 = async () => {
    setDwellController((prev_values) => ({
      ...prev_values,
      loading: true,
    }));

    await takeScreenshot(ref3.current);

    setDwellController((prev_values) => ({
      ...prev_values,
      loading: false,
      ImageUpdated: true,
      coordinates: [],
    }));
  };

  // screenshot after marking area
  const getImage4 = async () => {
    setDataController((prev_values) => ({
      ...prev_values,
      loading: true,
    }));

    await takeScreenshot(ref4.current);

    setDataController((prev_values) => ({
      ...prev_values,
      loading: false,
      ImageUpdated: true,
      coordinates: [],
    }));
  };

  const GetSubTypes = (id) => {
    axiosClient
      .get("/cameraEvents/getfootfalltype?eventId=" + id)
      .then((response) => {
        if (response.data.issuccess === true && response.data.data.length > 0) {
          const Types = response.data.data.map((item) => ({
            label: item.footfallTypeName,
            value: item.id,
          }));
          setFootfallTypes(Types);
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const GetSubTypesDirection = (id) => {
    axiosClient
      .get("/cameraEvents/getfootfalltype?eventId=" + id)
      .then((response) => {
        if (response.data.issuccess === true && response.data.data.length > 0) {
          const Types = response.data.data.map((item) => ({
            label: item.footfallTypeName,
            value: item.id,
          }));
          setFootfallTypesDirection(Types);
        } else {
        }
      })
      .catch((err) => {

        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg);
        }
      });
  };
 
  const testFinish = () => {
    //SchedulerController.SchedluerType

    if (SchedulerController.SchedluerType === 1) {
      setScheduleData((prev) => ({
        ...prev,
        startTime:
          OneTimeSChedule.StartDate + " "  `${OneTimeSChedule.StartTime}`,
        endTime: OneTimeSChedule.EndDate + " "  `${OneTimeSChedule.EndTime}`,
        //  daystartTime : OneTimeSCheduleSub.StartTime,
        //  dayendTime : OneTimeSCheduleSub.EndTime,
      }));
    } else if (SchedulerController.SchedluerType === 2) {
      setScheduleData((prev) => ({
        ...prev,
        day: EveryDaySChedule.Day,
        daystartTime: EveryDaySChedule.StartTime,
        dayendTime: EveryDaySChedule.EndTime,
      }));
    } else if (SchedulerController.SchedluerType === 3) {
      setScheduleData((prev) => ({
        ...prev,
        day: CustomSChedule.Day,
        daystartTime: CustomSChedule.StartTime,
        dayendTime: CustomSChedule.EndTime,
      }));
    }
    setScheduleDrawerContoller((prev) => ({
      ...prev,
      OpenDrawer: false,
      SchedulerSelected: true,
    }));

    setTimeout(() => {
      ScheduleForm.resetFields();
    }, 1000);
  };

  // onfinish for Environment based events
  const onFinishenv = (values) => {
    setPageload(true);

    let DirectionList = {};

    FootFallDirectionController.FinalCoordinates.forEach((item, index) => {
      if (index === 0) {
        DirectionList["Start"] = item;
      } else {
        DirectionList["Direction" + index] = item;
      }
    });
    const Clist = DwellController.FinalCoordinates.reduce((area, item) => {
      area[item.areaName] = item.coordinates; // Use areaName as the key
      return area;
    }, {});

    if (
      DataController.id === 5 ||
      DataController.id === 10 ||
      DataController.id === 6 ||
      DataController.id === 3
    ) {
      axiosClient
        .post("/cameraEvents/addCameraEvents", {
          data: [
            {
              serverId: values.server,
              anprType: values.intimeOuttime,
              cameraId: values.envcameralist,
              eventId: values.envevents,
              isSchedule: SchedulerStatus,
              option: SchedulerController.SchedluerType,
              ...scheduleData,
              coordinates: DataController.FinalCoordinates,
              coordinatesEdit: DataController.FinalCoordinatesEdit,
              threshold: null,

              cam_images:
                DataController.baseimage !== ""
                  ? "data:image/png;base64," + DataController.baseimage
                  : null,
              // startTime, endTime, option, day, daystartTime, dayendTime
            },
          ],
        })
        .then((response) => {
          if (response.data.issuccess === true) {
            message.success(response.data.msg);
            basicform.resetFields();
            setPageload(false);
            setShowMarkAreaField(false);
            setShowIntimeOuttimeDropdown(false);
            setAddRefresh(AddRefresh + 1);
          } else if (response.data.issuccess === false) {
            basicform.resetFields();
            message.error(response.data.msg);
            setPageload(false);
          }
        })
        .catch((err) => {
          message.error(err.msg);
          setPageload(false);
          basicform.resetFields();
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
        });
    } else if (DataController.id === 14 || DataController.id === 13) {
      axiosClient
        .post("/cameraEvents/addCameraEvents", {
          data: [
            {
              serverId: values.server,
              cameraId: values.envcameralist,
              eventId: values.envevents,
              footfallCoordinates:
                FootFallDirectionController.FinalCoordinates.length > 0
                  ? DirectionList
                  : FootFallController.FinalCoordinates,
              dwellCoordinates: Clist,
              threshold: null,
              footfallType: values.checkbox
                ? values.checkbox.filter((val) => val !== "passerby")
                : values.checkboxdirection,
              dwellCoordinatesEdit: DwellController.FinalCoordinates,
              footfallCoordinatesEdit:
                FootFallDirectionController.FinalCoordinatesEdit
                  ? FootFallDirectionController.FinalCoordinatesEdit
                  : FootFallController.FinalCoordinatesEdit,

              passerby: PasserByCheck,
              //foot:DataController
              //footfall Direction:DwellController
              // cam_images:
              //   DwellController.baseimage !== ""
              //     ? "data:image/png;base64," + DwellController.baseimage
              //     : null,
              cam_images:
                DwellController.baseimage !== ""
                  ? "data:image/png;base64," + DwellController.baseimage
                  : FootFallController.baseimage !== ""
                  ? "data:image/png;base64," + DataController.baseimage
                  : FootFallDirectionController.baseimage !== ""
                  ? "data:image/png;base64," +
                    FootFallDirectionController.baseimage
                  : null,
              // startTime, endTime, option, day, daystartTime, dayendTime

              isSchedule: SchedulerStatus,
              option: SchedulerController.SchedluerType,
              //  startTime: "",
              // endTime: "",
              // day:[],
              // daystartTime: "",
              //  dayendTime: "",
              ...scheduleData,
            },
          ],
        })
        // .post("/cameraEvents/addCameraEvents", {
        //   data: [
        //     {
        //       cameraId: [values.envcameralist],
        //       eventId: [values.envevents],
        //       footfallCoordinates:
        //         FootFallDirectionController.FinalCoordinates.length > 0
        //           ? DirectionList
        //           : DataController.FinalCoordinates,
        //       dwellCoordinates: Clist,

        //       threshold: null,
        //       footfallType: values.checkbox
        //         ? values.checkbox
        //         : values.checkboxdirection,
        //       dwellCoordinatesEdit: DwellController.FinalCoordinates,
        //       footfallCoordinatesEdit:
        //         FootFallDirectionController.FinalCoordinatesEdit
        //           ? FootFallDirectionController.FinalCoordinatesEdit
        //           : DataController.FinalCoordinatesEdit,
        //       cam_images:
        //         DwellController.baseimage !== ""
        //           ? "data:image/png;base64," + DwellController.baseimage
        //           : null,
        //       // startTime, endTime, option, day, daystartTime, dayendTime

        //       isSchedule: SchedulerStatus,
        //       option: SchedulerController.SchedluerType,
        //       ...scheduleData,
        //     },
        //   ],
        // })
        .then((response) => {
          if (response.data.issuccess === true) {
            message.success(response.data.msg);
            setPageload(false);

            setAddRefresh(AddRefresh + 1);
          } else if (response.data.issuccess === false) {
            message.error(response.data.msg);
            setPageload(false);
          } else {
            message.error(response.data.msg);
            setPageload(false);
          }
        })
        .catch((err) => {
          setPageload(false);
          message.error(err.msg);
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
        });
    }

    basicform.resetFields();
    setDataController(InitialData);
    setDwellController(InitialData);
    setFootFallDirectionController(InitialData);
    setOneTimeSChedule({
      StartDate: "",
      EndDate: "",
      StartTime: "",
      EndTime: "",
    });
    setEveryDaySChedule({
      Day: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      StartTime: "",
      EndTime: "",
    });
    setCustomSChedule({
      Day: [],
      StartTime: "",
      EndTime: "",
    });
    setScheduleData({
      startTime: null,
      endTime: null,
      day: null,
      daystartTime: null,
      dayendTime: null,
    });
    setSchedulerStatus(false);
    setNewENVevent(false);
    ScheduleForm.resetFields();
  };

  // Onfinish for Basic events
  const onFinish = (values) => {
    setPageload(true);
    axiosClient
      .post("/cameraEvents/addCameraEvents", {
        data: [
          {
            cameraId: selectedcamids ? selectedcamids : values.eventsl,
            eventId: allids ? allids : values.eventsl,
            // coordinates: OverallHVPoints.length >= 1 ? OverallHVPoints : HVpoints,
            coordinates: null,
            threshold: values.threshold,
            cam_images: null,
            isSchedule:
              selectedtimes.start_time.length > 0 &&
              selectedtimes.end_time.length > 0
                ? true
                : false,
            startTime: selectedtimes.start_time,
            endTime: selectedtimes.end_time,
          },
        ],
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);
          setAddRefresh(AddRefresh + 1);
          setPageload(false);
        } else {
          message.error(response.data.msg);
          setPageload(false);
        }
      })
      .catch((err) => {
        setPageload(false);
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg);
        }
      });
    form.resetFields();
    setselectedcameralist([]);
    setSelectedevents([]);
  };

  const fetchDropdownOptions = async (id) => {
    axiosClient
      .get("/cameraEvents/getfootfalltype?eventId=" + id)
      .then((response) => {
        if (response.data.issuccess === true && response.data.data.length > 0) {
          const Types = response.data.data.map((item) => ({
            label: item.footfallTypeName,
            value: item.id,
          }));
          setDropdownOptions(Types);
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg);
        }
      });
  };

  // Onchange of Event select
  async function properties(e) {
    let FormValues = basicform.getFieldsValue();

    if (!FormValues.envcameralist) {
      basicform.resetFields();
      basicform.setFields([
        {
          name: "envcameralist",
          errors: ["Please select "],
        },
      ]);
    }
    if (FormValues.envevents === 13) {
      setFootFallController(InitialData);

      GetSubTypes(FormValues.envevents);
      setCommonEventController((prev) => ({
        ...prev,
        SlectedEvent: FormValues.envevents,
        // DisableCheckbox: false,
      }));
      setFootFallController((prev) => ({
        ...prev,
        DisableCheckbox: false,
        SelectedEventid: FormValues.envevents,
      }));
      setDwellController((prev) => ({
        ...prev,
        DisableCheckbox: false,
        SelectedEventid: FormValues.envevents,
      }));
      setFootFallDirectionController(InitialData);
    }

    if (FormValues.envevents === 5 || 10 || 6) {
      // setDataController(InitialData);

      setCommonEventController((prev) => ({
        ...prev,
        SlectedEvent: FormValues.envevents,
      }));
      getFrameforTresspassing(FormValues.envevents);
      // setDwellController(InitialData);
      // setFootFallDirectionController(InitialData);
    }
    if (FormValues.envevents === 14) {
      GetSubTypesDirection(FormValues.envevents);
      setCommonEventController((prev) => ({
        ...prev,
        SlectedEvent: FormValues.envevents,
      }));
      setFootFallDirectionController((prev) => ({
        ...prev,
        DisableCheckbox: false,
        SelectedEventid: FormValues.envevents,
      }));
      setDwellController(InitialData);
      setDataController(InitialData);

      // getFrameforFootFallDirection(FormValues.envevents);
    }
    if (FormValues.envevents === 3) {
      setShowIntimeOuttimeDropdown(true);
      setShowMarkAreaField(false);
      fetchDropdownOptions(FormValues.envevents);
      return;
    } else {
      setShowIntimeOuttimeDropdown(false);
      setShowMarkAreaField(false);
    }
  }

  function handleIntimeOuttimeChange(value) {
    // Perform necessary actions based on the selected value
    setShowMarkAreaField(true);
  }

  const OpenModaltoMark = () => {
    // if (DataController.id === 13) {
    //   setDataController((prev_values) => ({
    //     ...prev_values,
    //     OpenModalToMark: true,
    //   }));
    // } else {
    setDataController((prev_values) => ({
      ...prev_values,
      OpenModalToMark: true,
    }));
    // }
  };

  const DwellOpenModaltoMark = () => {
    setDwellController((prev_values) => ({
      ...prev_values,
      OpenModalToMark: true,
    }));
  };

  const FootfallOpenModaltoMark = () => {
    setFootFallController((prev_values) => ({
      ...prev_values,
      OpenModalToMark: true,
    }));
  };

  const FootfallDirectionOpenModal = () => {
    setFootFallDirectionController((prev_values) => ({
      ...prev_values,
      OpenModalToMark: true,
    }));
  };

  const CloseModal = () => {
    // setDataController((prev_values) => ({
    //   ...prev_values,
    //   OpenModalToMark: false,
    // }));
    if (DataController.id === 5 || 10 || 6) {
      setDataController((prev) => ({
        ...prev,

        OpenModalToMark: false,
      }));
    }
    if (CommonEventController.SlectedEvent === 13) {
      if (FootFallController.FinalCoordinates.length < 2) {
        Modal.confirm({
          title: "Confirm Close?",
          content:
            DataController.FinalCoordinates.length === 0 ? (
              "You haven't marked any, want to close it anyway? "
            ) : DataController.FinalCoordinates.length === 1 ? (
              "You haven't marked inner area, want to close it anyway? "
            ) : (
              <></>
            ),
          okText: "Confirm",
          okType: "danger",
          onOk: () => {
            setFootFallController((prev) => ({
              ...prev,
              coordinates: [],
              FinalCoordinates: [],
              ImageUpdated: false,
              OpenModalToMark: false,
            }));
          },
        });
      } else {
        setFootFallController((prev) => ({
          ...prev,

          OpenModalToMark: false,
        }));
      }
    } else if (CommonEventController.SlectedEvent === 14) {
      if (FootFallDirectionController.FinalCoordinates.length < 3) {
        Modal.confirm({
          title: "Confirm Close?",
          content:
            FootFallDirectionController.FinalCoordinates.length === 0 ? (
              "You haven't marked any, want to close it anyway? "
            ) : FootFallDirectionController.FinalCoordinates.length <= 2 ? (
              "At least two directions have to be marked. want to close it anyway? "
            ) : (
              <></>
            ),
          okText: "Confirm",
          okType: "danger",
          onOk: () => {
            setFootFallDirectionController((prev) => ({
              ...prev,
              coordinates: [],
              FinalCoordinates: [],
              ImageUpdated: false,
              OpenModalToMark: false,
            }));
          },
        });
      } else {
        setFootFallDirectionController((prev) => ({
          ...prev,

          OpenModalToMark: false,
        }));
      }
    }
  };

  const CloseModalDwell = () => {
    setDwellController((prev_values) => ({
      ...prev_values,
      OpenModalToMark: false,
    }));
  };

  // const disabledHours = () => {
  //   const hours = [];
  //   for (let i = 0; i < moment().hour() + 1; i += 1) hours.push(i);
  //   return hours;
  // };

  const handlecreateevent = () => {
    setNewEventModalOpen(true);
  };

  const handlecreateeventclose = () => {
    setNewEventModalOpen(false);
    setSelectedevents([]);
    setselectedtimes({
      start_time: [],
      end_time: [],
      count: 1,
    });
  };

  const EventConfig = () => {
    axiosClient
      .post("/report/addmanualevent")
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);
          setAddRefresh(AddRefresh + 1);
          setPageload(false);
        } else {
          message.error(response.data.msg);
          setPageload(false);
        }
      })
      .catch((err) => {
        setPageload(false);
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg ? err.msg : "Invalid url");
        }
      });
  };

  const HandleMenu = (e) => {
    if (e.key === "1") {
      handlecreateevent();
    } else if (e.key === "2") {
      HandleNewEnveventopen();
    } else if (e.key === "3") {
      Modal.confirm({
        title: "Do you want to save the events?",

        content: "Live Events will be saved",
        onOk() {
          EventConfig();
        },
        onCancel() {
        },
      });
    } else {
    }
  };

  const HandleNewEnveventopen = () => {
    setNewENVevent(true);
    basicform.resetFields();
    setShowIntimeOuttimeDropdown(false);
    setShowMarkAreaField(false);
    basicform.resetFields(["envevents"]);
    // properties();
  };

  const HandleNewENVeventclose = () => {
    setNewENVevent(false);
    ResetForm();
    setShowIntimeOuttimeDropdown(false);
    setShowMarkAreaField(false);
  };

  const ResetForm = () => {
    basicform.resetFields();
    setDataController(InitialData);
    setDwellController(InitialData);
    setFootFallDirectionController(InitialData);
    setFootFallController(InitialData);
  };

  const onChange_StartTime = (time, timeString) => {
    setselectedtimes({
      ...selectedtimes,
      start_time: [...selectedtimes.start_time, timeString],
    });
  };

  const onChange_EndTime = (time, timeString) => {
    setselectedtimes({
      ...selectedtimes,
      end_time: [...selectedtimes.end_time, timeString],
    });
  };

  const timepickerr = (i) => {
    return (
      <TimePicker
        key={i}
        placeholder="End Time"
        format={"HH"}
        disabledHours={() => {
          const hours = [];
          const currentHour = moment(selectedtimes.start_time[i], "HH").hour();

          for (let ii = 0; ii <= currentHour; ii++) {
            hours.push(ii);
          }

          return hours;
        }}
        disabled={selectedtimes.start_time[i] ? false : true}
        onChange={onChange_EndTime}
        value={handle_hour_endTime(i)}
        allowClear={false}
      />
    );
  };

  const handle_hour = (count) => {
    if (selectedtimes.start_time[count]) {
      return moment()
        .startOf("day")
        .add(selectedtimes.start_time[count], "hours");
    }
  };

  const handle_hour_endTime = (count) => {
    if (selectedtimes.end_time[count]) {
      return moment()
        .startOf("day")
        .add(selectedtimes.end_time[count], "hours");
    }
  };

  const List_time = () => {
    if (selectedtimes.count >= 1) {
      let list = [];

      for (var i = 0; i < selectedtimes.count; i += 1) {
        list.push(
          <Flex
            key={selectedtimes}
            justify="space-evenly"
            style={{ marginBottom: "10px" }}
          >
            <TimePicker
              value={handle_hour(i)}
              placeholder="Start Time"
              format={"HH"}
              onChange={onChange_StartTime}
              allowClear={false}
            />
            {timepickerr(i)}
          </Flex>
        );
      }
      return list;
    }
  };

  const Handle_Add_time = () => {
    if (
      selectedtimes.start_time.length !== selectedtimes.count &&
      selectedtimes.end_time.length !== selectedtimes.count
    ) {
      message.info("start time or end time is missing");
    } else {
      if (selectedtimes.count < 5) {
        setselectedtimes({
          ...selectedtimes,
          count: selectedtimes.count + 1,
        });
      } else {
        message.warning("cannot add morethan 5 schedules");
      }
    }
  };

  const Handle_minus_time = () => {
    if (selectedtimes.count > 5) {
      setselectedtimes({
        ...selectedtimes,
        count: 5,
      });
    } else {
      setselectedtimes({
        count: selectedtimes.count - 1,
        start_time: selectedtimes.start_time.slice(0, -1),
        end_time: selectedtimes.end_time.slice(0, -1),
      });
    }
  };

  const repeater = (coordinates, index) => {
    let camdata = {
      id: coordinates + index,
      title: "Mark",
      shape: "poly",
      name: "1",
      preFillColor: "rgba(161, 161, 156, 0.7)",
      fillColor: "#eab54d4d",
      strokeColor: "red",
      coords: coordinates,
    };
    setEditEnvController((prev) => ({
      ...prev,
      Mapperformat: [...prev.Mapperformat, camdata],
    }));
  };

  // const mapperformatter = (data) => {
  //   setPageload(true);

  //   data.map((item, index) => {
  //     repeater(item, index);
  //   });
  //   setPageload(false);
  // };

  // const OnEdit = (id) => {
  //   setPageload(true);

  //   setEditEnvController((prev) => ({ ...prev, loading: true }));

  //   axiosClient
  //     .get("/cameraEvents/getCameraEventsBasedOnEventId?id=" + id)
  //     .then(async (response) => {
  //       if (response.data.issuccess === true) {
  //         // setCameralistBasic(response.data.data);
  //         if (response.data.data.coordinates.length > 0) {
  //           setEditEnvController((prev) => ({
  //             ...prev,
  //             startedit: true,
  //             baseimage: response.data.data.cam_images,
  //             resData: response.data.data,
  //           }));
  //           mapperformatter(response.data.data.coordinates);
  //           setEditEnvController((prev) => ({ ...prev, loading: false }));
  //           setAddRefresh(AddRefresh + 1);
  //           setPageload(false);
  //         }
  //       } else {
  //         setPageload(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setEditEnvController((prev) => ({ ...prev, loading: false }));
  //       setPageload(false);
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // };

  const OnDeleteBasic = (camEventId, id) => {
    Modal.confirm({
      title: "Confirm Delete?",
      content: "Event will be deleted",

      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        axiosClient
          .delete(
            "/cameraEvents/deleteCameraEvents?id=" +
              camEventId +
              "&option=" +
              null +
              "&serverId=" +
              id +
              "&scheduleId=[]"
          )
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              // setDeleteRefresh(DeleteRefresh + 1);
              setAddRefresh(AddRefresh + 1);
            } else {
            }
          })
          .catch((err) => {
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
  };

  // const OnEdit = (values) => {
  //   setediteventdata([]);

  //   seteditcamname(values.cameraName);
  //   seteditcamid(values.cameraid);
  //   values.eventdata.map((item) => {
  //     let evtdata = {
  //       label: item.eventname,
  //       value: item.eventid,
  //     };
  //     setediteventdata((cameralist) => [...cameralist, evtdata]);
  //   });
  //   form.setFieldValue({ camname: values.cameraName });
  //   setEditModalopen(true);
  // };

  const submitedit = () => {
    axiosClient
      .put("/cameraEvents/editCameraEvents", {
        id: EditEnvController.resData.id,
        image: EditEnvController.baseimage,
        coordinates: EditEnvController.coords,
        threshold: null,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          // setCameralistBasic(response.data.data);

          message.error(response.data.msg);
        } else {
        }
      })
      .catch((err) => {
        setEditEnvController((prev) => ({ ...prev, loading: false }));
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const EditCoords = (record) => {
    if (EditEnvController.Mapperformat.length > 1) {
      const removed = EditEnvController.coords.filter(
        (x) => JSON.stringify(x) !== JSON.stringify(record.scaledCoords)
      );

      setEditEnvController((prev) => ({ ...prev, coords: [] }));

      if (removed.length > 0) {
        removed.map((item, index) => {
          repeater(item, index);
        });
        setEditEnvController((prev) => ({ ...prev, coords: removed }));
      }
    } else {
      message.error("Atleast one area has to be Marked");
    }
  };

  const closeEventeditENV = () => {
    setEditEnvController(editData);
  };

  // const getFrameforFootFallDirection = async (id) => {
  //   setFootFallDirectionController((prev_values) => ({
  //     ...prev_values,
  //     loading: true,
  //     SelectedEventid: id,
  //   }));

  //   let CamRTSP = null;

  //   cameralist.map((item) => {
  //     if (item.id === CommonEventController.SelectedCamera) {
  //       CamRTSP = item;
  //     }
  //   });
  //   // fetching image to mark region for Trespassing from socket
  //   if (
  //     CamRTSP &&
  //     CamRTSP.RTSP !== null &&
  //     CamRTSP.RTSP !== "" &&
  //     CamRTSP.RTSP !== undefined
  //   ) {
  //     await axiosClient
  //       .get(
  //         "/cameraEvents/getCameraFramefromAI?RTSP=" +
  //           encodeURIComponent(CamRTSP.RTSP.trim()) +
  //           "&cameraid=" +
  //           CamRTSP.id
  //       )
  //       .then((response) => {
  //         if (response.data.issuccess === true) {
  //           function update(response) {
  //             setFootFallDirectionController((prevValues) => ({
  //               ...prevValues,
  //               id: id,
  //               baseimage: response.msg.frame,
  //               Marker: true,
  //               ImagetoMark: "data:image/png;base64," + response.msg.frame,
  //               FrameReceived: true,
  //               loading: false,
  //             }));
  //           }
  //           update(response.data);
  //         } else {
  //           message.error("image unavailable");
  //           setFootFallDirectionController(InitialData);
  //           basicform.resetFields();
  //         }
  //       })
  //       .catch((rr) => {
  //         message.error("image unavailable");
  //         setFootFallDirectionController(InitialData);
  //         basicform.resetFields();
  //       });
  //   }
  // };

  const getFrameforTresspassing = async (id) => {
    setDataController((prev_values) => ({
      ...prev_values,
      loading: true,
    }));

    let CamRTSP = null;

    cameralist.map((item) => {
      if (item.id === CommonEventController.SelectedCamera) {
        CamRTSP = item;
      }
    });
    // fetching image to mark region for Trespassing from socket
    if (
      CamRTSP &&
      CamRTSP.RTSP !== null &&
      CamRTSP.RTSP !== "" &&
      CamRTSP.RTSP !== undefined
    ) {
      await axiosClient
        .get(
          "/cameraEvents/getCameraFramefromAI?RTSP=" +
            encodeURIComponent(CamRTSP.RTSP.trim()) +
            "&cameraid=" +
            CamRTSP.id
        )
        .then((response) => {
          if (response.data.issuccess === true) {
            function update(response) {
              setDataController((prevValues) => ({
                ...prevValues,
                id: id,
                baseimage: response.msg.frame,
                Marker: true,
                ImagetoMark: "data:image/png;base64," + response.msg.frame,
                FrameReceived: true,
                loading: false,
              }));
            }
            update(response.data);
          } else {
            message.error("image unavailable");
            setDataController(InitialData);
            basicform.resetFields();
          }
        })
        .catch((rr) => {
          message.error("image unavailable");
          setDataController(InitialData);
          basicform.resetFields();
        });
    }
  };

  // Handle checkbox function
  // const HandleCheckbox = async () => {
  //   let FormValues = basicform.getFieldsValue();

  //   if (!FormValues.envcameralist && !FormValues.envevents) {
  //     basicform.setFields([
  //       {
  //         name: "envcameralist",
  //         errors: ["Please select "],
  //       },
  //       {
  //         name: "envevents",
  //         errors: ["Please select "],
  //       },
  //     ]);
  //   } else {
  //     if (FormValues.checkbox.includes(1) || FormValues.checkbox.includes(2)) {
  //       if (
  //         FormValues.envevents !== null &&
  //         FormValues.envevents !== undefined
  //       ) {
  //         setDataController((prev_values) => ({
  //           ...prev_values,
  //           loading: true,
  //         }));

  //         let CamRTSP = null;

  //         cameralist.map((item) => {
  //           if (item.id === CommonEventController.SelectedCamera) {
  //             CamRTSP = item;
  //           }
  //         });
  //         // fetching image to mark region for Trespassing from socket
  //         if (
  //           CamRTSP &&
  //           CamRTSP.RTSP !== null &&
  //           CamRTSP.RTSP !== "" &&
  //           CamRTSP.RTSP !== undefined
  //         ) {
  //           await axiosClient
  //             .get(
  //               "/cameraEvents/getCameraFramefromAI?RTSP=" +
  //                 encodeURIComponent(CamRTSP.RTSP.trim()) +
  //                 "&cameraid=" +
  //                 CamRTSP.id
  //             )
  //             .then(async (response) => {
  //               if (response.data.issuccess === true) {
  //                 function update(response) {
  //                   setDataController((prevValues) => ({
  //                     ...prevValues,
  //                     id: FormValues.envevents,
  //                     Marker: true,
  //                     baseimage: response.msg.frame,
  //                     ImagetoMark:
  //                       "data:image/png;base64," + response.msg.frame,
  //                     FrameReceived: true,
  //                     loading: false,
  //                   }));
  //                 }
  //                 update(response.data);
  //               } else {
  //                 message.error("image unavailable");
  //                 // setDataController(InitialData);
  //                 basicform.resetFields();
  //               }
  //             })
  //             .catch((rr) => {
  //               message.error("image unavailable");
  //               // setDataController(InitialData);
  //               basicform.resetFields();
  //             });
  //         }
  //       } else {
  //         // setDataController(InitialData);
  //       }
  //     }
  //     if (FormValues.checkbox.includes(3)) {
  //       if (
  //         FormValues.envevents !== null &&
  //         FormValues.envevents !== undefined
  //       ) {
  //         setDwellController((prev_values) => ({
  //           ...prev_values,
  //           loading: true,
  //         }));

  //         let CamRTSP = null;

  //         cameralist.map((item) => {
  //           if (item.id === CommonEventController.SelectedCamera) {
  //             CamRTSP = item;
  //           }
  //         });
  //         // fetching image to mark region for Trespassing from socket
  //         if (
  //           CamRTSP &&
  //           CamRTSP.RTSP !== null &&
  //           CamRTSP.RTSP !== "" &&
  //           CamRTSP.RTSP !== undefined
  //         ) {
  //           await axiosClient
  //             .get(
  //               "/cameraEvents/getCameraFramefromAI?RTSP=" +
  //                 encodeURIComponent(CamRTSP.RTSP.trim()) +
  //                 "&cameraid=" +
  //                 CamRTSP.id
  //             )
  //             .then((response) => {
  //               if (response.data.issuccess === true) {
  //                 function update(response) {
  //                   setDwellController((prevValues) => ({
  //                     ...prevValues,
  //                     id: FormValues.envevents,
  //                     baseimage: response.msg.frame,
  //                     Marker: true,
  //                     ImagetoMark:
  //                       "data:image/png;base64," + response.msg.frame,
  //                     FrameReceived: true,
  //                     loading: false,
  //                   }));
  //                 }
  //                 update(response.data);
  //               } else {
  //                 message.error("image unavailable");
  //                 setDwellController(InitialData);
  //                 basicform.resetFields();
  //               }
  //             })
  //             .catch((rr) => {
  //               message.error("image unavailable");
  //               setDwellController(InitialData);
  //               basicform.resetFields();
  //             });
  //         }
  //       } else {
  //         setDwellController(InitialData);
  //       }
  //     }
  //     if (
  //       !FormValues.checkbox.includes(1) &&
  //       !FormValues.checkbox.includes(2)
  //     ) {
  //       setDataController((prevValues) => ({
  //         ...prevValues,
  //         id: FormValues.envevents,
  //         baseimage: "",
  //         Marker: false,
  //         ImagetoMark: "",
  //         FrameReceived: false,
  //         loading: false,
  //       }));
  //     }
  //     if (!FormValues.checkbox.includes(3)) {
  //       setDwellController((prevValues) => ({
  //         ...prevValues,
  //         id: FormValues.envevents,
  //         baseimage: "",
  //         Marker: false,
  //         ImagetoMark: "",
  //         FrameReceived: false,
  //         loading: false,
  //       }));
  //     }
  //   }
  // };
  
  // Handle checkbox function
  
  const HandleCheckbox = async () => {
   
    let FormValues = basicform.getFieldsValue();
    if (FormValues.checkbox.includes("passerby")) {
      setPasserByCheck(true);
      if (FormValues.envevents !== null && FormValues.envevents !== undefined) {
        setFootFallController((prev_values) => ({
          ...prev_values,
          loading: true,
        }));

        let CamRTSP = null;

        cameralist.map((item) => {
          if (item.id === CommonEventController.SelectedCamera) {
            CamRTSP = item;
          }
        });

        if (
          CamRTSP &&
          CamRTSP.RTSP !== null &&
          CamRTSP.RTSP !== "" &&
          CamRTSP.RTSP !== undefined
        ) {
          axiosClient
            .get(
              "/cameraEvents/getCameraFramefromAI?RTSP=" +
                encodeURIComponent(CamRTSP.RTSP.trim()) +
                "&cameraid=" +
                CamRTSP.id
            )
            .then(async (response) => {
              if (response.data.issuccess === true) {
                function update(response) {
                  setFootFallController((prevValues) => ({
                    ...prevValues,
                    id: FormValues.envevents,
                    Marker: true,
                    baseimage: response.msg.frame,
                    ImagetoMark: "data:image/png;base64," + response.msg.frame,
                    FrameReceived: true,
                    loading: false,
                  }));
                }
                update(response.data);
              } else {
                message.error("image unavailable");
                // setDataController(InitialData);
                basicform.resetFields();
              }
            })
            .catch((rr) => {
              message.error("image unavailable");
              // setDataController(InitialData);
              basicform.resetFields();
            });
        }
      }
    } else {    
      setPasserByCheck(false);
    }
    FormValues.checkbox = FormValues.checkbox.filter(
      (value) => value !== "passerby"
    );


    if (!FormValues.envcameralist && !FormValues.envevents) {
      basicform.setFields([
        {
          name: "envcameralist",
          errors: ["Please select "],
        },
        {
          name: "envevents",
          errors: ["Please select "],
        },
      ]);
    } else {
      if (FormValues.checkbox.includes(1) || FormValues.checkbox.includes(2)) {
        //setShowTimeFields(true);
        if (
          FormValues.envevents !== null &&
          FormValues.envevents !== undefined
        ) {
          setFootFallController((prev_values) => ({
            ...prev_values,
            loading: true,
          }));

          let CamRTSP = null;

          cameralist.map((item) => {
            if (item.id === CommonEventController.SelectedCamera) {
              CamRTSP = item;
            }
          });
          // fetching image to mark region for Trespassing from socket
          if (
            CamRTSP &&
            CamRTSP.RTSP !== null &&
            CamRTSP.RTSP !== "" &&
            CamRTSP.RTSP !== undefined
          ) {
            await axiosClient
              .get(
                "/cameraEvents/getCameraFramefromAI?RTSP=" +
                  encodeURIComponent(CamRTSP.RTSP.trim()) +
                  "&cameraid=" +
                  CamRTSP.id
              )
              .then(async (response) => {
                if (response.data.issuccess === true) {
                  function update(response) {
                    setFootFallController((prevValues) => ({
                      ...prevValues,
                      id: FormValues.envevents,
                      Marker: true,
                      baseimage: response.msg.frame,
                      ImagetoMark:
                        "data:image/png;base64," + response.msg.frame,
                      FrameReceived: true,
                      loading: false,
                    }));
                  }
                  update(response.data);
                } else {
                  message.error("image unavailable");
                  // setDataController(InitialData);
                  basicform.resetFields();
                }
              })
              .catch((rr) => {
                message.error("image unavailable");
                // setDataController(InitialData);
                basicform.resetFields();
              });
          }
        } else {
          // setDataController(InitialData);
        }
      }
      if (FormValues.checkbox.includes(3)) {
        if (
          FormValues.envevents !== null &&
          FormValues.envevents !== undefined
        ) {
          setDwellController((prev_values) => ({
            ...prev_values,
            loading: true,
          }));

          let CamRTSP = null;

          cameralist.map((item) => {
            if (item.id === CommonEventController.SelectedCamera) {
              CamRTSP = item;
            }
          });
          // fetching image to mark region for Trespassing from socket
          if (
            CamRTSP &&
            CamRTSP.RTSP !== null &&
            CamRTSP.RTSP !== "" &&
            CamRTSP.RTSP !== undefined
          ) {
            await axiosClient
              .get(
                "/cameraEvents/getCameraFramefromAI?RTSP=" +
                  encodeURIComponent(CamRTSP.RTSP.trim()) +
                  "&cameraid=" +
                  CamRTSP.id
              )
              .then((response) => {
                if (response.data.issuccess === true) {
                  function update(response) {
                    setDwellController((prevValues) => ({
                      ...prevValues,
                      id: FormValues.envevents,
                      baseimage: response.msg.frame,
                      Marker: true,
                      ImagetoMark:
                        "data:image/png;base64," + response.msg.frame,
                      FrameReceived: true,
                      loading: false,
                    }));
                  }
                  update(response.data);
                } else {
                  message.error("image unavailable");
                  setDwellController(InitialData);
                  basicform.resetFields();
                }
              })
              .catch((rr) => {
                message.error("image unavailable");
                setDwellController(InitialData);
                basicform.resetFields();
              });
          }
        } else {
          setDwellController(InitialData);
        }
      }
      if (
        !FormValues.checkbox.includes(1) &&
        !FormValues.checkbox.includes(2)
      ) {
        setFootFallController((prevValues) => ({
          ...prevValues,
          id: FormValues.envevents,
          baseimage: "",
          Marker: false,
          ImagetoMark: "",
          FrameReceived: false,
          loading: false,
        }));
      }
      if (!FormValues.checkbox.includes(3)) {
        setDwellController((prevValues) => ({
          ...prevValues,
          id: FormValues.envevents,
          baseimage: "",
          Marker: false,
          ImagetoMark: "",
          FrameReceived: false,
          loading: false,
        }));
      }
    }
  };

  // Handle checkbox function
  const HandleCheckboxDirection = async () => {
    let FormValues = basicform.getFieldsValue();

    if (!FormValues.envcameralist && !FormValues.envevents) {
      basicform.setFields([
        {
          name: "envcameralist",
          errors: ["Please select "],
        },
        {
          name: "envevents",
          errors: ["Please select "],
        },
      ]);
    } else {
      if (FormValues.checkboxdirection.includes(4)) {
        if (
          FormValues.envevents !== null &&
          FormValues.envevents !== undefined
        ) {
          setFootFallDirectionController((prev_values) => ({
            ...prev_values,
            loading: true,
            SelectedEventid: FormValues.envevents,
          }));

          let CamRTSP = null;

          cameralist.map((item) => {
            if (item.id === CommonEventController.SelectedCamera) {
              CamRTSP = item;
            }
          });
          // fetching image to mark region for Trespassing from socket
          if (
            CamRTSP &&
            CamRTSP.RTSP !== null &&
            CamRTSP.RTSP !== "" &&
            CamRTSP.RTSP !== undefined
          ) {
            await axiosClient
              .get(
                "/cameraEvents/getCameraFramefromAI?RTSP=" +
                  encodeURIComponent(CamRTSP.RTSP.trim()) +
                  "&cameraid=" +
                  CamRTSP.id
              )
              .then((response) => {
                if (response.data.issuccess === true) {
                  function update(response) {
                    setFootFallDirectionController((prevValues) => ({
                      ...prevValues,
                      id: FormValues.envevents,
                      baseimage: response.msg.frame,
                      Marker: true,
                      ImagetoMark:
                        "data:image/png;base64," + response.msg.frame,
                      FrameReceived: true,
                      loading: false,
                    }));
                  }
                  update(response.data);
                } else {
                  message.error("image unavailable");
                  setFootFallDirectionController(InitialData);
                  basicform.resetFields();
                }
              })
              .catch((rr) => {
                message.error("image unavailable");
                setFootFallDirectionController(InitialData);
                basicform.resetFields();
              });
          }
        } else {
          // setDataController(InitialData);
        }
      }

      if (!FormValues.checkboxdirection.includes(4)) {
        setFootFallDirectionController((prevValues) => ({
          ...prevValues,
          id: FormValues.envevents,
          baseimage: "",
          Marker: false,
          ImagetoMark: "",
          FrameReceived: false,
          loading: false,
        }));
      }
      if (!FormValues.checkboxdirection.includes(5)) {
        setDwellController((prevValues) => ({
          ...prevValues,
          id: FormValues.envevents,
          baseimage: "",
          Marker: false,
          ImagetoMark: "",
          FrameReceived: false,
          loading: false,
        }));
      }
    }
  };

  const SchedulerCheckbox = (e) => {
    ScheduleForm.resetFields();
    setSchedulerStatus(e.target.checked);
    if (e.target.checked) {
      OpenScheduler();
    } else {
      CloseScheduler();
      setOneTimeSChedule({
        StartDate: "",
        EndDate: "",
        StartTime: "",
        EndTime: "",
      });
      setEveryDaySChedule({
        Day: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        StartTime: "",
        EndTime: "",
      });
      setCustomSChedule({
        Day: [],
        StartTime: "",
        EndTime: "",
      });
    }
  };

  const SChedulerCancel = () => {
    setScheduleDrawerContoller((prev) => ({
      ...prev,
      OpenDrawer: false,
      SchedulerSelected: false,
    }));
    setSchedulerStatus(false);
  };

  // const SChedulerSubmit = () => {
  //   testFinish();
  //   ScheduleForm.resetFields();
  //   setScheduleDrawerContoller((prev) => ({
  //     ...prev,
  //     OpenDrawer: false,
  //     SchedulerSelected: true,
  //   }));
  // };

  const OpenScheduler = () => {
    ScheduleForm.resetFields();
    setScheduleDrawerContoller((prev) => ({
      ...prev,
      OpenDrawer: true,
      SchedulerSelected: true,
    }));
  };

  const CloseScheduler = () => {
    setScheduleDrawerContoller((prev) => ({
      ...prev,
      OpenDrawer: false,
      SchedulerSelected: false,
    }));
  };

  const ScheduleSlector = (e) => {
    setSchedulerController((prev) => ({
      ...prev,
      SchedluerType: e.target.value,
    }));
    ScheduleForm.resetFields();
    setOneTimeSChedule((...prev) => ({
      ...prev,
      StartDate: "",
      EndDate: "",
    }));
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTimeStart = () => {
    const isToday =
      dayjs(OneTimeSChedule.StartDate).format("YYYY-MM-DD") ===
      dayjs().format("YYYY-MM-DD");

    if (isToday) {
      const currentHour = dayjs().hour();
      const currentMinute = dayjs().minute();

      const startHour = currentMinute > 0 ? currentHour + 1 : currentHour;

      return {
        disabledHours: () => Array.from({ length: startHour }, (_, i) => i),
      };
    }

    return { disabledHours: () => [] };
  };

  const disabledDateTimeEnd = () => {
    const isToday =
      dayjs(OneTimeSChedule.EndDate).format("DD-MM-YYYY") ===
      dayjs().format("DD-MM-YYYY");

    if (isToday) {
      const currentHour = dayjs().hour();

      return { disabledHours: () => range(0, currentHour + 1) };
    }
    return { disabledHours: () => [] };
  };

  const OnChangeDay = (value) => {
    const selectedDays = value
      .map((val, index) => (val === 1 ? weekdays[index] : null))
      .filter((day) => day !== null);
    setCustomSChedule((prev) => ({
      ...prev,
      Day: selectedDays,
    }));
  };

  const disableStartDate = (current) => {
    // Disable dates before today and after selected EndDate
    if (!OneTimeSChedule.EndDate) {
      return current && current.isBefore(moment().subtract(1, "day"));
    }
    return (
      current &&
      (current.isBefore(moment().subtract(1, "day")) ||
        current.isBefore(moment(OneTimeSChedule.EndDate)))
    );
  };

  const disableEndDate = (current) => {
    // Disable dates before today and before selected StartDate
    if (!OneTimeSChedule.StartDate) {
      return current && current.isBefore(moment().subtract(1, "day"));
    }
    return (
      current &&
      (current.isBefore(moment().subtract(1, "day")) ||
        current.isBefore(moment(OneTimeSChedule.StartDate)))
    );
  };

  const getEventTimeTableForMonth = () => {
    axiosClient
      .get(`/cameraEvents/geteventtimetableformonth?month=${Month}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          setSchedulerCount(response?.data?.data);
        } else {
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // const getListData = (value) => {
  //   let listData;
  //   const dateString = value.format("YYYY-MM-DD");
  //   const countData = SchedulerCount.find((item) => item.date === dateString);

  //   if (countData) {
  //     const count = countData.eventCount;
  //     listData = [
  //       {
  //         type: count > 0 ? "success" : "warning",
  //         content: count > 0 ? `${count} Schedule` : "No Schedule",
  //       },
  //     ];
  //   }

  //   return listData || [];
  // };

  // const dateCellRender = (currentDate) => {
  //   const listData = getListData(currentDate);
  //   const isSpecialDate = listData.length > 0;
  //   const cellStyle = isSpecialDate
  //     ? { backgroundColor: "#a3d1f5", padding: "5px", borderRadius: "5px" }
  //     : {};

  //   return (
  //     <div style={cellStyle}>
  //       <div
  //         className="events"
  //         style={{ listStyleType: "none", margin: 0, padding: 0 }}
  //       >
  //         {listData.map((item, index) => (
  //           <p key={index}>
  //             <Badge
  //               xs={8}
  //               sm={5}
  //               md={24}
  //               lg={2}
  //               status={item.type}
  //               text={item.content}
  //             />
  //           </p>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  // const handleModalClose = () => {
  //   setActiveKey1({});
  //   setSchedulerModel(false);
  //   setSchedulerSchedulerView(null); // Clear the event data when closing the modal
  // };

  // const getEventTimeForSpecificDate = (value) => {
  //   axiosClient
  //     .get(
  //       `/cameraEvents/geteventtimetableforspecificdate?date=${dayjs(
  //         value
  //       ).format("YYYY-MM-DD")}`
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         if (response.data.data !== null && response.data.data.length > 0) {
  //           setSchedulerSchedulerView(response.data.data);
  //           setSchedulerModel(true);
  //         } else if (
  //           response.data.data === null ||
  //           response.data.data === undefined ||
  //           response.data.data.length <= 0
  //         ) {
  //           message.error("No Data Available");
  //         }

  //         //setSchedulerModel(true)
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //     });
  // };

  // const handlePanelChange = (cameraName, key) => {
  //   // Toggle logic: If the same panel is clicked, it should collapse
  //   setActiveKey1((prevKeys) => ({
  //     ...prevKeys,
  //     [cameraName]: prevKeys[cameraName] === key ? null : key, // Toggle activeKey
  //   }));
  // };

  const convertDaysToValueFormat = (daysArray) => {
    const weekArray = [0, 0, 0, 0, 0, 0, 0];
    const dayMapping = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6,
    };

    daysArray.forEach((day) => {
      const dayName = day[0];
      const index = dayMapping[dayName];
      if (index !== undefined) {
        weekArray[index] = 1;
      }
    });

    return weekArray;
  };

  // const renderEventOptions = (options) => {
  //   return Object.keys(options).map((key) => {
  //     if (options[key].length > 0) {
  //       let title = "";
  //       switch (key) {
  //         case "option1":
  //           title = "One Time";
  //           break;
  //         case "option2":
  //           title = "Every Day";
  //           break;
  //         case "option3":
  //           title = "Custom Day";
  //           break;
  //         default:
  //           title = key.toUpperCase();
  //       }

  //       return (
  //         // <div key={key}>
  //         //   <Title level={5}>Schedule Type: {title}</Title>
  //         //   {options[key].map((option) => (
  //         //     <div key={option.id} style={{ marginBottom: "1rem" }}>
  //         //       <p>Start Time: {option.dayendTime}</p>
  //         //       <p>End Time: {option.dayendTime}</p>
  //         //       <p>Day: {option.day || "N/A"}</p>
  //         //     </div>
  //         //   ))}
  //         // </div>
  //         <div key={key}>
  //           <Title level={5}>Schedule Type: {title}</Title>
  //           {options[key].map((option) => (
  //             <div key={option.id} style={{ marginBottom: "1rem" }}>
  //               {key === "option1" ? (
  //                 <>
  //                   <p>
  //                     Start Time:{" "}
  //                     {moment(option.startTime).format("YYYY-MM-DD HH:mm:ss")}
  //                   </p>
  //                   <p>
  //                     End Time:{" "}
  //                     {moment(option.endTime).format("YYYY-MM-DD HH:mm:ss")}
  //                   </p>
  //                 </>
  //               ) : (
  //                 <>
  //                   <p>Start Time: {option.daystartTime}</p>
  //                   <p>End Time: {option.dayendTime}</p>
  //                   <p>Day: {option.day || "N/A"}</p>
  //                 </>
  //               )}
  //             </div>
  //           ))}
  //         </div>
  //       );
  //     }
  //     return null;
  //   });
  // };

  // const convertDaysToValueFormat = (daysArray) => {
  //   // Initialize an array with 7 elements, all set to 0 (representing Sunday to Saturday)
  //   const weekArray = [0, 0, 0, 0, 0, 0, 0];

  //   // Create a mapping of days to indices
  //   const dayMapping = {
  //     Monday: 0,
  //     Tuesday: 1,
  //     Wednesday: 2,
  //     Thursday: 3,
  //     Friday: 4,
  //     Saturday: 5,
  //     Sunday: 6,
  //   };

  //   // Iterate over the response array and set the corresponding index in weekArray to 1
  //   daysArray.forEach((day) => {
  //     const dayName = day[0]; // Get the day name from the array
  //     const index = dayMapping[dayName]; // Get the index from the dayMapping
  //     if (index !== undefined) {
  //       weekArray[index] = 1; // Set the corresponding index to 1
  //     }
  //   });

  //   return weekArray;
  // };
  
  const compareOptions = (option, Events) => {
    let matchedOptionKey = null;
    let matchedOptionNumber = null;

    Events.option.forEach((optionGroup) => {
      Object.keys(optionGroup).forEach((key) => {
        optionGroup[key].forEach((eventOption) => {
          if (
            eventOption.scheduleId === option.scheduleId &&
            eventOption.daystartTime === option.daystartTime &&
            eventOption.dayendTime === option.dayendTime
          ) {
            matchedOptionKey = key;
          }
        });
      });
    });

    switch (matchedOptionKey) {
      case "option1":
        matchedOptionNumber = 1;
        break;
      case "option2":
        matchedOptionNumber = 2;
        break;
      case "option3":
        matchedOptionNumber = 3;
        break;
      default:
    }

    if (matchedOptionNumber !== null) {
      return matchedOptionNumber;
    } else {

      return null;
    }
  };

  // const OnDeleteScheduler = (option, Events) => {
  //   const serverid = Events.serverDetails[0]?.id;

  //   if (!serverid) {
  //     message.error("No server found.");
  //     return;
  //   }

  //   const matchedOptionNumber = compareOptions(
  //     option,
  //     Events,
  //     Events.serverDetails
  //   );

  //   if (matchedOptionNumber === 3) {
  //     handleOption2Delete(option, Events, matchedOptionNumber, serverid);
  //   } else {
  //     handleDeleteWithoutCheckbox(
  //       option,
  //       Events,
  //       matchedOptionNumber,
  //       serverid
  //     );
  //   }
  // };

  // const handleOption2Delete = (
  //   option,
  //   Events,
  //   matchedOptionNumber,
  //   serverid
  // ) => {
  //   const dayOptions = option.days.map((day) => ({
  //     label: day[0],
  //     value: day[1],
  //   }));

  //   let CheckedDate = [];

  //   const onDaySelectionChange = (checkedValues) => {
  //     CheckedDate.push(checkedValues);
  //   };

  //   Modal.confirm({
  //     title: "Confirm Delete?",
  //     content: (
  //       <div>
  //         <p>Select the days you want to delete for this schedule:</p>
  //         <Checkbox.Group
  //           options={dayOptions}
  //           defaultValue={CheckedDate}
  //           onChange={onDaySelectionChange}
  //         />
  //       </div>
  //     ),
  //     okText: "Confirm",
  //     okType: "danger",
  //     onOk: () => {
  //       axiosClient
  //         .delete(
  //           "/cameraEvents/deleteCameraEvents?id=" +
  //             Events.camEventId +
  //             "&option=" +
  //             matchedOptionNumber +
  //             "&serverId=" +
  //             serverid +
  //             "&scheduleId=" +
  //             JSON.stringify([...new Set(CheckedDate.flat())])
  //         )
  //         .then((response) => {
  //           if (response.data.issuccess === true) {
  //             message.success(response.data.msg);
  //             setAddRefresh((prev) => prev + 1);
  //           } else {
  //           }
  //         })
  //         .catch((err) => {;
  //           if (err.status === 0) {
  //             message.error("Server error");
  //           } else {
  //             message.error(err.msg);
  //           }
  //         });
  //     },
  //   });
  // };

  const handleDeleteWithoutCheckbox = (
    option,
    Events,
    matchedOptionNumber,
    serverid
  ) => {

    Modal.confirm({
      title: "Confirm Delete?",
      content: `You are about to delete the scheduler for ${
        matchedOptionNumber === 1
          ? "One Time"
          : matchedOptionNumber === 2
          ? "Every Day"
          : "Custom Day"
      }.`,
      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        const scheduleId =
          matchedOptionNumber === 2
            ? JSON.stringify(option.days.map((day) => day[1]))
            : `[${option.scheduleId}]`;

        axiosClient
          .delete(
            "/cameraEvents/deleteCameraEvents?id=" +
              Events.camEventId +
              "&option=" +
              matchedOptionNumber +
              "&scheduleId=" +
              scheduleId
          )
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setAddRefresh((prev) => prev + 1);
            } else {
              message.error(response.data.msg);
            }
          })
          .catch((err) => {
            message.error(err.msg);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
  };

  const OnDeleteScheduler = (option, Events) => {
    const matchedOptionNumber = compareOptions(
      option,
      Events
      // option.serverDetails
    );

    // const specificServerId = getServerId(option, matchedOptionNumber);

    // if (!specificServerId) {
    //   message.error("No server found.");
    //   return;
    // }

    // Handle deletion based on the matched option number
    switch (matchedOptionNumber) {
      case 3:
        // Handle deletion for Custom Day
        handleOption3Delete(
          option,
          Events,
          matchedOptionNumber
          // specificServerId
        );
        break;

      case 2:
        // Handle deletion for Every Day
        handleOption2Delete(
          option,
          Events,
          matchedOptionNumber
          // specificServerId
        );
        break;

      case 1:
        // Handle deletion for One Time
        handleDeleteWithoutCheckbox(
          option,
          Events,
          matchedOptionNumber
          // specificServerId
        );
        break;

      default:
        message.error("Invalid option.");
        break;
    }
  };

  const handleOption2Delete = (
    option,
    Events,
    matchedOptionNumber
    // serverid
  ) => {
    Modal.confirm({
      title: "Confirm Delete?",
      content: "You are about to delete the scheduler for Every Day",
      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        const scheduleId =
          matchedOptionNumber === 2
            ? JSON.stringify(option.days.map((day) => day[1]))
            : `[${option.scheduleId}]`;
        axiosClient
          .delete(
            "/cameraEvents/deleteCameraEvents?id=" +
              Events.camEventId +
              "&option=" +
              matchedOptionNumber +
              // "&serverId=" +
              // [serverid] +
              "&scheduleId=" +
              scheduleId
          )
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setAddRefresh((prev) => prev + 1);
            } else {
              message.error(response.data.msg);
            }
          })
          .catch((err) => {
            message.error(err.msg);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
  };

  const handleOption3Delete = (option, Events, matchedOptionNumber) => {

    const dayOptions = option.days.map((day) => ({
      label: day[0], // Day name (e.g., "Monday")
      value: day[1], // Day ID (e.g., 8)
    }));

    let CheckedDate = [];

    const onDaySelectionChange = (checkedValues) => {
      CheckedDate = checkedValues; // Store selected days
    };

    Modal.confirm({
      title: "Confirm Delete?",
      content: (
        <div>
          <p>Select the days you want to delete for this schedule:</p>
          <Checkbox.Group
            options={dayOptions}
            onChange={onDaySelectionChange}
          />
        </div>
      ),
      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        // Collect only valid server IDs based on the selected days
        // const selectedServerIds = [];

        // CheckedDate.forEach((dayValue) => {
        //   const day = option.days.find((d) => d[1] === dayValue);
        //   if (day && day[3]) {
        //     const server = day[3].find((server) => server.id === serverId);
        //     if (server) {
        //       selectedServerIds.push(server.id);
        //     }
        //   }
        // });

        // Check if selectedServerIds is empty
        // if (selectedServerIds.length === 0) {
        //   message.error("No valid server IDs found for the selected days.");
        //   return;
        // }

        // Send selectedServerIds in the delete request
        axiosClient
          .delete(
            "/cameraEvents/deleteCameraEvents?id=" +
              Events.camEventId +
              "&option=" +
              matchedOptionNumber +
              // "&serverIds=" +
              // JSON.stringify(selectedServerIds) + // Send only selected server IDs
              "&scheduleId=" +
              JSON.stringify([...new Set(CheckedDate)])
          )
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setAddRefresh((prev) => prev + 1);
            } else {
              message.error(response.data.msg);
            }
          })
          .catch((err) => {
            message.error(err.msg);
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
  };

  // const renderEventOptionsList = (options, Events) => {
  //   return Object.keys(options).map((key) => {
  //     if (options[key].length > 0) {
  //       let title = "";
  //       switch (key) {
  //         case "option1":
  //           title = "One Time";
  //           break;
  //         case "option2":
  //           title = "Every Day";
  //           break;
  //         case "option3":
  //           title = "Custom Day";
  //           break;
  //         default:
  //           title = key.toUpperCase();
  //       }

  //       return (
  //         <Collapse key={key} style={{ marginBottom: "0.7rem" }}>
  //           <CollapsePanel header={`Schedule Type: ${title}`}>
  //             {options[key].map((option, index) => {
  //               return (
  //                 <>
  //                   {key !== "option1" &&
  //                     option?.days?.some((day) => day[2] !== null) && (
  //                       <Alert
  //                         className="scheduler-alert"
  //                         description={
  //                           <Flex vertical>
  //                             {option?.days?.map((day, index) => {
  //                               const [dayName, dayId, errorLog] = day;
  //                               return (
  //                                 errorLog !== null &&
  //                                 errorLog !== undefined && (
  //                                   <Text key={index}>
  //                                     <VscDebugBreakpointLog /> {dayName} -{" "}
  //                                     {errorLog}
  //                                   </Text>
  //                                 )
  //                               );
  //                             })}
  //                           </Flex>
  //                         }
  //                         type="warning"
  //                         showIcon
  //                       />
  //                     )}
  //                 </>
  //               );
  //             })}

  //             {options[key].map((option, index) => {
  //               const { start, end, days } = option;
  //               return (
  //                 <div key={index}>
  //                   <Row justify="space-between" align="middle">
  //                     {key === "option1" && option.errorLog !== null && (
  //                       <Alert
  //                         className="scheduler-alert"
  //                         message={
  //                           <>
  //                             <Text>
  //                               <VscDebugBreakpointLog />
  //                               {option.errorLog}
  //                             </Text>
  //                           </>
  //                         }
  //                         //description="The scheduler triggers events at a specified start time, monitors them, and ends them at a specified end time."
  //                         type="warning"
  //                         showIcon
  //                       />
  //                     )}

  //                     <Col>
  //                       <Col>
  //                         <p>
  //                           Start Time:{" "}
  //                           {option.startTime
  //                             ? dayjs(option.startTime).format(
  //                                 "DD-MM-YYYY HH:mm"
  //                               )
  //                             : option.daystartTime
  //                             ? dayjs(option.daystartTime, "HH:mm").format(
  //                                 "HH:mm"
  //                               )
  //                             : "N/A"}
  //                         </p>
  //                       </Col>
  //                       <Col>
  //                         <p>
  //                           End Time:{" "}
  //                           {option.endTime
  //                             ? dayjs(option.endTime).format("DD-MM-YYYY HH:mm")
  //                             : option.dayendTime
  //                             ? dayjs(option.dayendTime, "HH:mm").format(
  //                                 "HH:mm"
  //                               )
  //                             : "N/A"}
  //                         </p>
  //                       </Col>
  //                     </Col>
  //                     <Flex gap={"5px"}>
  //                       <span
  //                         style={{
  //                           display: "flex",
  //                           alignItems: "center",
  //                           justifyContent: "center",
  //                         }}
  //                         onClick={() =>
  //                           getImagewithRTSPTableSchduler(Events, option)
  //                         }
  //                       >
  //                         {PreviewDetails.loading &&
  //                         PreviewDetails.LoadRTSP === option ? (
  //                           <>
  //                             <Button type="primary">
  //                               <PulseLoader size={2} color="white" />
  //                             </Button>
  //                           </>
  //                         ) : (
  //                           <Button type="primary">
  //                             <EyeOutlined
  //                               style={{
  //                                 fontSize: "14px",
  //                                 color: "#fffff",
  //                               }}
  //                             />
  //                           </Button>
  //                         )}{" "}
  //                       </span>
  //                       <Button
  //                         //style={{ marginRight: "2rem" }}
  //                         onClick={() =>
  //                           OnDeleteScheduler(
  //                             option,
  //                             Events,
  //                             Events.serverDetails
  //                           )
  //                         }
  //                       >
  //                         <DeleteOutlined
  //                           style={{
  //                             color: "red",
  //                           }}
  //                         />
  //                       </Button>
  //                     </Flex>
  //                   </Row>

  //                   {key === "option3" && (
  //                     <div style={{ width: "200px" }}>
  //                       <Popover
  //                         placement="top"
  //                         title={"text"}
  //                         content={"content"}
  //                       >
  //                         Days:
  //                       </Popover>

  //                       <WeekdaysInput
  //                         style={{
  //                           fontSize: "12px !important",
  //                           padding: "4px 8px !important",
  //                         }}
  //                         value={convertDaysToValueFormat(option.days)}
  //                         showChars={1}
  //                         textCase={"firstToUpper"}
  //                         onChange={(value) => {
  //                           OnChangeDay(value);
  //                         }}
  //                         disabledDays={option.days.map((day) => day[0])}
  //                       />
  //                     </div>
  //                   )}
  //                 </div>
  //               );
  //             })}
  //           </CollapsePanel>
  //         </Collapse>
  //       );
  //     }
  //     return null;
  //   });
  // };

  const renderEventOptionsList = (options, Events) => {
    return Object.keys(options).map((key) => {
      if (options[key].length > 0) {
        let title = "";
        switch (key) {
          case "option1":
            title = "One Time";
            break;
          case "option2":
            title = "Every Day";
            break;
          case "option3":
            title = "Custom Day";
            break;
          default:
            title = key.toUpperCase();
        }

        return (
          <Collapse key={key} style={{ marginBottom: "0.7rem" }}>
            <CollapsePanel header={`Schedule Type: ${title}`}>
              {options[key].map((option, index) => {
                return (
                  <div key={index}>
                    <Row justify="space-between" align="middle">
                      {key === "option1" && option.errorLog !== null && (
                        <Alert
                          className="scheduler-alert"
                          message={
                            <Text>
                              <VscDebugBreakpointLog />
                              {option.errorLog}
                            </Text>
                          }
                          type="warning"
                          showIcon
                        />
                      )}

                      <Col>
                        {/* Separate Server Name Rendering for Each Option Type */}
                        {key === "option1" &&
                          option.serverDetails &&
                          option.serverDetails.length > 0 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p>
                                <Tag
                                  style={{
                                    fontSize: "15px",
                                    marginLeft: "5px",
                                  }}
                                  color="green"
                                >
                                  {option.serverDetails[0].serverName}
                                </Tag>
                              </p>
                              <Flex
                                gap={"5px"}
                                style={{
                                  marginLeft: "60px",
                                  // marginTop: "18px",
                                }}
                              >
                                <span
                                  // style={{
                                  //   display: "flex",
                                  //   alignItems: "center",
                                  //   justifyContent: "center",
                                  // }}
                                  onClick={() =>
                                    getImagewithRTSPTableSchduler(
                                      Events,
                                      option
                                    )
                                  }
                                >
                                  {previewDetails.loading &&
                                  previewDetails.LoadRTSP === option ? (
                                    <Button type="primary">
                                      <PulseLoader size={2} color="white" />
                                    </Button>
                                  ) : (
                                    <Button type="primary">
                                      <EyeOutlined
                                        style={{
                                          fontSize: "14px",
                                          color: "#fffff",
                                        }}
                                      />
                                    </Button>
                                  )}
                                </span>
                                <Button
                                  onClick={() =>
                                    OnDeleteScheduler(
                                      option,
                                      Events
                                      // option.serverDetails
                                      // Events.serverDetails
                                    )
                                  }
                                >
                                  <DeleteOutlined style={{ color: "red" }} />
                                </Button>
                              </Flex>
                            </div>
                          )}
                        {key === "option2" &&
                          option.days &&
                          option.days.length > 0 && (
                            <p>
                              Server Name:{" "}
                              <Tag
                                style={{
                                  fontSize: "15px",
                                  marginLeft: "5px",
                                }}
                                color="green"
                              >
                                {option.days[0][3][0].serverName}
                              </Tag>
                            </p>
                          )}

                        {key === "option1" && (
                          <>
                            {/* <Flex gap={"5px"}>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() =>
                                  getImagewithRTSPTableSchduler(Events, option)
                                }
                              >
                                {PreviewDetails.loading &&
                                PreviewDetails.LoadRTSP === option ? (
                                  <Button type="primary">
                                    <PulseLoader size={2} color="white" />
                                  </Button>
                                ) : (
                                  <Button type="primary">
                                    <EyeOutlined
                                      style={{
                                        fontSize: "14px",
                                        color: "#fffff",
                                      }}
                                    />
                                  </Button>
                                )}
                              </span>
                              <Button
                                onClick={() =>
                                  OnDeleteScheduler(
                                    option,
                                    Events,
                                    option.serverDetails
                                    // Events.serverDetails
                                  )
                                }
                              >
                                <DeleteOutlined style={{ color: "red" }} />
                              </Button>
                            </Flex> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {" "}
                                <Col>
                                  <p>
                                    Start Time:{" "}
                                    {option.startTime
                                      ? dayjs(option.startTime).format(
                                          "DD-MM-YYYY HH:mm"
                                        )
                                      : "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                    End Time:{" "}
                                    {option.endTime
                                      ? dayjs(option.endTime).format(
                                          "DD-MM-YYYY HH:mm"
                                        )
                                      : "N/A"}
                                  </p>
                                </Col>
                              </div>
                            </div>
                          </>
                        )}
                        {key === "option2" && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {" "}
                                <Col>
                                  <p>
                                    Start Time:{" "}
                                    {option.daystartTime
                                      ? dayjs(
                                          option.daystartTime,
                                          "HH:mm"
                                        ).format("HH:mm")
                                      : "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                    End Time:{" "}
                                    {option.dayendTime
                                      ? dayjs(
                                          option.dayendTime,
                                          "HH:mm"
                                        ).format("HH:mm")
                                      : "N/A"}
                                  </p>
                                </Col>
                              </div>

                              <Flex
                                gap={"5px"}
                                style={{
                                  marginLeft: "40px",
                                  marginTop: "10px",
                                }}
                              >
                                <span
                                  // style={{
                                  //   display: "flex",
                                  //   alignItems: "center",
                                  //   justifyContent: "center",
                                  // }}
                                  onClick={() =>
                                    getImagewithRTSPTableSchduler(
                                      Events,
                                      option
                                    )
                                  }
                                >
                                  {previewDetails.loading &&
                                  previewDetails.LoadRTSP === option ? (
                                    <Button type="primary">
                                      <PulseLoader size={2} color="white" />
                                    </Button>
                                  ) : (
                                    <Button type="primary">
                                      <EyeOutlined
                                        style={{
                                          fontSize: "14px",
                                          color: "#fffff",
                                        }}
                                      />
                                    </Button>
                                  )}
                                </span>
                                <Button
                                  onClick={() =>
                                    OnDeleteScheduler(
                                      option,
                                      Events
                                      // option.serverDetails
                                      // Events.serverDetails
                                    )
                                  }
                                >
                                  <DeleteOutlined style={{ color: "red" }} />
                                </Button>
                              </Flex>
                            </div>
                          </>
                        )}
                        {key === "option3" && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {" "}
                                <Col>
                                  <p>
                                    Start Time:{" "}
                                    {option.daystartTime
                                      ? dayjs(
                                          option.daystartTime,
                                          "HH:mm"
                                        ).format("HH:mm")
                                      : "N/A"}
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                    End Time:{" "}
                                    {option.dayendTime
                                      ? dayjs(
                                          option.dayendTime,
                                          "HH:mm"
                                        ).format("HH:mm")
                                      : "N/A"}
                                  </p>
                                </Col>
                              </div>
                              <Flex
                                gap={"5px"}
                                style={{
                                  marginLeft: "40px",
                                  marginTop: "10px",
                                }}
                              >
                                <span
                                  // style={{
                                  //   display: "flex",
                                  //   alignItems: "center",
                                  //   justifyContent: "center",
                                  // }}
                                  onClick={() =>
                                    getImagewithRTSPTableSchduler(
                                      Events,
                                      option
                                    )
                                  }
                                >
                                  {previewDetails.loading &&
                                  previewDetails.LoadRTSP === option ? (
                                    <Button type="primary">
                                      <PulseLoader size={2} color="white" />
                                    </Button>
                                  ) : (
                                    <Button type="primary">
                                      <EyeOutlined
                                        style={{
                                          fontSize: "14px",
                                          color: "#fffff",
                                        }}
                                      />
                                    </Button>
                                  )}
                                </span>
                                <Button
                                  onClick={() =>
                                    OnDeleteScheduler(
                                      option,
                                      Events
                                      // option.serverDetails
                                      // Events.serverDetails
                                    )
                                  }
                                >
                                  <DeleteOutlined style={{ color: "red" }} />
                                </Button>
                              </Flex>
                            </div>

                            {/* <Flex gap={"5px"}>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() =>
                                  getImagewithRTSPTableSchduler(Events, option)
                                }
                              >
                                {PreviewDetails.loading &&
                                PreviewDetails.LoadRTSP === option ? (
                                  <Button type="primary">
                                    <PulseLoader size={2} color="white" />
                                  </Button>
                                ) : (
                                  <Button type="primary">
                                    <EyeOutlined
                                      style={{
                                        fontSize: "14px",
                                        color: "#fffff",
                                      }}
                                    />
                                  </Button>
                                )}
                              </span>
                              <Button
                                onClick={() =>
                                  OnDeleteScheduler(
                                    option,
                                    Events,
                                    option.serverDetails
                                    // Events.serverDetails
                                  )
                                }
                              >
                                <DeleteOutlined style={{ color: "red" }} />
                              </Button>
                            </Flex> */}
                          </>
                        )}
                      </Col>
                      {/* <Flex gap={"5px"}>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() =>
                            getImagewithRTSPTableSchduler(Events, option)
                          }
                        >
                          {PreviewDetails.loading &&
                          PreviewDetails.LoadRTSP === option ? (
                            <Button type="primary">
                              <PulseLoader size={2} color="white" />
                            </Button>
                          ) : (
                            <Button type="primary">
                              <EyeOutlined
                                style={{ fontSize: "14px", color: "#fffff" }}
                              />
                            </Button>
                          )}
                        </span>
                        <Button
                          onClick={() =>
                            OnDeleteScheduler(
                              option,
                              Events
                              // Events.serverDetails
                            )
                          }
                        >
                          <DeleteOutlined style={{ color: "red" }} />
                        </Button>
                      </Flex> */}
                    </Row>

                    {/* {key === "option3" && (
                      <div style={{ width: "200px" }}>
                        <Popover
                          placement="top"
                          title={"text"}
                          content={"content"}
                        >
                          Days:
                        </Popover>
                        <WeekdaysInput
                          style={{
                            fontSize: "12px !important",
                            padding: "4px 8px !important",
                          }}
                          value={convertDaysToValueFormat(option.days)}
                          showChars={1}
                          textCase={"firstToUpper"}
                          onChange={(value) => {
                            OnChangeDay(value);
                          }}
                          disabledDays={option.days.map((day) => day[0])}
                        />
                      </div>
                    )} */}

                    {key === "option3" && (
                      <div style={{ width: "200px" }}>
                        <Popover
                          placement="top"
                          title={"text"}
                          content={"content"}
                        ></Popover>

                        {Object.entries(
                          option.days.reduce((acc, day) => {
                            const serverName = day[3][0].serverName; // Assuming the server name is here
                            if (!acc[serverName]) {
                              acc[serverName] = [];
                            }
                            acc[serverName].push(day); // Store the whole day object for further processing
                            return acc;
                          }, {})
                        ).map(([serverName, days]) => (
                          <div
                            key={serverName}
                            style={{ marginBottom: "10px" }}
                          >
                            <Tag
                              style={{
                                fontSize: "15px",
                                marginLeft: "5px",
                                marginBottom: "10px",
                              }}
                              color="green"
                            >
                              {serverName}
                            </Tag>
                            <WeekdaysInput
                              style={{
                                fontSize: "12px !important",
                                padding: "4px 8px !important",
                              }}
                              value={convertDaysToValueFormat(days)} // Convert days for this server
                              showChars={1}
                              textCase={"firstToUpper"}
                              onChange={(value) => {
                                OnChangeDay(value); // Handle change for this specific server
                              }}
                              disabledDays={days.map((day) => day[0])} // Disable the days for this server
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </CollapsePanel>
          </Collapse>
        );
      }
      return null;
    });
  };

  // const isChangingRef = useRef(isMonthChanging); // Use ref to keep track of month changes

  // const handleMonthChange = useCallback((newMonth) => {
  //   setIsMonthChanging(true);
  //   isChangingRef.current = true; // Update ref to indicate month change
  //   setMonth(dayjs(newMonth).format("YYYY-MM"));
  //   setTimeout(() => {
  //     setIsMonthChanging(false);
  //     isChangingRef.current = false; // Reset ref after month change
  //   }, 100);
  // }, []);

  // const handleSelect = useCallback((value) => {
  //   if (isChangingRef.current) {
  //     // Skip handling if the month is changing
  //     return;
  //   }
  //   getEventTimeForSpecificDate(value);
  // }, []);

  const onServerChange = (value) => {
    setSelectedServer(value);
  };

  const handleCancel = () => {
    setSelectedServer(null); // Clear the selected server
  };


  useEffect(() => {
    axiosClient
      .get("/serverdetails/getserverdetails")
      .then((response) => {
        if (response.data.issuccess === true) {
          setgetserverdata(response.data.data);
          const id = response.data.data.map((item) => item.id);
          setserverid(id);
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [AddRefresh]);

  useEffect(() => {
    if (selectedServer) {
      const data = { serverId: selectedServer };     
      socket.emit("Socketevent", data);
      // receive for the CPU usage response
      socket.on("Cpuusage", (data) => {
        const cpu = data.cpu !== undefined ? data.cpu : 0;
        setCpuUsage(cpu);
        // const gpu = data.gpu;
        // setGpuUsage(gpu);
        const gpuArray = data.gpu || [];
        setGpuUsage(gpuArray);
      });

      // Emit socket request every 5 seconds
      const interval = setInterval(() => {
        socket.emit("Socketevent", data);
      }, 5000);

      const timer = setTimeout(() => {
        clearInterval(interval); // Stop emitting
        setSelectedServer(null); // Clear selected server
      }, 700000);

      // setSocketInterval(interval);
      // setSocketTimer(timer);

      return () => {
        clearInterval(interval);
        clearTimeout(timer);
        socket.off("Cpuusage");
      };
    }
  }, [selectedServer]);

  useEffect(() => {
    axiosClient
      .get("/serverdetails/getserverdetails")
      .then((response) => {
        if (response.data.issuccess === true) {
          setgetserverdata(response.data.data);
          const id = response.data.data.map((item) => item.id);

          setserverid(id);
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [AddRefresh]);

  useEffect(
    (startDate, endDate) => {
      fetchHistoryData(startDate, endDate);
    },
    [AddRefresh]
  );

  useEffect(() => {
    // api to get camera list
    getCameraListApi1()
      .then((response) => {
        if (response.status === 200) {
          setcameralist(response.data.msg);

          const multiselectData = response.data.msg.map((item) => ({
            label: item.cameraName,
            value: item.id,
          }));

          setcameralistmultiselect(multiselectData);
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
    return () => {
    };
  }, []);

  useEffect(() => {
    getEventTimeTableForMonth();
  }, [Month, AddRefresh]);

  useEffect(() => {
    // API to get basic event list

    axiosClient
      .get("/cameraEvents/get_eventsBasedOnEnv?mode=0")
      .then((response) => {
        if (response.data.issuccess === true) {
          // setevents(response.data.msg);

          response.data.msg.map((item, index) => {
            let camdata = {
              label: item.event_name,
              value: item.id,
            };
            // setallids((ids) => [...ids, item.id]);
            setevents((cam) => [...cam, camdata]);
          });
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg);
        }
      });

    // api to get event list of Environment dependant
    axiosClient
      .get("/cameraEvents/get_eventsBasedOnEnv?mode=1")
      .then((response) => {
        if (response.data.issuccess === true) {
          setevents(response.data.msg);
          let camdataArray = [];

          response.data.msg.forEach((item) => {
            let camdata = {
              label: item.event_name,
              value: item.id,
            };
            camdataArray.push(camdata);
          });
          setEnvEvents((cam) => {
            const newArray = [...cam, ...camdataArray];
            const uniqueArray = newArray.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.value === value.value)
            );
            return uniqueArray;
          });
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg);
        }
      });

    axiosClient
      .get("/cameraEvents/getAllCamerasList")
      .then((response) => {
        if (response.data.issuccess === true) {
          // setCameralistBasic(response.data.data);
          const data = response.data.data.sort(
            (a, b) => a.camerId - b.cameraid
          );
          setCameralistBasic(data);
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.msg);
        }
      });
  }, [AddRefresh]);

  useEffect(() => {
  }, [
    ScheduleDrawerContoller.SchedulerSelected,
    SchedulerController.SchedluerType,
  ]);

  useEffect(() => {
    if (ScheduleDrawerContoller.OpenDrawer) {
      setSchedulerController((prev) => ({
        ...prev,
        SchedluerType: 1, // Ensure default value is "One Time"
      }));
    }
  }, [ScheduleDrawerContoller.OpenDrawer]);

  // useEffect(() => {
  //   axiosClient
  //     .get("/cameraEvents/geteventhistory")
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         sethistorydata(response.data.data);
  //       } else {

  //       }
  //     })
  //     .catch((err) => {
  //       if (err.status === 0) {
  //         message.error("Network error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // }, []);

  return FootFallDirectionController.OpenModalToMark ? (
    <Skeleton loading={FootFallDirectionController.loading} active round>
      {FootFallDirectionController.ImageUpdated ? (
        <div ref={ref1} className="lasso" style={{ width: "100%" }}>
          <ReactLasso
            className="lasso"
            src={Screenshot}
            imageAlt="vehicle image"
            value={FootFallDirectionController.coordinates}
            onChange={(path) => {
              setFootFallDirectionController((prev) => ({
                ...prev,
                coordinates: path,
              }));
            }}
            imageStyle={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onImageLoad={(e) => {
              const img = e.target;
              setImg({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            }}
          />
        </div>
      ) : (
        <div ref={ref} className="lasso" style={{ width: "100%" }}>
          <ReactLasso
            className="lasso"
            src={FootFallDirectionController.ImagetoMark}
            imageAlt="vehicle image"
            value={FootFallDirectionController.coordinates}
            onChange={(path) => {
              setFootFallDirectionController((prev) => ({
                ...prev,
                coordinates: path,
              }));
            }}
            imageStyle={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onImageLoad={(e) => {
              const img = e.target;
              setImg({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            }}
          />
        </div>
      )}
      <Row
        justify={"center"}
        gutter={[20, 60]}
        style={{ marginBottom: "1em", marginTop: "1em" }}
      >
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={() => {
              setFootFallDirectionController((prev) => ({
                ...prev,
                coordinates: [],
                FinalCoordinates: [],
                ImageUpdated: false,
              }));
            }}
            type="danger"
            danger
          >
            Reset
          </Button>
        </Col>
        {FootFallDirectionController.FinalCoordinates.length >= 1 ? (
          <Col>
            <Badge
              dot={
                FootFallDirectionController.coordinates.length > 1
                  ? true
                  : false
              }
            >
              <Button
                disabled={
                  FootFallDirectionController.coordinates.length >= 1 ||
                  !FootFallDirectionController.FinalCoordinates.length > 2
                    ? false
                    : true
                }
                name="btn"
                key="btn"
                onClick={(e) => {
                  if (
                    FootFallDirectionController.FinalCoordinates.length === 1
                  ) {
                    message.info("Please Mark the Direction");
                  }

                  // let coords = {};

                  // coords["Direction"] = FootFallDirectionController.coordinates;

                  setFootFallDirectionController((prev) => ({
                    ...prev,

                    FinalCoordinates: [
                      ...prev.FinalCoordinates,
                      FootFallDirectionController.coordinates,
                    ],
                    FinalCoordinatesEdit: [
                      ...prev.FinalCoordinatesEdit,
                      [...prev.coordinates],
                    ],
                  }));
                  getImage1();
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        ) : (
          <Col>
            <Badge
              dot={
                FootFallDirectionController.coordinates.length > 1
                  ? true
                  : false
              }
            >
              <Button
                disabled={
                  FootFallDirectionController.coordinates.length >= 1
                    ? false
                    : true
                }
                name="btn"
                key="btn"
                onClick={(e) => {
                  getImage();

                  message.info("Please Mark the Direction");

                  // let coords = {};
                  // coords["Start"] = FootFallDirectionController.coordinates;

                  setFootFallDirectionController((prev) => ({
                    ...prev,

                    FinalCoordinates: [
                      ...prev.FinalCoordinates,
                      FootFallDirectionController.coordinates,
                    ],

                    FinalCoordinatesEdit: [
                      ...prev.FinalCoordinates,
                      [...prev.coordinates],
                    ],
                  }));
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        )}
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={(e) => {
              CloseModal();
            }}
            type="danger"
            danger
          >
            Close
          </Button>
        </Col>
      </Row>
    </Skeleton>
  ) : DataController.OpenModalToMark ? (
    <Skeleton loading={DataController.loading} active round>
      {DataController.ImageUpdated ? (
        <div
          ref={DataController.id === 5 || 10 || 6 ? ref5 : ref1}
          className="lasso"
          style={{ width: "100%" }}
        >
          <ReactLasso
            className="lasso"
            src={Screenshot}
            imageAlt="vehicle image"
            value={DataController.coordinates}
            onChange={(path) => {
              setDataController((prev) => ({
                ...prev,
                coordinates: path,
              }));
            }}
            imageStyle={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onImageLoad={(e) => {
              const img = e.target;
              setImg({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            }}
          />
        </div>
      ) : (
        <div
          ref={DataController.id === 5 || 10 || 6 ? ref4 : ref}
          className="lasso"
          style={{ width: "100%" }}
        >
          <ReactLasso
            className="lasso"
            src={DataController.ImagetoMark}
            imageAlt="vehicle image"
            value={DataController.coordinates}
            onChange={(path) => {
              setDataController((prev) => ({
                ...prev,
                coordinates: path,
              }));
            }}
            imageStyle={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onImageLoad={(e) => {
              const img = e.target;
              setImg({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            }}
          />
        </div>
      )}
      <Row
        justify={"center"}
        gutter={[20, 60]}
        style={{ marginBottom: "1em", marginTop: "1em" }}
      >
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={() => {
              setDataController((prev) => ({
                ...prev,
                coordinates: [],
                FinalCoordinates: [],
                ImageUpdated: false,
              }));
            }}
            type="danger"
            danger
          >
            Reset
          </Button>
        </Col>
        {DataController.FinalCoordinates.length >= 1 ? (
          <Col>
            <Badge dot={DataController.coordinates.length > 1 ? true : false}>
              <Button
                disabled={
                  DataController.coordinates.length >= 1 ||
                  !DataController.FinalCoordinates.length > 2
                    ? false
                    : true
                }
                name="btn"
                key="btn"
                onClick={(_e) => {
                  if (DataController.id === 5 || 10 || 6) {                   
                    setDataController((prev) => ({
                      ...prev,

                      FinalCoordinates: [
                        ...prev.FinalCoordinates,
                        DataController.coordinates,
                      ],
                      FinalCoordinatesEdit: [
                        ...prev.FinalCoordinatesEdit,
                        [...prev.coordinates],
                      ],
                    }));
                    getImage4();
                  } else {
                    // getImage1();
                    if (DataController.FinalCoordinates.length > 1) {
                      message.info(
                        "You have Marked the Inner and Outer area, please close and submit the event 1"
                      );
                      setDataController((prev) => ({
                        ...prev,

                        coordinates: [],
                      }));
                    } else {
                      let coords = {};

                      coords["IN"] = DataController.coordinates;

                      setDataController((prev) => ({
                        ...prev,

                        FinalCoordinates: [...prev.FinalCoordinates, coords],
                        FinalCoordinatesEdit: [
                          ...prev.FinalCoordinatesEdit,
                          [...prev.coordinates],
                        ],
                      }));
                      message.info("Please Mark the Inner area for footfall");
                      getImage1();
                    }
                  }
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        ) : (
          <Col>
            <Badge dot={DataController.coordinates.length > 1 ? true : false}>
              <Button
                disabled={DataController.coordinates.length >= 1 ? false : true}
                name="btn"
                key="btn"
                onClick={(_e) => {
                  if (DataController.id === 5 || 10 || 6) {
                    getImage4();

                    setDataController((prev) => ({
                      ...prev,

                      FinalCoordinates: [
                        ...prev.FinalCoordinates,
                        DataController.coordinates,
                      ],

                      FinalCoordinatesEdit: [
                        ...prev.FinalCoordinates,
                        [...prev.coordinates],
                      ],
                    }));
                  } else if (DataController.id === 13) {
                    getImage1();
                    if (DataController.FinalCoordinates.length > 1) {                      
                      message.info(
                        "You have Marked the Inner and Outer area, please close and submit the event 2"
                      );
                    } else {
                      getImage4();

                      let coords = {};

                      coords["OUT"] = DataController.coordinates;

                      // const list = DataController.coordinatesmap(
                      //   (item, index) => {
                      //     const count = index + 1;
                      //     coords["Area" + count] = item;
                      //   }
                      // );

                      setDataController((prev) => ({
                        ...prev,

                        FinalCoordinates: [...prev.FinalCoordinates, coords],

                        FinalCoordinatesEdit: [
                          ...prev.FinalCoordinates,
                          [...prev.coordinates],
                        ],
                      }));
                      message.info("Plaese Mark the Inner area for footfall");
                    }
                  }
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        )}
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={(e) => {
              CloseModal();
            }}
            type="danger"
            danger
          >
            Close
          </Button>
        </Col>
      </Row>
    </Skeleton>
  ) : EditEnvController.startedit ? (
    <Skeleton loading={EditEnvController.loading} active round>
      <Row justify={"center"} gutter={[10, 20]} style={{ marginBottom: "2em" }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 24, offset: 0 }}
        >
          <div>
            <ImageMapper
              src={EditEnvController.baseimage && EditEnvController.baseimage}
              map={{ name: "mapper", areas: EditEnvController.Mapperformat }}
              onClick={(e) => EditCoords(e)}
            />
          </div>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 8, offset: 0 }}
        >
          <Row justify={"space-evenly"}>
            <Col>
              <Button onClick={closeEventeditENV}>Cancel</Button>
            </Col>
            <Col>
              <Button onClick={submitedit} type="primary">
                save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Skeleton>
  ) : DwellController.OpenModalToMark ? (
    <Skeleton loading={DwellController.loading} active round>
      {DwellController.ImageUpdated ? (
        <div ref={ref3} className="lasso" style={{ width: "100%" }}>
          <ReactLasso
            className="lasso"
            src={Screenshot}
            imageAlt="vehicle image"
            value={DwellController.coordinates}
            onChange={(path) => {
              setDwellController((prev) => ({
                ...prev,
                coordinates: path,
              }));
            }}
            imageStyle={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onImageLoad={(e) => {
              const img = e.target;
              setImg({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            }}
          />
        </div>
      ) : (
        <div ref={ref2} className="lasso" style={{ width: "100%" }}>
          <ReactLasso
            className="lasso"
            src={DwellController.ImagetoMark}
            imageAlt="vehicle image"
            value={DwellController.coordinates}
            onChange={(path) => {
              setDwellController((prev) => ({
                ...prev,
                coordinates: path,
              }));
            }}
            imageStyle={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onImageLoad={(e) => {
              const img = e.target;
              setImg({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            }}
          />
        </div>
      )}
      <Row
        justify={"center"}
        gutter={[20, 60]}
        style={{ marginBottom: "1em", marginTop: "1em" }}
      >
        <Col>
          <Input
            placeholder="Area Name"
            value={DwellController.areaName}
            onChange={(e) => {
              const newAreaName = e.target.value;
              setDwellController((prev) => ({
                ...prev,
                areaName: newAreaName,
              }));
            }}
          />
        </Col>
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={() => {
              setDwellController((prev) => ({
                ...prev,
                coordinates: [],
                FinalCoordinates: [],
                ImageUpdated: false,
                areaName: "", // Clear areaName on reset
              }));
            }}
            type="danger"
            danger
          >
            Reset
          </Button>
        </Col>

        {DwellController.FinalCoordinates.length >= 1 ? (
          <Col>
            <Badge dot={DwellController.coordinates.length > 1}>
              <Button
                disabled={
                  !DwellController.areaName ||
                  DwellController.coordinates.length < 1
                }
                name="btn"
                key="btn"
                // onClick={(e) => {
                //   setDwellController((prev) => ({
                //     ...prev,
                //     FinalCoordinates: [
                //       ...prev.FinalCoordinates,
                //       [...prev.coordinates],
                //     ],
                //     areaName: "", // Clear areaName after adding
                //   }));
                //   getImage3();
                // }}
                onClick={() => {
                  const currentAreaName = DwellController.areaName;
                  if (currentAreaName) { 
                    // Update state to add both coordinates and area name
                    setDwellController((prev) => ({
                      ...prev,
                      FinalCoordinates: [
                        ...prev.FinalCoordinates,
                        {
                          areaName: currentAreaName,
                          coordinates: [...prev.coordinates],
                        },
                      ],
                      coordinates: [], // Reset coordinates if needed
                      areaName: "", // Clear areaName after adding
                    }));

                    // Call the appropriate function based on the current context
                    getImage3(); // or getImage2();
                  } else {
                  }
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        ) : (
          <Col>
            <Badge dot={DwellController.coordinates.length > 1}>
              <Button
                disabled={
                  !DwellController.areaName ||
                  DwellController.coordinates.length < 1
                }
                name="btn"
                key="btn"
                onClick={() => {
                  const currentAreaName = DwellController.areaName;

                  if (currentAreaName) {
                    // Update state to add both coordinates and area name
                    setDwellController((prev) => ({
                      ...prev,
                      FinalCoordinates: [
                        ...prev.FinalCoordinates,
                        {
                          areaName: currentAreaName,
                          coordinates: [...prev.coordinates],
                        },
                      ],
                      coordinates: [], // Reset coordinates if needed
                      areaName: "", // Clear areaName after adding
                    }));

                    // Call the appropriate function based on the current context
                    getImage2(); // or getImage2();
                  } else {
                  }
                }}
                // onClick={(e) => {
                //   setDwellController((prev) => ({
                //     ...prev,
                //     FinalCoordinates: [
                //       ...prev.FinalCoordinates,
                //       [...prev.coordinates],
                //     ],
                //     areaName: "", // Clear areaName after adding
                //   }));
                //   getImage2();
                // }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        )}

        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={(e) => {
              CloseModalDwell();
            }}
            type="danger"
            danger
          >
            Close
          </Button>
        </Col>
      </Row>

      {/* 
      <Row
        justify={"center"}
        gutter={[20, 60]}
        style={{ marginBottom: "1em", marginTop: "1em" }}
      >
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={() => {
              setDwellController((prev) => ({
                ...prev,
                coordinates: [],
                FinalCoordinates: [],
                ImageUpdated: false,
              }));
            }}
            type="danger"
            danger
          >
            Reset
          </Button>
        </Col>
        {DwellController.FinalCoordinates.length >= 1 ? (
          <Col>
            <Badge dot={DwellController.coordinates.length > 1 ? true : false}>
              <Button
                disabled={
                  DwellController.coordinates.length >= 1 ? false : true
                }
                name="btn"
                key="btn"
                onClick={(e) => {
                  setDwellController((prev) => ({
                    ...prev,

                    FinalCoordinates: [
                      ...prev.FinalCoordinates,
                      [...prev.coordinates],
                    ],
                  }));
                  getImage3();
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        ) : (
          <Col>
            <Badge dot={DwellController.coordinates.length > 1 ? true : false}>
              <Button
                disabled={
                  DwellController.coordinates.length >= 1 ? false : true
                }
                name="btn"
                key="btn"
                onClick={(e) => {
                  setDwellController((prev) => ({
                    ...prev,

                    FinalCoordinates: [
                      ...prev.FinalCoordinates,
                      [...prev.coordinates],
                    ],
                  }));
                  getImage2();
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        )}
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={(e) => {
              CloseModalDwell();
            }}
            type="danger"
            danger
          >
            Close
          </Button>
        </Col>
      </Row> */}
    </Skeleton>
  ) : FootFallController.OpenModalToMark ? (
    <Skeleton loading={FootFallController.loading} active round>
      {FootFallController.ImageUpdated ? (
        <div ref={ref1} className="lasso" style={{ width: "100%" }}>
          <ReactLasso
            className="lasso"
            src={Screenshot}
            imageAlt="vehicle image"
            value={FootFallController.coordinates}
            onChange={(path) => {
              setFootFallController((prev) => ({
                ...prev,
                coordinates: path,
              }));
            }}
            imageStyle={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onImageLoad={(e) => {
              const img = e.target;
              setImg({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            }}
          />
        </div>
      ) : (
        <div ref={ref} className="lasso" style={{ width: "100%" }}>
          <ReactLasso
            className="lasso"
            src={FootFallController.ImagetoMark}
            imageAlt="vehicle image"
            value={FootFallController.coordinates}
            onChange={(path) => {
              setFootFallController((prev) => ({
                ...prev,
                coordinates: path,
              }));
            }}
            imageStyle={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onImageLoad={(e) => {
              const img = e.target;
              setImg({
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            }}
          />
        </div>
      )}
      <Row
        justify={"center"}
        gutter={[20, 60]}
        style={{ marginBottom: "1em", marginTop: "1em" }}
      >
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={() => {
              setFootFallController((prev) => ({
                ...prev,
                coordinates: [],
                FinalCoordinates: [],
                ImageUpdated: false,
              }));
            }}
            type="danger"
            danger
          >
            Reset
          </Button>
        </Col>
        {FootFallController.FinalCoordinates.length >= 1 ? (
          <Col>
            <Badge
              dot={FootFallController.coordinates.length > 1 ? true : false}
            >
              <Button
                disabled={
                  FootFallController.coordinates.length >= 1 ||
                  !FootFallController.FinalCoordinates.length > 2
                    ? false
                    : true
                }
                name="btn"
                key="btn"
                onClick={(e) => {
                  // setFootFallController((prev) => ({
                  //   ...prev,

                  //   FinalCoordinates: [
                  //     ...prev.FinalCoordinates,
                  //     [...prev.coordinates],
                  //   ],
                  // }));

                  if (FootFallController.FinalCoordinates.length > 1) {
                    message.info(
                      "You have Marked the Inner and Outer area, please close and submit the event"
                    );                 
                    setFootFallController((prev) => ({
                      ...prev,

                      coordinates: [],
                    }));
                    getImage1();                 
                  } else {
                    let coords = {};

                    coords["IN"] = FootFallController.coordinates;

                    setFootFallController((prev) => ({
                      ...prev,

                      FinalCoordinates: [...prev.FinalCoordinates, coords],
                      FinalCoordinatesEdit: [
                        ...prev.FinalCoordinatesEdit,
                        [...prev.coordinates],
                      ],
                    }));                       
                    getImage1();               
                  }
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        ) : (
          <Col>
            <Badge
              dot={FootFallController.coordinates.length > 1 ? true : false}
            >
              <Button
                disabled={
                  FootFallController.coordinates.length >= 1 ? false : true
                }
                name="btn"
                key="btn"
                onClick={(e) => {
                  setDataController((prev) => ({
                    ...prev,

                    FinalCoordinates: [
                      ...prev.FinalCoordinates,
                      [...prev.coordinates],
                    ],
                  }));
                  if (FootFallController.FinalCoordinates.length > 1) {
                    message.info(
                      "You have Marked the Inner and Outer area, please close and submit the event 3"
                    );           
                  } else {
                    getImage();
                    let coords = {};

                    coords["OUT"] = FootFallController.coordinates;

                    // const list = FootFallController.coordinatesmap(
                    //   (item, index) => {
                    //     const count = index + 1;
                    //     coords["Area" + count] = item;
                    //   }
                    // );

                    setFootFallController((prev) => ({
                      ...prev,

                      FinalCoordinates: [...prev.FinalCoordinates, coords],

                      FinalCoordinatesEdit: [
                        ...prev.FinalCoordinates,
                        [...prev.coordinates],
                      ],
                    }));
                    message.info("Please Mark the Inner area for footfall");           
                  }
                }}
                type="primary"
              >
                Add
              </Button>
            </Badge>
          </Col>
        )}
        <Col>
          <Button
            name="btn"
            key="btn"
            onClick={(e) => {
              CloseModal();
            }}
            type="danger"
            danger
          >
            Close
          </Button>
        </Col>
      </Row>
    </Skeleton>
  ) : (
    <>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 24, offset: 0 }}
          style={{ height: "90%" }}
        >
          <div
            className="report-content"
            style={{
              backgroundColor: "#000816",
              width: "100%",
              margin: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              overflowX: "hidden",
            }}
          >
            <Tabs defaultActiveKey="1">
              <TabPane tab="Event Addition" key="1">
                <div
                  className="report-content"
                  style={{
                    backgroundColor: "#000816",
                    width: "100%",
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    overflowX: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginLeft: "3%",
                      height: "43px",
                      // marginTop: "30px",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {/* <p
                      style={{
                        color: "white",
                        fontSize: "21px",
                        fontFamily: "revert-layer",
                      }}
                    >
                      Event Addition
                    </p> */}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginRight: "3%",
                        userSelect: "none",
                        marginBottom: "7px",
                      }}
                    >
                      <Col>
                        <Dropdown
                          menu={{ items: items, onClick: HandleMenu }}
                          placement="bottomLeft"
                          trigger={["hover"]}
                        >
                          <Button
                            style={{ userSelect: "none" }}
                            className="adduser"
                          >
                            <FaPlus style={{ marginRight: "2px" }} /> New{" "}
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </Col>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "white" }}>
                    <header
                      style={{
                        backgroundColor: "white",
                        height: "auto",
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "space-between",
                        // justifyContent: "flex-start",
                        padding: "15px",
                        flexWrap: "wrap",
                        // marginTop: "20px",
                      }}
                    >
                      {/* <div></div> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "23px",
                            fontFamily: "revert-layer",
                            // margin: "0 20px 0 0",
                          }}
                        >
                          Event Addition
                        </span>
                        <span style={{ marginLeft: "15px" }}>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Select
                              placeholder={<span>Select a Server</span>}
                              style={{
                                width: 200,
                                height: 42,
                                // marginLeft: "15px",
                              }}
                              value={selectedServer}
                              onChange={onServerChange}
                            >
                              {getserverdata.map((server) => (
                                <Select.Option
                                  key={server.id}
                                  value={server.id}
                                >
                                  {server.serverName}
                                </Select.Option>
                              ))}
                            </Select>
                            <CloseCircleOutlined
                              onClick={handleCancel}
                              style={{
                                fontSize: "20px",
                                marginLeft: "10px",
                              }}
                            />
                          </span>
                        </span>
                      </div>
                      <div style={{ marginTop: "8px", marginRight: "100px" }}>
                        {selectedServer && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {cpuUsage ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "20px",
                                }}
                              >
                                <span
                                  style={{ fontSize: "17px", color: "grey" }}
                                >
                                  CPU: {cpuUsage}%
                                </span>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "30px",
                                }}
                              >
                                <span
                                  style={{ fontSize: "16px", color: "grey" }}
                                >
                                  CPU: unavailable
                                </span>
                              </div>
                            )}
                            {gpuUsage && gpuUsage.length > 0 ? (
                              gpuUsage.map((gpu, index) => {
                                const gpuKey = `gpu${index + 1}`;
                                const gpuValue = gpu[gpuKey];

                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "30px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        color: "grey",
                                      }}
                                    >
                                      {gpuValue !== null
                                        ? index === 0
                                          ? `GPU: ${gpuValue}%`
                                          : `GPU ${index + 1}: ${gpuValue}%`
                                        : `GPU ${index + 1}: undefined`}
                                    </span>
                                  </div>
                                );
                              })
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "30px",
                                }}
                              >
                                <span
                                  style={{ fontSize: "16px", color: "grey" }}
                                >
                                  GPU: unavailable
                                </span>
                              </div>
                            )}

                            {/* {gpuUsage &&
                              gpuUsage.map((gpu, index) => {
                                const gpuKey = `gpu${index + 1}`;
                                const gpuValue = gpu[gpuKey];

                                if (gpuValue !== null) {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "30px",
                                      }}
                                    >
                                      <span style={{ fontSize: "20px" }}>
                                        {index === 0
                                          ? `GPU: ${gpuValue}%`
                                          : `GPU ${index + 1}: ${gpuValue}%`}
                                      </span>
                                    </div>
                                  );
                                }
                                return null;
                              })} */}
                          </div>
                        )}
                      </div>
                    </header>

                    <hr
                      style={{
                        border: "none",
                        borderTop: "1px solid #ddd",
                        // margin: "0 45px",
                        width: "100%",
                      }}
                    />
                  </div>

                  <Row style={{ backgroundColor: "white" }}>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      xxl={{ span: 24, offset: 0 }}
                    >
                      <Row
                        className="top-space"
                        gutter={[10, 10]}
                        align={"middle"}
                        justify={"center"}
                      >
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          xxl={{ span: 24, offset: 0 }}
                        >
                          <Row
                            className="top-space"
                            gutter={[10, 10]}
                            align={"middle"}
                            justify={"center"}
                          >
                            <Col
                              xs={{ span: 22, offset: 0 }}
                              sm={{ span: 22, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 23, offset: 0 }}
                              xxl={{ span: 23, offset: 0 }}
                            >
                              <Row
                                gutter={[6, 6]}
                                style={{ minHeight: "100%" }}
                              >
                                {/* Check if CameralistBasic contains data */}
                                {CameralistBasic.length > 0 ? (
                                  CameralistBasic.map((items) => {
                                    // Check if the camera has environments
                                    if (items.environment.length > 0) {
                                      return (
                                        <Col
                                          xs={{ span: 20 }}
                                          sm={{ span: 16 }}
                                          md={{ span: 12 }}
                                          lg={{ span: 10 }}
                                          xl={{ span: 9 }}
                                          xxl={{ span: 8 }}
                                          key={items.cameraId}
                                        >
                                          <Card
                                            className="customcard"
                                            hoverable
                                            title={
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  boxShadow: "none",
                                                }}
                                              >
                                                <span>{items.cameraName}</span>
                                                <Tag
                                                  color={
                                                    items.cameraStatus ===
                                                    "active"
                                                      ? "green"
                                                      : "red"
                                                  }
                                                >
                                                  {items.cameraStatus}
                                                </Tag>
                                              </div>
                                            }
                                          >
                                            <Collapse>
                                              <CollapsePanel
                                                header={
                                                  items.environment.length +
                                                  " Event has been Added"
                                                }
                                              >
                                                <Row gutter={[10, 10]}>
                                                  <Col
                                                    span={24}
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      gap: "0.5rem",
                                                    }}
                                                  >
                                                    {/* Loop through environment events */}
                                                    {items.environment &&
                                                      items.environment.map(
                                                        (Events) => {
                                                          if (
                                                            Events.env_support ===
                                                            true
                                                          ) {
                                                            return (
                                                              <Row
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "column",
                                                                }}
                                                                // gutter={[4, 4]}
                                                                //justify={"space-between"}
                                                              >
                                                                <Row
                                                                  justify="space-between"
                                                                  style={{
                                                                    alignItems:
                                                                      "center",
                                                                    marginBottom:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  <Col
                                                                    style={{
                                                                      flex: "none",
                                                                      width:
                                                                        "200px",
                                                                      overflow:
                                                                        "hidden",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "16px",
                                                                        padding:
                                                                          "5px",
                                                                        whiteSpace:
                                                                          "normal", // Allows wrapping
                                                                        overflow:
                                                                          "hidden",
                                                                        textOverflow:
                                                                          "ellipsis",
                                                                        display:
                                                                          "block",
                                                                      }}
                                                                    >
                                                                      {
                                                                        Events.eventName
                                                                      }
                                                                    </span>
                                                                  </Col>

                                                                  <Col>
                                                                    <Row
                                                                      gutter={[
                                                                        4, 4,
                                                                      ]}
                                                                    >
                                                                      <span>
                                                                        {Events?.serverDetails?.map(
                                                                          (
                                                                            item
                                                                          ) => (
                                                                            <Tag
                                                                              key={
                                                                                item.id
                                                                              }
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                              color="green"
                                                                            >
                                                                              {
                                                                                item.serverName
                                                                              }
                                                                            </Tag>
                                                                          )
                                                                        )}
                                                                      </span>
                                                                    </Row>
                                                                  </Col>
                                                                  <Col>
                                                                    {Events
                                                                      .option
                                                                      .length ===
                                                                      0 && (
                                                                      <span
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          alignItems:
                                                                            "center",
                                                                          marginBottom:
                                                                            "10px",
                                                                        }}
                                                                        onClick={() =>
                                                                          getImagewithRTSPTable(
                                                                            Events
                                                                          )
                                                                        }
                                                                      >
                                                                        {previewDetails.loading &&
                                                                        previewDetails.LoadRTSP ===
                                                                          items.RTSP ? (
                                                                          <Button type="primary">
                                                                            <PulseLoader
                                                                              size={
                                                                                2
                                                                              }
                                                                              color="white"
                                                                            />
                                                                          </Button>
                                                                        ) : (
                                                                          <Button type="primary">
                                                                            <EyeOutlined
                                                                              style={{
                                                                                fontSize:
                                                                                  "18px",
                                                                                color:
                                                                                  "#ffffff",
                                                                              }}
                                                                            />
                                                                          </Button>
                                                                        )}
                                                                      </span>
                                                                    )}
                                                                    <Col>
                                                                      {Events?.serverDetails?.map(
                                                                        (
                                                                          server
                                                                        ) => (
                                                                          <Button
                                                                            key={
                                                                              server.id
                                                                            }
                                                                            onClick={() =>
                                                                              OnDeleteBasic(
                                                                                Events.camEventId,
                                                                                server.id
                                                                              )
                                                                            }
                                                                          >
                                                                            <DeleteOutlined
                                                                              style={{
                                                                                color:
                                                                                  "red",
                                                                              }}
                                                                            />
                                                                          </Button>
                                                                        )
                                                                      )}
                                                                    </Col>
                                                                  </Col>
                                                                </Row>

                                                                <Modal
                                                                  visible={
                                                                    previewDetails.openmodal
                                                                  }
                                                                  onCancel={() =>
                                                                    setpreviewDetails(
                                                                      (
                                                                        prev
                                                                      ) => ({
                                                                        ...prev,
                                                                        openmodal: false,
                                                                      })
                                                                    )
                                                                  }
                                                                  footer={null}
                                                                  width={
                                                                    imageWidth +
                                                                    50
                                                                  }
                                                                  height={
                                                                    imageHeight +
                                                                    20
                                                                  }
                                                                  bodyStyle={{
                                                                    marginTop: 20,
                                                                  }}
                                                                >
                                                                  {previewDetails.image && (
                                                                    <ImageWithCoordinates
                                                                      image={
                                                                        previewDetails.image
                                                                      }
                                                                      coordinates={
                                                                        previewDetails.coordinates
                                                                      }
                                                                      footfallCoordinates={
                                                                        previewDetails.footfallCoordinates
                                                                      }
                                                                      Dwell={
                                                                        previewDetails.DwellCoordinates
                                                                      }
                                                                      EventId={
                                                                        previewDetails.EventId
                                                                      }
                                                                    />
                                                                  )}
                                                                </Modal>
                                                                <ul
                                                                  style={{
                                                                    marginTop:
                                                                      "-1rem",
                                                                  }}
                                                                >
                                                                  {Events?.subEvent?.map(
                                                                    (item) => {
                                                                      return (
                                                                        <li>
                                                                          {item}
                                                                        </li>
                                                                      );
                                                                    }
                                                                  )}
                                                                </ul>
                                                                {Events.option
                                                                  .length >
                                                                  0 && (
                                                                  <Collapse>
                                                                    <CollapsePanel
                                                                      header="Scheduler"
                                                                      key={
                                                                        Events.camEventId
                                                                      }
                                                                    >
                                                                      {Events.option.map(
                                                                        (
                                                                          event
                                                                        ) => (
                                                                          <div
                                                                            key={
                                                                              event.eventId
                                                                            }
                                                                          >
                                                                            {renderEventOptionsList(
                                                                              event,
                                                                              Events
                                                                            )}
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </CollapsePanel>
                                                                  </Collapse>
                                                                )}
                                                                {/* <ul
                                                                  style={{
                                                                    marginTop:
                                                                      "-1rem",
                                                                  }}
                                                                > */}

                                                                {/* </ul> */}
                                                              </Row>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                  </Col>
                                                </Row>
                                              </CollapsePanel>
                                            </Collapse>
                                          </Card>
                                        </Col>
                                      );
                                    }
                                    return null;
                                  })
                                ) : (
                                  <Row
                                    style={{
                                      minHeight: "70vh",
                                      // display: "flex",
                                      // alignItems: "center",
                                      // justifyContent: "center",
                                      marginLeft: "40%",
                                    }}
                                  >
                                    <Col
                                      xs={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      md={{ span: 24 }}
                                      lg={{ span: 24 }}
                                      xl={{ span: 23 }}
                                      xxl={{ span: 24 }}
                                      style={{}}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Empty
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "200px",
                                          }}
                                          description={
                                            <Text type="secondary">
                                              No data found{" "}
                                              <PulseLoader
                                                color="gray"
                                                size={5}
                                              />
                                            </Text>
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                {/* Add other content for Event Addition */}
              </TabPane>

              {/* <TabPane tab="Scheduler List" key="2">
                <div style={{ backgroundColor: "white" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      color: "white",
                      //marginLeft: "3%",
                      backgroundColor: "#000816",
                    }}
                  >
                    {" "}
                    {/* <p
                      style={{
                        color: "white",
                        fontSize: "21px",
                        fontFamily: "revert-layer",
                        marginLeft: "3%",
                      }}
                    >
                      Scheduler List Calendar
                    </p> */}
              {/* </div>
                  <div
                    style={{
                      marginTop: "1rem",
                      padding: "1rem",
                      // height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                    }}
                  >
                    <Calendar
                      style={{ width: "70vw" }}
                      onPanelChange={handleMonthChange}
                      onSelect={handleSelect}
                      cellRender={dateCellRender}
                      disabledDate={disabledDate}
                      headerRender={({
                        value,
                        type,
                        onChange,
                        onTypeChange,
                      }) => {
                        const start = 0;
                        const end = 12;
                        const monthOptions = [];
                        let current = value.clone();
                        const localeData = value.localeData();
                        const months = [];
                        for (let i = 0; i < 12; i++) {
                          current = current.month(i);
                          months.push(localeData.monthsShort(current));
                        }
                        for (let i = start; i < end; i++) {
                          monthOptions.push(
                            <Select.Option
                              key={i}
                              value={i}
                              className="month-item"
                            >
                              {months[i]}
                            </Select.Option>
                          );
                        }
                        const year = value.year();
                        const month = value.month();
                        const options = [];
                        for (let i = year - 10; i < year + 10; i += 1) {
                          options.push(
                            <Select.Option
                              key={i}
                              value={i}
                              className="year-item"
                            >
                              {i}
                            </Select.Option>
                          );
                        }

                        return (
                          <div style={{}}>
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Col>
                                <Title level={2}>Calender</Title>
                              </Col>
                              <Flex gap={5}>
                                <Select
                                  size="large"
                                  popupMatchSelectWidth={false}
                                  className="my-year-select"
                                  value={year}
                                  onChange={(newYear) => {
                                    const now = value.clone().year(newYear);
                                    onChange(now);
                                  }}
                                >
                                  {options}
                                </Select>

                                <Select
                                  size="large"
                                  popupMatchSelectWidth={false}
                                  value={month}
                                  onChange={(newMonth) => {
                                    const now = value.clone().month(newMonth);
                                    onChange(now);
                                  }}
                                >
                                  {monthOptions}
                                </Select>
                              </Flex>
                            </Row>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <Modal
                  title={
                    <>
                      <Row align={"middle"} justify={"center"}>
                        <Col>
                          <Text className="Modal-Header">
                            Scheduler Details
                          </Text>
                        </Col>
                      </Row>
                    </>
                  }
                  centered
                  destroyOnClose
                  open={SchedulerModel}
                  onCancel={handleModalClose}
                  onOk={handleModalClose}
                  style={{ minWidth: "40%" }}
                >
                  <Row
                    style={{ display: "flex", flexDirection: "row" }}
                    gutter={[10, 10]}
                  >
                    {SchedulerView ? (
                      SchedulerView.map((cameraEvent) => (
                        <Col
                          xs={{ span: 23, offset: 0 }}
                          sm={{ span: 16, offset: 0 }}
                          md={{ span: 11, offset: 0 }}
                          lg={{ span: 11, offset: 0 }}
                          xl={{ span: 11, offset: 0 }}
                          xxl={{ span: 12, offset: 0 }}
                          key={cameraEvent.cameraName}
                        >
                          <Collapse
                            accordion
                            activeKey={activeKey1[cameraEvent.cameraName]}
                            onChange={(key) =>
                              handlePanelChange(cameraEvent.cameraName, key)
                            }
                          >
                            {cameraEvent.events.map((event) => (
                              <Collapse.Panel
                                header={cameraEvent.cameraName}
                                key={event.eventId}
                              >
                                <Row gutter={[10, 10]}>
                                  <Col
                                    xs={{ span: 23, offset: 0 }}
                                    sm={{ span: 24, offset: 0 }}
                                    md={{ span: 23, offset: 0 }}
                                    lg={{ span: 23, offset: 0 }}
                                    xl={{ span: 23, offset: 0 }}
                                    xxl={{ span: 23, offset: 0 }}
                                  >
                                    <div>
                                      <Title level={5} type="secondary">
                                        Event: {event.eventName}
                                      </Title>
                                      {renderEventOptions(event.option)}
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse.Panel>
                            ))}
                          </Collapse>
                        </Col>
                      ))
                    ) : (
                      <p>No data available</p>
                    )}
                  </Row>
                </Modal>

                <Modal
                  title={
                    <>
                      <Row align={"middle"} justify={"center"}>
                        <Col>
                          <Text className="Modal-Header">
                            Scheduler Details
                          </Text>
                        </Col>
                      </Row>
                    </>
                  }
                  centered
                  destroyOnClose
                  open={SchedulerModel}
                  onCancel={handleModalClose}
                  onOk={handleModalClose}
                  //cancelButtonProps={{ style: { display: "none" } }}
                  style={{
                    minWidth: "40%",
                  }}
                >
                  <Row
                    style={{ display: "flex", flexDirection: "row" }}
                    gutter={[10, 10]}
                  >
                    {SchedulerView ? (
                      SchedulerView.map((cameraEvent) => (
                        <Col
                          xs={{ span: 23, offset: 0 }}
                          sm={{ span: 16, offset: 0 }}
                          md={{ span: 11, offset: 0 }}
                          lg={{ span: 11, offset: 0 }}
                          xl={{ span: 11, offset: 0 }}
                          xxl={{ span: 12, offset: 0 }}
                          key={cameraEvent.cameraName}
                        >
                          <Collapse
                            accordion
                            // activeKey={activeKey1}
                            activeKey={activeKey1[cameraEvent.cameraName]}
                            // onChange={handlePanelChange}
                            onChange={(key) =>
                              handlePanelChange(cameraEvent.cameraName, key)
                            }
                          >
                            {cameraEvent.events.map((event) => (
                              <Collapse.Panel
                                header={cameraEvent.cameraName}
                                key={event.eventId}
                              >
                                <Row gutter={[10, 10]}>
                                  <Col
                                    xs={{ span: 23, offset: 0 }}
                                    sm={{ span: 24, offset: 0 }}
                                    md={{ span: 23, offset: 0 }}
                                    lg={{ span: 23, offset: 0 }}
                                    xl={{ span: 23, offset: 0 }}
                                    xxl={{ span: 23, offset: 0 }}
                                  >
                                    <div>
                                      <Title level={5} type="secondary">
                                        Event: {event.eventName}
                                      </Title>
                                      {renderEventOptions(event.option)}
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse.Panel>
                            ))}
                          </Collapse>
                        </Col>
                      ))
                    ) : (
                      <p>No data available</p>
                    )}
                  </Row>
                </Modal>
              </TabPane> */}
              <TabPane tab="History" key="3">
                <div style={{ backgroundColor: "#f5f7f7", height: "100vh" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      backgroundColor: "#000816",
                    }}
                  >
                    {/* <p
                      style={{
                        color: "white",
                        fontSize: "21px",
                        fontFamily: "revert-layer",
                        marginLeft: "3%",
                      }}
                    >
                      History
                    </p> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // height: "70%",
                      minHeight: "77vh",
                      backgroundColor: "#f5f7f7",
                    }}
                  >
                    <Card
                      style={{
                        // display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        margin: "12px 30px",
                        // height: "77vh",
                        minHeight: "77vh",
                        // maxHeight: auto,
                        width: "70%",
                        backgroundColor: "white",
                        overflowY: "scroll",
                        overflowX: "auto",
                      }}
                    >
                      <RangePicker
                        style={{ marginBottom: "50px" }}
                        onChange={handleDateRangeChange}
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        //disabledDate={disabledDate}
                      />

                      {currentData.length > 0 ? (
                        <>
                          <Timeline
                            // mode="right"
                            mode="left"
                            style={{
                              textAlign: "center",
                              marginRight: "300px",
                              // justifyContent: "flex-start",
                            }}
                            // style={{
                            //   textAlign: "center",
                            //   margin: "0 auto", // Center align the Timeline
                            // }}
                          >
                            {currentData.map((item, index) => {
                              const dot = {
                                icon:
                                  item.status === "delete" ? (
                                    <RiDeleteBin2Fill
                                      style={{ fontSize: "21px" }}
                                    />
                                  ) : item.status === "add" ? (
                                    <MdNoteAdd style={{ fontSize: "21px" }} />
                                  ) : (
                                    <TbAlertOctagonFilled
                                      style={{ fontSize: "21px" }}
                                    />
                                  ),
                                color:
                                  item.status === "delete"
                                    ? "red"
                                    : item.status === "add"
                                    ? "green"
                                    : "blue",
                              };

                              // Determine the title based on the status
                              const title =
                                item.status === "delete"
                                  ? "Delete"
                                  : item.status === "add"
                                  ? "Add"
                                  : item.status === "server"
                                  ? "Server Log"
                                  : "Schedule Log";

                              return (
                                <Timeline.Item
                                  key={index}
                                  dot={dot.icon}
                                  color={dot.color}
                                  label={<span>{item.date}</span>}
                                >
                                  {/* Display title above the message */}
                                  <strong style={{ fontSize: "17px" }}>
                                    {title}
                                  </strong>
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      color: "gray",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {formatDate(item.date)}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      whiteSpace:
                                        item.msg.split(" ").length > 14
                                          ? "normal"
                                          : "nowrap",
                                    }}
                                  >
                                    {item.msg}
                                  </span>
                                </Timeline.Item>
                              );
                            })}
                          </Timeline>

                          <Pagination
                            current={historycurrentpage}
                            pageSize={historypageSize}
                            total={historydata.length}
                            onChange={onPageChange}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          />
                        </>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "160px",
                            color: "gray",
                            height: "44vh",
                          }}
                        >
                          <Empty />
                        </div>
                      )}
                    </Card>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>

      {/* Loading  */}
      <Modal
        className="LoadinModal"
        open={Pageload}
        style={{ background: "none", textAlign: "center" }}
        footer={false}
        closable={false}
        centered
      >
        <ClipLoader
          color="#23234d"
          loading={Pageload}
          cssOverride={{
            display: "block",
            margin: "0 auto",

            borderWidth: "5px",
            marginBottom: "10px",
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
          title="Loading..."
        />
        <Text style={{ color: "white", fontSize: "1.2em" }}>
          Loading <PulseLoader color="#fff" size={2} />
        </Text>
      </Modal>

      {/* Basic event adding model */}
      <Modal
        destroyOnClose
        centered
        open={NewEventModalOpen}
        title={
          <>
            <Row align={"middle"} justify={"center"}>
              <Col>
                <Text className="Modal-Header">Event Addition</Text>
              </Col>
            </Row>
          </>
        }
        footer={
          <>
            <Row className="top-space" justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submitbasicform"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={handlecreateeventclose}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
      >
        <Form
          id="submitbasicform"
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 22,
          }}
          initialValues={
            {
              // remember: true,
            }
          }
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            className="top-space"
            label="Camera List"
            name="cameralist"
            rules={[
              {
                required: true,
                message: "Please select!",
              },
            ]}
          >
            <div>
              <MultiSelect
                options={cameralistmultiselect}
                value={selectedcameralist}
                onChange={setselectedcameralist}
                labelledBy="Select"
              />
            </div>
          </Form.Item>

          <Form.Item
            label="Camera Events"
            name="events"
            rules={[
              {
                required: true,
                message: "Please Select Camera Events!",
              },
            ]}
          >
            <div>
              <MultiSelect
                options={events}
                value={Selectedevents}
                onChange={setSelectedevents}
                labelledBy="Select"
              />
            </div>
          </Form.Item>

          <Form.Item
            className="top-space"
            label="Schedule Time"
            name="time"
            rules={[
              {
                required:
                  selectedtimes.start_time.length !==
                  selectedtimes.end_time.length
                    ? true
                    : false,
                message: "Please select hours",
              },
            ]}
            tooltip={
              "selected events will run between this scheduled times only"
            }
          >
            <Flex justify="space-evenly">
              <Row style={{ width: "100%" }}>
                <Col
                  xs={{ span: 22, offset: 0 }}
                  sm={{ span: 22, offset: 0 }}
                  md={{ span: 22, offset: 0 }}
                  lg={{ span: 22, offset: 0 }}
                  xl={{ span: 22, offset: 0 }}
                  xxl={{ span: 22, offset: 0 }}
                  key={selectedtimes.count}
                >
                  {List_time()}
                </Col>
                <Col
                  xs={{ span: 2, offset: 0 }}
                  sm={{ span: 2, offset: 0 }}
                  md={{ span: 2, offset: 0 }}
                  lg={{ span: 2, offset: 0 }}
                  xl={{ span: 2, offset: 0 }}
                  xxl={{ span: 2, offset: 0 }}
                  style={{ display: "flex", alignItems: "inherit" }}
                >
                  <Flex
                    vertical
                    justify={selectedtimes.count > 2 ? "space-evenly" : "unset"}
                  >
                    <Flex
                      align="center"
                      justify="center"
                      // style={{ height: "100%" }}
                    >
                      <Button
                        onClick={Handle_Add_time}
                        // type="primary"
                        shape="default"
                        icon={<IoMdAdd />}
                      />

                      {/* <Button>
                      <IoMdAdd />
                    </Button> */}
                    </Flex>
                    {selectedtimes.count > 1 ? (
                      <Flex
                        align="center"
                        justify="center"
                        // style={{ height: "100%" }}
                      >
                        <Button
                          onClick={Handle_minus_time}
                          // type="primary"
                          shape="default"
                          icon={<BiMinus />}
                        />

                        {/* <Button>
                      <IoMdAdd />
                    </Button> */}
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Col>
              </Row>
            </Flex>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit event */}
      <Modal
        destroyOnClose
        centered
        open={NewEventModalOpen}
        title={
          <>
            <Row align={"middle"} justify={"center"}>
              <Col>
                <Text className="Modal-Header">Event Addition</Text>
              </Col>
            </Row>
          </>
        }
        footer={
          <>
            <Row className="top-space" justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submitbasicform"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={handlecreateeventclose}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
      >
        <Form
          id="submitbasicform"
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 22,
          }}
          initialValues={
            {
              // remember: true,
            }
          }
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            className="top-space"
            label="Camera List"
            name="cameralist"
            rules={[
              {
                required: true,
                message: "Please select!",
              },
            ]}
          >
            <div>
              <MultiSelect
                options={cameralistmultiselect}
                value={selectedcameralist}
                onChange={setselectedcameralist}
                labelledBy="Select"
              />
            </div>
          </Form.Item>

          <Form.Item
            label="Camera Events"
            name="events"
            rules={[
              {
                required: true,
                message: "Please Select Camera Events!",
              },
            ]}
          >
            <div>
              <MultiSelect
                options={events}
                value={Selectedevents}
                onChange={setSelectedevents}
                labelledBy="Select"
              />
            </div>
          </Form.Item>

          <Form.Item
            className="top-space"
            label="Schedule Time"
            name="time"
            rules={[
              {
                required:
                  selectedtimes.start_time.length !==
                  selectedtimes.end_time.length
                    ? true
                    : false,
                message: "Please select hours",
              },
            ]}
            tooltip={
              "selected events will run between this scheduled times only"
            }
          >
            <Flex justify="space-evenly">
              <Row style={{ width: "100%" }}>
                <Col
                  xs={{ span: 22, offset: 0 }}
                  sm={{ span: 22, offset: 0 }}
                  md={{ span: 22, offset: 0 }}
                  lg={{ span: 22, offset: 0 }}
                  xl={{ span: 22, offset: 0 }}
                  xxl={{ span: 22, offset: 0 }}
                  key={selectedtimes.count}
                >
                  {List_time()}
                </Col>
                <Col
                  xs={{ span: 2, offset: 0 }}
                  sm={{ span: 2, offset: 0 }}
                  md={{ span: 2, offset: 0 }}
                  lg={{ span: 2, offset: 0 }}
                  xl={{ span: 2, offset: 0 }}
                  xxl={{ span: 2, offset: 0 }}
                  style={{ display: "flex", alignItems: "inherit" }}
                >
                  <Flex
                    vertical
                    justify={selectedtimes.count > 2 ? "space-evenly" : "unset"}
                  >
                    <Flex
                      align="center"
                      justify="center"
                      // style={{ height: "100%" }}
                    >
                      <Button
                        onClick={Handle_Add_time}
                        // type="primary"
                        shape="default"
                        icon={<IoMdAdd />}
                      />

                      {/* <Button>
                      <IoMdAdd />
                    </Button> */}
                    </Flex>
                    {selectedtimes.count > 1 ? (
                      <Flex
                        align="center"
                        justify="center"
                        // style={{ height: "100%" }}
                      >
                        <Button
                          onClick={Handle_minus_time}
                          // type="primary"
                          shape="default"
                          icon={<BiMinus />}
                        />

                        {/* <Button>
                      <IoMdAdd />
                    </Button> */}
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Col>
              </Row>
            </Flex>
          </Form.Item>
        </Form>
      </Modal>

      {/* Add env event */}

      <Modal
        destroyOnClose
        centered
        open={NewENVevent}
        onCancel={() => setNewENVevent(false)}
        title={
          <>
            <Row align={"middle"} justify={"center"}>
              <Col>
                <Text className="Modal-Header">Event Addition</Text>
              </Col>
            </Row>
          </>
        }
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="ENVsubmit"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={HandleNewENVeventclose}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
      >
        <Skeleton
          loading={
            DataController.loading ||
            DwellController.loading ||
            FootFallController.loading ||
            FootFallDirectionController.loading
          }
          active
          round
        >
          <Form
            id="ENVsubmit"
            form={basicform}
            name="basic"
            labelCol={{
              span: 11,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinishenv}
            onFinishFaileonFinishenvd={(e) => {
              message.error(e.errorFields[0].errors[0]);
            }}
            autoComplete="off"
          >
            <Form.Item
              className="top-space"
              label="Camera List"
              name="envcameralist"
              rules={[
                {
                  type: "number",
                  required: true,
                  message: "Please select any Camera!",
                },
              ]}
            >
              <Select
                onChange={(e) => {
                  setCommonEventController((prev) => ({
                    SelectedCamera: e && e,
                    DisableEvent: false,
                  }));

                  setDataController(InitialData);
                  setFootFallController(InitialData);
                  // setFootFallDirectionController(InitialData);
                  setDwellController(InitialData);
                  basicform.resetFields(["envevents"]);
                }}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select any Camera..."
              >
                {cameralist.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.cameraName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="server"
              label="Select Server"
              // // value={selectedServer}
              // onChange={onServerChange}
              rules={[{ required: true, message: "Please select a server" }]}
            >
              <Select
                placeholder={<span>Select a Server</span>}
                // style={{
                //   width: 300,
                //   height: 42,
                //   marginLeft: "20px",
                // }}
                value={selectedServer}
                onChange={onServerChange}
              >
                {getserverdata.map((server) => (
                  <Select.Option key={server.id} value={server.id}>
                    {server.serverName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selectedServer && (
              <Col
                style={{ textAlign: "center", marginBottom: "20px" }}
                span={24}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "28%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "5px",
                    }}
                  >
                    {cpuUsage ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "20px",
                        }}
                      >
                        <span style={{ fontSize: "17px", color: "grey" }}>
                          CPU: {cpuUsage}%
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "30px",
                        }}
                      >
                        <span style={{ fontSize: "16px", color: "grey" }}>
                          CPU: unavailable
                        </span>
                      </div>
                    )}
                  </div>

                  {gpuUsage && gpuUsage.length > 0 ? (
                    gpuUsage.map((gpu, index) => {
                      const gpuKey = `gpu${index + 1}`;
                      const gpuValue = gpu[gpuKey];

                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "30px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              color: "grey",
                            }}
                          >
                            {gpuValue !== null
                              ? index === 0
                                ? `GPU: ${gpuValue}%`
                                : `GPU ${index + 1}: ${gpuValue}%`
                              : `GPU ${index + 1}: undefined`}
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "30px",
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "grey" }}>
                        GPU: unavailable
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            )}

            {/* {selectedServer &&
            (cpuUsage ||
              (gpuUsage && gpuUsage.some((gpu) => gpu.gpu1 !== null))) ? (
              <Col
                style={{ textAlign: "center", marginBottom: "20px" }}
                span={24}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "24%",
                  }}
                >
                  {cpuUsage && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "5px",
                      }}
                    >
                      <span style={{ fontSize: "17px", color: "grey" }}>
                        CPU: {cpuUsage || 0}%
                      </span>
                    </div>
                  )}

                  {gpuUsage &&
                  gpuUsage.length > 0 &&
                  gpuUsage.some((gpu) => gpu.gpu1 !== null)
                    ? gpuUsage.map((gpu, index) => {
                        const gpuKey = `gpu${index + 1}`;
                        const gpuValue = gpu[gpuKey];

                        return gpuValue !== null ? (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "30px",
                            }}
                          >
                            <span style={{ fontSize: "17px", color: "grey" }}>
                              {index === 0
                                ? `GPU: ${gpuValue}%`
                                : `GPU ${index + 1}: ${gpuValue}%`}
                            </span>
                          </div>
                        ) : null;
                      })
                    : null}
                </div>
              </Col>
            ) : null} */}

            <Form.Item
              label="Camera Events"
              name="envevents"
              rules={[
                {
                  required: true,
                  message: "Please select an Event!",
                },
              ]}
            >
              <Select
                disabled={CommonEventController.DisableEvent}
                onChange={(value) => {
                  // setCommonEventController((prev) => ({
                  //   ...prev,
                  //   SlectedEvent: value, // Update selected event
                  // }));
                  properties(value);
                }}
                onClear={() => {
                  basicform.resetFields(["envevents"]);
                  setDataController(InitialData);
                  setCommonEventController((prev) => ({
                    ...prev,
                    DisableEvent: false,
                  }));
                  setDwellController(InitialData);
                  setFootFallDirectionController(InitialData);
                  setFootFallController(InitialData);
                }}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select an Event..."
              >
                {EnvEvents.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {showIntimeOuttimeDropdown && (
              <Form.Item
                label="Select Time"
                name="intimeOuttime"
                rules={[
                  {
                    required: true,
                    message: "Please select a time!",
                  },
                ]}
              >
                <Select
                  placeholder="Select intime or outtime"
                  onChange={handleIntimeOuttimeChange}
                >
                  {dropdownOptions.map((option) => (
                    <Option key={option.value} value={option.label}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {FootFallController.DisableCheckbox ||
            DwellController.DisableCheckbox ? (
              <></>
            ) : (
              <Form.Item
                label="Select type"
                name="checkbox"
                rules={[
                  {
                    required: true,
                    message: "Please select any type!",
                  },
                ]}
              >
                <Checkbox.Group
                  name="checkbox"
                  className="subtype"
                  disabled={
                    FootFallController.DisableCheckbox ||
                    DwellController.DisableCheckbox
                  }
                  onChange={HandleCheckbox}
                  options={[
                    ...FootfallTypes, // Main options from FootfallTypes
                    { label: "Passerby", value: "passerby" }, // Adding the "Passerby" option
                  ]}
                ></Checkbox.Group>
              </Form.Item>
            )}
            {/* {showTimeFields && (
              <>
                <Form.Item
                  label="Start Time"
                  name="startTime"
                  rules={[
                    { required: true, message: "Please select start time!" },
                  ]}
                >
                  <TimePicker format="HH:mm" />
                </Form.Item>
                <Form.Item
                  label="End Time"
                  name="endTime"
                  rules={[
                    { required: true, message: "Please select end time!" },
                  ]}
                >
                  <TimePicker format="HH:mm" />
                </Form.Item>
              </>
            )} */}
            {CommonEventController.SlectedEvent === 3 &&
            showMarkAreaField &&
            DataController.Marker ? (
              <Form.Item
                label="Mark the ANPR Area"
                name="MarkingArea"
                tooltip="Mark the area using Mouse"
                rules={[
                  {
                    type: "array",
                    required: DataController.FinalCoordinates.length < 1,
                    message: "Please select the Area!",
                  },
                ]}
              >
                <Link
                  onClick={() => {
                    if (DataController.FrameReceived) {
                      OpenModaltoMark();
                    } else {
                      message.error("Image unavailable for selected event");
                    }
                  }}
                >
                  Click here to Mark the area..
                </Link>
              </Form.Item>
            ) : CommonEventController.SlectedEvent === 13 &&
              FootFallController.Marker ? (
              <Form.Item
                label="Mark the Footfall Area"
                name="MarkingArea"
                tooltip="Mark the area using Mouse"
                rules={[
                  {
                    type: "array",
                    required: FootFallController.FinalCoordinates.length < 1,
                    message: "Please select the Area!",
                  },
                ]}
              >
                <Link
                  onClick={() => {
                    if (FootFallController.FrameReceived) {
                      FootfallOpenModaltoMark();
                    } else {
                      message.error("Image unavailable for selected event");
                    }
                  }}
                >
                  Click here to Mark the area..
                </Link>
              </Form.Item>
            ) : (CommonEventController.SlectedEvent === 5 ||
                CommonEventController.SlectedEvent === 10 ||
                CommonEventController.SlectedEvent === 6) &&
              DataController.Marker ? (
              <Form.Item
                label="Mark the Area"
                name="MarkingArea"
                tooltip="Mark the area using Mouse"
                rules={[
                  {
                    type: "array",
                    required: DataController.FinalCoordinates.length < 1,
                    message: "Please select the Area!",
                  },
                ]}
              >
                <Link
                  onClick={() => {
                    if (DataController.FrameReceived) {
                      OpenModaltoMark();
                    } else {
                      message.error("Image unavailable for selected event");
                    }
                  }}
                >
                  Click here to Mark the area..
                </Link>
              </Form.Item>
            ) : null}

            {FootFallDirectionController.DisableCheckbox ? (
              <></>
            ) : (
              <Form.Item
                label="Select type"
                name="checkboxdirection"
                rules={[
                  {
                    required: true,
                    message: "Please select any type!",
                  },
                ]}
              >
                <Checkbox.Group
                  className="subtype"
                  disabled={FootFallDirectionController.DisableCheckbox}
                  onChange={HandleCheckboxDirection}
                  options={FootfallTypesDirection}
                ></Checkbox.Group>
              </Form.Item>
            )}
            {DwellController.Marker ? (
              <Form.Item
                label="Mark the Area for Dwell-Time"
                name="MarkingArea"
                tooltip="Mark the area using Mouse"
                rules={[
                  {
                    type: "array",
                    required:
                      DwellController.FinalCoordinates.length >= 1
                        ? false
                        : true,
                    message: "Please select the Area!",
                  },
                ]}
              >
                <Link
                  onClick={() => {
                    if (DwellController.FrameReceived) {
                      DwellOpenModaltoMark();
                      if (DataController.SelectedEventid === 14) {
                        message.info("Plaese Mark the Starting Point");
                      }
                    } else {
                      message.error("Image unavailable for selected event");
                    }
                  }}
                >
                  Click here to Mark the area..
                </Link>
              </Form.Item>
            ) : (
              <></>
            )}
            {/* {FootFallController.Marker ? (
              <Form.Item
                label="Mark the Area "
                name="MarkingArea"
                tooltip="Mark the area using Mouse"
                rules={[
                  {
                    type: "array",
                    required:
                      FootFallController.FinalCoordinates.length >= 1
                        ? false
                        : true,
                    message: "Please select the Area!",
                  },
                ]}
              >
                <Link
                  onClick={() => {
                    if (FootFallController.FrameReceived) {
                      FootfallOpenModaltoMark();
                      if (FootFallController.SelectedEventid === 13) {
                        message.info("Plaese Mark the Innerouter area");
                      }
                    } else {
                      message.error("Image unavailable for selected event");
                    }
                  }}
                >
                  Click here to Mark the area..
                </Link>
              </Form.Item>
            ) : (
              <></>
            )} */}
            {FootFallDirectionController.Marker ? (
              <Form.Item
                label="Mark the Area for Footfall Direction"
                name="MarkingArea"
                tooltip="Mark the area using Mouse"
                labelCol={13}
                rules={[
                  {
                    type: "array",
                    required:
                      FootFallDirectionController.FinalCoordinates.length >= 1
                        ? false
                        : true,
                    message: "Please select the Area!",
                  },
                ]}
              >
                <Link
                  onClick={() => {
                    if (FootFallDirectionController.FrameReceived) {
                      FootfallDirectionOpenModal();
                      if (CommonEventController.SlectedEvent === 14) {
                        message.info(
                          "Plaese Mark the Starting point for Footfall Direction"
                        );
                      }
                    } else {
                      message.error("Image unavailable for selected event");
                    }
                  }}
                >
                  Click here to Mark the area..
                </Link>
              </Form.Item>
            ) : (
              <></>
            )}
            {CommonEventController.SlectedEvent === 5 && (
              <Form.Item
                className="top-space"
                label="Scheduler"
                name="schedule"
              >
                <Checkbox
                  checked={SchedulerStatus}
                  onChange={SchedulerCheckbox}
                >
                  Schedule this event!
                </Checkbox>
              </Form.Item>
            )}
          </Form>
        </Skeleton>
      </Modal>

      <Drawer
        title="Event scheduler"
        width={"30%"}
        onClose={SChedulerCancel}
        destroyOnClose
        open={ScheduleDrawerContoller.OpenDrawer}
        // extra={
        //   <Space>
        //     <Button onClick={SChedulerCancel}>Cancel</Button>
        //     <Button onClick={SChedulerSubmit} type="primary">
        //       Submit
        //     </Button>
        //   </Space>
        // }
      >
        <Row style={{ height: "100%", width: "100%" }}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 24, offset: 0 }}
          >
            <Form
              name="SchedulerForm"
              id="ENVsubmit"
              form={ScheduleForm}
              layout="horizontal"
              labelCol={{
                span: 12,
              }}
              wrapperCol={{
                span: 18,
              }}
              labelAlign="right"
              onFinish={testFinish}
              onFinishFailed={(e) => {
                message.error(e.errorFields[0].errors[0]);
              }}
              autoComplete="off"
              style={{ height: "100%", width: "100%" }}
            >
              <Flex justify="center" align="center">
                <Radio.Group
                  defaultValue={1}
                  buttonStyle="solid"
                  onChange={(values) => {
                    ScheduleSlector(values);
                  }}
                >
                  <Radio.Button value={1}>One Time</Radio.Button>
                  <Radio.Button value={2}>EveryDay</Radio.Button>
                  <Radio.Button value={3}>Custom</Radio.Button>
                </Radio.Group>
              </Flex>

              {SchedulerController.SchedluerType === 1 && (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ marginTop: "20%" }}
                >
                  <>
                    <Flex>
                      <Alert
                        className="scheduler-alert"
                        message={
                          <>
                            <Title level={5}>Time-Based Event Scheduler</Title>
                          </>
                        }
                        description="The scheduler triggers events at a specified start time, monitors them, and ends them at a specified end time."
                        type="info"
                        showIcon
                      />
                    </Flex>
                    <Flex
                      className="top-space"
                      // justify="center"
                      // align="center"
                      gap={5}
                      style={{ width: "80%" }}
                    >
                      <Form.Item
                        name="start"
                        label="Start time"
                        rules={[
                          {
                            type: "date",
                            required: true,
                            message: "Please select Start Date!",
                          },
                        ]}
                      >
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          placeholder="Date"
                          disabledDate={disableStartDate}
                          onChange={(value, dateString) => {
                            setOneTimeSChedule((prev) => ({
                              ...prev,
                              StartDate: dateString,
                            }));
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="starthour"
                        rules={[
                          {
                            required: true,
                            message: "Please select Hour!",
                          },
                        ]}
                      >
                        <TimePicker
                          key={OneTimeSChedule.StartTime}
                          style={{ width: 100 }}
                          format={"HH"}
                          placeholder="Hour"
                          disabledTime={disabledDateTimeStart}
                          onChange={(e, hour) => {
                            setOneTimeSChedule((prev) => ({
                              ...prev,
                              StartTime: hour + ":00:00",
                            }));
                          }}
                          changeOnScroll
                        />
                      </Form.Item>
                    </Flex>
                    <Flex
                      // justify="center"
                      gap={5}
                      style={{ width: "80%" }}
                    >
                      <Form.Item
                        name="enddate"
                        label="End time"
                        rules={[
                          {
                            type: "date",
                            required: true,
                            message: "Please select End Date!",
                          },
                        ]}
                      >
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          placeholder="Date"
                          disabledDate={disableEndDate}
                          needConfirm={false}
                          onChange={(value, dateString) => {
                            setOneTimeSChedule((prev) => ({
                              ...prev,
                              EndDate: dateString,
                            }));
                            disabledDateTimeEnd();
                          }}
                          // onOk={onOk}
                        />
                      </Form.Item>
                      <Form.Item
                        name="endhour"
                        rules={[
                          {
                            type: "date",
                            required: true,
                            message: "Please select Hour!",
                          },
                        ]}
                      >
                        <TimePicker
                          key={OneTimeSChedule.EndTime}
                          style={{ width: 100 }}
                          format={"HH"}
                          placeholder="Hour"
                          disabledTime={disabledDateTimeEnd}
                          needConfirm={true}
                          onChange={(e, hour) => {
                            setOneTimeSChedule((prev) => ({
                              ...prev,
                              EndTime: hour + ":00:00",
                            }));
                          }}
                          changeOnScroll
                        />
                      </Form.Item>
                    </Flex>
                  </>
                </Flex>
              )}

              {SchedulerController.SchedluerType === 2 && (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ marginTop: "20%" }}
                >
                  <>
                    <Flex>
                      <Alert
                        className="scheduler-alert"
                        message={
                          <>
                            <Title level={5}>Daily Event Runner</Title>
                          </>
                        }
                        description=" The Daily Event scheduler triggers, monitors, and ends events daily within specified start and end times, providing real-time updates."
                        type="info"
                        showIcon
                      />
                    </Flex>
                    <Flex className="top-space" gap={5}>
                      <Form.Item
                        name="starthour"
                        label="Start time"
                        rules={[
                          {
                            type: "date",
                            required: true,
                            message: "Please select Hour!",
                          },
                        ]}
                      >
                        <TimePicker
                          key={EveryDaySChedule.StartTime}
                          style={{ width: 100 }}
                          format={"HH"}
                          placeholder="Hour"
                          needConfirm={true}
                          onChange={(e, hour) => {
                            setEveryDaySChedule((prev) => ({
                              ...prev,
                              StartTime: hour + ":00",
                            }));
                          }}
                          changeOnScroll
                        />
                      </Form.Item>
                    </Flex>

                    <Flex gap={5}>
                      <Form.Item
                        name="endhour"
                        label="End time"
                        rules={[
                          {
                            type: "date",
                            required: true,
                            message: "Please select Hour!",
                          },
                        ]}
                      >
                        <TimePicker
                          key={EveryDaySChedule.EndTime}
                          style={{ width: 100 }}
                          format={"HH"}
                          placeholder="Hour"
                          needConfirm={true}
                          disabledHours={() => {
                            if (EveryDaySChedule.StartTime) {
                              // Disable hours less than or equal to the selected start time
                              const startHour = parseInt(
                                EveryDaySChedule.StartTime.split(":")[0],
                                10
                              );
                              return Array.from(
                                { length: startHour + 1 },
                                (_, i) => i
                              );
                            }
                            return [];
                          }}
                          onChange={(e, hour) => {
                            setEveryDaySChedule((prev) => ({
                              ...prev,
                              EndTime: hour + ":00",
                            }));
                          }}
                          changeOnScroll
                        />
                      </Form.Item>
                    </Flex>
                  </>
                </Flex>
              )}

              {SchedulerController.SchedluerType === 3 && (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ marginTop: "20%" }}
                >
                  <>
                    <Flex>
                      <Alert
                        className="scheduler-alert"
                        message={
                          <>
                            <Title level={5}>Customized Event Manager</Title>
                          </>
                        }
                        description="Efficiently manage AI tasks by scheduling them to run at specific times and days automatically."
                        type="info"
                        showIcon
                      />
                    </Flex>

                    <Flex className="top-space">
                      <WeekdaysInput
                        value={daysAsString}
                        showChars={3}
                        textCase={"firstToUpper"}
                        onChange={(value) => {
                          OnChangeDay(value);
                        }}
                      />
                    </Flex>
                    <Flex className="top-space" gap={5}>
                      <Form.Item
                        name="starthour"
                        label="Start time"
                        rules={[
                          {
                            type: "date",
                            required: true,
                            message: "Please select Hour!",
                          },
                        ]}
                      >
                        <TimePicker
                          key={CustomSChedule.StartTime}
                          style={{ width: 100 }}
                          format={"HH"}
                          placeholder="Hour"
                          needConfirm={true}
                          onChange={(e, hour) => {
                            setCustomSChedule((prev) => ({
                              ...prev,
                              StartTime: hour + ":00",
                            }));
                          }}
                          changeOnScroll
                        />
                      </Form.Item>
                    </Flex>

                    <Flex gap={5}>
                      <Form.Item
                        name="endhour"
                        label="End time"
                        rules={[
                          {
                            type: "date",
                            required: true,
                            message: "Please select Hour!",
                          },
                        ]}
                      >
                        <TimePicker
                          key={CustomSChedule.EndTime}
                          style={{ width: 100 }}
                          format={"HH"}
                          placeholder="Hour"
                          needConfirm={true}
                          disabledHours={() => {
                            if (CustomSChedule.StartTime) {
                              // Disable hours less than or equal to the selected start time
                              const startHour = parseInt(
                                CustomSChedule.StartTime.split(":")[0],
                                10
                              );
                              return Array.from(
                                { length: startHour + 1 },
                                (_, i) => i
                              );
                            }
                            return [];
                          }}
                          onChange={(e, hour) => {
                            setCustomSChedule((prev) => ({
                              ...prev,
                              EndTime: hour + ":00",
                            }));
                          }}
                          changeOnScroll
                        />
                      </Form.Item>
                    </Flex>
                  </>
                </Flex>
              )}

              {/* <Form.Item className="top-space" style={{ minWidth: "100%" }}> */}
              <Flex
                gap={10}
                justify="center"
                align="center"
                className="top-space"
              >
                <Button onClick={SChedulerCancel}>Cancel</Button>

                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Flex>
              {/* </Form.Item> */}
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}

export default EventSetup;