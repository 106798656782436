// import React from "react";
import { Button, Col,  Input, Row, Select, Tooltip, Form, Typography, Modal, message, Card, Empty} from "antd";
import React, { useState, useEffect } from "react";
import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";
import ClipLoader from "react-spinners/ClipLoader";
import { UserOutlined, EditOutlined,DeleteOutlined} from "@ant-design/icons";
import _, {  } from "lodash";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { FaNfcDirectional } from "react-icons/fa6";

const StoreOnboarding = () => {
  const { Text } = Typography;
  const [modal, setModal] = useState(false);
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [AddRefresh, setAddRefresh] = useState(1);
  const [Pageload, setPageload] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [tabledata, settabledata] = useState([]);
  const [editrecord, seteditrecord] = useState(null);
  const [editModal, seteditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [ReasonModal, setReasonModal] = useState(false);
  const [customState, setCustomState] = useState(null);
  const [event, setevent] = useState([]);
  const { Option } = Select;
  const onclickmodal = () => {
    setModal(true);
    form.resetFields();
  };

  const handlecreateeventclose = () => {
    setModal(false);
    form.resetFields();
  };

  //onfish of store onboard details
  const onFinish = (values) => {
    const cleanedOnBoardLocation = values.location.replace(/\s+/g, "");
    axiosClient
      .post("/storeonboard/addstoreonboarddetails ", {
        storeName: values.storeName,
        eventId: values.events,
        onBoardLocation: cleanedOnBoardLocation,
        region: values.region,
        country: selectedCountry,
        state: values.state,
        city: values.city,
        pincode: values.pincode,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.data);

          setAddRefresh(AddRefresh + 1);
          setModal(false);

          form.resetFields();
        }
      })
      .catch((err) => {
        setModal(false);
        setPageload(false);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const onEditRecord = (items) => {
    seteditModal(true);
    // const eventNames = items.eventName.map((event) => event.event_name);

    editform.setFieldsValue({
      storeName: items.storeName,
      onBoardLocation: items.onBoardLocation,
      events: items.eventName,
      region: items.region,
      country: items.country,
      state: items.state,
      city: items.city,
      pincode: items.pincode,
    });
  };
 
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  //get state dropdown List
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    const country = value;

    axiosClient
      .get("/storeonboard/getstate?country=" + country)
      .then((response) => {
        if (response) {
          let Options = response.data.data.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          setselectedState(Options);
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  const handleclose = () => { seteditModal(false);};

  const onFinishedit = (values) => {
    axiosClient
      .put("storeonboard/editstoreonboarddetails", {
        id: editrecord.id,
        storeName: values.storeName,
        region: values.region,
        pincode: values.pincode,
      })
      .then((response) => {
        if (response.status === 200) {
          // setPageload(false)
          seteditModal(false);
          setAddRefresh(AddRefresh + 1);
          message.success(response.data.msg);
        } else {
          setPageload(false);
          message.error(response.data.msg);
        }
      })
      .catch((err) => {
        seteditModal(false);
        // setPageload(false)
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    form.resetFields();
  };

  //Delete Record
  const onDeleteRecord = (items) => {
    Modal.confirm({
      title: (
        <div>
          <h3>Confirm Delete?</h3>
          <h4>
            {" "}
            All the policy and Camera associated to this store will be deleted
          </h4>
        </div>
      ),
      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        axiosClient
          .put("/storeonboard/deletestoreonboarddetails?id=" + items.id)
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setAddRefresh(AddRefresh + 1);
            } else {
              setAddRefresh(AddRefresh + 1);
            }
          })
          .catch((err) => {
            if (err.status === 0) {
              message.error("Server error");
              setAddRefresh(AddRefresh + 1);
            } else {
              message.error(err.msg);
            }
          });
      },
    });
    form.resetFields();
  };

  const oncancelofform = () => {
    setModal(false);
    form.resetFields();
  };

  const checkstorename = (Text) => {
    let list = tabledata && _.map(tabledata, "storeName");
    let checklist = _.includes(list, Text);
    if (checklist) {
      return Promise.reject("store Name  name is already exists");
    } else {
      return Promise.resolve();
    }
  };

  const checklocationname = (Text) => {
    // Convert the input text to uppercase
    const uppercaseText = Text.toUpperCase();

    // Map tabledata to get a list of onboard locations in uppercase
    let list =
      tabledata &&
      _.map(tabledata, (item) => item.onBoardLocation.toUpperCase());

    // Check if the uppercase input text is included in the list
    let checklist = _.includes(list, uppercaseText);

    if (checklist) {
      return Promise.reject(new Error("OnBoardLocation name already exists"));
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    axiosClient
      .get("/storeonboard/getcountries")
      .then((response) => {
        if (response) {
          setCountries(response.data.data);
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });

    axiosClient
      .get("/storeonboard/getalleventdetails")
      .then((response) => {
        if (response) {
          const events = response.data.data.map((item) => ({
            label: item.event_name,
            value: item.id,
          }));

          // Set the events to your state
          setevent(events);
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  useEffect(() => {
     const fetchData1 = () => {
       axiosClient
         .get("/storeonboard/getstoreonboarddetails")
         .then((response) => {
           if (response && response.data && response.data.data) {
             // Flatten the nested arrays
             const flattenedData = response.data.data.flat();
             settabledata(flattenedData);
           }
         })
         .catch((err) => {
           if (err.status === 0) {
             message.error("Server error");
           } else {
             message.error(err.msg);
           }
         });
     };
 
     fetchData1();
   }, [AddRefresh]);

  return (
    <>
      <div
        className="report-content"
        style={{
          // height: "120px",
          backgroundColor: "#000816",
          width: "100%",
          // marginLeft: "9px",
          margin: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "3%",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "21px",
              fontFamily: "revert-layer",
            }}
          >
            Store Onboarding
          </p>
          <div style={{ display: "flex", marginRight: "3%" }}>
            <Button
              style={{ userSelect: "none" }}
              className="adduser"
              onClick={onclickmodal}
            >
              + New
            </Button>
          </div>
        </div>
      </div>

      {/* //adding store modal */}
      <Modal
        className="onsubmitrtspmodal"
        destroyOnClose
        centered
        open={modal}
        onCancel={oncancelofform}
        title={
          <>
            <Row align={"middle"} justify={"center"}>
              <Col>
                <Text className="Modal-Header">Store Onboarding</Text>
              </Col>
            </Row>
          </>
        }
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submitAdduserForm"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={() => handlecreateeventclose()}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 24, offset: 0 }}
          >
            {" "}
            <div style={{ marginTop: "20px" }}>
              <Form
                id="submitAdduserForm"
                // layout="vertical"
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete={"off"}
                style={{ padding: "27px" }}
              >
                <Form.Item
                  // className="top-space"
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 11,
                  }}
                  label="Store Name"
                  name="storeName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Store Name!",
                    },
                    {
                      validator: (_, Values) => checkstorename(Values),
                    },
                  ]}
                >
                  <Input
                    style={{ width: 200 }}
                    placeholder="Store Name"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 10,
                  }}
                  label="Events"
                  name="events"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Events!",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: 200 }}
                    placeholder="Select an event"
                  >
                    {event.map((event) => (
                      <Option key={event.value} value={event.value}>
                        {event.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  // className="top-space"
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 10,
                  }}
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your country!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a country"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSearch={handleSearch}
                    // value={searchTerm}
                    value={selectedCountry}
                    onChange={(value) => {
                      handleCountryChange(value);
                    }}
                  >
                    {countries.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 10,
                  }}
                  label="State"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your State!",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select a state"
                    allowClear
                    showSearch // Enables search functionality
                    value={customState}
                    onSearch={handleSearch}
                    optionFilterProp="children" // Enables filtering based on children (option text)
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={selectedState}
                  />
                </Form.Item>

                <Form.Item
                  // className="top-space"
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 11,
                  }}
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your City!",
                    },
                  ]}
                >
                  <Input style={{ width: 200 }} />
                </Form.Item>
                <Form.Item
                  // className="top-space"
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 11,
                  }}
                  label="Region"
                  name="region"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Region!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: 200 }}
                    placeholder="Region"
                    prefix={
                      <FaNfcDirectional className="site-form-item-icon" />
                    }
                  />
                </Form.Item>

                <Form.Item
                  // className="top-space"
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 11,
                  }}
                  label="OnBoard Location"
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Location!",
                    },
                    {
                      validator: (_, Values) => checklocationname(Values),
                    },
                  ]}
                >
                  <Input
                    style={{ width: 200 }}
                    placeholder="Location"
                    prefix={
                      <MdOutlineAddLocationAlt
                        className="site-form-item-icon"
                        style={{ fontSize: "15px" }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{
                    span: 9,
                  }}
                  wrapperCol={{
                    span: 11,
                  }}
                  label="Pin Code"
                  name="pincode"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Pincode!",
                    },
                    {
                      validator: (_, value) => {
                        // Check if the value is empty or if it's a number
                        if (!value || /^\d+$/.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("Pincode must be a number!")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    style={{ width: 200 }}
                    placeholder="Pincode"
                    prefix={
                      <MdOutlineAddLocationAlt
                        className="site-form-item-icon"
                        style={{ fontSize: "15px" }}
                      />
                    }
                  />
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>

      {/* //loading modal */}
      <Modal
        className="LoadinModal"
        open={Pageload}
        style={{ background: "none", textAlign: "center" }}
        footer={false}
        closable={false}
        centered
      >
        <ClipLoader
          color="#23234d"
          loading={Pageload}
          cssOverride={{
            display: "block",
            margin: "0 auto",

            borderWidth: "5px",
            marginBottom: "10px",
          }}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
          title="Loading..."
        />
        <Text style={{ color: "white", fontSize: "1.2em" }}>Loading ...</Text>
      </Modal>
      {/* //display the store data */}
      <Row
        gutter={[10, 10]}
        align={"middle"}
        justify={"center"}
        style={{ marginTop: "18px" }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 23, offset: 0 }}
          xxl={{ span: 23, offset: 0 }}
        >
          {tabledata.length > 0 ? (
            <Row gutter={[6, 6]}>
              {tabledata.map((items) => (
                <Col
                  xs={{ span: 14, offset: 0 }}
                  sm={{ span: 14, offset: 0 }}
                  md={{ span: 14, offset: 0 }}
                  lg={{ span: 14, offset: 0 }}
                  xl={{ span: 4, offset: 0 }}
                  xxl={{ span: 4, offset: 0 }}
                >
                  <Card
                    hoverable
                    title={items.storeName}
                    styles={{
                      title: { textAlign: "center" },
                      body: { padding: 0 },
                      height: "300px",
                    }}
                  >
                    <div
                      className="border-line"
                      style={{ borderBottom: "1px solid #d9d0d0" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "0 16px",
                        }}
                      >
                        <Button
                          type="text"
                          icon={<EditOutlined />}
                          onClick={() => {
                            onEditRecord(items);
                            seteditrecord(items);
                          }}
                        />

                        <Button
                          type="text"
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          // onClick={handleDelete}
                          onClick={() => {
                            onDeleteRecord(items);
                            setReasonModal(!ReasonModal);
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "230px",
              }}
            >
              <Empty />
            </div>
          )}
        </Col>
      </Row>

      <Modal
        title="Edit Record"
        onCancel={() => seteditModal(false)}
        centered
        open={editModal}
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submiteditform"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={() => handleclose()}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
        // onCancel={handleCancel}
      >
        <Form
          form={editform}
          id="submiteditform"
          name="basic"
          onFinish={onFinishedit}
          autoComplete={"off"}
        >
          <Form.Item
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="Store Name"
            name="storeName"
            rules={[
              {
                required: true,
                message: "Please enter your Store Name!",
              },
              // {
              //   validator: (_, Values) => checkstorename(Values),
              // },
            ]}
          >
            <Input
              style={{ width: 200 }}
              placeholder="Store Name"
              prefix={<UserOutlined className="site-form-item-icon" />}
              // onChange={(e) => checkStoreName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 8,
            }}
            label="Events"
            name="events"
            rules={[
              {
                required: true,
                message: "Please Select Events!",
              },
            ]}
          >
            <Select
              style={{ width: 200 }}
              disabled
              mode="multiple"
              placeholder="Select events"
              // options={eventOptions}
              value={form.getFieldValue("events")}
            />
          </Form.Item>
          <Form.Item
            // className="top-space"
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="Country"
            name="country"
            rules={[
              {
                required: true,
                message: "Please enter your country!",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a country"
              optionFilterProp="children"
              disabled
            ></Select>
          </Form.Item>
          <Form.Item
            // className="top-space"
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="State"
            name="state"
            rules={[
              {
                required: true,
                message: "Please enter your State!",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a state"
              optionFilterProp="children"
              disabled
            >
              {/* {selectedState.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))} */}
            </Select>
          </Form.Item>
          <Form.Item
            // className="top-space"
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: "Please enter your City!",
              },
            ]}
          >
            <Input style={{ width: 200 }} disabled />
          </Form.Item>
          <Form.Item
            // className="top-space"
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="OnBoard Location"
            name="onBoardLocation"
            rules={[
              {
                required: true,
                message: "Please enter your Location!",
              },
            ]}
          >
            <Input
              style={{ width: 200 }}
              disabled
              // placeholder="Location"
              // prefix={
              //   <MdOutlineAddLocationAlt
              //     className="site-form-item-icon"
              //     style={{ fontSize: "15px" }}
              //   />
              // }
            />
          </Form.Item>
          <Form.Item
            // className="top-space"
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="Region"
            name="region"
            rules={[
              {
                required: true,
                message: "Please enter your Region!",
              },
            ]}
          >
            <Input
              style={{ width: 200 }}
              placeholder="Region"
              prefix={<FaNfcDirectional className="site-form-item-icon" />}
            />
          </Form.Item>

          <Form.Item
            // className="top-space"
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="Pincode"
            name="pincode"
            rules={[
              {
                required: true,
                message: "Please enter your pincode!",
              },
            ]}
          >
            <Input
              style={{ width: 200 }}
              disabled
              placeholder="pincode"
              prefix={
                <MdOutlineAddLocationAlt
                  className="site-form-item-icon"
                  style={{ fontSize: "15px" }}
                />
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      {/* //modal for delete  */}
      {/* <Modal
         className="modal-antd"
        title="Want to Delete!"
        // style={{ backgroundColor: "blue" }}
        open={ReasonModal}
        footer={[
          <Button
            onClick={(e) => {
              setReasonModal(false);
            }}
            key="cancel"
          >
            Cancel
          </Button>,
          <Button
            form="DeleteModal"
            key="submit"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>,
        ]}
        onCancel={(e) => {
          setReasonModal(false);
        }}
      >
        <Form
          id="DeleteModal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          form={form}
          onFinish={(e) => {
            onDeleteRecord(e);
          }}
        >
          <Form.Item
            label="Reason to Delete"
            name="DeleteReason"
            rules={[{ required: true, message: "Enter Reason to Delete User" }]}
          >
            <Input placeholder="Reason..." />
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  );
};
export default StoreOnboarding;
