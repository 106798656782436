import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Select, Typography, message, Table, Button, Space, Divider, Tooltip, Image, Skeleton, Empty, Flex, Modal} from "antd";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import moment from "moment/moment";
import Chart from "react-apexcharts";
import axiosClient from "../../../Authentication/ApiCall";
import _, { map } from "lodash";
import { CiImageOn } from "react-icons/ci";
import ReactApexChart from "react-apexcharts";
// import { Carousel } from "react-responsive-carousel";
import "../../../../font/Helvetica-Bold.ttf";
import "../../../../font/Helvetica.ttf";
// import { current } from "@reduxjs/toolkit";
import "./ImageModalComponent.css";
// import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip as Tool, Legend } from 'chart.js';
// import authService from "../../../Authentication/authService";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Text } = Typography;
const dateFormat = "YYYY-MM-DD";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tool,
  Legend
);

let RenderComp = {
  IngressEgress: false,
  DwellFootfall: false,
  EngagedBounced: false,
  AgeGender: false,
  PeakHours: false,
};

// let FootfallDirectionHourlyDetails = {
//   camlist: [],
//   selectedcam: "",
//   selectedcamName: "",
//   selectedDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
//   DataOfDate: "",
//   DirectionImage: null,
//   DirectionData: [],
//   loading: false,
//   Hour: "", // moment().hours(),
// };

const initialOptions = {
  chart: {
    id: "basic-bar",
    type: "bar",
    stacked: true,
    toolbar: { show: false },
    contextmenu: { enabled: false },
    animations: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: { horizontal: false },
  },
  xaxis: {
    categories: [],
    title: { text: "Hour ", offsetY: -10 },
  },
  yaxis: { title: { text: "Seconds" } },
  legend: {
    position: "top",
    horizontalAlign: "center",
    offsetX: 0,
  },
  colors: [
    "#00e396",
    "#feb019",
    "#ff4560",
    "#775dd0",
    "#008ffb",
    "#00d9e9",
    "#ff9f00",
  ],
  tooltip: {
    enabled: true,
    y: {
      formatter: function (val) {
        return val + " sec";
      },
    },
  },
};

const Weekly = () => {
  const [RenderCompController, setRenderCompController] = useState(RenderComp);
  const targetRef = useRef();
  // const containerRef = useRef(null);
  // const [DownloadDashboard, setDownloadDashboard] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [PasserByCount, setPasserByCount] = useState([]);
  const initialDate = dayjs().startOf("month").format("YYYY-MM-DD");
  const [spiderMonth, setSpiderMonth] = useState(initialDate);
  // const [minAverage, setMinAverage] = useState(0);
  // const [maxAverage, setMaxAverage] = useState(0);
  // const [DwellSelectedCam, setDwellSelectedCam] = useState(null);

  // const [chartFootfallAnalysis, setChartFootfallAnalysis] = useState({
  //   series: [],
  //   options: {
  //     chart: {
  //       type: 'area',
  //       stacked: false,
  //       height: 350,
  //       zoom: {
  //         enabled: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     markers: {
  //       size: 0,
  //     },
  //     fill: {
  //       type: 'gradient',
  //       gradient: {
  //         shadeIntensity: 1,
  //         inverseColors: false,
  //         opacityFrom: 0.45,
  //         opacityTo: 0.05,
  //         stops: [20, 100, 100, 100],
  //       },
  //     },
  //     yaxis: {
  //       labels: {
  //         style: {
  //           colors: '#8e8da4',
  //         },
  //         offsetX: 0,
  //         formatter: function (val) {
  //           return val.toFixed(0); // Adjust if needed
  //         },
  //       },
  //       axisBorder: {
  //         show: false,
  //       },
  //       axisTicks: {
  //         show: false,
  //       },
  //     },
  //     xaxis: {
  //       categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  //       labels: {
  //         rotate: -15,
  //         rotateAlways: true,
  //       },
  //     },
  //     // title: {
  //     //   text: 'Weekly Footfall Analysis',
  //     //   align: 'left',
  //     //   offsetX: 14,
  //     // },
  //     tooltip: {
  //       shared: true,
  //     },
  //     legend: {
  //       position: 'bottom',
  //       horizontalAlign: 'center',
  //       offsetX: -10,
  //     },
  //   },
  // })

  const [chartFootfallAnalysis, setChartFootfallAnalysis] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "area",
        dropShadow: {
          enabled: true,
          blur: 0,
          left: 1,
          top: 1,
        },
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.2,
      },
      markers: {
        size: 4,
      },
      yaxis: {
        stepSize: 200,
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
      tooltip: {
        y: {
          formatter: (val) => val,
        },
      },
    },
  });

  const [DwellHourly, setDwellHourly] = useState({
    options: { ...initialOptions },
    series: [],
    cameraList: [],
    selectedCam: null,
  });

  const [DwellHourlyDateWise, setDwellHourlyDateWise] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Date", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: [
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
        "#008ffb",
        "#00d9e9",
        "#ff9f00",
      ],
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val + " sec";
          },
        },
      },
    },
    series: [],
    cameraList: [],
    selectedCam: null,
  });

  const [UniqueGraph, setUniqueGraph] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Date", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },

      // colors: [
      //   "#feb019",
      //   "#ff4560",
      //   "#775dd0",
      //   "#008ffb",
      //   "#00d9e9",
      //   "#ff9f00",
      // ],
      // tooltip: {
      //   enabled: true,
      //   y: {
      //     formatter: function (val) {
      //       return val + " sec";
      //     },
      //   },
      // },
    },
    series: [],
  });

  const [Engagersweeklychart, setEngagersweeklychart] = useState({
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        title: { text: "Date ", offsetY: -10 },
      },
      yaxis: {
        title: { text: "Count" },
        // labels: {
        //   formatter: function (val) {
        //     return val.toFixed() // Formats Y-axis labels to two decimal places
        //   },
        // },
      },
      title: {
        text: "Engaged",
        align: "center",
        margin: 10,
        style: {
          fontSize: "16px",
          fontFamily: "Helvetica",
        },
      },
    },
    series: [],
  });

  const [Bouncedweeklychart, setBouncedweeklychart] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: [],
        title: { text: "Date ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },

      colors: ["#00e396"],
      title: {
        text: "Bounced",
        align: "center",
        margin: 10,
        style: {
          fontSize: "16px",
          fontFamily: "Helvetica",
        },
      },
    },
    series: [
      {
        name: "Bounced",
        data: [],
        // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
  });

  const [chartDatapieGender, setChartDatapieGender] = useState({
    options: {
      labels: ["Male", "Female"],
      tooltip: {
        enabled: true, // Enable tooltip
        formatter: function (val) {
          return `${val}%`; // Customize the tooltip to display percentage
        },
      },
    },

    series: [],
    maleCount: 0,
    femaleCount: 0,
  });

  const [chartDatapieAge, setChartDatapieAge] = useState({
    options: {
      labels: [],
      colors: [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#255aee",
        "#4eed9e",
        "#078f4b",
        "#6088d6",
        "#FFC107",
      ],
    },
    series: [],
  });

  const [pickhoursweeklyData, setpickhoursweeklydata] = useState({
    options: {
      chart: {
        height: 350,
        type: "area",
        color: "#64e38a",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // curve: "straight",
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },

      yaxis: {
        min: 0,
        max: 24,
        tickAmount: 12,
        title: { text: "Hour" },
        labels: {
          formatter: function (value) {
            return Math.round(value); // Convert the y-axis values to decimal format
          },
        },
      },
    },
    series: [],
  });

  const [graphLoading, setGraphLoading] = useState(false);

  const [ingressloading, setingressloading] = useState(true);

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        contextmenu: {
          enabled: false,
        },
        zoom: {
          enabled: false, // Ensure zoom is disabled
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: "category", // Change type to category for categorical data
        categories: [], // Initialize categories as empty array
        title: {
          offsetY: -10,
        },
      },
      yaxis: {
        type: "Count",
        title: {
          text: "Count",
          offsetY: -10,
        },
        labels: {
          formatter: function (value) {
            return parseInt(value); // Convert the y-axis values to decimal format
          },
        },
      },
      colors: ["#00e396", "#008ffb"],
      dataLabels: {
        enabled: false,
        offsetY: -15,
        style: {
          colors: ["#615494", "#f27777"],
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
            offsetY: -20,
            width: 1,
            style: {
              fontSize: "12px",
              colors: [" #000"],
              offsetY: -20,
            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          formatter: function () {
            return 25;
          },
          offsetY: -20,
        },
      },
    },
    series: [
      {
        name: "Ingress",
        data: [],
      },
      {
        name: "Egress",
        data: [],
      },
    ],
  });

  const [chartDataDate, setChartDataDate] = useState({
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        contextmenu: {
          enabled: false,
        },
        zoom: {
          enabled: false, // Ensure zoom is disabled
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: "category", // Change type to category for categorical data
        categories: [], // Initialize categories as empty array
        title: {
          offsetY: -10,
        },
      },
      yaxis: {
        type: "Count",
        title: {
          text: "Count",
          offsetY: -10,
        },
        labels: {
          formatter: function (value) {
            return parseInt(value); // Convert the y-axis values to decimal format
          },
        },
      },
      colors: ["#00e396", "#008ffb"],
      dataLabels: {
        enabled: false,
        offsetY: -15,
        style: {
          colors: ["#615494", "#f27777"],
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
            offsetY: -20,
            width: 1,
            style: {
              fontSize: "12px",
              colors: [" #000"],
              offsetY: -20,
            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          formatter: function () {
            return 25;
          },
          offsetY: -20,
        },
      },
    },
    series: [
      {
        name: "Ingress",
        data: [],
      },
      {
        name: "Egress",
        data: [],
      },
    ],
    cameraId: "",
  });

  const [PreviewDetails, setPreviewDetails] = useState({
    image: null,
    loading: false,
    openModal: false,
    CameraName: "",
    avg: 0,
    min:0,
    max:0,
  });

  //const [FootfallDirectionHourlyController, setFootfallDirectionHourlyController] = useState(FootfallDirectionHourlyDetails);  

  const [mainFilter, setMainFilter] = useState({
    selectFilter: 2,
    startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  });

  // const [HeatMapBlock, setHeatMapBlock] = useState({
  //   cameraDetails: [],
  //   modelOpen: false,
  //   selectedDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  // });

  const [AgeMaleFemaleCount, setAgeMaleFemaleCount] = useState({
    graph: {
      series: [
        {
          name: "Male",
          data: [],
        },
        {
          name: "Female",
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `${val} Count`,
          },
        },
      },
    },
    selecteddate: "",
  });

  const colors = [
    { background: 'rgba(0, 143, 251, 0.1)', border: '#008ffb' }, // Week 1 - Blue
    { background: 'rgba(0, 227, 150, 0.1)', border: '#00e396' }, // Week 2 - Green
    { background: 'rgba(254, 176, 25, 0.1)', border: '#feb019' }, // Week 3 - Yellow
    { background: 'rgba(255, 69, 96, 0.1)', border: '#ff4560' },  // Week 4 - Red
    { background: 'rgba(119, 93, 208, 0.1)', border: '#775dd0' }, // Week 5 - Purple
  ];

  // const disablemonth = (current) => {
  //   const createdDate = authService.getCreatedDate();
  //   const today = dayjs();

  //   return createdDate
  //     ? current < dayjs(createdDate).startOf("month") ||
  //         current > today.endOf("month")
  //     : false;
  // };

  // const [HeatmapController, setHeatmapController] = useState({
  //   loading: false,
  //   cameralist: [],
  // });


  // const [HeatMap, setHeatMap] = useState({
  //   cameraDetails: [
  //     {
  //       cameraId: 1,
  //       CameraName: "cam1",
  //       average: 10000,
  //     },
  //     {
  //       cameraId: 2,
  //       CameraName: "cam2",
  //       average: 100,
  //     },
  //     {
  //       cameraId: 3,
  //       CameraName: "cam3",
  //       average: 1000,
  //     },
  //     {
  //       cameraId: 4,
  //       CameraName: "cam4",
  //       average: 2000,
  //     },
  //     {
  //       cameraId: 5,
  //       CameraName: "cam5",
  //       average: 3000,
  //     },
  //     {
  //       cameraId: 6,
  //       CameraName: "cam6",
  //       average: 4000,
  //     },
  //     {
  //       cameraId: 7,
  //       CameraName: "cam7",
  //       average: 5000,
  //     },
  //     {
  //       cameraId: 8,
  //       CameraName: "cam8",
  //       average: 6000,
  //     },
  //     {
  //       cameraId: 9,
  //       CameraName: "cam9",
  //       average: 7000,
  //     },
  //     {
  //       cameraId: 10,
  //       CameraName: "cam10",
  //       average: 8000,
  //     },
  //   ],
  // });

  // const [HeatmapController, setHeatmapController] = useState({
  //   loading: false,
  //   cameralist: [],
  //   HeatMap: [],
  //   SelectedCamera: "",
  //   TotalPages: 1,
  //   currentPage: 1,
  //   selectedDate: dayjs().format("YYYY-MM-DD"),
  // });

  // const [PasserTableHour, setPasserTableHour] = useState({
  //   table: [],
  //   selecteddate: dayjs().subtract(1, "day"),
  // });

  // const [PeakDayCount, setPeakDayCount] = useState({
  //   cameraList: [],
  //   TotalCount: 0,
  //   selectedCamId: 0,
  //   selectedDate: "",
  //   startDate: dayjs().subtract(8, "day").format("YYYY-MM-DD"),
  //   endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  // });

  // const getBlockImage = (item) => {
  //   axiosClient
  //     .get(`/dwelltime/getDwellTimeChartMall?id=${item.id}`)
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         setPreviewDetails((prev) => ({
  //           ...prev,
  //           image: response.data.data.heatMapImage,
  //           openModal: true,
  //           CameraName: item.cameraName,
  //           avg: item.avg,
  //           min:item.min,
  //           max:item.max
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       setPreviewDetails((prev) => ({
  //         ...prev,
  //         openModal: false,
  //       }));
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error("Image unavailable");
  //       }
  //     });
  // };

  // const interpolateColor = (value, min, max, color1, color2) => {
  //   const ratio = min === max ? 0.5 : (value - min) / (max - min);
  //   const r = Math.ceil(
  //     parseInt(color1.substring(1, 3), 16) * (1 - ratio) +
  //       parseInt(color2.substring(1, 3), 16) * ratio
  //   );
  //   const g = Math.ceil(
  //     parseInt(color1.substring(3, 5), 16) * (1 - ratio) +
  //       parseInt(color2.substring(3, 5), 16) * ratio
  //   );
  //   const b = Math.ceil(
  //     parseInt(color1.substring(5, 7), 16) * (1 - ratio) +
  //       parseInt(color2.substring(5, 7), 16) * ratio
  //   );
  //   return `rgba(${r},${g},${b},1)`;
  // };

  // const getColorForValue = (value, min, max) => {
  //   const lightRed = "#ffb3b3"; // Light Red
  //   const darkRed = "#990000"; // Dark Red
  //   return interpolateColor(value, min, max, lightRed, darkRed);
  // };

  // const interpolateColor = (value, min, max, colors) => {
  //   const ratio = (value - min) / (max - min);
  //   const colorIndex = Math.floor(ratio * (colors.length - 1));
  //   const startColor = colors[colorIndex];
  //   const endColor = colors[colorIndex + 1] || colors[colorIndex];

  //   const startR = parseInt(startColor.slice(1, 3), 16);
  //   const startG = parseInt(startColor.slice(3, 5), 16);
  //   const startB = parseInt(startColor.slice(5, 7), 16);

  //   const endR = parseInt(endColor.slice(1, 3), 16);
  //   const endG = parseInt(endColor.slice(3, 5), 16);
  //   const endB = parseInt(endColor.slice(5, 7), 16);

  //   const r = Math.round(startR + (endR - startR) * (ratio * (colors.length - 1) - colorIndex));
  //   const g = Math.round(startG + (endG - startG) * (ratio * (colors.length - 1) - colorIndex));
  //   const b = Math.round(startB + (endB - startB) * (ratio * (colors.length - 1) - colorIndex));

  //   return `rgb(${r},${g},${b})`;
  // };

  // const getColorForValue = (value) => {
  //   const colors = ['#0000ff', '#00ff00', '#ffff00', '#ff9900', '#ff0000']; // Blue to Red
  //   return interpolateColor(value, minAverage, maxAverage, colors);
  // };

   // function getPasserByCountHour() {
  //   axiosClient
  //     .get(`/livereport/getpasserbycount?camId=&date=${mainFilter.startDate}`)
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         // const updatedData = response.data.data.data
  //         //   .filter((item) => item.hour >= 7)
  //         //   .sort((a, b) => b.hour - a.hour) // Sort by hour in descending order
  //         //   .map((item) => ({
  //         //     hour: moment(item.hour, "HH").format("h A"),
  //         //     passerby: item.passerby || 0, // Set passerby to 0 if null, undefined, or empty
  //         //     ingress: item.ingress || 0, // Set ingress to 0 if null, undefined, or empty
  //         //   }))
  //         // setPasserTableHour((prev) => ({
  //         //   ...prev,
  //         //   table: updatedData,
  //         // }))
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // }

  // const onChangeHeatMap = (date) => {
  //   if (date) {
  //     setHeatMapBlock((prev) => ({
  //       ...prev,
  //       selectedDate: dayjs(date).format("YYYY-MM-DD"),
  //     }));
  //   } else {
  //     setHeatMapBlock((prev) => ({
  //       ...prev,
  //       selectedDate: dayjs().format("YYYY-MM-DD"),
  //     }));
  //   }
  // };

  // function PeakDay() {
  //   axiosClient
  //     .get(
  //       `/livereport/getpeakday?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}&cameraId=${PeakDayCount.selectedCamId}`
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         setPeakDayCount((prevState) => ({
  //           ...prevState,
  //           TotalCount: dayjs(response.data.data).format("DD-MM-YYYY"),
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err);
  //       }
  //     });
  // }

  // const AgeBasedMaleFemaleCount = () => {
  //   axiosClient
  //     .get(
  //       `/livereport/getageandgender?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camid=`
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         const responseData = response.data.data.gender_based_age_distribution;
  //         const maleData = responseData.map((item) => item.maleCount);
  //         const femaleData = responseData.map((item) => item.femaleCount);
  //         const categories = responseData.map((item) => item.ageRange);

  //         setAgeMaleFemaleCount((prevState) => ({
  //           ...prevState,
  //           graph: {
  //             ...prevState.graph,
  //             series: [
  //               {
  //                 name: "Male",
  //                 data: maleData,
  //               },
  //               {
  //                 name: "Female",
  //                 data: femaleData,
  //               },
  //             ],
  //             options: {
  //               ...prevState.graph.options,
  //               xaxis: {
  //                 categories: categories,
  //               },
  //             },
  //           },
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       //setLoading(false);
  //       if (err.response && err.response.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.message);
  //       }
  //     });
  // };

  //passer table column
  // const PasserColumn = [
  //   {
  //     title: "Date",
  //     dataIndex: "date",
  //     key: "date",
  //     render: (text) => (
  //       <span style={{ color: "black" }}>
  //         {dayjs(text).format("DD-MM-YYYY")}
  //       </span>
  //     ),
  //     align: "center",
  //   },
  //   {
  //     title: "Passerby",
  //     dataIndex: "sumpasserby",
  //     key: "sumpasserby",
  //     render: (text) => <span style={{ color: "black" }}>{text}</span>,
  //     align: "center",
  //   },
  //   {
  //     title: "Ingress",
  //     dataIndex: "sumofingress",
  //     key: "sumofingress",
  //     render: (text) => <span style={{ color: "black" }}>{text}</span>,
  //     align: "center",
  //   },
  //   {
  //     title: "Egress",
  //     dataIndex: "sumofegress",
  //     key: "sumofegress",
  //     render: (text) => <span style={{ color: "black" }}>{text}</span>,
  //     align: "center",
  //   },
  // ]
  // const PasserColumnHour = [
  //   {
  //     title: "Hour",
  //     dataIndex: "hour",
  //     key: "hour",
  //     render: (text) => <span style={{ color: "black" }}>{text}</span>,
  //     align: "center",
  //   },
  //   {
  //     title: "Passerby",
  //     dataIndex: "passerby",
  //     key: "passerby",
  //     render: (text) => <span style={{ color: "black" }}>{text}</span>,
  //     align: "center",
  //   },
  //   {
  //     title: "Ingress",
  //     dataIndex: "ingress",
  //     key: "ingress",
  //     render: (text) => <span style={{ color: "black" }}>{text}</span>,
  //     align: "center",
  //   },
  // ]

  // const paginationConfig = {
  //   pageSize: 4,
  // }

  //PDF
  
  // const generatePDF = async () => {
  //   if (containerRef.current && targetRef.current) {
  //     setDownloadDashboard(true);
  //     try {
  //       const pdfExportComponent = containerRef.current;
  //       await pdfExportComponent.save();
  //       setDownloadDashboard(false);
  //     } catch (error) {
  //     }
  //   }
  // };

  const onMainFilterChange = (val) => {
    if (val === 1) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val === 2) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val === 3) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(15, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val === 4) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val === 5) {     
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    }
  };

  const onMainFilterDateChange = (date) => {
    if (date === null || date === "") {
      setMainFilter((prevState) => ({
        ...prevState,
      }));
    } else {
      setMainFilter((prevState) => ({
        ...prevState,

        startDate: dayjs(date).format("YYYY-MM-DD"),
        endDate: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  };

  const onMainRangeChange = (date) => {
    if (date === null || date === "") {
      setMainFilter((prevState) => ({
        ...prevState,
      }));
    } else {
      setMainFilter((prevState) => ({
        ...prevState,
        startDate: dayjs(date[0]).format("YYYY-MM-DD"),
        endDate: dayjs(date[1]).format("YYYY-MM-DD"),
      }));
    }
  };

  const DwellHourComp = () => {
    return (
      <Card
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title
                  level={5}
                  type="secondary"
                  style={{ fontFamily: "Helvetica" }}
                >
                  {mainFilter.selectFilter === 1
                    ? "Dwell time per hour"
                    : mainFilter.selectFilter === 2
                    ? "Weekly Dwell time"
                    : mainFilter.selectFilter === 3
                    ? "Last 15 Days Dwell time"
                    : mainFilter.selectFilter === 4
                    ? "Monthly Dwell time"
                    : "Specific Date range Dwell time"}
                </Title>
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Flex align="center">
                    <Col>
                      <Text type="secondary">
                        Date:{" "}
                        {mainFilter.selectFilter === 1
                          ? dayjs(mainFilter.startDate).format("DD-MM-YYYY")
                          : dayjs(mainFilter.startDate).format("DD-MM-YYYY") +
                            " - " +
                            dayjs(mainFilter.endDate).format("DD-MM-YYYY")}
                      </Text>
                    </Col>
                    {/* <Col>
                      <Select
                        onChange={OnChangeCameraSelect}
                        placeholder={<Text type="secondary">Camera</Text>}
                        value={DwellSelectedCam}
                        style={{ width: 120 }}
                      >
                        {" "}
                        {DwellHourlyDateWise.cameraList.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Col> */}
                  </Flex>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Skeleton loading={graphLoading}>
          <Chart
            options={DwellHourlyDateWise.options}
            series={DwellHourlyDateWise.series}
            type="bar"
            height={"200%"}
          />
        </Skeleton>
      </Card>
    );
  };

  const OnSpiderMonthChange = (date) => {
    if (date) {
      // Format the selected month to 'YYYY-MM'
      // const formattedDate = date.startOf('month').format('YYYY-MM');

      setSpiderMonth(date.format("YYYY-MM-DD"));
    } else {
      setSpiderMonth(initialDate);
    }
  };

  // const fetchHourlyData = async () => {
  //   setGraphLoading(true);
  //   try {
  //     const response = await axiosClient.get(
  //       `/dwelltime/getDwellTimeHourData?date=${mainFilter.startDate}&camId=${DwellHourly.selectedCam}`
  //     );
  //     if (response.data.issuccess === true) {
  //       const data = response.data.data.dwellData || [];
  //       const CameraList = response.data.data.cameraData || [];

  //       updateCameraList(CameraList);

  //       if (data.length > 0) {
  //         const zoneNames = extractZoneNames(data).sort();
  //         const { hours, series } = transformData(data, zoneNames);

  //         setDwellHourly((prevState) => ({
  //           ...prevState,
  //           options: {
  //             ...prevState.options,
  //             xaxis: {
  //               ...prevState.options.xaxis,
  //               categories: hours,
  //             },
  //           },
  //           series: series,
  //           selectedCam: response.data.data.selectedCameraDetails.cameraId,
  //         }));
  //       } else {
  //         setDwellHourly((prevState) => ({
  //           ...prevState,
  //           options: {
  //             ...prevState.options,
  //             xaxis: {
  //               ...prevState.options.xaxis,
  //               categories: [],
  //             },
  //           },
  //           series: [],
  //         }));
  //       }
  //       setGraphLoading(false);
  //     } else {
  //       setGraphLoading(false);
  //     }
  //   } catch (error) {
  //     handleApiError(error);
  //     setGraphLoading(false);
  //   }
  // };

  // const fetchDatewiseData = async () => {
  //   setGraphLoading(true);
  //   try {
  //     const response = await axiosClient.get(
  //       `/dwelltime/getDwellTimeDatewiseData?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camId=${DwellHourly.selectedCam}`
  //     );
  //     if (response.data.issuccess === true) {
  //       const data = response.data.data.dwellData || [];
  //       const CameraList = response.data.data.cameraData || [];

  //       updateCameraList(CameraList);

  //       if (data.length > 0) {
  //         const zoneNames = extractZoneNames1(data).sort();
  //         const { hours, series } = transformData1(data, zoneNames);

  //         setDwellHourlyDateWise((prevState) => ({
  //           ...prevState,
  //           options: {
  //             ...prevState.options,
  //             xaxis: {
  //               ...prevState.options.xaxis,
  //               categories: hours,
  //             },
  //           },
  //           series: series,
  //           selectedCam: response.data.data.selectedCameraDetails.cameraId,
  //         }));
  //       } else {
  //         setDwellHourlyDateWise((prevState) => ({
  //           ...prevState,
  //           options: {
  //             ...prevState.options,
  //             xaxis: {
  //               ...prevState.options.xaxis,
  //               categories: [],
  //             },
  //           },
  //           series: [],
  //         }));
  //       }
  //       setGraphLoading(false);
  //     } else {
  //       setGraphLoading(false);
  //     }
  //   } catch (error) {
  //     handleApiError(error);
  //     setGraphLoading(false);
  //   }
  // };

  const updateCameraList = (CameraList) => {
    if (CameraList) {
      const newCamList = CameraList.map((item) => ({
        value: item.cameraId,
        label: item.cameraName,
      }));
      setDwellHourly((prev) => ({
        ...prev,
        cameraList: [...newCamList],
      }));
      setDwellHourlyDateWise((prev) => ({
        ...prev,
        cameraList: [...newCamList],
      }));
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 0) {
      message.error("Server error");
    } else {
      message.error(error.message || "Error fetching data");
    }
  };

  const transformData1 = (data, zoneNames) => {
    const hours = _.map(data, "createdDate");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };

  const extractZoneNames1 = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };

  const transformData = (data, zoneNames) => {
    const hours = _.map(data, "hour");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };

  const extractZoneNames = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };

  // const OnChangeCameraSelect = (cam) => {
  //   setDwellSelectedCam(DwellHourly.cameraList.find((item) => item.value === cam)?.label || null)
  //   setDwellHourly((prev) => ({
  //     ...prev,
  //     selectedCam: cam,
  //   }));
  // };

  const hasNonZeroValues = chartDatapieGender.series.reduce((acc, curr) => acc + curr, 0) > 0;
  const hasNonZeroValues2 = chartDatapieAge.series.reduce((acc, curr) => acc + curr, 0) > 0;

  const EngageAndBounce = () => {
    return (
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            userSelect: "none",
            boxShadow: "none",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Title
                    level={5}
                    type="secondary"
                    style={{ fontFamily: "Helvetica" }}
                  >
                    {mainFilter.selectFilter === 1
                      ? "Engaged and Bounced"
                      : mainFilter.selectFilter === 2
                      ? "Weekly Engaged and Bounced"
                      : mainFilter.selectFilter === 3
                      ? "Last 15 Days Engaged and Bounced"
                      : mainFilter.selectFilter === 4
                      ? "Monthly Engaged and Bounced"
                      : "Specific Date range Engaged and Bounced"}
                  </Title>
                </Col>

                <Col>
                  <Flex gap={10} align="center">
                    {/* {WeeklyBouncedController.search && (
                      <RangePicker
                        onChange={handleDateRangeChangeforBounce}
                        disabledDate={(current) => {
                          return current && current > moment().endOf("day");
                        }}
                        allowClear
                      />
                    )}
                    <Dropdown
                      menu={{
                        items: menuforBounced,
                        onClick: handleMenuClickWeeklyBounced,
                      }}
                      placement="bottomRight"
                    >
                      <Button>
                        <Flex gap={10} align="center">
                          Filters
                          <LuFilter />
                        </Flex>
                      </Button>
                    </Dropdown> */}
                    <Text type="secondary">
                      Date:{" "}
                      {mainFilter.selectFilter === 1
                        ? dayjs(mainFilter.startDate).format("DD-MM-YYYY")
                        : dayjs(mainFilter.startDate).format("DD-MM-YYYY") +
                          " - " +
                          dayjs(mainFilter.endDate).format("DD-MM-YYYY")}
                    </Text>
                  </Flex>
                </Col>
              </Row>
            </>
          }
        >
          <Row justify={"space-between"} align={"middle"}>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 12 / 1, offset: 0 }}
              lg={{ span: 12 / 1, offset: 0 }}
              xl={{ span: 12 / 1, offset: 0 }}
            >
              <Chart
                type="bar"
                options={Engagersweeklychart.options}
                series={Engagersweeklychart.series}
                height="200%"
              />
            </Col>

            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 12 / 1, offset: 0 }}
              lg={{ span: 12 / 1, offset: 0 }}
              xl={{ span: 12 / 1, offset: 0 }}
            >
              <Chart
                type="bar"
                options={Bouncedweeklychart.options}
                series={Bouncedweeklychart.series}
                height="200%"
              />
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  // const EngagedAndBounced = () => {
  //   axiosClient
  //     .get(
  //       `dwelltime/getdwellengagebouncedanalytic?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
  //     )
  //     .then((response) => {
  //       const apiData = response.data.data;
  //       const categories = apiData.map((item) => item.createdDate);
  //       const seriesData = apiData.map((item) => item.totalbounce);

  //       const BouncedData = apiData.map((item) => item.totalengaged);
  //       setEngagersweeklychart((prevState) => ({
  //         ...prevState,
  //         options: {
  //           ...prevState.options,

  //           xaxis: { ...prevState.options.xaxis, categories: categories },
  //         },
  //         series: [{ name: "Engagers", data: seriesData }],
  //       }));
  //       setBouncedweeklychart((prevState) => ({
  //         ...prevState,
  //         options: {
  //           ...prevState.options,

  //           xaxis: { ...prevState.options.xaxis, categories: categories },
  //         },
  //         series: [
  //           {
  //             ...prevState.options.series,
  //             name: "Bounced",
  //             data: BouncedData,
  //           },
  //         ],
  //       }));
        
  //     })
  //     .catch((error) => {
  //     });
  // };

  const GetDwellTimeAnalytics = () => {
    setGraphLoading(true);
    axiosClient
      .get(
        `dwelltime/getdwellengagebouncedanalytic?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const apiData = response.data.data.engagedbounced;
          const categories = apiData.map((item) => item.createdDate);
          const seriesData = apiData.map((item) => item.totalbounce);

          const BouncedData = apiData.map((item) => item.totalengaged);
          setEngagersweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,

              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
            series: [{ name: "Engagers", data: seriesData }],
          }));
          setBouncedweeklychart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,

              xaxis: { ...prevState.options.xaxis, categories: categories },
            },
            series: [
              {
                ...prevState.options.series,
                name: "Bounced",
                data: BouncedData,
              },
            ],
          }));         

          if (mainFilter.selectFilter === 1) {
            const data = response.data.data.dwellData || [];
            const CameraList = response.data.data.cameraData || [];
    
            updateCameraList(CameraList);
    
            if (data.length > 0) {
              const zoneNames = extractZoneNames(data).sort();
              const { hours, series } = transformData(data, zoneNames);
    
              setDwellHourly((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                },
                series: series,
                selectedCam: response.data.data.selectedCameraDetails.cameraId,
              }));
            } else {
              setDwellHourly((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: [],
                  },
                },
                series: [],
              }));
            }
            setGraphLoading(false);
          }
          else {
            const data = response.data.data.dwelltime.dwellData || [];
            const CameraList = response.data.data.dwelltime.cameraData || [];
  
            updateCameraList(CameraList);
  
            if (data.length > 0) {
              const zoneNames = extractZoneNames1(data).sort();
              const { hours, series } = transformData1(data, zoneNames);
  
              setDwellHourlyDateWise((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },
                },
                series: series,
                selectedCam: response.data.data.dwelltime.selectedCameraDetails.cameraId,
              }));
            } else {
              setDwellHourlyDateWise((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: [],
                  },
                },
                series: [],
              }));
            }
            setGraphLoading(false);
          }
        }
        else {
          setGraphLoading(false);
        }
      })
      .catch((error) => {
        handleApiError(error);
        setGraphLoading(false);
      });
  };

  const genderGraph = () => {
    axiosClient
      .get(
        `/livereport/getageandgender?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camid=`
      )
      .then((response) => {
        //setLoading(false);
        if (response.data.issuccess === true) {
          const responseData = response.data.data;

          // Handle null or zero values for gender percentages
          const malePercentage = responseData.male_percentage || 0;
          const femalePercentage = responseData.female_percentage || 0;
          const maleCount = responseData.male_count || 0;
          const femaleCount = responseData.female_count || 0;

          setChartDatapieGender((prevState) => ({
            ...prevState,
            series: [malePercentage, femalePercentage],
            maleCount: maleCount,
            femaleCount: femaleCount,
          }));

          // Handle zero values for age distribution
          const ageLabels = responseData.age_distribution.map(
            (group) => group.age
          );
          const ageCounts = responseData.age_distribution.map(
            (group) => group.count
          );
          setChartDatapieAge({
            ...chartDatapieAge,
            options: { ...chartDatapieAge.options, labels: ageLabels },
            series: ageCounts,
          });

          const responseData1 = response.data.data.gender_based_age_distribution;
          const maleData = responseData1.map((item) => item.maleCount);
          const femaleData = responseData1.map((item) => item.femaleCount);
          const categories1 = responseData1.map((item) => item.ageRange);

          setAgeMaleFemaleCount((prevState) => ({
              ...prevState,
              graph: {
                ...prevState.graph,
                series: [
                  {
                    name: "Male",
                    data: maleData,
                  },
                  {
                    name: "Female",
                    data: femaleData,
                  },
                ],
                options: {
                  ...prevState.graph.options,
                  xaxis: {
                    categories: categories1,
                  },
                },
              },
            }));
          }
      })
      .catch((err) => {
        //setLoading(false);
        if (err.response && err.response.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.message);
        }
      });
  };

  // const handleDateRangeChangeforPeakHours = () => {
  //   axiosClient
  //     .get(
  //       `livereport/getpeakhourforspecifieddates?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
  //     )
  //     .then((response) => {
  //       const apiData = response.data.data;

  //       const categories = apiData.map((item) => item.date);

  //       const seriesData = apiData.map((item) =>
  //         item.peakHour === null || item.peakHour === undefined
  //           ? 0
  //           : item.peakHour
  //       );

  //       // const yAxisLabels = apiData.map((item) => {
  //       //   if (typeof item.peakHour === "string") {
  //       //     return item.peakHour;
  //       //   } else {
  //       //     return "";
  //       //   }
  //       // });

  //       setpickhoursweeklydata((prevState) => ({
  //         ...prevState,
  //         options: {
  //           ...prevState.options,

  //           xaxis: { categories: categories, title: { text: "Date" } },
  //           // yaxis: { categories: yAxisLabels },
  //         },
  //         series: [
  //           {
  //             name: "Peak hour",
  //             data: seriesData,
  //           },
  //         ],
  //       }));
  //     })
  //     .catch((error) => {
  //     });
  // };

  // function getPasserByCount() {
  //   axiosClient
  //     .get(
  //       `/livereport/getprocesspasserbycount?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camId=${chartDataDate.cameraId}`
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         const TableData = response?.data?.data?.data?.sort(
  //           (a, b) => dayjs(a.date) - dayjs(b.date)
  //         );
  //         if(TableData !== undefined)
  //         {
  //           // const categories = TableData.map((item) => item.date)
  //           const categories = TableData.map((item) => {
  //             const [year, month, day] = item.date.split("-");
  //             return `${day}-${month}-${year}`;
  //           });
  
  //           const ingressData = TableData.map((item) =>
  //             parseInt(item.sumofingress)
  //           );
  //           const egressData = TableData.map((item) =>
  //             parseInt(item.sumofegress)
  //           );
  
  //           setChartDataDate((prevState) => ({
  //             ...prevState,
  //             options: {
  //               ...prevState.options,
  //               xaxis: {
  //                 title: { text: "Date" },
  //                 categories: categories,
  //               },
  //             },
  //             series: [
  //               {
  //                 name: "Ingress",
  //                 data: ingressData,
  //               },
  //               {
  //                 name: "Egress",
  //                 data: egressData,
  //               },
  //             ],
  //             // cameraId:response?.data?.data?.selectedCameraData.cameraId
  //           }));
  //         }         
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // }

  const UniqueFootFallGraph = () => {
    axiosClient
      .get(
        `/livereport/getuniquecountgraph?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camId=`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data;
          if(data.length > 0)
          {
            const dates = data.map((item) => item.createddate);
            const totals = data.map((item) => item.count);
  
            setUniqueGraph((prevState) => ({
              ...prevState,
              options: {
                ...prevState.options,
                xaxis: {
                  ...prevState.options.xaxis,
                  categories: dates,
                },
              },
              series: [
                {
                  ...prevState.series[0],
                  name: "Count",
                  data: totals,
                },
              ],
            }));
          }         
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
  };

  const ClosePreviewModal = () => {
    setPreviewDetails((prev) => ({
      ...prev,
      openModal: false,
    }));
  };

  // const PasserByNew = () => {
  //   axiosClient
  //     .get(
  //       `/livereport/getpasserbydetails?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}&cameraId=`
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         setPasserByCount(response.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // };

  const GetIngressEgressAnalytics = () => {
    axiosClient
    .get(
      `/livereport/getingressegressanalytic?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}&cameraId=`
    )
    .then((response) => {
      if (response.data.issuccess === true) {
        setingressloading(false);
        setPasserByCount(response.data.data.passerByLists);

        const list_hour = map(response.data.data.total_records_hour, "hour");

        const list_ingress = map(
          response.data.data.total_records_hour,
          "totalingress"
        );
        const list_egress = map(
          response.data.data.total_records_hour,
          "totalegress"
        );

        setChartData((prev) => ({
          ...prev,
          options: {
            ...prev.options,
            xaxis: {
              title: { text: "Hour" },
              categories: list_hour,
            },
          },
          series: [
            {
              name: "Ingress",
              data: list_ingress,
            },
            {
              name: "Egress",
              data: list_egress,
            },
          ],
        }));

        const apiData = response.data.data.peakHours;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) =>
          item.peakHour === null || item.peakHour === undefined
            ? 0
            : item.peakHour
        );

        // const yAxisLabels = apiData.map((item) => {
        //   if (typeof item.peakHour === "string") {
        //     return item.peakHour;
        //   } else {
        //     return "";
        //   }
        // });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,

            xaxis: { categories: categories, title: { text: "Date" } },
            // yaxis: { categories: yAxisLabels },
          },
          series: [
            {
              name: "Peak hour",
              data: seriesData,
            },
          ],
        }));

        const TableData = response?.data?.data?.passerbycount?.sort(
          (a, b) => dayjs(a.date) - dayjs(b.date)
        );
        if(TableData !== undefined)
        {
          // const categories = TableData.map((item) => item.date)
          const categories = TableData.map((item) => {
            const [year, month, day] = item.date.split("-");
            return `${day}-${month}-${year}`;
          });

          const ingressData = TableData.map((item) =>
            parseInt(item.sumofingress)
          );
          const egressData = TableData.map((item) =>
            parseInt(item.sumofegress)
          );

          setChartDataDate((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: {
                title: { text: "Date" },
                categories: categories,
              },
            },
            series: [
              {
                name: "Ingress",
                data: ingressData,
              },
              {
                name: "Egress",
                data: egressData,
              },
            ],
            // cameraId:response?.data?.data?.selectedCameraData.cameraId
          }));
        }  
      }
    })
    .catch((err) => {
      if (err.status === 0) {
        message.error("Server error");
      } else {
        message.error(err.msg);
      }
    });
  };


  //passer table column  ingressCount
  const PasserColumn = [
    {
      title: "Camera name",
      dataIndex: "cameraName",
      key: "cameraName",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Store",
      dataIndex: "location",
      key: "location",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Ingress count",
      dataIndex: "ingressCount",
      key: "ingressCount",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Passer by count",
      dataIndex: "passerByCount",
      key: "passerByCount",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
  ];

  const paginationConfig = {
    pageSize: 3,
    response: true,
  };

  // useEffect(() => {
  //   // setMinAverage(Math.min(...averages));
  //   // setMaxAverage(Math.max(...averages));
  // }, [HeatMapBlock.cameraDetails]);

  useEffect(() => {
    //EngagedAndBounced();
    //handleDateRangeChangeforPeakHours();
    //PeakDay();
    //AgeBasedMaleFemaleCount();
    genderGraph();
    //PasserByNew();
    GetIngressEgressAnalytics()   
    GetDwellTimeAnalytics()
  }, [mainFilter.selectFilter, mainFilter.startDate, mainFilter.endDate]);

  useEffect(() => {
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, IngressEgress: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, DwellFootfall: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, EngagedBounced: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, AgeGender: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, PeakHours: true }));
    }, 1000);
  }, []);

  useEffect(() => {
    axiosClient
      .get(`/livereport/spidergraphdata?month=${spiderMonth}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          const responseData = response.data.data.newspider;

          const weeks = {};
          responseData.forEach((item) => {
            if (!weeks[item.weekwise]) {
              weeks[item.weekwise] = {
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
                Sunday: 0,
              };
            }
            weeks[item.weekwise][item.week] = item.totalingress;
          });

          const datasets = Object.keys(weeks).map((week, index) => ({
            label: `Week ${index + 1}`,
            data: [
              weeks[week].Monday,
              weeks[week].Tuesday,
              weeks[week].Wednesday,
              weeks[week].Thursday,
              weeks[week].Friday,
              weeks[week].Saturday,
              weeks[week].Sunday,
            ],
           
          }));

          // setChartFootfallAnalysis({
          //   labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          //   datasets: datasets,
          // });
          setChartFootfallAnalysis((prevState) => ({
            ...prevState,
            series: datasets.map((dataset, index) => ({
              name: dataset.label,
              data: dataset.data,
            })),
            options: {
              ...prevState.options,
              fill: {
                colors: colors.map((color) => color.background),
              },
              stroke: {
                colors: colors.map((color) => color.border),
                width: 3,
              },
              colors: colors.map((color) => color.border)
            },
          }));
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
  }, [spiderMonth]);

  // //?date=${mainFilter.startDate}
  // useEffect(() => {
  //   //  setHeatmapController((prev) => ({ ...prev, loading: true }))
  //   axiosClient
  //     .get(`/dwelltime/getDwellTimeChartMall?date=${HeatMapBlock.selectedDate}`)
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         //setHeatmapController((prev) => ({ ...prev, loading: false }))
  //         const data = response.data.data.sort(
  //           (a, b) => a.cameraId - b.cameraId
  //         );
  //         const avgValues = data.map((item) => item.avg);
  //         const minAverage = Math.min(...avgValues);
  //         const maxAverage = Math.max(...avgValues);
  //         // setMinAverage(minAverage);
  //         // setMaxAverage(maxAverage);

  //         setHeatMapBlock((prev) => ({
  //           ...prev,
  //           cameraDetails: data,
  //           minAverage,
  //           maxAverage,
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       //setHeatmapController((prev) => ({ ...prev, loading: false }));
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // }, [HeatMapBlock.selectedDate]);

  // //Dwell time data
  // useEffect(() => {
  //   setHeatmapController((prev) => ({ ...prev, loading: true }));

  //   axiosClient
  //     .get(`/dwelltime/getDwellTimeHeatmap?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}`)
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         setHeatmapController((prev) => ({
  //           ...prev,
  //           cameralist: response.data.data,
  //         }));
  //         setHeatmapController((prev) => ({ ...prev, loading: false }));
  //       }
  //     })
  //     .catch((err) => {
  //       setHeatmapController((prev) => ({ ...prev, loading: false }));
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // }, []);

  // useEffect(() => {    
  //   //getPasserByCountHour();
  //   //getPasserByCount();
  //   // PeakDay();
  // }, [mainFilter.startDate, mainFilter.endDate]);

  useEffect(() => {
    axiosClient
      .get("/livereport/getcameralistforingressegresslive")
      .then((response) => {
        setMenuOptions(response.data.data);
      })
      .catch((error) => {
      });
  }, []);

  // //Ingress and engress graph
  // useEffect(() => {
  //   axiosClient
  //     .get(
  //       `/livereport/getingressegress?date=` +
  //         mainFilter.startDate +
  //         `&camid=` +chartDataDate.cameraId
          
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         setingressloading(false);

  //         const list_hour = map(response.data.data.total_records_hour, "hour");

  //         const list_ingress = map(
  //           response.data.data.total_records_hour,
  //           "totalingress"
  //         );
  //         const list_egress = map(
  //           response.data.data.total_records_hour,
  //           "totalegress"
  //         );
  //         setChartData((prev) => ({
  //           ...prev,
  //           options: {
  //             ...prev.options,
  //             xaxis: {
  //               title: { text: "Hour" },
  //               categories: list_hour,
  //             },
  //           },
  //           series: [
  //             {
  //               name: "Ingress",
  //               data: list_ingress,
  //             },
  //             {
  //               name: "Egress",
  //               data: list_egress,
  //             },
  //           ],
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.status === 0) {
  //         message.error("Network error");
  //       } else {
  //         message.error(err.message);
  //       }
  //     });
  // }, [mainFilter.selectFilter == 1]);

  useEffect(() => {
    UniqueFootFallGraph();
  }, [mainFilter.startDate, mainFilter.endDate]);

  // Fetching date-wise data
  // useEffect(() => {
  //   // if (mainFilter.selectFilter === 1) {
  //   //   fetchHourlyData()
  //   // } else {
  //   //   fetchDatewiseData()
  //   // }
  //   //fetchDatewiseData()
  // }, [mainFilter.startDate, mainFilter.endDate, DwellHourly.selectedCam]);
  
  // useEffect(() => {
  //   fetchDatewiseData();
  // },[mainFilter.startDate, mainFilter.endDate]);

  // useEffect(() => {
  //   setFootfallDirectionHourlyController((prev) => ({
  //     ...prev,
  //     loading: true,
  //   }));
  //   axiosClient
  //     .get(
  //       `/livereport/getfootfalldirectiondaywise?date=${dayjs(
  //         FootfallDirectionHourlyController.selectedDate
  //       ).format("YYYY-MM-DD")}&camId=${
  //         FootfallDirectionHourlyController.selectedcam
  //       }`
  //     )

  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         const data = response.data.data.dwellData
  //           ? response.data.data.dwellData
  //           : [];

  //         const selectedcamDetails = response.data.data;
  //         const CameraList = response.data.data.cameraData;
  //         if (
  //           selectedcamDetails.selectedCameraData !== null &&
  //           selectedcamDetails.selectedCameraData !== undefined &&
  //           selectedcamDetails.selectedCameraData !== ""
  //         ) {
  //           setFootfallDirectionHourlyController((prev) => ({
  //             ...prev,
  //             selectedcam: selectedcamDetails.selectedCameraData.cameraId,

  //             selectedcamName: selectedcamDetails.selectedCameraData.cameraName,
  //           }));
  //         }

  //         if (
  //           selectedcamDetails.directionData !== null &&
  //           selectedcamDetails.directionData !== undefined &&
  //           selectedcamDetails.directionData !== ""
  //         ) {
  //           setFootfallDirectionHourlyController((prev) => ({
  //             ...prev,
  //             DirectionData: selectedcamDetails.directionData,
  //           }));
  //         } else {
  //           setFootfallDirectionHourlyController((prev) => ({
  //             ...prev,
  //             DirectionData: "",
  //           }));
  //         }
  //         if (
  //           selectedcamDetails.directionImage !== null &&
  //           selectedcamDetails.directionImage !== undefined
  //         ) {
  //           setFootfallDirectionHourlyController((prev) => ({
  //             ...prev,
  //             DirectionImage: selectedcamDetails.directionImage,
  //           }));
  //         } else {
  //           setFootfallDirectionHourlyController((prev) => ({
  //             ...prev,
  //             DirectionImage: "",
  //           }));
  //         }

  //         if (
  //           selectedcamDetails.hour !== null &&
  //           selectedcamDetails.hour !== undefined &&
  //           selectedcamDetails.hour !== ""
  //         ) {
  //           setFootfallDirectionHourlyController((prev) => ({
  //             ...prev,
  //             Hour: selectedcamDetails.hour,
  //           }));
  //         }
  //         if (CameraList !== null && CameraList !== undefined) {
  //           const newCamList = CameraList.map((item) => ({
  //             value: item.cameraid,
  //             label: item.cameraname,
  //           }));

  //           setFootfallDirectionHourlyController((prev) => ({
  //             ...prev,
  //             camlist: [...newCamList],
  //           }));
  //         }
  //       } else {
  //       }
  //       setFootfallDirectionHourlyController((prev) => ({
  //         ...prev,
  //         loading: false,
  //       }));
  //     })
  //     .catch((err) => {
  //       setFootfallDirectionHourlyController((prev) => ({
  //         ...prev,
  //         loading: false,
  //       }));
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err.msg);
  //       }
  //     });
  // }, [
  //   FootfallDirectionHourlyController.selectedcam,
  //   FootfallDirectionHourlyController.selectedDate,
  //   FootfallDirectionHourlyController.Hour,
  // ]);

  // //gender age graph male female count based on age
  // useEffect(() => {
  //   AgeBasedMaleFemaleCount(AgeMaleFemaleCount);
  // }, [AgeMaleFemaleCount.selecteddate]);

  //  // Handle null or zero values for gender percentages
  //  const malePercentage =
  //  responseData.male_percentage !== null
  //    ? parseInt(responseData.male_percentage)
  //    : 0;
  // const femalePercentage =
  //  responseData.female_percentage !== null
  //    ? parseInt(responseData.female_percentage)
  //    : 0;

  // // Update Gender Distribution Chart Data
  // setChartDatapieGender({
  //  ...chartDatapieGender,
  //  series: [malePercentage, femalePercentage],
  // });

  // // Handle zero values for age distribution
  // const ageLabels = responseData.age_distribution.map(
  //  (group) => group.age
  // );
  // const ageCounts = responseData.age_distribution.map(
  //  (group) => group.count
  // );
  // setChartDatapieAge({
  //  ...chartDatapieAge,
  //  options: { ...chartDatapieAge.options, labels: ageLabels },
  //  series: ageCounts,
  // });

  return (
    <div style={{ margin: "1rem" }}>
      <Row
        justify={"end"}
        gutter={[30, 30]}
        style={{ margin: "15px", width: "100%" }}
      >
        <Flex style={{ height: "100%", width: "100%" }} align="center" gap={50}>
          <Flex
            align="center"
            gap={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Title level={5} style={{ fontFamily: "Helvetica" }}>
              Filter:
            </Title>

            <Select
              style={{ width: "10rem" }}
              defaultValue={2}
              onChange={onMainFilterChange}
            >
              <Option value={2}>Weekly</Option>
              <Option value={3}>Last 15 days</Option>
              <Option value={4}>Monthly</Option>
              <Option value={1}>Single Day</Option>
              <Option value={5}>Select date</Option>
            </Select>
          </Flex>

          {mainFilter.selectFilter === 5 ? (
            <RangePicker
             // allowClear
             defaultValue={[
              dayjs(
               mainFilter.startDate,
                dateFormat
              ),
              dayjs(
                mainFilter.endDate,
                dateFormat
              ),
            ]}
              onChange={onMainRangeChange}
              disabledDate={(current) => {
                // Disable today and future dates
                const isFutureOrToday =
                  current >= dayjs().subtract(1, "day").endOf("day");
                // Disable dates older than 45 days from yesterday
                // const isBefore45DaysAgo =
                //   current < dayjs().subtract(45, "day").startOf("day");
                // return isFutureOrToday || isBefore45DaysAgo;
                return isFutureOrToday;
              }}
            />
          ) : (
            ""
          )}
          {mainFilter.selectFilter === 1 ? (
            <DatePicker
              //style={{ width: "6rem" }}
              format={"DD-MM-YYYY"}
              className="new_card_date"
              onChange={onMainFilterDateChange}
              disabledDate={(current) =>
                current >= dayjs().subtract(1, "day").endOf("day")
              }
              value={dayjs(mainFilter.startDate)}
            />
          ) : (
            ""
          )}

          {/* <Button
              title="Download"
              type="default"
              htmlType="submit"
              loading={DownloadDashboard}
              onClick={generatePDF}
            >
              Download
            </Button> */}
        </Flex>
      </Row>
        <div ref={targetRef}>
          {RenderCompController.DwellFootfall && (
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              xxl={{ span: 24, offset: 0 }}
            >
              <Row gutter={[20, 30]}>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  xxl={{ span: 24, offset: 0 }}
                  //style={{backgroundColor:"red"}}
                >
                  <Row gutter={[20, 20]}>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      xxl={{ span: 24, offset: 0 }}
                    >
                      <Row gutter={[10, 10]}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 16, offset: 0 }}
                          xl={{ span: 16, offset: 0 }}
                          xxl={{ span: 16, offset: 0 }}
                          style={{}}
                        >
                          <Card
                            loading={ingressloading}
                            // hoverable

                            style={{
                              cursor: "pointer",
                              userSelect: "none",
                              boxShadow: "none",
                              height: "30rem",
                            }}
                            title={
                              <Row justify={"space-between"} align={"middle"}>
                                <Col>
                                  <Row gutter={5} align={"middle"}>
                                    <Col>
                                      <Title
                                        level={5}
                                        type="secondary"
                                        style={{ fontFamily: "Helvetica" }}
                                      >
                                        {mainFilter.selectFilter === 1
                                          ? "Ingress and Egress Counts per Hour"
                                          : mainFilter.selectFilter === 2
                                          ? "Weekly Ingress and Egress Counts"
                                          : mainFilter.selectFilter === 3
                                          ? "Last 15 Days Ingress and Egress Counts"
                                          : mainFilter.selectFilter === 4
                                          ? "Monthly Ingress and Egress Counts"
                                          : "Specific Date range Ingress and Egress Counts"}
                                      </Title>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  {/* <Select
                                    onChange={(val) => {
                                      setChartDataDate((prev) => ({
                                        ...prev,
                                        cameraId: val,
                                      }));
                                    }}
                                    style={{
                                      width: 120,
                                      marginLeft: "140px",
                                    }}
                                    placeholder={
                                      <Text type="secondary">Camera</Text>
                                    }
                                  >
                                    {" "}
                                    {menuOptions.map((option) => (
                                      <Option key={option.id} value={option.id}>
                                        {option.cameraName}
                                      </Option>
                                    ))}
                                  </Select> */}
                                  <Text
                                    type="secondary"
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    Date:{" "}
                                    {mainFilter.selectFilter === 1
                                      ? dayjs(mainFilter.startDate).format(
                                          "DD-MM-YYYY"
                                        )
                                      : dayjs(mainFilter.startDate).format(
                                          "DD-MM-YYYY"
                                        ) +
                                        " - " +
                                        dayjs(mainFilter.endDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </Text>
                                </Col>
                              </Row>
                            }
                          >
                            {chartData.series && chartData.series.length > 0 ? (
                              <div className="app">
                                <div className="row">
                                  <div className="mixed-chart">
                                    <Chart
                                      options={
                                        mainFilter.selectFilter === 1
                                          ? chartData.options
                                          : chartDataDate.options
                                      }
                                      series={
                                        mainFilter.selectFilter === 1
                                          ? chartData.series
                                          : chartDataDate.series
                                      }
                                      type="area"
                                      height={350}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "20px",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: "16px",
                                    color: "gray",
                                  }}
                                >
                                  No Data Available
                                </Text>
                              </div>
                            )}
                          </Card>
                        </Col>

                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          xl={{ span: 8, offset: 0 }}
                        >
                          <Card
                            style={{
                              cursor: "pointer",
                              userSelect: "none",
                              boxShadow: "none",
                              height: "30rem",
                            }}
                            title={
                              <Flex align="center" justify="space-between">
                                <Col>
                                  <Title
                                    level={5}
                                    type="secondary"
                                    style={{ fontFamily: "Helvetica" }}
                                  >
                                    {mainFilter.selectFilter === 1
                                      ? "Single day passerby Count"
                                      : mainFilter.selectFilter === 2
                                      ? "Weekly Passerby Count"
                                      : mainFilter.selectFilter === 3
                                      ? "Last 15 Days Passerby Count"
                                      : mainFilter.selectFilter === 4
                                      ? "Monthly Passerby Count"
                                      : "Specific Date range Passerby Count"}
                                  </Title>
                                </Col>
                                <Col>
                                  <Text
                                    type="secondary"
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    Date:{" "}
                                    {mainFilter.selectFilter === 1
                                      ? dayjs(mainFilter.startDate).format(
                                          "DD-MM-YYYY"
                                        )
                                      : dayjs(mainFilter.startDate).format(
                                          "DD-MM-YYYY"
                                        ) +
                                        " - " +
                                        dayjs(mainFilter.endDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </Text>
                                </Col>
                              </Flex>
                            }
                          >
                          
                           <Table
                              dataSource={PasserByCount}
                              columns={PasserColumn}
                              rowKey="cameraId"
                              pagination={paginationConfig}
                              scroll={{ y: "max-content" }}
                            />
                         
                          </Card>
                        </Col>
                      </Row>
                    </Col>
           

            
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                    >
                      <Row>
                      <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                        >
                          <Row gutter={[10,10]}>
                       <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <Card
                            style={{
                              // borderRadius: "15px",
                              // height: "360px",
                              userSelect: "none",
                              boxShadow: "none",
                              // marginTop: "1rem",
                            }}
                            title={
                              <>
                                <Row justify={"space-between"} align={"middle"}>
                                  <Col>
                                    <Title
                                      level={5}
                                      type="secondary"
                                      style={{ fontFamily: "Helvetica" }}
                                    >
                                      {mainFilter.selectFilter === 1
                                        ? "Peak Hour"
                                        : mainFilter.selectFilter === 2
                                        ? "Weekly Peak Hour"
                                        : mainFilter.selectFilter === 3
                                        ? "Last 15 Days Peak Hour"
                                        : mainFilter.selectFilter === 4
                                        ? "Monthly Peak Hour"
                                        : "Specific Date range Peak Hour"}
                                    </Title>
                                  </Col>
                                  <Col>
                                    <Flex gap={10}>
                                      <Text type="secondary">
                                        Date:{" "}
                                        {mainFilter.selectFilter === 1
                                          ? dayjs(mainFilter.startDate).format(
                                              "DD-MM-YYYY"
                                            )
                                          : dayjs(mainFilter.startDate).format(
                                              "DD-MM-YYYY"
                                            ) +
                                            " - " +
                                            dayjs(mainFilter.endDate).format(
                                              "DD-MM-YYYY"
                                            )}
                                      </Text>
                                    </Flex>
                                  </Col>
                                </Row>
                              </>
                            }
                          >
                            <div style={{ height: "250px" }}>
                              <Chart
                                options={pickhoursweeklyData.options}
                                series={pickhoursweeklyData.series}
                                type="area"
                                height="100%"
                              />
                            </div>
                          </Card>
                        </Col>
                        <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                  >
                    <Card
                      style={{
                        // borderRadius: "15px",
                        // height: "360px",
                        userSelect: "none",
                        boxShadow: "none",
                        //marginTop: "1rem",
                      }}
                      title={
                        <>
                          <Flex gap={5} align="center" justify="space-between">
                            <Title
                              level={5}
                              type="secondary"
                              style={{ fontFamily: "Helvetica" }}
                            >
                              Unique Footfall
                            </Title>
                            <Col>
                              <Text type="secondary">
                                Date:{" "}
                                {mainFilter.selectFilter === 1
                                  ? dayjs(mainFilter.startDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : dayjs(mainFilter.startDate).format(
                                      "DD-MM-YYYY"
                                    ) +
                                    " - " +
                                    dayjs(mainFilter.endDate).format(
                                      "DD-MM-YYYY"
                                    )}
                              </Text>
                            </Col>
                          </Flex>
                        </>
                      }
                    >
                      <div style={{ height: "250px" }}>
                        <ReactApexChart
                          //width={"100%"}
                          options={UniqueGraph.options}
                          series={UniqueGraph.series}
                          type="bar"
                          height={250}
                        />
                      </div>
                    </Card>
                  </Col>
                  </Row>
                    </Col>
                       
                        {/* <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <DwellHourComp />
                        </Col> */}
                      </Row>

                      {/* //DwellHourComp */}
                    </Col>
                    
                  </Row>
                </Col>
              </Row>
            </Col>
          )}


        <Col
          xs={{ span: 24 / 1, offset: 0 }}
          sm={{ span: 24 / 1, offset: 0 }}
          md={{ span: 24 / 1, offset: 0 }}
          lg={{ span: 24 / 1, offset: 0 }}
          xl={{ span: 24 / 1, offset: 0 }}
        >
          <Row style={{ width: "100%", marginTop: "1rem" }}>
            <EngageAndBounce />
          </Row>
        </Col>
            
          
          <Col
            xs={{ span: 24 , offset: 0 }}
            sm={{ span: 24 , offset: 0 }}
            md={{ span: 24 , offset: 0 }}
            lg={{ span: 24 , offset: 0 }}
            xl={{ span: 24 , offset: 0 }}
          >
            <Card
              style={{
                // borderRadius: "15px",
                // height: "360px",
                userSelect: "none",
                boxShadow: "none",
                marginTop: "1rem",
              }}
              title={
                <>
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Title
                        level={5}
                        type="secondary"
                        style={{ fontFamily: "Helvetica" }}
                      >
                        Total detected Gender Distribution in %
                      </Title>
                    </Col>
                    <Col>
                      <Text type="secondary">
                        Date:{" "}
                        {mainFilter.selectFilter === 1
                          ? dayjs(mainFilter.startDate).format("DD-MM-YYYY")
                          : dayjs(mainFilter.startDate).format("DD-MM-YYYY") +
                            " - " +
                            dayjs(mainFilter.endDate).format("DD-MM-YYYY")}
                      </Text>
                    </Col>
                    <Col>
                      <Title
                        level={5}
                        type="secondary"
                        style={{ fontFamily: "Helvetica" }}
                      >
                        Total detected Age Distribution in %
                      </Title>
                    </Col>
                  </Row>
                </>
              }
            >
              <Row
                style={{ height: "100%" }}
                justify={"space-between"}
                align={"middle"}
              >
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                >
                  {hasNonZeroValues ? (
                    <Row>
                      <div
                        style={{
                          width: "15%",
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "start",
                          flexDirection: "column",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Helvetica",
                            fontWeight: "bold",
                          }}
                        >
                          Total:{" "}
                          {chartDatapieGender.maleCount +
                            chartDatapieGender.femaleCount}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Helvetica",
                            fontWeight: "bold",
                            color: "#007acc",
                          }}
                        >
                          Male: {chartDatapieGender.maleCount}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Helvetica",
                            fontWeight: "bold",
                            color: "#00b374",
                          }}
                        >
                          Female: {chartDatapieGender.femaleCount}
                        </Text>
                      </div>
                      <div style={{ width: "85%" }}>
                        <Chart
                          options={chartDatapieGender.options}
                          series={chartDatapieGender.series}
                          type="donut"
                          height="250px"
                        />
                      </div>
                    </Row>
                  ) : (
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                        padding: "30px",
                      }}
                    >
                      <Empty />
                    </Col>
                  )}
                </Col>
                {/* <Divider type="vertical" style={{ height: "250px" }} /> */}
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  style={{ height: "100%" }}
                >
                  <div style={{ height: "250px" }}>
                    {hasNonZeroValues2 ? (
                      <Chart
                        options={chartDatapieAge.options}
                        series={chartDatapieAge.series}
                        type="donut"
                        height="100%"
                      />
                    ) : (
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "100%",
                          padding: "30px",
                        }}
                      >
                        <Empty />
                      </Col>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          {RenderCompController.PeakHours && (
            <div>
              <Col
                xs={{ span: 24 / 1, offset: 0 }}
                sm={{ span: 24 / 1, offset: 0 }}
                md={{ span: 24 / 1, offset: 0 }}
                lg={{ span: 24 / 1, offset: 0 }}
                xl={{ span: 24 / 1, offset: 0 }}
              >
                <Row gutter={[20, 30]}>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    <Card
                      style={{
                        // borderRadius: "15px",
                        // height: "360px",
                        userSelect: "none",
                        boxShadow: "none",
                        marginTop: "1rem",
                      }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"middle"}>
                            <Col>
                              <Title
                                level={5}
                                type="secondary"
                                style={{ fontFamily: "Helvetica" }}
                              >
                                Total detected gender age distribution in count
                              </Title>
                            </Col>
                            <Col>
                              <Text type="secondary">
                                Date:{" "}
                                {mainFilter.selectFilter === 1
                                  ? dayjs(mainFilter.startDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : dayjs(mainFilter.startDate).format(
                                      "DD-MM-YYYY"
                                    ) +
                                    " - " +
                                    dayjs(mainFilter.endDate).format(
                                      "DD-MM-YYYY"
                                    )}
                              </Text>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <div style={{ height: "250px" }}>
                        <ReactApexChart
                          options={AgeMaleFemaleCount.graph.options}
                          series={AgeMaleFemaleCount.graph.series}
                          type="bar"
                          height={250}
                        />
                      </div>
                    </Card>
                  </Col>
                 
                </Row>
              </Col>

              <Divider style={{ margin: "1rem 0rem" }}></Divider>

              <Col
                xs={{ span: 24 / 1, offset: 0 }}
                sm={{ span: 24 / 1, offset: 0 }}
                md={{ span: 24 / 1, offset: 0 }}
                lg={{ span: 24 / 1, offset: 0 }}
                xl={{ span: 24 / 1, offset: 0 }}
                style={{ marginTop: "1rem" }}
              >
                <Row gutter={[20, 30]}>
                  {/* <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                  >
                    <Card
                      style={{ height: "26rem" }}
                      //bodyStyle={{ minHeight: "33rem",maxHeight:"33rem" }}
                      title={
                        <Flex align="center" justify="space-between">
                          <Title
                            level={5}
                            type="secondary"
                            style={{ fontFamily: "Helvetica" }}
                          >
                            Dwell Time Heatmap
                          </Title>
                          <Col>
                            <DatePicker
                              style={{ width: "150px" }}
                              format={"DD-MM-YYYY"}
                              placeholder="Date"
                              value={dayjs(HeatMapBlock.selectedDate)}
                              onChange={onChangeHeatMap}
                              disabledDate={(current) =>
                                current >=
                                dayjs().subtract(1, "day").endOf("day")
                              }
                            />
                          </Col>
                        </Flex>
                      }
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                        >
                          {HeatMapBlock.cameraDetails.length > 0 ? (
                            HeatMapBlock.cameraDetails.map((item) => {
                              const backgroundColor =
                                minAverage !== undefined &&
                                maxAverage !== undefined
                                  ? getColorForValue(
                                      item.avg,
                                      minAverage,
                                      maxAverage
                                    )
                                  : "#ccc"; // Default color if min or max is not yet defined
                              return (
                                <div>
                                  <Tooltip title={item.cameraName}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Button
                                        style={{
                                          display: "flex",
                                          width: "5rem",
                                          height: "5rem",
                                          backgroundColor: backgroundColor,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "black",
                                        }}
                                        onClick={() => {
                                          setHeatMapBlock((prev) => ({
                                            ...prev,
                                            modelOpen: true,
                                          }));
                                          getBlockImage(item);
                                        }}
                                      >
                                        Avg: {item.avg.toFixed(2)}
                                      </Button>
                                    </div>
                                  </Tooltip>
                                </div>
                              );
                            })
                          ) : (
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 23, offset: 0 }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                // marginTop: "60px",
                              }}
                            >
                              <Empty />
                            </Col>
                          )}
                        </div>
                      </div>
                    </Card>
                  </Col> */}

                {/* <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 8, offset: 0 }}
                  lg={{ span: 8, offset: 0 }}
                  xl={{ span: 8, offset: 0 }}
                >
                  <Card
                    style={{ height: "33rem" }}
                    title={
                      <>
                        <Row justify={"space-between"} align={"middle"}>
                          <Col>
                            <Title
                              level={5}
                              type="secondary"
                              style={{ fontFamily: "Helvetica" }}
                            >
                              Dwell Time Heatmap
                            </Title>
                          </Col>
                          <Col>
                            <Text type="secondary">
                              Date:{" "}
                              {mainFilter.selectFilter === 1
                                ? dayjs(mainFilter.startDate).format("DD-MM-YYYY")
                                : dayjs(mainFilter.startDate).format("DD-MM-YYYY") +
                                " - " +
                                dayjs(mainFilter.endDate).format("DD-MM-YYYY")}
                            </Text>
                          </Col>                        
                        </Row>
                      </>
                    }
                  >
                    <Row>
                      <Col span={24}>
                        <div>
                          {HeatmapController.cameralist &&
                            HeatmapController.cameralist.length > 0 ? (
                            <Carousel showThumbs={false}>
                              {HeatmapController.cameralist.map((item) => (
                                <Skeleton
                                  loading={HeatmapController.loading}
                                  active
                                  round
                                  key={item.cameraName}
                                >
                                  <Image
                                    src={`data:image/png;base64,${item.heatMapBase64}`}
                                    style={{
                                      height: "16rem",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Text className="label-heatmap">
                                      Avg: {item.avg} sec
                                      <br />
                                      Camera: {item.cameraName}
                                    </Text>
                                  </div>
                                </Skeleton>
                              ))}
                            </Carousel>
                          ) : (
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24, offset: 0 }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "100%",
                                padding: "30px",
                              }}
                            >
                              <Empty />
                            </Col>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col> */}

                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                >
                  <DwellHourComp />
                </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                  >
                    <Card
                      style={{ height: "33rem", }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"bottom"}>
                            <Col>
                              <Title
                                level={5}
                                type="secondary"
                                style={{ fontFamily: "Helvetica" }}
                              >
                                Footfall Analysis
                              </Title>
                            </Col>
                            {/* <Col>
                              <Text type="secondary">
                                Date:{" "}
                                {mainFilter.selectFilter === 1
                                  ? dayjs(mainFilter.startDate).format("DD-MM-YYYY")
                                  : dayjs(mainFilter.startDate).format("DD-MM-YYYY") +
                                  " - " +
                                  dayjs(mainFilter.endDate).format("DD-MM-YYYY")}
                              </Text>
                            </Col> */}
                            <Col>
                              <div style={{ display: "inline-flex" }}>
                                <div>
                                  <DatePicker
                                    onChange={OnSpiderMonthChange}
                                    format={"MM-YYYY"}
                                    value={dayjs(spiderMonth)}
                                    picker="month"
                                 
                                    disabledDate={(current) => {
                                      // Disable dates after today
                                      return (
                                        current &&
                                        current > moment().endOf("month")
                                      );
                                    }}
                                    allowClear={true}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      {chartFootfallAnalysis.series.length > 0 ? (
                        <ReactApexChart
                        options={chartFootfallAnalysis.options}
                        series={chartFootfallAnalysis.series}
                        type="area"
                        height={350}
                      />
                //         <Flex align="center" justify="center" style={{height:"28rem"}}>
                //  <Radar data={chartFootfallAnalysis}  />
                //         </Flex>
                        
                      ) : (
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100%",
                            padding: "30px",
                          }}
                        >
                          <Empty />
                        </Col>
                      )}
                    </Card>
                  </Col>

                  
                </Row>
              </Col>
            </div>
          )}

          <Modal
            title={
              <>
                <Row align={"middle"} justify={"center"}>
                  <Col>
                    <Text className="Modal-Header">Preview</Text>
                  </Col>
                </Row>
              </>
            }
            centered
            destroyOnClose
            open={PreviewDetails.openModal}
            onCancel={ClosePreviewModal}
            onOk={ClosePreviewModal}
            cancelButtonProps={{ style: { display: "none" } }}
            style={{
              minWidth: "60%",
            }}
          >
            {PreviewDetails.image && PreviewDetails.image ? (
              <>
                <Row justify={"center"} gutter={[20, 20]}>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    {PreviewDetails.image && (
                      <Image
                        alt={"Preview image="}
                        src={"data:image/png;base64," + PreviewDetails.image}
                        preview={false}
                      />
                    )}
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    <Row justify={"center"}>
                      <Col>
                        <Text strong>
                          Camera Name :{" "}
                          <Text title="Camera Name" type="secondary">
                            {PreviewDetails.CameraName &&
                              PreviewDetails.CameraName}
                          </Text>
                        </Text>
                        <Text strong style={{ marginLeft: "2rem" }}>
                          Average :{" "}
                          <Text title="Average" type="secondary">
                            {PreviewDetails.avg &&
                              PreviewDetails.avg.toFixed(0)}
                            s
                          </Text>
                        </Text>
                        <Text strong style={{ marginLeft: "2rem" }}>
                          Min :{" "}
                          <Text title="Average" type="secondary">
                            {PreviewDetails.min &&
                              PreviewDetails.min.toFixed(0)}
                            s
                          </Text>
                        </Text>
                        <Text strong style={{ marginLeft: "2rem" }}>
                          Max :{" "}
                          <Text title="Average" type="secondary">
                            {PreviewDetails.max &&
                              PreviewDetails.max.toFixed(0)}
                            s
                          </Text>
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                styles={{
                  item: {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
              >
                <CiImageOn title="Image unavailable" size={150} />
                <Text strong>Image unavailable</Text>
              </Space>
            )}
          </Modal>
        </div>

    </div>
  );
};

export default Weekly;
