import { Row, Col, Tabs} from "antd";
import FootfallReport from "./FootfallReport";
import TabPane from "antd/lib/tabs/TabPane";
import React from "react";
import { useState } from "react";

const Reports = () => {
  document.title = "Intelense - REPORT";
  const [activeKey, setActiveKey] = useState("1");

  const ReportItems = [
    {
      key: "1",
      label: "Footfall",
      children: <FootfallReport />,
    },
    // {
    //   key: "2",
    //   label: "ANPR",
    //   children: <ANPRreport />,
    // },
    // {
    //   key: "3",
    //   label: "Others",
    //   children: <OtherReports />,
    // },
  ];

  return (
    <div
      style={{
        backgroundColor: "#f7f7f7",
        height: "100vh",
        // height: "100%",
        // // minHeight: "600px",
        // width: "100%",
        // overflowY: "hidden",
      }}
    >
      {/* Your content here */}

      <Row gutter={[10, 10]} justify="center" style={{ rowGap: "0" , backgroundColor: "#f7f7f7"  }}>
        <Col xs={24}>
          <Row gutter={[10, 10]}>
            <Col
              xs={24}
              style={{
                backgroundColor: "#000716",
                width: "100%",
                overflowX: "hidden",
              }}
            >
              <Row align="middle" justify="center" gutter={[20, 20]}>
                <Col xs={23}>
                  <Row align="middle" justify="space-between">
                    {/* <Col>
                      <Title style={{ color: "white" }} level={4}>
                        Reports
                      </Title>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} style={{ width: "100%", padding: 0 }}>
          <Tabs activeKey={activeKey} onChange={setActiveKey} tabPosition="top">
            {ReportItems.map((item) => (
              <TabPane tab={item.label} key={item.key}>
                {item.children}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
