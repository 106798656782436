import React, { useState, useEffect } from "react";
import { Input, Form, Button, Layout, Image, message, Empty, Row, Col } from "antd";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

const { Content } = Layout;
const URL = process.env.REACT_APP_API_KEY;

// Forgot password page to
function ForgotPassword() {
  document.title = "Intelense - ForgotPassword";
  const location = useLocation();
  const [email, setemail] = useState();

  const navigate = useNavigate();
  const [issuccess, setissuccess] = useState(true);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    setemail(query.get("email"));
    axios
      .get(
        URL +
          "/password/resetPassword_linkexpiryCheck?email=" +
          query.get("email") +
          "&&enc1=" +
          query.get("enc1") +
          "&&enc2=" +
          query.get("enc2") +
          "&&enc3=" +
          query.get("enc3")
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setissuccess(false);
        }
      })
      .catch((error) => {
      });
  }, [location]);

  // updating password with mail
  const onFinish = (event) => {
    axios
      .put(URL + "/password/resetpasswordUpdate", {
        email: email,
        Password: event.confirm,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);
          navigate("/Login");
        } else {
          if (response.status === 0) {
            alert("Could not connect with server. Try after sometime");
            message.warning(
              "Could not connect with server. Try after sometime"
            );
          } else {
            message.warning(response.data.msg);
          }
        }
      })
      .catch((err) => {
        message.warning(err);
      });
  };

  return (
    <>
      {issuccess ? (
        <Row
          align="middle"
          justify="center"
          style={{ minHeight: "100%", minWidth: "100%" }}
        >
          <Col span={32}>
            <Empty description="Validating..">
              <LoadingOutlined />
            </Empty>
          </Col>
        </Row>
      ) : (
        <Layout
          style={{ backgroundColor: "white", height: "100vh", width: "100%" }}
        >
          <Content>
            <Row style={{ height: "100vh" }}>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ heigh: "100vh" }}
              >
                <Image
                  preview={false}
                  style={{ maxHeight: "100vh" }}
                  className="image-property"
                  src="pic1.jpg"
                ></Image>
              </Col>

              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-5em",
                  width: "100%",
                }}
                className="gutter-row"
                xs={{ span: 18, offset: 3 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Form
                  style={{ width: "100%" }}
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 12, offset: 0 },
                    md: { span: 12, offset: 0 },
                    lg: { span: 8, offset: 0 },
                    xl: { span: 8, offset: 0 },
                  }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col className="gutter-row " span={4}>
                      <Image
                        preview={false}
                        className="gutter-row bottom-space"
                        src="IntelenseLogoSmall.png"
                      ></Image>
                    </Col>
                  </Row>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,

                        message: "Please input your password!",
                      },
                      {
                        min: 8,
                        message: "Password must be more than 8 Character",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      xs: { span: 24, offset: 0 },
                      sm: { span: 12, offset: 8 },
                      md: { span: 12, offset: 8 },
                      lg: { span: 6, offset: 8 },
                      xl: { span: 8, offset: 8 },
                    }}
                  >
                    <Button
                      style={{ backgroundColor: "#3e3a75", border: "none" }}
                      type="primary"
                      block
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      )}
    </>
  );
}

export default ForgotPassword;
