import { GET_CAMERA_LIST_CALL1, GET_EVENT_LIST_CALL1, GET_REPORT_INITIAL_CALL} from "../../../ApiUrls/ApiConst";
import { GET_CAMERA_LIST_CALL } from "../../../ApiUrls/ApiConst";
import { GET_EVENT_LIST_CALL } from "../../../ApiUrls/ApiConst";
import { GET_REPORT_FINAL_SUBMIT_CALL } from "../../../ApiUrls/ApiConst";
import axiosClient from "../../../Authentication/ApiCall";

export function getInitialReportAPI() {
  return axiosClient.get(
    GET_REPORT_INITIAL_CALL +
      "?startDate=" +
      "" +
      "&&endDate=" +
      "" +
      "&&cameraId=" +
      "" +
      "&&eventId=" +
      13
  );
}

export function getFinalReportAPI() {
  return axiosClient.get(GET_REPORT_FINAL_SUBMIT_CALL);
}
export function getCameraListApi() {
  return axiosClient.get(GET_CAMERA_LIST_CALL);
}
export function getCameraListApi1() {
  return axiosClient.get(GET_CAMERA_LIST_CALL1);
}

export function getEventListApi() {
  return axiosClient.get(GET_EVENT_LIST_CALL);
}

export function getEventListApi1() {
  return axiosClient.get(GET_EVENT_LIST_CALL1);
}
