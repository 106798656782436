import {Card, Col, Row, Select, Typography, message, Table, Tag, Divider, Image, Skeleton, Empty, Flex} from "antd";
import React, { useRef, useState, useEffect } from "react";
import { GiCctvCamera, GiFootsteps } from "react-icons/gi";
import moment from "moment/moment";
import axiosClient from "../../../Authentication/ApiCall";
import dayjs from "dayjs";
import ReactApexChart from "react-apexcharts";
// import {FaRegDotCircle } from "react-icons/fa";
import useInterval from "use-interval";
import Chart from "react-apexcharts";
import { ImHourGlass } from "react-icons/im";
import "../../../../font/Helvetica.ttf"
import { GrDirections } from "react-icons/gr";
import _, {map} from "lodash";
import randomColor from "randomcolor";

const { Title, Text } = Typography;
const { Option } = Select;

let RenderComp = {
  IngressEgress: false,
  DwellFootfall: false,
  EngagedBounced: false,
  AgeGender: false,
  PeakHours: false,
};

let IngressEgressDetails = {
  SelectedDate: "",
  ContinueFetch: true,
};

let FootfallDirectionHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedcamName: "",
  selectedDate: "",
  DataOfDate: "",
  DirectionImage: null,
  DirectionData: [],
  loading: false,
  Hour: "",
};

let EngageAndBounceHourlyDetails = {
  camlist: [],
  selectedcam: null,
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: null,
};

const Live = () => {
  const [RenderCompController, setRenderCompController] = useState(RenderComp);
  const [HandleDateForIngressEgress, setHandleDateForIngressEgress] = useState(IngressEgressDetails);
  const [totalingress, settotalingress] = useState();
  const [totalingrespercentage, settotalingresspercentage] = useState();
  const [ingressloading, setingressloading] = useState(true);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: { 
          show: false 
        },
        contextmenu: { 
          enabled: false 
        },
        zoom: {
          enabled: false, // Ensure zoom is disabled
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: "category", // Change type to category for categorical data
        categories: [], // Initialize categories as empty array
        title: {
          text: "Hour",
          offsetY: -10,
        },
      },
      yaxis: {
        type: "count",
        title: {
          text: "count",
          offsetY: -10,
        },
        labels: {
          formatter: function (value) {
            return parseInt(value) // Convert the y-axis values to decimal format
          },
        },
      },
      colors: ["#00e396", "#008ffb"],
      dataLabels: {
        enabled: false,
        offsetY: -15,
        style: {
          colors: ["#615494", "#f27777"],
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
            offsetY: -20,
            width: 1,
            style: {
              fontSize: "12px",
              colors: [" #000"],
              offsetY: -20,
            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          formatter: function () {
            return 25;
          },
          offsetY: -20,
        },
      },
    },
    series: [
      {
        name: "Ingress",
        data: [],
      },
      {
        name: "Egress",
        data: [],
      },
    ],
  });
  const [peakHour, setpeakHour] = useState();
  const [yesterdaypeakHour, setyesterdaypeakHour] = useState();
  const [Totalcamera, setTotalcamera] = useState(0);
  const [totalfootfallactivecamera, settotalfootfallactivecamera] = useState(0);
  const [eventbasedactivecamera, seteventbasedactivecamera] = useState(0);
  const [data, setdata] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);
  // const containerRef = useRef(null);
  // const targetRef = useRef();
  // const [DownloadDashboard, setDownloadDashboard] = useState(false);
  const [FootfallDirectionHourlyController, setFootfallDirectionHourlyController] = useState(FootfallDirectionHourlyDetails);
  const [EngageBounceHourController, setEngageBounceHourController] = useState(EngageAndBounceHourlyDetails);
  const [EngagedHourlyChart, setEngagedHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
  
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      title: {
        text: "Engaged",
        align: "center",
        margin: 10,
        style: {
          fontSize: '16px',
          fontFamily:"Helvetica"
        }
      },
      colors: ['#00e396', '#feb019', '#ff4560', '#775dd0', '#008ffb', '#00d9e9', '#ff9f00']
    },
    series: [],
  });
  const [BouncedHourlyChart, setBouncedHourlyChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      title: {
        text: "Bounced",
        align: "center",
        margin: 10,
        style: {
          fontSize: '16px',
          fontFamily: "Helvetica"
        }
      },
      colors: ['#00e396', '#feb019', '#ff4560', '#775dd0', '#008ffb', '#00d9e9', '#ff9f00']
    },
    series: [],
  });
  const [DwellHourlyController, setDwellHourlyController] = useState({
    camlist: [],
    selectedcam: null,
    selectedDate: dayjs().format("YYYY-MM-DD"),
    DataOfDate: null,
    selectedcameraname: null,
  });

  // const LiveTag = () => (
  //   <Tag color="green">
  //     <Flex gap={3} align="center">
  //       <Text
  //         style={{
  //           margin: "0px",
  //           fontSize: "13px",
  //           fontWeight: "initial",
  //         }}
  //       >
  //         Live
  //       </Text>
  //       <FaRegDotCircle />
  //     </Flex>
  //   </Tag>
  // );

  // const HandleDateselectIngressEgress = (date, datestring) => {
  //   if (date === null || date === undefined) {
  //     setHandleDateForIngressEgress((prev) => ({
  //       ContinueFetch: true,
  //       SelectedDate: "",
  //     }));
  //   } else {
  //     setHandleDateForIngressEgress((prev) => ({
  //       ContinueFetch: false,
  //       SelectedDate: datestring,
  //     }));
  //   }
  // };

  // const onChangeDateDwellHourly = (date, dateString) => {
  //   if (dateString !== "") {
  //     setDwellHourlyController((prev) => ({
  //       ...prev,
  //       selectedDate: dateString,
  //       selectedcam: null,
  //     }));
  //   } else {
  //     setDwellHourlyController((prev) => ({
  //       ...prev,
  //       selectedDate: "",
  //       selectedcam: null,
  //     }));
  //   }
  // };

  // // Onchange date function for engaged and bouncee hourly
  // const onChangeDateEngageAndBounceHourly = (date, dateString) => {
  //   const datefrom_picker = moment(dateString, "YYYY-MM-DD");
  //   if (datefrom_picker._isValid) {
  //     setEngageBounceHourController((prev) => ({
  //       ...prev,
  //       selectedDate: dateString,
  //       selectedcam: null,
  //     }));
  //   }
  // };

  // const generateColorPalette = (numberOfColors) => {
  //   const palette = [];
  //   if (
  //     numberOfColors > 0 &&
  //     numberOfColors !== null &&
  //     numberOfColors !== undefined
  //   ) {
  //     for (let j = 0; j < numberOfColors; j++) {
  //       palette.push(
  //         randomColor({
  //           luminosity: "dark",
  //           // hue: "blue",
  //         })
  //       );
  //     }
  //   }
  //   return palette;
  // };
  
  const EngageAndBounceHourly = () => {
    return (
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            userSelect: "none",
            boxShadow: "none",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Flex align="center" gap={10}>
                    <Title level={5}  type="secondary" style={{fontFamily:"Helvetica"}}>Hourly Engaged and Bounced</Title>
                    {/* <Tag>
                      {EngageBounceHourController.DataOfDate &&
                        EngageBounceHourController.DataOfDate}
                    </Tag> */}
                  </Flex>
                </Col>
                <Col>
                  <Row align="center" gutter={[10, 0]}>
                    <Col>
                      {/* <DatePicker
                        onChange={onChangeDateEngageAndBounceHourly}
                        format={"YYYY-MM-DD"}
                        disabledDate={(current) => {
                          return current && current > moment().endOf("day");
                        }}
                        defaultValue={dayjs(
                          EngageBounceHourController.DataOfDate
                            ? EngageBounceHourController.DataOfDate
                            : EngageBounceHourController.selectedDate
                        )}
                      /> */}
                    </Col>
                    <Col>
                      <Select
                        onChange={OnChangeCameraSelectEngageAndBounceHourly}
                        placeholder={<Text type="secondary">Camera</Text>}
                        value={EngageBounceHourController.selectedcam}
                        defaultValue={EngageBounceHourController.selectedcam}
                        style={{
                          width: 120,
                        }}
                        options={EngageBounceHourController.camlist}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          }
        >
          <Row gutter={[1, 10]} justify={"space-between"} align={"middle"}>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={EngagedHourlyChart.options}
                  series={EngagedHourlyChart.series}
                  height="100%"
                  key={EngagedHourlyChart.series}
                />
              </div>
            </Col>
            <Divider type="vertical" style={{ height: "250px" }} />
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 11 / 1, offset: 0 }}
              lg={{ span: 11 / 1, offset: 0 }}
              xl={{ span: 11, offset: 0 }}
            >
              <div style={{ height: "250px" }}>
                <ReactApexChart
                  type="bar"
                  options={BouncedHourlyChart.options}
                  series={BouncedHourlyChart.series}
                  height="100%"
                  key={BouncedHourlyChart.series}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  const OnChangeCameraSelectEngageAndBounceHourly = (selectedcam) => {
    if (selectedcam !== null && selectedcam !== undefined) {
      setEngageBounceHourController((prev) => ({
        ...prev,
        selectedcam:selectedcam
      }));
    }
  };

  const transformDataEngageAndBounce = (data, zoneNames) => {
    const hours = _.map(data, "hour");

    const seriesEngage = _.map(zoneNames, (zone) => {
      const Engage_avg = _.map(data, zone + ".engaged");
      const Final_Engage = Engage_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Engage,
      };
    });

    const seriesBounce = _.map(zoneNames, (zone) => {
      const Bounce_avg = _.map(data, zone + ".bounced");
      const Final_Bounce = Bounce_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Bounce,
      };
    });

    return { hours, seriesEngage, seriesBounce };
  };

  const extractZoneNames = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };

  const DwellHourComp = () => {
    return (
      <Card
        // styles={{
        //   body: { maxHeight: "100%" }
        // }}
        style={{width:"100%"}}
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title level={5}  type="secondary" style={{fontFamily:"Helvetica"}}>Dwell time per hour</Title>
                {/* <Tag>
                  {DwellHourlyController.DataOfDate &&
                    DwellHourlyController.DataOfDate}
                  {DwellHourlyController.selectedcameraname &&
                    " - " + DwellHourlyController.selectedcameraname}
                </Tag> */}
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Col>
                    {/* <DatePicker
                      onChange={onChangeDateDwellHourly}
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                      defaultValue={dayjs()}
                    /> */}
                  </Col>
                  <Col>
                    <Select
                      onChange={OnChangeCameraSelect}
                      defaultValue={DwellHourlyController.selectedcam}
                      style={{
                        width: 120,
                      }}
                      placeholder={<Text type="secondary">Camera</Text>}
                      options={DwellHourlyController.camlist}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <ReactApexChart
          options={DwellHourly.options}
          series={DwellHourly.series}
          type="bar"
          height={"100%"}
        />
      </Card>
    );
  };

  useEffect(() => {
    axiosClient
      .get(
        `/dwelltime/getDwellTimeHourData?date=${DwellHourlyController.selectedDate}&camId=${DwellHourlyController.selectedcam}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data.selectedCameraDetails;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setDwellHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.id,
              selectedcameraname: selectedcamDetails.cameraName,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.id,
              label: item.cameraName,
            }));

            setDwellHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
            // CameraList.map((item) => {
            //   let cam = {
            //     value: item.cameraId,
            //     label: item.cameraName,
            //   };

            //   setDwellHourlyController((prev) => ({
            //     ...prev,
            //     camlist: [...prev.camlist, cam],
            //   }));
            // });
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const zoneNames = extractZoneNames(data).sort();
            if (zoneNames.length > 0) {
              const { hours, series, seriesEngage, seriesBounce } =
                transformData(data, zoneNames);
              // const colors = generateColorPalette(
              //   zoneNames ? zoneNames.length : 0
              // );
              setDwellHourly((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },

                },
                series: series,
              }));

              setDwellHourlyController((prev) => ({
                ...prev,
                DataOfDate: DwellHourlyController.selectedDate,
              }));
            }
          }
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [DwellHourlyController.selectedcam,]);

  const OnChangeCameraSelect = (cam) => {
    const selectedCameraName =
      DwellHourlyController.camlist.find((item) => item.value === cam)?.label ||
      null;

    setDwellHourlyController((prev) => ({
      ...prev,
      selectedcam: cam,
      selectedcameraname: selectedCameraName,
    }));
  };

  const [DwellHourly, setDwellHourly] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Hour ", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: ['#00e396', '#feb019', '#ff4560', '#775dd0', '#008ffb', '#00d9e9', '#ff9f00'],
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val + " sec";
          }
        }
      }

    },
    series: [],
  });
 
  const transformData = (data, zoneNames) => {
    const hours = _.map(data, "hour");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };

  //ingress and passer by graph 
  const [passerby, setPasserby] = useState({
    series: [
      {
        name: 'Passer By',
        data: []
      },
      {
        name: 'Ingress',
        data: []
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 150,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false // Disable the toolbar
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '100%',
        },
      },

      xaxis: {
        categories: ['Passer By and Ingress',],
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        show: false
      },
      fill: {
        opacity: 1,
        //colors: ['#1E90FF', '#FFD700'] // Define colors for each segment
      },
      legend: {
        show: true
      },
      title: {
        text: '', // Add the title here
        align: 'center', // Align the title to the center
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#263238'
        }
      }
    },
  });

  function getOverallPasserByCount() {
    axiosClient
      .get("/livereport/getoverallpasserbycount")
      .then((response) => {
        if (response.data.issuccess === true) {
          if (response.data.data.data.length > 0) {
            const data = response.data.data.data[0]; // Assuming there's only one object in the array
            const passerbyCount = data.passerby === null || data.passerby === undefined ? 0 : parseInt(data.passerby)
            const ingressCount = data.ingress === null || data.ingress === undefined ? 0 : parseInt(data.ingress)
            // parseInt(data.passerby);parseInt(data.ingress)

            const updatedSeries = [
              {
                name: 'Passer By',
                data: [passerbyCount]
              },
              {
                name: 'Ingress',
                data: [ingressCount]
              }
            ];

            const updatedOptions = {
              ...passerby.options,
              title: {
                ...passerby.options.title,
                text: `Total: ${passerbyCount + ingressCount}`
              }
            };

            setPasserby({ series: updatedSeries, options: updatedOptions });
          }

        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }

  useEffect(() => {
    axiosClient
      .get("/livereport/geteventbasedactivecameraformall")

      .then((response) => {
        if (response.data.issuccess === true) {
          if (response.data.data.length > 0) {
            const eventsbasedcamera = response.data.data[0].eventDetail;
            seteventbasedactivecamera(eventsbasedcamera);

            const totalcount = response.data.data[0].totalCamera;
            settotalfootfallactivecamera(totalcount);
          } else {
            const totalcount = 0;
            settotalfootfallactivecamera(totalcount);
          }
        }
      })
      .catch((err) => {
      });
  }, []);

  const [PasserTableValue, setPasserTableValue] = useState("")

  function getPasserByCount() {
    axiosClient
      .get("/livereport/getpasserbycount?camId=&date=")
      .then((response) => {
        if (response.data.issuccess === true) {
          if (response.data.data.data.length > 0) {
            const updatedData = response.data.data.data
              .filter((item) => item.hour >= 7)
              .sort((a, b) => b.hour - a.hour) // Sort by hour in descending order
              .map((item) => ({
                hour: moment(item.hour, 'HH').format('h A'),
                passerby: item.passerby || 0, // Set passerby to 0 if null, undefined, or empty
                ingress: item.ingress || 0,   // Set ingress to 0 if null, undefined, or empty
              }));
            setPasserTableValue(updatedData);
          }
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }

  //passer table column
  const PasserColumn = [
    {
      title: 'Hour',
      dataIndex: 'hour',
      key: 'hour',
      render: (text) => <span style={{ color: 'black' }}>{text}</span>,
      align: 'center',

    },
    {
      title: 'Passerby',
      dataIndex: 'passerby',
      key: 'passerby',
      render: (text) => <span style={{ color: 'black' }}>{text}</span>,
      align: 'center',
    },
    {
      title: 'Ingress',
      dataIndex: 'ingress',
      key: 'ingress',
      render: (text) => <span style={{ color: 'black' }}>{text}</span>,
      align: 'center',
    },
  ];

  const paginationConfig = {
    pageSize: 9,
  };

  const handleSelectChange = (value) => {
    const selectedOption = menuOptions.find((option) => option.id === value);
    axiosClient
      .get("/livereport/getingressegress?camid=" + selectedOption.id)
      .then((response) => {
        if (response.data.issuccess === true) {
          setingressloading(false);

          const list_hour = map(response.data.data.total_records_hour, "hour");

          const list_ingress = map(
            response.data.data.total_records_hour,
            "totalingress"
          );
          const list_egress = map(
            response.data.data.total_records_hour,
            "totalegress"
          );
          // const minVal = parseInt(Math.min(...list_ingress, ...list_egress));
          // const maxVal = Math.ceil(Math.max(...list_ingress, ...list_egress));
          setChartData((prev) => ({
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                // ...prev.options.xaxis,
                categories: list_hour,
              },
              // yaxis: {
              //   min: minVal,
              //   max: maxVal,
              // },
            },
            series: [
              {
                name: "Ingress",
                data: list_ingress,
              },
              {
                name: "Egress",
                data: list_egress,
              },
            ],
          }));
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, IngressEgress: true }));
    }, 10);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, DwellFootfall: true }));
    }, 10);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, EngagedBounced: true }));
    }, 10);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, AgeGender: true }));
    }, 10);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, PeakHours: true }));
    }, 10);
  }, []);

  useInterval(async () => {
    getPasserByCount()
    getOverallPasserByCount()
    if (HandleDateForIngressEgress.ContinueFetch === true) {
      axiosClient
      .get(
        `/livereport/getingressegress?date=` +
          HandleDateForIngressEgress.SelectedDate +
          `&camid=` +
          ""
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setingressloading(false);
          settotalingress(response.data.data.total_ingress_egress);
          settotalingresspercentage(
            response.data.data.percentage_ingress_basedOnTime
          );

          setpeakHour(response.data.data.currentPeakHourRange);
          setyesterdaypeakHour(response.data.data.yesterdayPeakHourRange);
          const list_hour = map(response.data.data.total_records_hour, "hour");

          const list_ingress = map(
            response.data.data.total_records_hour,
            "totalingress"
          );
          // const minVal = parseInt(Math.min(...list_ingress, ...list_egress));
          // const maxVal = parseInt(Math.max(...list_ingress, ...list_egress));
          const list_egress = map(
            response.data.data.total_records_hour,
            "totalegress"
          );
          setChartData((prev) => ({
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                // ...prev.options.xaxis,
                categories: list_hour,
              },
              // yaxis: {
              //   min: minVal,
              //   max: maxVal,
              // },
            },
            series: [
              {
                name: "Ingress",
                data: list_ingress,
              },
              {
                name: "Egress",
                data: list_egress,
              },
            ],
          }));
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
    }
  }, 120000);

  useEffect(() => {
    axiosClient
      .get(
        `/livereport/getingressegress?date=` +
          HandleDateForIngressEgress.SelectedDate +
          `&camid=` +
          ""
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          setingressloading(false);
          settotalingress(response.data.data.total_ingress_egress);
          settotalingresspercentage(
            response.data.data.percentage_ingress_basedOnTime
          );
          setpeakHour(response.data.data.currentPeakHourRange);
          setyesterdaypeakHour(response.data.data.yesterdayPeakHourRange);
          const list_hour = map(response.data.data.total_records_hour, "hour");

          const list_ingress = map(
            response.data.data.total_records_hour,
            "totalingress"
          );
          const list_egress = map(
            response.data.data.total_records_hour,
            "totalegress"
          );
          setChartData((prev) => ({
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                // ...prev.options.xaxis,
                categories: list_hour,
              },
            },
            series: [
              {
                name: "Ingress",
                data: list_ingress,
              },
              {
                name: "Egress",
                data: list_egress,
              },
            ],
          }));
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });

    const FetchDataForCrowdAnalysis = async () => {
      try {
        await axiosClient
          .get("crowdAnalysis/getCrowdAnalysisDetails")
          .then((response) => {
            if (response.data.issuccess === true) {
              setdata(response.data.data);
            }
          })
          .catch((err) => {
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.message);
            }
          });
      } catch (err) {
      }
    };

    try {
      FetchDataForCrowdAnalysis();
    } catch (err) {
    }
  }, [HandleDateForIngressEgress.SelectedDate]);

  useEffect(() => {
    axiosClient
      .get("/notification/getEventBasedActiveCamera?eventid=" + 13)

      .then((response) => {
        if (response.data.issuccess === true) {
          setTotalcamera(response.data.onboardCameraDetails.count);
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  useEffect(() => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      loading: true,
    }));
    axiosClient
      .get(
        `/livereport/getfootfalldirectionimage?date=&camId=&hour=`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const selectedcamDetails = response.data.data;
          const CameraList = response.data.data.cameraData;
          if (
            selectedcamDetails.selectedCameraData !== null &&
            selectedcamDetails.selectedCameraData !== undefined &&
            selectedcamDetails.selectedCameraData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.selectedCameraData.cameraId,

              selectedcamName: selectedcamDetails.selectedCameraData.cameraName,
            }));
          }

          if (
            selectedcamDetails.directionData !== null &&
            selectedcamDetails.directionData !== undefined &&
            selectedcamDetails.directionData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionData: selectedcamDetails.directionData,
            }));
          }
          if (
            selectedcamDetails.directionImage !== null &&
            selectedcamDetails.directionImage !== undefined
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionImage: selectedcamDetails.directionImage,
            }));
          }

          if (
            selectedcamDetails.hour !== null &&
            selectedcamDetails.hour !== undefined &&
            selectedcamDetails.hour !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              Hour: selectedcamDetails.hour,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraid,
              label: item.cameraname,
            }));

            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }
        } else {
        }
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [
    FootfallDirectionHourlyController.selectedcam,
    FootfallDirectionHourlyController.selectedDate,
    FootfallDirectionHourlyController.Hour,
  ]);

  useEffect(() => {
    getPasserByCount()
    getOverallPasserByCount()
  }, [])

  useEffect(() => {
    axiosClient
      .get(
        `/dwelltime/getEngageBounceTimeHourData?date=${dayjs(
          EngageBounceHourController.selectedDate
        ).format("YYYY-MM-DD")}&camId=${EngageBounceHourController.selectedcam}`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const data = response.data.data.dwellData
            ? response.data.data.dwellData
            : [];

          const selectedcamDetails = response.data.data.selectedCameraDetails;
          const CameraList = response.data.data.cameraData;
          if (selectedcamDetails) {
            setEngageBounceHourController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.id,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.id,
              label: item.cameraName,
            }));

            setEngageBounceHourController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }

          if (data.length > 0 || data !== null || data !== undefined) {
            const zoneNames = extractZoneNames(data).sort();
            if (zoneNames.length > 0) {
              const { hours, seriesEngage, seriesBounce } =
                transformDataEngageAndBounce(data, zoneNames);
              // const colors = generateColorPalette(
              //   zoneNames ? zoneNames.length : 0
              // );

              setEngagedHourlyChart((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },

                },
                series: seriesEngage,
              }));

              setBouncedHourlyChart((prevState) => ({
                ...prevState,
                options: {
                  ...prevState.options,
                  xaxis: {
                    ...prevState.options.xaxis,
                    categories: hours,
                  },

                },
                series: seriesBounce,
              }));

              setEngageBounceHourController((prev) => ({
                ...prev,
                DataOfDate: EngageBounceHourController.selectedDate,
              }));
            }
          }
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  },
    [
      EngageBounceHourController.selectedcam,
  ]);

  // useEffect(() => {
    //   // Define an asynchronous function to fetch data
    //   const fetchData = async () => {
    //     try {
    //       // Fetch Dwell Time Data
    //       const dwellResponse = await axiosClient.get(
    //         `/dwelltime/getDwellTimeHourData?date=${DwellHourlyController.selectedDate}&camId=${DwellHourlyController.selectedcam}`
    //       );
    //       if (dwellResponse.data.issuccess) {
    //         const dwellData = dwellResponse.data.data.dwellData || [];
    //         const selectedCamDetails = dwellResponse.data.data.selectedCameraDetails;
    //         const cameraList = dwellResponse.data.data.cameraData;
    
    //         // Update DwellHourlyController with camera list and selected camera details
    //         if (selectedCamDetails) {
    //           setDwellHourlyController((prev) => ({
    //             ...prev,
    //             selectedcam: selectedCamDetails.cameraId,
    //             selectedcameraname: selectedCamDetails.cameraName,
    //           }));
    //         }
    //         if (cameraList) {
    //           const newCamList = cameraList.map((item) => ({
    //             value: item.cameraId,
    //             label: item.cameraName,
    //           }));
    
    //           setDwellHourlyController((prev) => ({
    //             ...prev,
    //             camlist: newCamList,
    //           }));
    //         }
    
    //         // Process dwell data
    //         if (dwellData.length > 0) {
    //           const zoneNames = extractZoneNames(dwellData).sort();
    //           if (zoneNames.length > 0) {
    //             const { hours, series } = transformData(dwellData, zoneNames);
    //             const colors = generateColorPalette(zoneNames.length);
    
    //             setDwellHourly((prevState) => ({
    //               ...prevState,
    //               options: {
    //                 ...prevState.options,
    //                 xaxis: {
    //                   ...prevState.options.xaxis,
    //                   categories: hours,
    //                 },
    //               },
    //               series,
    //             }));
    
    //             setDwellHourlyController((prev) => ({
    //               ...prev,
    //               DataOfDate: DwellHourlyController.selectedDate,
    //             }));
    //           }
    //         }
    //       } else {
    //       }
    
    //       // Fetch Engage Bounce Hour Data
    //       const engageResponse = await axiosClient.get(
    //         `/dwelltime/getDwellTimeHourData?date=${dayjs(
    //           EngageBounceHourController.selectedDate
    //         ).format("YYYY-MM-DD")}&camId=${EngageBounceHourController.selectedcam}`
    //       );
    
    //       if (engageResponse.data.issuccess) {
    //         const engageData = engageResponse.data.data.dwellData || [];
    //         const selectedEngageCamDetails = engageResponse.data.data.selectedCameraDetails;
    //         const engageCameraList = engageResponse.data.data.cameraData;
    
    //         // Update EngageBounceHourController with camera list and selected camera details
    //         if (selectedEngageCamDetails) {
    //           setEngageBounceHourController((prev) => ({
    //             ...prev,
    //             selectedcam: selectedEngageCamDetails.cameraId,
    //           }));
    //         }
    
    //         if (engageCameraList) {
    //           const newEngageCamList = engageCameraList.map((item) => ({
    //             value: item.id,
    //             label: item.cameraName,
    //           }));
    
    //           setEngageBounceHourController((prev) => ({
    //             ...prev,
    //             camlist: newEngageCamList,
    //           }));
    //         }
    
    //         // Process engage and bounce data
    //         if (engageData.length > 0) {
    //           const zoneNames = extractZoneNames(engageData).sort();
    //           if (zoneNames.length > 0) {
    //             const { hours, seriesEngage, seriesBounce } = transformDataEngageAndBounce(engageData, zoneNames);
    //             const colors = generateColorPalette(zoneNames.length);
    
    //             setEngagedHourlyChart((prevState) => ({
    //               ...prevState,
    //               options: {
    //                 ...prevState.options,
    //                 xaxis: {
    //                   ...prevState.options.xaxis,
    //                   categories: hours,
    //                 },
    //               },
    //               series: seriesEngage,
    //             }));
    
    //             setBouncedHourlyChart((prevState) => ({
    //               ...prevState,
    //               options: {
    //                 ...prevState.options,
    //                 xaxis: {
    //                   ...prevState.options.xaxis,
    //                   categories: hours,
    //                 },
    //               },
    //               series: seriesBounce,
    //             }));
    
    //             setEngageBounceHourController((prev) => ({
    //               ...prev,
    //               DataOfDate: EngageBounceHourController.selectedDate,
    //             }));
    //           }
    //         }
    //       } else {
    //       }
    //     } catch (err) {
    //       if (err.status === 0) {
    //         message.error("Server error");
    //       } else {
    //         message.error(err.msg);
    //       }
    //     }
    //   };
    
    //   fetchData();
    // }, [DwellHourlyController.selectedcam, DwellHourlyController.selectedDate, EngageBounceHourController.selectedcam, EngageBounceHourController.selectedDate]);
    
  return (
    <div style={{ backgroundColor: "#fffff", marginBottom: "10rem" }}>

      <Row
        className="Base-row"
        gutter={[30, 20]}
        style={{
          margin: "10px",
          backgroundColor: "white",
          marginBottom: "30px",
        }}
      >
        {RenderCompController.IngressEgress && (
          <Col
            xs={{ span: 24 / 1, offset: 0 }}
            sm={{ span: 24 / 1, offset: 0 }}
            md={{ span: 24 / 1, offset: 0 }}
            lg={{ span: 24 / 1, offset: 0 }}
            xl={{ span: 24 / 1, offset: 0 }}
          >
            <Row gutter={[20, 20]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 20, offset: 0 }}
                lg={{ span: 14, offset: 0 }}
                xl={{ span: 16 / 1, offset: 0 }}
              >
                <Row gutter={[20, 20]}>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    <Row gutter={[20, 20]}>
                      <Col
                        xs={{ span: 18, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        lg={{ span: 12, offset: 0 }}
                        xl={{ span: 12 / 1, offset: 0 }}
                      >
                        <Card
                          className="ingress-hover"
                          style={{
                            cursor: "pointer",
                            userSelect: "none",
                            boxShadow: "none",
                          }}
                          title={
                            <Row justify={"space-between"} align={"middle"}>
                              <Col>
                                <Title level={5} type="secondary" style={{ fontFamily: "Helvetica" }}>
                                  Footfall
                                </Title>
                              </Col>
                              {/* <Col>
                                    {HandleDateForIngressEgress.SelectedDate ? (
                                      <>
                                        <Tag color="green">
                                          {
                                            HandleDateForIngressEgress.SelectedDate
                                          }
                                        </Tag>
                                      </>
                                    ) : (
                                      <LiveTag />
                                    )}
                                  </Col> */}
                            </Row>
                          }
                          styles={{
                            body: {
                              paddingTop: "0px",
                              // paddingBottom: "0px",
                            },
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: "2rem",
                              overflow: "hidden",
                            }}
                          >
                            <span>
                              <Title
                                level={1}
                                style={{
                                  fontWeight: "normal",
                                  margin: 0,
                                  padding: "5px",
                                  fontFamily: "Helvetica",
                                }}
                              >
                                {totalingress !== null &&
                                  totalingress !== undefined
                                  ? totalingress
                                  : 0}
                              </Title>
                            </span>

                            <span>
                              <GiFootsteps size={70} />
                              {/* <GiCctvCamera size={70}  color=""/> */}
                            </span>
                          </div>
                          <div>
                            <span>
                              <Text style={{ backgroundColor: "#abd1b5" }}>
                                {totalingrespercentage !== null &&
                                  totalingrespercentage !== undefined
                                  ? totalingrespercentage > 100
                                    ? "100"
                                    : totalingrespercentage
                                  : 0}
                                %
                              </Text>
                              <Text> Vs.PreviousDay</Text>
                            </span>
                          </div>
                        </Card>
                      </Col>

                      <Col
                        xs={{ span: 18, offset: 0 }}
                        sm={{ span: 18, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        lg={{ span: 12, offset: 0 }}
                        xl={{ span: 12 / 1, offset: 0 }}
                      >
                        <Card
                          className="ingress-hover"
                          style={{
                            cursor: "pointer",
                            userSelect: "none",
                            boxShadow: "none",
                          }}
                          title={
                            <>
                              <Row
                                justify={"space-between"}
                                align={"middle"}
                              >
                                <Col>
                                  <Title level={5} type="secondary" style={{ fontFamily: "Helvetica" }}>
                                    Peak Hours
                                  </Title>
                                </Col>
                                {/* <Col>
                                      {HandleDateForIngressEgress.SelectedDate ? (
                                        <>
                                          <Tag color="green">
                                            {
                                              HandleDateForIngressEgress.SelectedDate
                                            }
                                          </Tag>
                                        </>
                                      ) : (
                                        <LiveTag />
                                      )}
                                    </Col> */}
                              </Row>
                            </>
                          }
                          styles={{ body: { paddingTop: "0px" } }}
                        >
                          <Col
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",

                              overflow: "hidden",
                            }}
                          >
                            <Col style={{ overflow: "hidden" }}>
                              {" "}
                              <Text
                                style={{
                                  fontWeight: "bold",
                                  margin: 0,
                                  padding: "5px",
                                  fontFamily: "Helvetica",
                                  fontSize: "29px",
                                }}
                              >
                                {peakHour &&
                                  peakHour !== "Invalid date - Invalid date"
                                  ? peakHour
                                  : "- -"}
                              </Text>
                            </Col>

                            <span style={{ marginTop: "2rem" }}>
                              <ImHourGlass size={60} />
                            </span>
                          </Col>

                          <Col style={{ marginBottom: "10px" }}>
                            {" "}
                            <span>
                              Yesterday's Peak Hours{" "}
                              {yesterdaypeakHour &&
                                yesterdaypeakHour !==
                                "Invalid date - Invalid date"
                                ? yesterdaypeakHour
                                : "- -"}
                            </span>
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                    <Row gutter={[20, 20]} style={{ marginTop: "1rem" }}>

                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        xl={{ span: 24 / 1, offset: 0 }}
                      >
                        <Card
                          className="ingress-hover"
                          style={{
                            cursor: "pointer",
                            userSelect: "none",
                            boxShadow: "none",

                            // overflowY: "scroll",
                            height: "18rem",
                          }}
                          title={
                            <>
                              <Row
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Title
                                  type="secondary"
                                  level={5}
                                  style={{ fontFamily: "Helvetica" }}
                                >
                                  Total Onboard Camera :
                                  <span style={{ marginLeft: "7px" }}>
                                    {totalfootfallactivecamera}
                                  </span>
                                </Title>
                                <div>
                                  <GiCctvCamera size={40} />
                                </div>
                              </Row>
                            </>
                          }
                        >
                          <Row gutter={[16, 16]}>
                            {eventbasedactivecamera &&
                              eventbasedactivecamera.length > 0 ? (
                              eventbasedactivecamera.map((item, index) => (
                                <Col key={index} xs={24} sm={12} md={8}>
                                  <Card
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "8px",
                                      height: "105px",
                                    }}
                                  >
                                    <Title
                                      level={5}
                                      style={{
                                        fontFamily: "Helvetica",
                                        fontWeight: "bold",
                                        margin: 0,
                                      }}
                                    >
                                      {item.eventName}
                                    </Title>
                                    <Text
                                      type="secondary"
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "10px",
                                        display: "block",
                                      }}
                                    >
                                      Active Cameras: {item.cameraCount}
                                    </Text>
                                  </Card>
                                </Col>
                              ))
                            ) : (
                              <Col span={24}>
                                <Empty description="No active cameras available" />
                              </Col>
                            )}
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    <Row gutter={[20, 20]}>
                      <Col
                        xs={{ span: 23, offset: 0 }}
                        sm={{ span: 22, offset: 0 }}
                        md={{ span: 20, offset: 0 }}
                        lg={{ span: 22, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                      // style={{ marginLeft: "1%" }}
                      >
                        <Card
                          loading={ingressloading}
                          // hoverable

                          style={{
                            cursor: "pointer",
                            userSelect: "none",
                            boxShadow: "none",
                          }}
                          title={
                            <Row justify={"space-between"} align={"middle"}>
                              <Col>
                                <Row gutter={5} align={"middle"}>
                                  <Col>
                                    <Title
                                      level={5}
                                      type="secondary"
                                      style={{ fontFamily: "Helvetica" }}
                                    >
                                      Ingress and Egress Counts per Hour
                                    </Title>
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <Select
                                  onChange={handleSelectChange}
                                  style={{
                                    width: 120,
                                  }}
                                  placeholder={
                                    <Text type="secondary">Camera</Text>
                                  }
                                >
                                  {menuOptions.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                      {option.cameraName}
                                    </Option>
                                  ))}
                                </Select>
                              </Col>
                            </Row>
                          }
                        >
                          {chartData.series && chartData.series.length > 0 ? (
                            <div className="app">
                              <div className="row">
                                <div className="mixed-chart">
                                  <ReactApexChart
                                    options={chartData.options}
                                    series={chartData.series}
                                    type="area"
                                    height={"200%"}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                textAlign: "center",
                                padding: "20px",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: "16px",
                                  color: "gray",
                                }}
                              >
                                No Data Available
                              </Text>
                            </div>
                          )}
                        </Card>
                      </Col>
                      <Col
                        xs={{ span: 23, offset: 0 }}
                        sm={{ span: 22, offset: 0 }}
                        md={{ span: 20, offset: 0 }}
                        lg={{ span: 22, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                      // style={{ marginLeft: "1%" }}
                      >
                        <Card
                          loading={ingressloading}
                          // hoverable


                          style={{
                            cursor: "pointer",
                            userSelect: "none",
                            boxShadow: "none",
                            height: "16rem"
                          }}
                          title={
                            <Row justify={"space-between"} align={"middle"}>
                              <Col>
                                <Row gutter={5} align={"middle"}>
                                  <Col>
                                    <Title level={5} type="secondary" style={{ fontFamily: "Helvetica" }}>
                                      Passerby and Ingress
                                    </Title>
                                  </Col>

                                </Row>
                              </Col>
                              <Col>
                                {/* <DatePicker
                                      className="dwell_date"
                                      onChange={HandleDateselectIngressEgress}
                                      disabledDate={(current) =>
                                        current &&
                                        current > moment().endOf("day")
                                      }
                                      // defaultValue={dayjs()}
                                    /> */}
                              </Col>
                            </Row>
                          }
                        >
                          <Chart options={passerby.options} series={passerby.series} type="bar" height={140} />
                        </Card>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Col>

              <Col
                xs={{ span: 23, offset: 0 }}
                sm={{ span: 23, offset: 0 }}
                md={{ span: 17 / 1, offset: 0 }}
                lg={{ span: 10 / 1, offset: 0 }}
                xl={{ span: 8 / 1, offset: 0 }}
              // style={{ marginRight: "1px" }}
              >
                <Row
                  justify={"space-between"}
                  gutter={[20, 20]}
                  style={{ height: "100%" }}
                >

                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    <Card
                      style={{ height: "32.25rem" }}
                      //styles={{ body: { minHeight: "100%" } }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"middle"}>
                            <Col span={6}>
                              <Flex gap={5} align="center">
                                <Title level={5} type="secondary" style={{ fontFamily: "Helvetica" }}>
                                  Footfall Direction <GrDirections />
                                </Title>
                              </Flex>

                              <Tag>
                                {FootfallDirectionHourlyController.selectedcamName ? (
                                  FootfallDirectionHourlyController.selectedcamName +
                                  " - "
                                ) : (
                                  <></>
                                )}
                                {FootfallDirectionHourlyController.selectedDate ? (
                                  FootfallDirectionHourlyController.selectedDate +
                                  " - "
                                ) : (
                                  <></>
                                )}
                                {FootfallDirectionHourlyController.Hour ? (
                                  FootfallDirectionHourlyController.Hour +
                                  " hr"
                                ) : (
                                  <></>
                                )}
                              </Tag>
                            </Col>

                          </Row>
                        </>
                      }
                    >
                      <div style={{ maxHeight: "250px" }}>
                        {/* <Image
                                  src={
                                  dire
                                  }
                                  style={{
                                    maxHeight: "250px",
                                    minHeight: "250px",
                                  }}
                                ></Image> */}

                        {FootfallDirectionHourlyController.DirectionImage &&
                          FootfallDirectionHourlyController.DirectionImage ? (
                          <Skeleton
                            style={{
                              maxHeight: "250px",
                              minHeight: "250px",
                            }}
                            loading={
                              FootfallDirectionHourlyController.loading
                            }
                            active
                            round
                          >
                            <Image
                              src={
                                "data:image/png;base64," +
                                FootfallDirectionHourlyController.DirectionImage
                              }
                              style={{
                                maxHeight: "250px",
                                minHeight: "250px",
                              }}
                            ></Image>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {FootfallDirectionHourlyController.DirectionData !==
                                "No data" ? (
                                // FootfallDirectionHourlyController.DirectionData.map(
                                //   (item) => (
                                //     <Text className="label-Direction">
                                //       {item.directionname} :{" "}
                                //       {item.count}
                                //     </Text>
                                //   )
                                // )
                                <></>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Skeleton>
                        ) : (
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 24, offset: 0 }}
                            xl={{ span: 23, offset: 0 }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // marginTop: "60px",
                            }}
                          >
                            <Empty />
                          </Col>
                        )}
                      </div>
                    </Card>

                  </Col>

                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    <Card
                      style={{ height: "43.5rem" }}
                      //styles={{ body: { minHeight: "100%" } }}
                      title={
                        <>

                          <Flex gap={5} align="center">
                            <Title level={5} type="secondary" style={{ fontFamily: "Helvetica" }}>
                              Passerby Count table
                            </Title>
                          </Flex>





                        </>
                      }
                    >
                      <Table dataSource={PasserTableValue} columns={PasserColumn} rowKey="hour" pagination={paginationConfig} />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Col>

        )}
        <Divider style={{ margin: 0 }}></Divider>
        {RenderCompController.EngagedBounced && (
          <Col
            xs={{ span: 24 / 1, offset: 0 }}
            sm={{ span: 24 / 1, offset: 0 }}
            md={{ span: 24 / 1, offset: 0 }}
            lg={{ span: 24 / 1, offset: 0 }}
            xl={{ span: 24 / 1, offset: 0 }}
          >
            <Row style={{ width: "100%" }}>
              <EngageAndBounceHourly />
            </Row>
          </Col>
        )}

        <Col
          // xs={{ span: 22, offset: 0 }}
          // sm={{ span: 20, offset: 0 }}
          // md={{ span: 16, offset: 0 }}
          // lg={{ span: 12, offset: 0 }}
          // xl={{ span: 14, offset: 0 }}
          // xs={{ span: 24 / 1, offset: 0 }}
          // sm={{ span: 24 / 1, offset: 0 }}
          // md={{ span: 24 / 1, offset: 0 }}
          // lg={{ span: 24 / 1, offset: 0 }}
          // xl={{ span: 24 / 1, offset: 0 }}
          style={{ width: "100%" }}
        >
          <Row style={{ width: "100%" }}>

            <DwellHourComp />
          </Row>
        </Col>
      </Row>

    </div>
  );
};

export default Live;

