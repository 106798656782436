import { Card, Col, Flex, Row, Typography, Drawer, Button, message} from "antd";
import React, { useEffect, useState } from "react";
import { FaBars, FaArrowDown, FaArrowUp } from "react-icons/fa";
import Chart from 'react-apexcharts';
import "./LiveCss.css";
import axiosClient from "../../../Authentication/ApiCall";

const { Title, Text } = Typography;

const LiveStore = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [EngagesBounced, setEngagesBounced] = useState({
    bouncedPercentageChange: 0,
    engagedPercentageChange: 0,
    todayBouncedCount: 0,
    todayEngagedCount: 0,
  });
  const [dwellData, setDwellData] = useState([]);
  const [TotalVisitor,setTotalVisitor]=useState({
    today:0,
    Yesterday:0
  })
  const [HourIngressEgress,setHourIngressEgress]=useState({
    options: {
      chart: { type: 'bar' },
      xaxis: { categories: [], title: { text: 'Time' } },
      yaxis: { title: { text: 'Count' } },
      legend: { position: 'top' },
      plotOptions: { bar: { horizontal: false, columnWidth: '50%' } }
    },
    series: [
      { name: 'Ingress', data: [] },
      { name: 'Egress', data: [] }
    ]
  })
  const [BusiestHour,setBusiestHour]=useState({
    hour:"--"
  })

  const tabs = Array.from({ length: 15 }, (_, index) => `Section ${index + 1}`);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  useEffect(() => {
    getEngagesAndBounced();
    getTotalEngageBounce();
    getStoreIngressDetails();
  }, []);

  
  const getEngagesAndBounced = () => {
    axiosClient
      .get(`/store/getengagebouncetotalcount`)

      .then((response) => {
        if (response.data.issuccess === true) {
          const {
            engagedPercentageChange,
            bouncedPercentageChange,
            todayBouncedCount,
            todayEngagedCount,
          } = response.data.data;

          setEngagesBounced({
            engagedPercentageChange:
              engagedPercentageChange !== null &&
              engagedPercentageChange !== undefined
                ? engagedPercentageChange
                : 0,
            bouncedPercentageChange:
              bouncedPercentageChange !== null &&
              bouncedPercentageChange !== undefined
                ? bouncedPercentageChange
                : 0,
            todayBouncedCount:
              todayBouncedCount !== null && todayBouncedCount !== undefined
                ? todayBouncedCount
                : 0,
            todayEngagedCount:
              todayEngagedCount !== null && todayEngagedCount !== undefined
                ? todayEngagedCount
                : 0,
          });
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };


  const getTotalEngageBounce = () => {
    axiosClient
      .get(`/store/getstoresectionwisedata`)

      .then((response) => {
        if (response.data.issuccess === true) {
          setDwellData(response.data.data.dwellData)
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };


  const getStoreIngressDetails = () => {
    axiosClient
      .get(`/store/getstoreingressdetails`)
      .then((response) => {
        if (response.data.issuccess === true) {
          const data=response.data.data          
          setTotalVisitor({
            today:data.totalVisitor!=null || data.totalVisitor !== undefined?data.totalVisitor:0,
            Yesterday:data.comparativePercentage!=null || data.comparativePercentage!==undefined?data.comparativePercentage:0
          })
          if(data.busiestHour){
            setBusiestHour({
              hour:data.busiestHour.hour!=null || data.busiestHour.hour !== undefined?data.busiestHour.hour.split(" ")[1]:"--"
            })
          }

          let categories = []
          let ingressData = []
          let egressData = []
          if(typeof data.hourData !== "undefined")
          {
            categories = data.hourData.length > 0 ? data.hourData.map(entry => entry.hour.split(" ")[1]):[] 
            ingressData = data.hourData.length > 0? data.hourData.map(entry => entry.total_ingress):[] 
            egressData = data.hourData.length > 0? data.hourData.map(entry => entry.total_egress):[]
          }                           
      
          // Update chart configuration
          setHourIngressEgress({
            options: {
              ...HourIngressEgress.options,
              xaxis: { ...HourIngressEgress.options.xaxis, categories: categories }
            },
            series: [
              { name: 'Ingress', data: ingressData },
              { name: 'Egress', data: egressData }
            ]
          });
          
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };



  return (
    <div style={{ marginBottom: "10rem", boxSizing: "border-box" }}>
      <Row
        gutter={[10, 10]}
        style={{ margin: window.innerWidth > 850 ? "1rem 3rem" : "1rem" }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Col>
            <h2>Dashboard</h2>
          </Col>
          <Col>
            <h2>Location: JP nagar </h2>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={6}>
              <Card
                title={
                  <>
                    <Col>
                      <Title type="secondary" level={5}>
                        Total Visitors
                      </Title>
                    </Col>
                  </>
                }
              >
                <Col style={{ height: "5rem" }}>
                  <Flex vertical align="flex-start" justify="center">
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        padding: 0,
                      }}
                    >
                      <Col>
                        <Text style={{ fontSize: "2rem", fontWeight: "500" }}>
                          {TotalVisitor.today}
                        </Text>
                      </Col>
                      <Col>
                        <Text style={{ fontSize: "1rem" }}>
                          {TotalVisitor.Yesterday.toFixed(2)}% {TotalVisitor.Yesterday<=0? <FaArrowDown size={15} />:<FaArrowUp size={15} />} 
                        </Text>
                      </Col>
                    </Col>
                    <Col>
                      <Text style={{ fontSize: "1rem" }}> Vs Previous Day</Text>
                    </Col>
                  </Flex>
                </Col>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6}>
              <Card
                title={
                  <>
                    <Col>
                      <Title type="secondary" level={5}>
                        Engages
                      </Title>
                    </Col>
                  </>
                }
              >
                <Col style={{ height: "5rem" }}>
                  <Flex vertical align="flex-start" justify="center">
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        padding: 0,
                      }}
                    >
                      <Col>
                        <Text style={{ fontSize: "2rem", fontWeight: "500" }}>
                          {EngagesBounced.todayEngagedCount}
                        </Text>
                      </Col>
                      <Col>
                        <Text style={{ fontSize: "1rem" }}>
                          {EngagesBounced.engagedPercentageChange}{" "}
                          {parseFloat(EngagesBounced.engagedPercentageChange) <
                          0 ? (
                            <FaArrowDown size={15} />
                          ) : (
                            <FaArrowUp size={15} />
                          )}
                        </Text>
                      </Col>
                    </Col>
                    <Col>
                      <Text style={{ fontSize: "1rem" }}> Vs Previous Day</Text>
                    </Col>
                  </Flex>
                </Col>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6}>
              <Card
                title={
                  <>
                    <Col>
                      <Title type="secondary" level={5}>
                        Bounced
                      </Title>
                    </Col>
                  </>
                }
              >
                <Col style={{ height: "5rem" }}>
                  <Flex vertical align="flex-start" justify="center">
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        padding: 0,
                      }}
                    >
                      <Col>
                        <Text style={{ fontSize: "2rem", fontWeight: "500" }}>
                          {EngagesBounced.todayBouncedCount}
                        </Text>
                      </Col>
                      <Col>
                        <Text style={{ fontSize: "1rem" }}>
                          {EngagesBounced.bouncedPercentageChange}{" "}
                          {parseFloat(EngagesBounced.bouncedPercentageChange) <
                          0 ? (
                            <FaArrowDown size={15} />
                          ) : (
                            <FaArrowUp size={15} />
                          )}
                        </Text>
                      </Col>
                    </Col>
                    <Col>
                      <Text style={{ fontSize: "1rem" }}> Vs Previous Day</Text>
                    </Col>
                  </Flex>
                </Col>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6}>
              <Card
                title={
                  <>
                    <Col>
                      <Title type="secondary" level={5}>
                        Camera's
                      </Title>
                    </Col>
                  </>
                }
              >
                <Col style={{ height: "5rem" }}>
                  <Flex
                    vertical
                    align="flex-start"
                    justify="center"
                    gap={"1rem"}
                  >
                    <Col>
                      <Text style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Active Camera: 20
                      </Text>
                    </Col>
                    <Col>
                      <Text style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Total Camera: 30
                      </Text>
                    </Col>
                  </Flex>
                </Col>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card
                title={
                  <>
                    <Col>
                      <Title type="secondary" level={5}>
                        Busiest Hour
                      </Title>
                    </Col>
                  </>
                }
              >
                <Col style={{ height: "5rem" }}>
                  <Col>
                    <Text style={{ fontSize: "2rem", fontWeight: "500" }}>
                      {BusiestHour.hour}
                    </Text>
                  </Col>
                </Col>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Card
                title={
                  <>
                    <Col>
                      <Title type="secondary" level={5}>
                        Employee Engagement
                      </Title>
                    </Col>
                  </>
                }
              >
                <Col style={{ height: "5rem" }}>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      padding: 0,
                    }}
                  >
                    <Col>
                      <Text style={{ fontSize: "2rem", fontWeight: "500" }}>
                        70%
                      </Text>
                    </Col>
                    <Col>
                      <Text style={{ fontSize: "1rem" }}>
                        of customer's are attended
                      </Text>
                    </Col>
                  </Col>
                </Col>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                title={
                  <>
                    <Col>
                      <Title type="secondary" level={5}>
                        Hourly Footfall
                      </Title>
                    </Col>
                  </>
                }
              >
                <Col style={{ height: "23rem" }}>
                <Chart options={HourIngressEgress.options} series={HourIngressEgress.series} type="bar" height={350} />
                </Col>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Card
                title={
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Title type="secondary" level={5}>
                      Section Wise Behavior
                    </Title>
                    <Button
                      className="btn"
                      type="primary"
                      icon={<FaBars />}
                      onClick={toggleDrawer}
                    />
                  </Col>
                }
              >
                <div
                  className="tab-container"
                  style={{ height: window.innerWidth > 850 ? "22rem" : "auto" }}
                >
                  <div className="tabs">
                    {dwellData.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? "active" : ""}`}
                        onClick={() => setActiveTab(index)}
                        style={{
                          display: window.innerWidth > 850 ? "block" : "none",
                        }} // Show tabs only on larger screens
                      >
                        {tab.area}
                      </div>
                    ))}
                  </div>
                  <div className="content">
                    <h2 style={{ width: "100%", textAlign: "center" }}>
                      
                      {dwellData.length>0?dwellData[activeTab].area:""}
                    </h2>
                    <Row gutter={[20, 20]}>
                      {/* Replace this section with your desired content */}
                      <Flex
                        align="center"
                        justify="space-between"
                        style={{
                          width: "100%",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        <Col style={{ width: "40%" }}>
                          <Text style={{ fontSize: "1rem", fontWeight: 600 }}>
                            Section Visitor
                          </Text>
                        </Col>
                        <Col style={{ width: "60%" }}>
                          <Flex gap={"1rem"} justify="flex-end">
                            <Col>
                              <Text
                                style={{ fontSize: "1rem", fontWeight: 600 }}
                              >
                                204
                              </Text>
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "1rem" }}>
                                25% <FaArrowDown size={15} />
                              </Text>
                            </Col>
                          </Flex>
                        </Col>
                      </Flex>
                      <Flex
                        align="center"
                        justify="space-between"
                        style={{
                          width: "100%",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        <Col style={{ width: "40%" }}>
                          <Text style={{ fontSize: "1rem", fontWeight: 600 }}>
                            Engaged Customer
                          </Text>
                        </Col>
                        <Col style={{ width: "60%" }}>
                          <Flex gap={"1rem"} justify="flex-end">
                            <Col>
                              <Text
                                style={{ fontSize: "1rem", fontWeight: 600 }}
                              >
                               {dwellData.length>0?dwellData[activeTab].totalEngaged:""}
                              </Text>
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "1rem" }}>
                                40% <FaArrowDown size={15} />
                              </Text>
                            </Col>
                          </Flex>
                        </Col>
                      </Flex>
                      <Flex
                        align="center"
                        justify="space-between"
                        style={{
                          width: "100%",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        <Col style={{ width: "40%" }}>
                          <Text style={{ fontSize: "1rem", fontWeight: 600 }}>
                            Bounced Customer
                          </Text>
                        </Col>
                        <Col style={{ width: "60%" }} justify="flex-end">
                          <Flex gap={"1rem"} justify="flex-end">
                            <Col>
                              <Text
                                style={{ fontSize: "1rem", fontWeight: 600 }}
                              >
                                {dwellData.length>0?dwellData[activeTab].totalBounced:""}
                              </Text>
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "1rem" }}>
                                5% <FaArrowDown size={15} />
                              </Text>
                            </Col>
                          </Flex>
                        </Col>
                      </Flex>
                      <Flex
                        align="center"
                        justify="space-between"
                        style={{
                          width: "100%",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        <Col style={{ width: "40%" }}>
                          <Text style={{ fontSize: "1rem", fontWeight: 600 }}>
                            Dwell Time (max)
                          </Text>
                        </Col>
                        <Col style={{ width: "60%" }}>
                          <Flex gap={"1rem"} justify="flex-end">
                            <Col>
                              <Text
                                style={{ fontSize: "1rem", fontWeight: 600 }}
                              >
                                3 min
                              </Text>
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "1rem" }}>
                                25% <FaArrowDown size={15} />
                              </Text>
                            </Col>
                          </Flex>
                        </Col>
                      </Flex>
                      <Flex
                        align="center"
                        justify="space-between"
                        style={{
                          width: "100%",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        <Col style={{ width: "40%" }}>
                          <Text style={{ fontSize: "1rem", fontWeight: 600 }}>
                            Customer Dwell Time (max)
                          </Text>
                        </Col>
                        <Col style={{ width: "60%" }}>
                          <Flex gap={"1rem"} justify="flex-end">
                            <Col>
                              <Text
                                style={{ fontSize: "1rem", fontWeight: 600 }}
                              >
                                {dwellData.length>0?dwellData[activeTab].maxDwellTime:""} s
                              </Text>
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "1rem" }}>
                                45% <FaArrowDown size={15} />
                              </Text>
                            </Col>
                          </Flex>
                        </Col>
                      </Flex>
                      <Flex
                        align="center"
                        justify="space-between"
                        style={{
                          width: "100%",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        <Col style={{ width: "40%" }}>
                          <Text style={{ fontSize: "1rem", fontWeight: 600 }}>
                            Customer Dwell Time (avg)
                          </Text>
                        </Col>
                        <Col style={{ width: "60%" }}>
                          <Flex gap={"1rem"} justify="flex-end">
                            <Col>
                              <Text
                                style={{ fontSize: "1rem", fontWeight: 600 }}
                              >
                                {dwellData.length>0?dwellData[activeTab].averageDwellTime:""} s
                              </Text>
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "1rem" }}>
                                5% <FaArrowDown size={15} />
                              </Text>
                            </Col>
                          </Flex>
                        </Col>
                      </Flex>
                    </Row>
                  </div>
                </div>
              </Card>

              {/* Drawer for small screens */}
              <Drawer
                title="Select Tab"
                placement="left" // Drawer opens from the left
                onClose={toggleDrawer}
                open={drawerVisible}
                width={200}
                style={{ padding: "0" }} // Remove padding for a tighter look
              >
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={`tab ${activeTab === index ? "active" : ""}`}
                    onClick={() => {
                      setActiveTab(index);
                      toggleDrawer(); // Close drawer on tab click
                    }}
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #e8e8e8",
                    }} // Add some styling
                  >
                    {tab}
                  </div>
                ))}
              </Drawer>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10}>
              <Card
                title={
                  <>
                    <Col>
                      <Title
                        type="secondary"
                        level={5}
                        style={{ textWrap: "wrap" }}
                      >
                        Employee Engagement Analysis
                      </Title>
                    </Col>
                  </>
                }
              >
                <div
                  style={{
                    height: window.innerWidth > 850 ? "22rem" : "auto",
                    width: "100%",
                  }}
                >
                  <Flex
                    vertical
                    justify="space-around"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Flex vertical gap={"2rem"}>
                      <Col>
                        <Text style={{ fontWeight: "600", fontSize: "1rem" }}>
                          Engaged Time:
                        </Text>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        style={{ width: "100%" }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Col
                            xs={24}
                            sm={24}
                            md={7}
                            lg={7}
                            style={{
                              padding: "1rem",
                              backgroundColor: "lightgray",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text>Max Time:20s</Text>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={7}
                            lg={7}
                            style={{
                              padding: "1rem",
                              backgroundColor: "lightgray",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text>Avg Time:20s</Text>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={7}
                            lg={7}
                            style={{
                              padding: "1rem",
                              backgroundColor: "lightgray",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text>Min Time:20s</Text>
                          </Col>
                        </Row>
                      </Col>
                    </Flex>
                    <Flex vertical gap={"2rem"}>
                      <Col>
                        <Text style={{ fontWeight: "600", fontSize: "1rem" }}>
                          Engaged Time:
                        </Text>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        style={{ width: "100%" }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Col
                            xs={24}
                            sm={24}
                            md={7}
                            lg={7}
                            style={{
                              padding: "1rem",
                              backgroundColor: "lightgray",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text>Max Time:20s</Text>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={7}
                            lg={7}
                            style={{
                              padding: "1rem",
                              backgroundColor: "lightgray",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text>Avg Time:20s</Text>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={7}
                            lg={7}
                            style={{
                              padding: "1rem",
                              backgroundColor: "lightgray",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text>Min Time:20s</Text>
                          </Col>
                        </Row>
                      </Col>
                    </Flex>
                  </Flex>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LiveStore;
