import React, { useState } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Input, Form, Checkbox, Button, Row, Col, Layout, Image, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import authService from "../Authentication/authService";
import jwtDecode from "jwt-decode";

const { Content } = Layout;

function Login_user() {
  document.title = "Intelense - Login";

  // changes the tab title in realtime
  window.addEventListener("blur", () => (document.title = "New Update *"));
  window.addEventListener("focus", () => (document.title = "Intelense - Login"));

  const navigate = useNavigate();
  const [Loadinglocal, setLoadinglocal] = useState(false);

  // submit the login details and returns to the page based on roles
  const onFinish = (event) => {
    setLoadinglocal(true);
    authService
      .login(event.Email, event.password, event.remember)
      .then(async (response) => {
        if (response.status === 200) {
          const decodedToken = jwtDecode(response.data.jwt_token);
          const PolicyData = decodedToken.policyDetails || [];
          const RoleFromRes = decodedToken.Roles;

          const RouteList = [
            { path: "Dashboard", route: "Dashboard" },
            { path: "Reports", route: "Reports" },
            { path: "Event Addition", route: "EventSetup" },
            { path: "Asset Onboarding", route: "Asset_onboarding" },
            { path: "Event Calendar", route: "Calendar" },
            { path: "Tech Support", route: "TechSupport" },
            { path: "OnBoarding Location", route: "StoreOnboarding" },
            { path: "Policy Configuration", route: "PolicyConfig" },
            { path: "UserMaster", route: "UserMaster" },
            { path: "ServerOnboarding", route: "ServerOnboarding" },
          ].filter((child) => PolicyData.includes(child.path));

          setLoadinglocal(false);

          // Navigate based on Role
          switch (RoleFromRes) {
            case "Admin":
              return navigate("/Admin/Dashboard");
            case "Security":
            case "Supervisor":
            case "user":
            case "Management":
            case "Manager":
              if (RouteList.length > 0) {
                return navigate(
                  `/${RoleFromRes.toLowerCase()}/${RouteList[0].route}`
                );
              } else {
                return navigate(`/${RoleFromRes.toLowerCase()}/Dashboard`);
              }
            default:
          }
        } else if (response.status === 400) {
          message.error(
            response.data?.msg || response.msg || "An error occurred"
          );
        } else {
          setLoadinglocal(false);
          handleResponseError(response);
        }
      })
      .catch((err) => {
        setLoadinglocal(false);
        handleResponseError(err);
      });
  };

  const handleResponseError = (error) => {
    const errorMessage =
      typeof error === "object" && error.message
        ? error.message
        : error.data || "Could not connect with server. Try after sometime";

    if (error.status === 0) {
      message.error("Server error");
    } else {
      message.error(errorMessage);
    }
  };

  return (
    <Layout
      style={{
        height: "100vh",
        width: "100%",
        overflowX: "hidden",
        backgroundImage: `url("/pexels-jplenio-1103970.jpg")`,  // Path to the image in the public folder
        backgroundSize: "cover", // Make sure the image covers the entire layout
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent repeating the image
      }}
    >
      <Content>
        <Row
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1em",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Add a background overlay to improve text visibility
              borderRadius: "10px", // Optional: Round the corners of the login box
              maxWidth: "100%", // Ensure no overflow
              margin: "auto", // Center the column
            }}
          >
            <Image
              preview={false}
              src="Intelense Logo Small.png"
              style={{
                marginBottom: "2em", // Reduced spacing for better responsiveness
                maxWidth: "150px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto", // Center image horizontally
              }}
            />

            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              validateTrigger={["onBlur"]}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Please Enter your Email!" },
                  { type: "email", warningOnly: true },
                  { type: "string", min: 6 },
                ]}
                style={{
                  marginLeft: "6em",
                  width: "100%",
                  maxWidth: "350px", // Limit input width
                }}
              >
                <Input
                  className="inputfield"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please Enter your password!" },
                  { type: "password", warningOnly: true },
                  { type: "string", min: 8 },
                ]}
                style={{
                  marginLeft: "6em",
                  width: "100%",
                  maxWidth: "350px", // Limit input width
                }}
              >
                <Input.Password
                  className="inputfield"
                  type="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
              >
                <Checkbox style={{ color: "#3e3a75", marginLeft: "7em", width: "100%", maxWidth: "350px", }}>
                  Remember me
                </Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  style={{
                    backgroundColor: "#3e3a75",
                    border: "none",
                    width: "254px",
                    marginLeft: "6em",
                    maxWidth: "350px"
                  }}
                  type="primary"
                  block
                  htmlType="submit"
                  loading={Loadinglocal}
                >
                  Submit
                </Button>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <Link
                  style={{
                    marginLeft: "6em",
                    width: "100%", 
                    maxWidth: "350px"
                  }}
                  to={"/Forgotpassword"}
                  className="login-form-forgot"
                  target="_blank"
                >
                  Forgot Password?
                </Link>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Login_user;
