import React from "react";
import { Input, Form, Button, Row, Col, Layout, Image, message } from "antd";
import axios from "axios";

const { Content } = Layout;
const URL = process.env.REACT_APP_API_KEY;

// To get email id to share the password reset link
function ForgotEmail() {
  document.title = "Intelense - Email";
  const [form] = Form.useForm();

// sending email id to backend
  const onFinish = (event) => {
    axios
      .get(URL + "/password/forgotpassword_linksend?mail=" + event.email)
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);
          form.resetFields();
        } else if (response.data.issuccess === false) {

          if (response.status === 0) {
            alert("Could not connect with server. Try after sometime");
            message.warning(
              "Could not connect with server. Try after sometime"
            );
          } else {
            message.warning(response.data.msg);
          }
        }
      })
      .catch((err) => {
        message.warning(err.response.data.msg);
      });
  };

  return (
    <Layout style={{ backgroundColor: "white", height: "100vh" }}>
      <Content>
        <Row style={{ height: "100vh" }}>
          <Col xs={0} sm={0} md={0} lg={0} xl={12} style={{ heigh: "100vh" }}>
            <Image
              preview={false}
              style={{ maxHeight: "100vh" }}
              className="image-property"
              src="pic1.jpg"
            ></Image>
          </Col>

          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-5em",
            }}
            className="gutter-row"
            xs={{ span: 18, offset: 3 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
          >
            <Form
              form={form}
              style={{ width: "100%" }}
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12, offset: 0 },
                md: { span: 12, offset: 0 },
                lg: { span: 8, offset: 0 },
                xl: { span: 8, offset: 0 },
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col className="gutter-row " span={4}>
                  <Image
                    preview={false}
                    className="gutter-row bottom-space"
                    src="Intelense Logo Small.png"
                  ></Image>
                </Col>
              </Row>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Email!",
                  },
                  { type: "email", warningOnly: true },
                  { type: "string", min: 6 },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 12, offset: 8 },
                  md: { span: 12, offset: 8 },
                  lg: { span: 8, offset: 8 },
                  xl: { span: 8, offset: 8 },
                }}
              >
                <Button
                  style={{ backgroundColor: "#3e3a75", border: "none" }}
                  type="primary"
                  block
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default ForgotEmail;
