import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {Calendar, Col, Row, Select, Typography, Badge, Card, Empty, Table, Flex} from "antd";
import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";
const { Title } = Typography;

const Event_calendar = () => {
  const [counts, setCounts] = useState([]);

  useEffect(() => {
    getCountBasedOnEachDayInMonth();
    getnotificationdetailsbasedondate();
  }, []);

  function getCountBasedOnEachDayInMonth() {
    axiosClient
      .get(`/notification/getCountBasedOnEachDayInMonth`)
      .then((response) => {
        if (response.data.issuccess === true) {
          setCounts(response.data.data);
        } else {
        }
      })
      .catch((err) => {
      });
  }

  const [event, setEvent] = useState([]);

  function getnotificationdetailsbasedondate(value) {
    axiosClient
      .get(`/notification/getnotificationdetailsbasedondate?date=${value}`)
      .then((response) => {
        if (response.status === 200) {
          setEvent(response.data.msg);
        } else {
        }
      })
      .catch((err) => {
      });
  }

  const getListData = (value) => {
    let listData;
    const dateString = value.format("YYYY-MM-DD");
    const countData = counts.find((item) => item.date === dateString);

    if (countData) {
      const count = countData.count;
      listData = [
        {
          type: count > 0 ? "success" : "warning",
          content: count > 0 ? `${count} events` : "No events",
        },
      ];
    }
    return listData || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    const isSpecialDate = listData.length > 0;
    const cellStyle = isSpecialDate ? { backgroundColor: "#a3d1f5", padding: "5px", borderRadius: "5px" }: {};

    return (
      <div style={cellStyle}>
        <div
          className="events"
          style={{ listStyleType: "none", margin: 0, padding: 0 }}
        >
          {listData.map((item, index) => (
            <p key={index}>
              <Badge
                xs={8}
                sm={5}
                md={24}
                lg={2}
                status={item.type}
                text={item.content}
              />
            </p>
          ))}
        </div>
      </div>
    );
  };

  const disabledDate = (current) => {
    const createdDate = authService.getCreatedDate();
    const today = dayjs();
    return createdDate
      ? current < dayjs(createdDate).startOf("day") ||
          current > today.endOf("day")
      : false;
  };

  const columns = [
    {
      title: (
        <span
          style={{ color: "#000816", fontSize: "1rem", textAlign: "center" }}
        >
          Event
        </span>
      ),
      dataIndex: "eventName",
      key: "event",
      align: "center",
    },
    {
      title: <span style={{ color: "#000816", fontSize: "1rem" }}>Time</span>,
      dataIndex: "Time",
      key: "time",
      align: "center",
    },
  ];

  return (
    <Row
      style={{
        display: "flex",
        // flexDirection: "row",
        justifyContent: "space-around",
        padding: "0px 20px",
        flexWrap: "wrap",
        gap: "1rem",
      }}
    >
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        {/* <Title level={2} style={{ textAlign: "center", fontSize: "1.5rem" }}>Calendar</Title> */}
        {/* <Card
          style={{
            marginTop: "1rem",
            padding: "1rem",
            height: "87vh",
            overflowY: "scroll",
          }}
        > */}
        {/* <div style={{ height: "100vh" }}> */}
        <Calendar
          onSelect={getnotificationdetailsbasedondate}
          dateCellRender={dateCellRender}
          disabledDate={disabledDate}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];
            let current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current = current.month(i);
              months.push(localeData.monthsShort(current));
            }
            for (let i = start; i < end; i++) {
              monthOptions.push(
                <Select.Option key={i} value={i} className="month-item">
                  {months[i]}
                </Select.Option>
              );
            }
            const year = value.year();
            const month = value.month();
            const options = [];
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>
              );
            }
            return (
              <div style={{}}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col>
                    <Title level={2}>Calender</Title>
                  </Col>
                  <Flex gap={5}>
                    <Select
                      size="large"
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      value={year}
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                    >
                      {options}
                    </Select>

                    <Select
                      size="large"
                      dropdownMatchSelectWidth={false}
                      value={month}
                      onChange={(newMonth) => {
                        const now = value.clone().month(newMonth);
                        onChange(now);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Flex>
                </Row>
              </div>
            );
          }}
        />
        {/* </div> */}
        {/* </Card> */}
      </Col>

      <Col xs={24} sm={24} md={10} lg={7} xl={7}>
        <Card
          // className="events-section"
          style={{
            width: "100%",
            color: "#666666",
            marginTop: "1rem",
            height: "80vh",
            overflowY: "scroll",
          }}
        >
          <Title
            level={3}
            style={{
              textAlign: "center",
              // borderBottom: "1px solid rgba(0,0,0,0.25)",
              color: "rgba(0,0,0,0.8)",
              fontSize: "1.25rem",
            }}
          >
            Events
          </Title>
          {event.length > 0 ? (
            <Table
              columns={columns}
              dataSource={event}
              pagination={{ showSizeChanger: false, pageSize: 10 }}
              rowKey={(record) => record.Time}
              size="small"
              scroll={{ x: true }}
              style={{ textAlign: "center" }}
            />
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Events"
                style={{
                  textAlign: "center",
                  margin: "20px 0",
                }}
              />
            </span>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Event_calendar;
