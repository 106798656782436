import React, { useEffect, useState } from "react";
import { Button, Row, Typography, Select, Table, Image, Col, Tag, Space, Empty, message,} from "antd";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { DatePicker, Modal } from "antd";
import moment from "moment";
import axiosClient from "../../Authentication/ApiCall";
import * as FileSaver from "file-saver";
const { RangePicker } = DatePicker;
const { Text } = Typography;

const FootfallReport = () => { 
  const [data, setData] = useState([]);
  // const [EventList, setEventList] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  // const [EventId, setEventId] = useState(null);
  const [cameraId, setCameraId] = useState(null);
  const [status, SetStatus] = useState(null);
  const [eventName, setEventName] = useState("");
  // const [flagEvent, setflagEvent] = useState(false);
  const [Model, setModel] = useState(false);
  const [AnprModal, setAnprModal] = useState(false);
  const [imagedata, setimagedata] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [previousData, setPreviousData] = useState([]);
  const [image, setImage] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [opencameramodal, setopencameramodal] = useState(false);
  const [cameradata, setcameradata] = useState([]);

  function disabledDate(current) {
    // Disable dates after today
    return current && current > moment().endOf("day");
  }

  function allReports(StartDate, EndDate, currentPage, pageSize) {
    axiosClient
      .get(
        `/report1/getreportforfootfallmall?startDate=${StartDate}&endDate=${EndDate}&page=${currentPage}&pageSize=${pageSize}`
      )
      .then((response) => {

        if (response.data.issuccess) {
          const responseData = response.data.data.map((item, index) => ({
            ...item,
            sno: index + 1 + (currentPage - 1) * pageSize,
            details: "-", // Additional fields if necessary
          }));
          if (StartDate && EndDate) {
            setPreviousData(responseData);
          }
          setData(responseData);

          // Calculate totalItems
          const totalItems = response.data.totalPages;
          setTotalCount(totalItems * 10);

          if (responseData.length > 0) {
            setEventName(responseData[0].eventName);
          }
        } else {
        }
      })
      .catch((err) => {
      });
  }

  const handleTableChange = (currentPage, pageSize) => {
    setcurrentPage(currentPage);
    setPageSize(pageSize);
    allReports(StartDate, EndDate, currentPage, pageSize);
    // allReports(currentPage, pageSize);
  };

  //getting  all reports
  // function allReports(StartDate, EndDate, EventId, cameraId) {
  //   axiosClient
  //     .get(
  //       "/report1/getreoptforfootfallmall?startDate=" +
  //         StartDate +
  //         "&endDate=" +
  //         EndDate
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         // if (EventId === null)
  //         // const responseData = response.data.data;

  //         if (EventId === null) {
  //           setflagEvent(true);
  //         } else {
  //           setflagEvent(false);
  //         }

  //         const responseData = response.data.data
  //           .sort((a, b) => new Date(b.createddate) - new Date(a.createddate))
  //           .map((item, index) => ({
  //             ...item,
  //             sno: index + 1,
  //             details: "-",
  //           }));
  //         setData(responseData);
  //         if (responseData.length > 0) {
  //           setEventName(responseData[0].eventName);
  //         }
  //       } else {
  //       }
  //     })

  //     .catch((err) => {
  //     });
  // }

  const columns = [
    {
      title: <span style={{ color: "#000816" }}>Sl No</span>,
      dataIndex: "serialNo",
      align: "center",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    // {
    //   title: <span style={{ color: "#000816" }}>camera Name</span>,

    //   dataIndex: "cameraName",
    //   key: Math.random(),
    //   align: "center",
    //   render: (text) => (
    //     <p
    //       justify="center"
    //       align="center"
    //       // style={{
    //       //   backgroundColor: "#564592",
    //       //   color: "white",
    //       //   borderRadius: "5px",
    //       // }}
    //     >
    //       <Text style={{ color: "black" }}>{text}</Text>
    //     </p>
    //   ),
    //   // filters: cameraFilters,
    //   filterSearch: true,
    //   onFilter: (value, record) => record.cameraName.indexOf(value) === 0,
    // },
    {
      title: <span style={{ color: "#000816" }}>Total Ingress</span>,

      dataIndex: "totalIngress",
      render: (text) => <Text>{text}</Text>,
      key: Math.random(),
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Total Egress</span>,

      dataIndex: "totalEgress",
      render: (text) => <Text>{text}</Text>,
      key: Math.random(),
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Average DwellTime</span>,
      // title: "Average DwellTime",
      dataIndex: "averageDwellTime",
      key: Math.random(),
      render: (text) => <Text>{text}</Text>,
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Total Male</span>,

      dataIndex: "totalMale",
      key: Math.random(),
      render: (text) => <Text>{text}</Text>,
      align: "center",
    },
    {
      title: <span style={{ color: "#000816" }}>Total Female</span>,

      dataIndex: "totalFemale",
      key: Math.random(),
      render: (text) => <Text>{text}</Text>,
      align: "center",
    },
    // {
    //   title: <span style={{ color: "#000816" }}>Location</span>,
    //   dataIndex: "location",
    //   key: Math.random(),
    //   align: "center",
    //   render: (text) => <Text>{text}</Text>,
    //   // filters: location.map((item) => item),
    //   filterSearch: true,
    //   onFilter: (value, record) => record.location.indexOf(value) === 0,
    // },

    {
      title: <span style={{ color: "#000816" }}>Created Date</span>,
      render: (text) => <Text>{text}</Text>,
      dataIndex: "date",
      align: "center",
      key: "initialDate",
    },
  ];

  const handleOk = () => {
    setModel(false);
    setImage("");
  };

  const handleCancel = () => {
    setModel(false);
    setImage("");
  };

  const onClickDownload = () => {
    axiosClient
      .get(
        "/report1/getpdfreportforfootfall?startDate=" +
          StartDate +
          "&endDate=" +
          EndDate +
          "&eventId=" +
          13 +
          "&cameraId=" +
          cameraId +
          "&download=" +
          true,
        { responseType: "blob" }
      )
      .then((response) => {
        if (response.status === 200) {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, "Footfallreport.pdf");
        }
      })
      .catch((err) => {
      });
  };

  const Clear = () => {
    //window.location.reload();
    setDateRange(null);
    setData(previousData);
    setcurrentPage(1);
    allReports("", "", currentPage, pageSize);
  };

  const onDateChange = (date) => {
    if (date) {
      const [startDate, endDate] = date;
      setStartDate(startDate.format("YYYY-MM-DD"));
      setEndDate(endDate.format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const onClose = () => {
    setAnprModal(false);
  };

  const opencamera = () => {
    setopencameramodal(true);
    fetchData(StartDate, EndDate);
  };

  const onClosecameramodal = () => {
    setopencameramodal(false);
  };

  const cameracolumns = [
    {
      title: (
        <span style={{ color: "#000816", fontSize: "17px" }}>camera Name</span>
      ),

      dataIndex: "cameraName",
      key: Math.random(),
      align: "center",
      render: (text) => (
        <p
          justify="center"
          align="center"
          // style={{
          //   backgroundColor: "#564592",
          //   color: "white",
          //   borderRadius: "5px",
          // }}
        >
          <Text style={{ color: "black" }}>{text}</Text>
        </p>
      ),
      // filters: cameraFilters,
      filterSearch: true,
      onFilter: (value, record) => record.cameraName.indexOf(value) === 0,
    },
    {
      title: (
        <span style={{ color: "#000816", fontSize: "17px" }}>Location</span>
      ),
      dataIndex: "location",
      key: Math.random(),
      align: "center",
      render: (text) => <Text>{text}</Text>,
      // filters: location.map((item) => item),
      filterSearch: true,
      onFilter: (value, record) => record.location.indexOf(value) === 0,
    },
  ];

  const fetchData = async (StartDate, EndDate) => {
    try {
      await axiosClient.get(
        "/report1/getcameralistforfootfallreport?date=" + EndDate
      ).then((response) => {
        if (response.data.issuccess === true) {
          setcameradata(response.data.data);
        } 
      })
      .catch((err) => {
        message.error(err.msg)
      });
    } catch (err) {
    }
  };

  useEffect(() => {
    // Reset to page 1 when StartDate or EndDate changes
    setcurrentPage(1);
    allReports(StartDate, EndDate, 1, pageSize);
  }, [StartDate, EndDate]);

  // useEffect(() => {
  //   allReports(StartDate, EndDate, currentPage, pageSize);
  // }, [currentPage, pageSize]);

  return (
    <div style={{ marginTop: "15px" }}>
      {/* <Row
        xs={24}
        md={12}
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 1rem",
        }}
      >
        <Text
          xs={24}
          md={12}
          style={{ color: "white", fontWeight: "700", fontSize: "2rem" }}
        >
          Report
        </Text>
        <Button
          xs={24}
          md={12}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
          onClick={onClickDownload}
        >
          Export to xlsx <FaCloudDownloadAlt size={22} />
        </Button>
      </Row> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Row
          style={{
            padding: "0rem 1rem",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "1%",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Col>
            <RangePicker
              //style={{width:260}}
              value={dateRange}
              onChange={(date) => {
                setDateRange(date);
                onDateChange(date);
              }}
              disabledDate={disabledDate}
            />
          </Col>
          {/* <Col>
            <Select
              style={{ color: "grey" }}
              placeholder="Select Camera"
              value={cameraId}
              onChange={(value) => {
                setCameraId(value);
              }}
            >
              {CameraList.map((item) => {
                return <Option value={item.cameraId}>{item.cameraName}</Option>;
              })}
            </Select>
          </Col> */}
          {/* <Col>
            <Select
              style={{ color: "grey" }}
              placeholder="Select Event"
              value={EventId}
              onChange={(value) => {
                setEventId(value);
              }}
            >
              {EventList.map((item) => {
                return <Option value={item.eventId}>{item.eventName}</Option>;
              })}
            </Select>
          </Col> */}
          <Col>
            <Button onClick={Clear}>Clear</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={opencamera} style={{ marginRight: "20px" }}>
              {" "}
              View Camera
            </Button>
          </Col>
          <Button
            xs={24}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginRight: "50px",
            }}
            onClick={onClickDownload}
          >
            Export to PDF <FaCloudDownloadAlt size={22} />
          </Button>
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          style={{ padding: "1rem 2rem", width: "100%" }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalCount,
            onChange: handleTableChange,
          }}
          rowKey="sno"
        />
      </div>
      {/* <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination({ current: page, pageSize })
        }
        style={{ display: "flex", justifyContent: "flex-end" }}
      /> */}
      <div>
        <Modal
          width={"50vw"}
          title="Basic Modal"
          open={Model}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {/* <Image
            src={"data:image/png;base64," + image}
            // src={`data:image/jpeg;base64,${image}`}
            alt="Image Not Found"
            preview={false}
            height={500}
          /> */}
          <img
            src={`data:image/png;base64,${image}`}
            alt="Image Not Found"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal>
        <Modal
          open={AnprModal}
          onCancel={onClose}
          footer={null}
          width={1000} // Adjust width as needed
        >
          <Row>
            <Col span={11}>
              {" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  marginBottom: "9px",
                  marginLeft: "30px",
                }}
              >
                <Tag
                  style={{
                    padding: "5px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  In Time Image
                </Tag>
              </span>
              {imagedata.inTimeImage ? (
                <Image
                  src={`data:image/jpeg;base64,${imagedata.inTimeImage}`}
                  alt="In Time"
                  style={{ height: "560px" }}
                  className="modal-image"
                />
              ) : (
                <div style={{ textAlign: "center", marginTop: "130px" }}>
                  <Empty />
                </div>
              )}
            </Col>
            <Col span={11} offset={1}>
              {" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  marginBottom: "9px",
                  marginLeft: "40px",
                }}
              >
                <Tag
                  style={{
                    padding: "5px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Out Time Image
                </Tag>
              </span>
              {imagedata.outTimeImage ? (
                <Image
                  src={`data:image/jpeg;base64,${imagedata.outTimeImage}`}
                  alt="Out Time"
                  style={{ height: "560px" }}
                  className="modal-image"
                />
              ) : (
                <div style={{ textAlign: "center", marginTop: "130px" }}>
                  {" "}
                  <Empty />
                </div>
              )}
            </Col>
          </Row>
        </Modal>
        <Modal
          open={opencameramodal}
          onCancel={onClosecameramodal}
          footer={null}
        >
          <Table
            columns={cameracolumns}
            dataSource={cameradata}
            pagination={{ pageSize: 5 }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default FootfallReport;
