import { Row, Col, Card, Form, Input, Select, InputNumber, Typography, Button, Upload, message, Alert} from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TechSupportList from "./TechSupportList";
import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";

const { Option } = Select;
const { Title } = Typography;

function CustomerSupport() {
  const [SupportForm] = Form.useForm();
  const [ImageUrl, setImageUrl] = useState();
  const [editorstate, seteditorstate] = useState(EditorState.createEmpty());
  const [refresh, setrefresh] = useState(1);
  const [contentState, setContentState] = useState(null);

  const onEditorStateChange = (editorState) => {
    seteditorstate(editorState);
  };

  const handleContentStateChange = (contentState) => {
    setContentState(draftToHtml(contentState));
  };

  const beforeUpload = (file) => {
    var isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    // return isJpgOrPng && isLt2M;
    return isLt2M;
  };

  const handleChange = (info) => {
    // Get this url from response in real world.
    setImageUrl(info.file.originFileObj);
  };

  const OnFinish = (values) => {
    if (contentState && contentState.length > 8) {
      var data = new FormData();
      data.append("file", ImageUrl);
      data.append("subject", values.subject);
      data.append("body", contentState);
      data.append("priority", values.priority);
      data.append("mobilenumber", values.contact);

      axiosClient
        .post(
          "/techSupprot/SendQueries",
          data,
          { headers: { "Content-Type": "multipart/form-data" } }
          // {
          //   subject: values.subject,
          //   body: text,
          //   priority: values.priority,
          //   mobilenumber: values.contact,
          //   file: ImageUrl,
          // }
        )
        .then((response) => {
          if (response.data.issuccess === true) {
            message.success(response.data.msg);
            setrefresh(refresh + 1);
          }
        })
        .catch((err) => {
          if (err.status === 0) {
            message.error("Server error");
          } else {
            message.error(err.msg);
          }
        });
      SupportForm.resetFields();
      seteditorstate();
    } else {
      message.error("Message empty");
    }
  };

  return (
    <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 20, offset: 0 }}
        lg={{ span: 18, offset: 0 }}
        xl={{ span: 23, offset: 0 }}
      >
        <Card hoverable title="Tech Support" style={{ height: "100%" }}>
          <Row gutter={[40, 20]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 22, offset: 0 }}
              lg={{ span: 10, offset: 0 }}
              xl={{ span: 10, offset: 0 }}
            >
              <Form
                form={SupportForm}
                onFinish={OnFinish}
                autoComplete="off"               
                wrapperCol={{ span: 4, offset: 0 }}
                labelCol={{ span: 3 }}
              >
                <Title level={5}>Subject</Title>
                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24, offset: 0 }}
                  // label={<Title level={5}>Subject</Title>}
                  name="subject"
                  rules={[
                    { required: true, message: "Please enter your subject!.." },
                  ]}
                >
                  <Input placeholder="Enter your Subject.." />
                </Form.Item>
                <Title level={5}>Priority</Title>
                <Form.Item
                  //   labelCol={{ offset: 1 }}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 14, offset: 0 }}
                  name="priority"
                  // label={<Title level={5}>Priority</Title>}
                  rules={[{ required: true, message: "Please select!.." }]}
                >
                  <Select placeholder="Select the priority" allowClear>
                    <Option value="High">High</Option>

                    <Option value="Medium">Medium</Option>
                    <Option value="Low">Low</Option>
                  </Select>
                </Form.Item>
                <Title level={5}>Mobile number</Title>
                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 14, offset: 0 }}
                  name="contact"
                  // label={<Title level={5}>Mobile number</Title>}
                  rules={[
                    // {
                    //   type: "enum",
                    //   required: true,
                    //   message: "Please enter your phone number!!",
                    //   min: 8,
                    //   max: 16,
                    // },
                    {
                      type: "number",
                      required: true,
                      message: "Please enter your phone number!..",
                    },
                    {
                      type: "integer",
                      message: "Invalid Mobile Number!..",
                      min: 60000000,
                      // max: 9999999999,
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Enter phone number.."
                  />
                </Form.Item>

                <Title level={5}>Message</Title>
                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24, offset: 0 }}
                  // label={<Title level={5}>Message</Title>}
                  name="msg"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Message!..",
                    },
                  ]}
                >
                  <Editor
                    editorState={editorstate}
                    onEditorStateChange={onEditorStateChange}
                    onContentStateChange={handleContentStateChange}
                    className="public-DraftEditor-content"
                    wrapperClassName="wrapper-class"
                    editorClassName="editorClassName"
                    toolbarClassName="toolbar-class"
                    // onEditorStateChange={}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        // "link",
                        // "embedded",
                        "emoji",
                        // "image",
                        "remove",
                        "history",
                      ],
                    }}
                    // toolbarCustomButtons={[<ImageUpload />]}

                    // onEditorStateChange={onEditorStateChange}
                    // style={{ height: "30vh" }}
                  />

                  {/* </Row> */}
                </Form.Item>
                <Form.Item
                  name="Image"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24, offset: 0 }}
                  rules={[
                    {
                      warningOnly: true,
                      // required: true,
                      message:
                        "choose the correct file - JPEG,PNG,PDF,docx are allowed!..",
                    },
                  ]}
                >
                  <Upload
                    accept=".jpeg,.png,.pdf,.docx"
                    name="img"
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture"
                    className="upload-list-inline"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    // onChange={(e) => setfileupload(e.target.files[0])}
                    maxCount={1}
                  >
                    <Button
                      style={{ minWidth: "100%" }}
                      icon={<UploadOutlined />}
                    >
                      Upload(Max:1)
                    </Button>
                  </Upload>
                  <div style={{ marginTop: "2em" }}>
                    <Alert
                      showIcon
                      type="info"
                      message="JPEG, PNG, PDF, docx are allowed"
                    ></Alert>
                  </div>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    xs: { span: 12, offset: 0 },
                    sm: { span: 10, offset: 7 },
                    md: { span: 10, offset: 7 },
                    lg: { span: 10, offset: 7 },
                    xl: { span: 10, offset: 7 },
                  }}
                >
                  {!authService.getCurrentUserReadOnly() ? (
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        backgroundColor: "#3e3a75",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      disabled
                      style={{
                        border: "none",
                        width: "100%",
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 22, offset: 0 }}
              lg={{ span: 10, offset: 0 }}
              xl={{ span: 14, offset: 0 }}
            >
              <TechSupportList props={refresh} />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default CustomerSupport;
