import { Card,Col,Row,Form,Typography ,Input, Button, Space} from "antd"

const {Title,Text}=Typography

const Subscription = () => {
    
return (
   <div style={{width:"100vw",display:"flex",alignItems:"center",justifyContent:"center"}}>
   <Col
    xs={24}
    sm={24}
    lg={9}
    xl={9}
    style={{marginTop:"5rem"}}
   >
   <Card
    title={
        <>
        <Title level={5}  type="secondary">
            Monthly Subscription
        </Title>
        </>
    }
    >
     <Col>
     <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
    >
      <Row justify="center">
        <Col span={12}>
          <Form.Item
            label="License Key"
            name="licenseKey"
            rules={[{ required: true, message: 'Please input your license key!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={12}>
          <Form.Item
            label="Secret Key"
            name="secretKey"
            rules={[{ required: true, message: 'Please input your secret key!' }]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center">
        <Col>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
   <Space align="center" direction="vertical" style={{ marginTop: '2rem',display:"flex", justifyContent: 'center', textAlign: 'center' }}>
   <Text style={{color:"red"}} >
    *Note: The license key expires every month. Kindly request a new key.
    </Text>
    <Text style={{color:"red"}}>
    License expires in 8 days.
    </Text>
    <Row style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"2rem"}}>
    <Button type="primary" htmlType="button">
              Request key
            </Button>
    </Row>
   </Space>
  
     </Col>
    </Card>
   </Col>
   </div>
  )
}

export default Subscription