import {Button, Col, DatePicker, Input, InputNumber, Radio, Row, Select, Switch, Tooltip, Form, Typography, Modal, message, Table, Tag} from "antd";
import React, { useState, useEffect } from "react";
import axiosClient from "../../Authentication/ApiCall";
import authService from "../../Authentication/authService";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment/moment";
import {UserOutlined, LockOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const { Option } = Select;
const { Text } = Typography;

function AddUser() {
  const [RegForm] = Form.useForm();
  const [editRegForm] = Form.useForm();
  const [RolesList, setRolesList] = useState([]);
  const [data, setdata] = useState([]);
  const [policynameList, setpolicynameList] = useState([]);
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [AddRefresh, setAddRefresh] = useState(1);
  const [editmodal, seteditmodal] = useState(false);
  const [isreadonly, setisreadonly] = useState(false);
  const [userId, setuserId] = useState();
  const [defaultmobileaccess, setdefaultmobileaccess] = useState();
  const [defaultblock, setdefaultblock] = useState();
  const [Pageload, setPageload] = useState(false);
  const [mobileAccess, setMobileAccess] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [buttonStates, setButtonStates] = useState({});
  const pageSize = 10;

  const columns = [
    {
      title: "s.no",
      key: "id",
      render: (item, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Name",
      dataIndex: "username",
      // width: "15%",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      editable: true,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      width: "15%",
      editable: true,
      align: " center",

      // onFilter: (value, record) => record.roles.indexOf(value) === 0,
      render: (roles, record) => {
        let color = roles.length > 5 ? "geekblue" : "blue";
        if (roles === "Security" || roles === "security") {
          color = "green";
        }
        if (roles === "Manager" || roles === "manager") {
          color = "#5e548e";
        }

        return (
          <>
            <Tag color={color} key={roles}>
              {roles.toUpperCase()}
            </Tag>
            {record.isreadonly ? <Tag>Read only</Tag> : <></>}
          </>
        );
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      // width: "25%",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      editable: false,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      // width: "20%",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      editable: false,
    },
    {
      title: "Policy Name",
      dataIndex: "policyName",
      // width: "15%",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      editable: true,
    },
    // {
    //   title: "On Board Location",
    //   dataIndex: "onBoardLocation",
    //   render: (text) => {
    //     const locations = Array.isArray(text) ? text : [text];
    //     return (
    //       <span style={{ color: "black" }}>
    //         {locations.length > 1
    //           ? locations.map((loc, index) => <div key={index}>{loc}</div>)
    //           : locations[0]}
    //       </span>
    //     );
    //   },
    //   editable: true,
    // },
    {
      title: "Mobile Access",
      dataIndex: "mobile_access",
      // width: "25%",
      align: "center",
      editable: true,
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "NO",
          value: false,
        },
      ],
      onFilter: (value, record) => record.mobile_access === value,

      render: (_, { mobile_access }) => {
        let color = "blue";
        if (mobile_access === true) {
          color = "green";
          mobile_access = "YES";
        } else {
          color = "red";
          mobile_access = "NO";
        }

        return (
          <Tag color={color} key={mobile_access}>
            {mobile_access}
          </Tag>
        );
      },
    },
    {
      title: "Account Status",
      dataIndex: "ismail_verified",
      align: "center",
      // width: "15%",
      editable: true,

      render: (_, { ismail_verified }) => {
        let color = "geekblue";
        if (ismail_verified === true) {
          ismail_verified = "Active";
          color = "green";
        } else {
          ismail_verified = "InActive";
          color = "red";
        }

        return (
          <Tag color={color} key={ismail_verified}>
            {ismail_verified}
          </Tag>
        );
      },
    },
    {
      title: "Blocked",
      dataIndex: "islock",
      align: "center",
      // width: "15%",
      editable: true,

      filters: [
        {
          text: "Blocked",
          value: true,
        },
        {
          text: "Unblock",
          value: false,
        },
      ],

      onFilter: (value, record) => record.islock === value,

      render: (_, { islock }) => {
        let color = "geekblue";
        if (islock === true) {
          islock = "BLOCKED";
          color = "red";
        }

        return (
          <Tag color={color} key={islock}>
            {islock}
          </Tag>
        );
      },
    },
    {
      title: "Edit / Delete",
      // align: "center",
      // width: "5%",
      render: (record) => {
        return (
          <Row gutter={[10, 5]}>
            <Col span={3}>
              {authService.getCurrentUserReadOnly() ? (
                <Col offset={5}>
                  <Tooltip title="you need access to edit">
                    <EditOutlined disabled />
                  </Tooltip>
                </Col>
              ) : (
                <Col offset={5}>
                  <EditOutlined
                    onClick={(e) => {
                      onEditRecord(record);
                    }}
                  />
                </Col>
              )}
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need access to delete">
                    <DeleteOutlined disabled />
                  </Tooltip>
                ) : (
                  <DeleteOutlined
                    style={{ color: "red" }}
                    onClick={(e) => {
                      onDeleteRecord(record);
                      // DeleteReason(record);
                    }}
                  />
                )}
              </Col>
            </Col>
          </Row>
        );
      },
    },
    // {
    //   title: "Active",
    //   dataIndex: "active",
    //   // width: "25%",
    //   align: "center",
    //   editable: true,
    //   render: (text, record) => (
    //     <Button
    //       type={text === true ? "danger" : "primary"}
    //       onClick={() => handleButtonClick(record)}
    //     >
    //       {text === true ? "Deactivate" : "Activate"}
    //     </Button>
    //   ),
    // },
    // {
    //   title: "Active",
    //   dataIndex: "active",
    //   align: "center",
    //   editable: true,
    //   render: (text, record) => {
    //     return (
    //       <Button
    //         type={text === true ? "danger" : "primary"}
    //         onClick={() => handleButtonClick(record)}
    //         disabled={isDisabled}
    //       >
    //         {text === true ? "Deactivate" : "Activate"}
    //       </Button>
    //     );
    //   },
    // },
    {
      title: "Active/DeActive",
      dataIndex: "active",
      align: "center",
      editable: true,
      render: (text, record) => {
        const isActive =
          buttonStates[record.id] !== undefined
            ? buttonStates[record.id]
            : record.ismail_verified;

        return (
          <Button
            style={{
              backgroundColor: isActive ? "#e88085" : "green",
              color: "white",
            }}
            onClick={() => handleButtonClick(record)}
          >
            {isActive ? "Deactivate" : "Activate"}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    const initialStates = {};
    data.forEach((record) => {
      initialStates[record.id] = record.ismail_verified;
    });
    setButtonStates(initialStates);
  }, [data]);

   //get userroles
   useEffect(() => {
    axiosClient
      .get("/registration/getRoles")
      .then((response) => {
        if (response.data.issuccess === true) {
          setRolesList(response.data.data);
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  //get policynames
  useEffect(() => {
    axiosClient
      .get("/registration/getpolicyName")
      .then((response) => {
        if (response.data.issuccess === true) {
          setpolicynameList(response.data.data);
        } else {
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000);

    return () => clearInterval(interval);
  }, [AddRefresh]);

  useEffect(() => {
    axiosClient
      .get("/storeonboard/getstoreonboarddetails")
      .then((response) => {
        if (response && response.data && response.data.data) {
          const flattenedData = response.data.data.flat();
          const locationValues = flattenedData.map(
            (store) => store.onBoardLocation
          );
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  // Function to trigger the API and update the button state
  const handleButtonClick = (record) => {
    const isCurrentlyActive = buttonStates[record.id];
    const newAction = isCurrentlyActive ? "Deactivate" : "Activate";
    const statusToSend = !isCurrentlyActive;

    Modal.confirm({
      title: `Confirm ${newAction}?`,
      okText: "Confirm",
      okType: isCurrentlyActive ? "danger" : "primary",
      onOk: () => {
        // Send both the ID and active status as query parameters in the URL
        axiosClient
          .put(
            `/registration/activation?id=${record.id}&activation=${statusToSend}`
          )
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);

              // Update the state for only the clicked button
              setButtonStates((prevStates) => ({
                ...prevStates,
                [record.id]: statusToSend, // Update the active state for this specific record
              }));

              // Optionally refetch data or update other parts of the UI
              fetchData();
            } else {
              message.error("Something went wrong");
            }
          })
          .catch((err) => {
            message.error(
              err.response ? err.response.data.msg : "Server error"
            );
          });
      },
    });
  };

   //on cancel button to reset fields
  const handlecreateeventclose = () => {
    setModal(false);
    seteditmodal(false);
    RegForm.resetFields();
    editRegForm.resetFields();
  };

  //cancel for edit form
  const handlecreateeventclose1 = () => {
    seteditmodal(false);
  };

   //pagination for table
  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: data.length,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page) => setCurrentPage(page),
  };


  //final sumittion for adding user
  const onFinish = (values) => {
    const { mobile_access = false } = values;
    // const { mobile_access } = values;
    // `mobile_access` will be either true or false based on switch state

    setPageload(true);
    axiosClient
      .post("/registration/registerUsers", {
        name: values.username,
        email: values.email,
        password: values.password,
        mobile_number: values.phone,
        roles: values.role,
        policyName: values.policyName,
        gender: values.gender,
        // onBoardLocation: values.location,
        // camera_list: values.cameralist,
        VerificationCode: values.vid,
        mobile_access: values.mobile_access,
        //  === undefined
        //   ? defaultmobileaccess
        //   : values.mobile_access,
        Dob: values.DOB,
        isreadonly: values.readonly,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          message.success(response.data.msg);
          setPageload(false);
          setAddRefresh(AddRefresh + 1);
          setModal(false);
          // setcount(count + 1);
          RegForm.resetFields();
        }
      })
      .catch((err) => {
        setModal(false);
        RegForm.resetFields();
        setPageload(false);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  };

  //table data in usermaster
  const fetchData = () => {
    axiosClient
      .get("/registration/userDetails")
      .then((response) => {
        if (response.data.issuccess === true) {
          setdata(response.data.data);
        } else {
        }
      })
      .catch((err) => {
        if (!err.response) {
          message.error("Server error");
        } else {
          message.error(err.msg || "An error occurred");
        }
      });
  };
  

  const onEditRecord = (record) => {
    seteditmodal(true);
    setuserId(record.id);
    setIsBlock(record.islock);
    setdefaultmobileaccess(record.mobile_access);
    setdefaultblock(record.islock);
    setisreadonly(record.isreadonly);
    editRegForm.setFieldsValue({
      name: record.username,
      role: record.roles,
      mobile_access: record.mobile_access, // Ensure form is set correctly
      phone: record.mobile_number,
      policyName: record.policyName,
      readonly: record.isreadonly,
    });
  };

  //onfinish for edit
  const OnFinishedit = (values) => {
    setPageload(false);
    axiosClient
      .put("/registration/editUser", {
        id: userId,
        policyName: values.policyName,
        username: values.name,
        // onBoardLocation: values.onBoardLocation,
        islock: values.block === undefined ? defaultblock : values.block,
        locked_reason:
          values.blocked_Reason === undefined ? null : values.blocked_Reason,
        mobile_number: values.phone,
        roles: values.role,
        mobile_accessReason:
          values.mobile_reason === undefined ? null : values.mobile_reason,
        // camera_list: values.cameralist,
        mobile_access:
          values.mobile_access === undefined
            ? defaultmobileaccess
            : values.mobile_access,

        isreadonly: isreadonly,
      })
      .then((response) => {
        if (response.data.issuccess === true) {
          setPageload(false);
          seteditmodal(false);
          setAddRefresh(AddRefresh + 1);
          message.success(response.data.msg);
        }
      })
      .catch((err) => {
        setPageload(false);
        seteditmodal(false);
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
    // form.resetFields();
  };

  //delete record
  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Confirm Delete?",

      okText: "Confirm",
      okType: "danger",
      onOk: () => {
        axiosClient
          .put("/registration/deleteUser?id=" + record.id)
          // , {
          //   id: record.id,
          //   // delete_reason:record
          // })
          .then((response) => {
            if (response.data.issuccess === true) {
              message.success(response.data.msg);
              setAddRefresh(AddRefresh + 1);
            } else {
            }
          })
          .catch((err) => {
            if (err.status === 0) {
              message.error("Server error");
            } else {
              message.error(err.msg);
            }
          });
      },
    });
    // form.resetFields();
  };

  const onclickmodal = () => {
    setModal(true);
  };

 
  const oncancelcard = () => {
    setModal(false);
    RegForm.resetFields();
    editRegForm.resetFields();
  };

  //update readonly field
  const handleReadonlyChange = (checked) => {
    setReadonly(checked); // Update local state
    RegForm.setFieldsValue({ readonly: checked }); // Sync with form
    editRegForm.setFieldValue({ readonly: checked });
  };

   //update mobile_access field
  const handleMobileAccessChange = (checked) => {
    setMobileAccess(checked); // Update local state
    RegForm.setFieldsValue({ mobile_access: checked }); // Sync with form
  };
  
  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      {" "}
      <div
        className="report-content"
        style={{
          // height: "120px",
          backgroundColor: "#000816",
          width: "100%",
          // marginLeft: "9px",
          margin: 0,
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "3%",
            padding: "10px",
            // marginTop: "10px",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "21px",
              fontFamily: "revert-layer",
              marginTop: "10px",
            }}
          >
            Register User
          </p>
          <div style={{ display: "flex", marginRight: "3%" }}>
            <Button className="adduser" onClick={onclickmodal}>
              + New
            </Button>
          </div>
        </div>
      </div>
      <Row gutter={[4, 4]} style={{ maxHeight: "90%" }}>
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          style={{ marginLeft: "1%" }}
        >
          <Table
            // rowSelection={{
            //   ...rowSelection,
            // }}
            columns={columns}
            dataSource={data}
            pagination={paginationConfig}
            // scroll={{ x: "100%" }}
          />
        </Col>
      </Row>
      {/* //Adding User */}
      <Modal
        className="submituserform"
        destroyOnClose
        centered
        open={modal}
        onCancel={() => oncancelcard()}
        title={
          <>
            <Row
              align={"middle"}
              justify={"center"}
              style={{ marginBottom: "30px" }}
            >
              <Col>
                <Text className="Modal-Header">Register User</Text>
              </Col>
            </Row>
          </>
        }
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submitAdduserForm"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={() => handlecreateeventclose()}>Cancel</Button>
              </Col>
            </Row>
          </>
        }
      >
        <Form
          // style={{ height: "340px" }}
          id="submitAdduserForm"
          form={RegForm}
          name="basic"
          onFinish={onFinish}
          autoComplete={"off"}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 7, offset: 0 },
            md: { span: 7, offset: 0 },
            lg: { span: 8, offset: 0 },
            xl: { span: 9, offset: 0 },
          }}
          wrapperCol={{
            xs: { span: 21 },
            sm: { span: 11, offset: 0 },
            md: { span: 11, offset: 0 },
            lg: { span: 8, offset: 0 },
            xl: { span: 14, offset: 0 },
          }}
          style={{ padding: "27px" }}
        >
          <Row gutter={16}>
            {" "}
            {/* Add gutter here */}
            <Col xs={24} sm={24} md={24} lg={24} xl={13} xxl={13}>
              {/* User Name, Email, Verification ID, Date of Birth, Policy Name, Password */}
              <Form.Item
                label="User Name"
                name="username"
                rules={[
                  { required: true, message: "Please enter your User Name!" },
                ]}
              >
                <Input
                  placeholder="User Name"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your Email!" },
                  { type: "email", warningOnly: true },
                  { type: "string", min: 6 },
                ]}
              >
                <Input
                  placeholder="Email"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item label="Verification ID" name="vid">
                <Input placeholder="Verification ID" />
              </Form.Item>
              <Form.Item label="Date of Birth" name="DOB">
                <DatePicker
                  format={"YYYY-MM-DD"}
                  disabledDate={(current) => {
                    // Calculate the date 18 years ago from today
                    const eighteenYearsAgo = moment()
                      .subtract(18, "years")
                      .endOf("day");

                    return current && current > eighteenYearsAgo;
                  }}
                  defaultPickerValue={dayjs().subtract(18, "years")}
                  // Ensure no value is selected initially, but the picker opens at 18 years ago
                  value={null}
                />
              </Form.Item>

              <Form.Item
                label="Contact"
                name="phone"
                rules={[
                  {
                    type: "number",
                    required: true,
                    message: "Please enter your Phone Number!",
                  },
                  {
                    type: "integer",
                    message: "Invalid Mobile Number",
                    min: 6000000000,
                    max: 9999999999,
                  },
                ]}
              >
                <InputNumber
                  placeholder="Phone Number"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please enter Password!" }]}
              >
                <Input.Password
                  placeholder="Password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item label="Gender" name="gender">
                <Radio.Group>
                  <Radio.Button value="male">Male</Radio.Button>
                  <Radio.Button value="female">Female</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={11}>
              {/* Gender, Contact, Role, Location, Read only user, Mobile Access */}
              <Form.Item
                labelCol={{
                  span: 9,
                }}
                wrapperCol={{
                  span: 12,
                }}
                label="Policy Name"
                name="policyName"
                rules={[
                  { required: true, message: "Please enter your Policy Name!" },
                ]}
              >
                <Select placeholder="select Policy">
                  {policynameList.map((option, index) => (
                    <Option key={index} value={option.policyName}>
                      {option.policyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{
                  span: 9,
                }}
                wrapperCol={{
                  span: 12,
                }}
                label="Role"
                name="role"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please select any Role!",
                  },
                ]}
              >
                <Select placeholder="select Role ">
                  {RolesList.map((option, index) => (
                    <Option key={index} value={option.userRole}>
                      {option.userRole}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                // className="top-space"
                // label="Read only user"
                name="readonly"
                initialValue={false}
                rules={[
                  {
                    type: "boolean",
                    required: false,
                    message: "Please select!",
                  },
                ]}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <Switch
                    checked={readonly} // Bind to state
                    onChange={handleReadonlyChange} // Handle change
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                  />
                  <Text>Read only user</Text>
                </div>
              </Form.Item>

              <Form.Item
                // className="top-space"
                // label="Mobile Access"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                name="mobile_access"
                valuePropName="checked"
                initialValue={false}
                rules={[
                  {
                    type: "boolean",
                    required: false,
                    message: "Please select!",
                  },
                ]}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <Switch
                    checked={mobileAccess} // Bind to state
                    onChange={handleMobileAccessChange} // Handle change
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                  />
                  <Text>Mobile Access</Text>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* //Edit User */}
      <Modal
        centered
        open={editmodal}
        onCancel={() => seteditmodal(false)}
        title={
          <>
            <Row align={"middle"} justify={"center"}>
              <Col>
                <Text className="Modal-Header">Edit User Details</Text>
              </Col>
            </Row>
          </>
        }
        footer={
          <>
            <Row justify={"space-around"}>
              <Col>
                {authService.getCurrentUserReadOnly() ? (
                  <Tooltip title="you need permission to submit">
                    <Button type="primary" block disabled>
                      Submit
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary-button"
                    form="submitedituserForm"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </Col>
              <Col>
                <Button onClick={() => handlecreateeventclose1()}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {" "}
        <Form
          form={editRegForm}
          id="submitedituserForm"
          onFinish={OnFinishedit}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 22,
          }}
          style={{ padding: "20px" }}
        >
          <Form.Item
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input placeholder="Name..." />
          </Form.Item>

          <Form.Item
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            className="top-space"
            label="Role"
            name="role"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please select!",
              },
            ]}
          >
            <Select
              // showSearch
              placeholder="Set Role to the user"
              // optionFilterProp="children"
              // onChange={onChange}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().includes(input.toLowerCase())
              // }
            >
              {RolesList.map((option, index) => {
                return (
                  <Option key={index} value={option.userRole}>
                    {option.userRole}
                  </Option>
                );
              })}
              {/* <Option value="security">Security</Option>
              <Option value="supervisor">Supervisor</Option>
              <Option value="manager">Manager</Option>
              <Option value="admin">Admin</Option> */}
            </Select>
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="Read only user"
            name="readonly"
            valuePropName="checked" // This will automatically bind the Switch's checked state to the form value
            initialValue={false}
            rules={[
              {
                type: "boolean",
                required: false,
                message: "Please select!",
              },
            ]}
          >
            <Switch
              onChange={(checked) => {
                setisreadonly(checked); // Update state on change if needed
                editRegForm.setFieldsValue({ readonly: checked }); // Ensure form value updates correctly
              }}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </Form.Item>

          <Form.Item
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            name="phone"
            label="Phone Number"
            rules={[
              {
                // type: "number",
                required: "true",
                message: "Please input your phone number!",
              },
              // {
              //   type: "integer",
              //   message: "10",
              //   min: 6000000000,
              //   max: 9999999999,
              // },
            ]}
          >
            <InputNumber
              // addonBefore={prefixSelector}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="Policy Name"
            name="policyName"
            rules={[
              {
                required: true,
                message: "Please enter your Policy Name!",
              },
            ]}
          >
            <Select>
              {" "}
              {policynameList.map((option, index) => {
                return (
                  <Option key={index} value={option.policyName}>
                    {option.policyName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 10 }}
            label="Mobile Access"
            name="mobile_access"
            valuePropName="checked"
            initialValue={false}
            rules={[
              {
                type: "boolean",
                required: false,
                message: "Please select!",
              },
            ]}
          >
            <Switch
              checked={mobileAccess}
              onChange={handleMobileAccessChange}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </Form.Item>

          <Form.Item
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 10,
            }}
            label="Block"
            name="block"
            rules={[
              {
                type: "boolean",
                required: false,
                message: "Please select!",
              },
            ]}
          >
            <Switch
              size="default"
              checked={isBlock}
              checkedChildren="yes"
              unCheckedChildren="no"
              onChange={(checked) => {
                setIsBlock(checked);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="LoadinModal"
        open={Pageload}
        style={{ background: "none", textAlign: "center" }}
        footer={false}
        closable={false}
        centered
      >
        <ClipLoader
          color="#23234d"
          loading={Pageload}
          cssOverride={{
            display: "block",
            margin: "0 auto",

            borderWidth: "5px",
            marginBottom: "10px",
          }}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
          title="Loading..."
        />
        <Text style={{ color: "white", fontSize: "1.2em" }}>Loading ...</Text>
      </Modal>
    </div>
  );
}

export default AddUser;
