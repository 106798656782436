import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Select, Typography, message, Table, Tag, Divider, Image, Skeleton, Empty, Flex, TimePicker} from "antd";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import moment from "moment/moment";
import Chart from "react-apexcharts";
import axiosClient from "../../../Authentication/ApiCall";
import _, { map} from "lodash";
import ReactApexChart from "react-apexcharts";
import { Carousel } from "react-responsive-carousel";
import { GrDirections } from "react-icons/gr";
import "../../../../font/Helvetica-Bold.ttf";
import "../../../../font/Helvetica.ttf";
import { PDFExport } from "@progress/kendo-react-pdf";

const { RangePicker } = DatePicker;
const { Option } = Select;

const { Title, Text } = Typography;

let RenderComp = {
  IngressEgress: false,
  DwellFootfall: false,
  EngagedBounced: false,
  AgeGender: false,
  PeakHours: false,
};

let FootfallDirectionHourlyDetails = {
  camlist: [],
  selectedcam: "",
  selectedcamName: "",
  selectedDate: dayjs().format("YYYY-MM-DD"),
  DataOfDate: "",
  DirectionImage: null,
  DirectionData: [],
  loading: false,
  Hour: "", // moment().hours(),
};

const initialOptions = {
  chart: {
    id: "basic-bar",
    type: "bar",
    stacked: true,
    toolbar: { show: false },
    contextmenu: { enabled: false },
    animations: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: { horizontal: false },
  },
  xaxis: {
    categories: [],
    title: { text: "Hour ", offsetY: -10 },
  },
  yaxis: { title: { text: "Seconds" } },
  legend: {
    position: "top",
    horizontalAlign: "center",
    offsetX: 0,
  },
  colors: [
    "#00e396",
    "#feb019",
    "#ff4560",
    "#775dd0",
    "#008ffb",
    "#00d9e9",
    "#ff9f00",
  ],
  tooltip: {
    enabled: true,
    y: {
      formatter: function (val) {
        return val + " sec";
      },
    },
  },
};
const Weekly = () => {
  const [RenderCompController, setRenderCompController] = useState(RenderComp);
  const targetRef = useRef();
  const containerRef = useRef(null);
  // const [DownloadDashboard, setDownloadDashboard] = useState(false);

  const [chartFootfallAnalysis, setChartFootfallAnalysis] = useState({
    series: [],
    options: {
      chart: {
        height: 400,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.2,
      },
      markers: {
        size: 4,
      },
      yaxis: {
        stepSize: 200,
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
      tooltip: {
        y: {
          formatter: (val) => val,
        },
      },
    },
  });
  const [DwellHourly, setDwellHourly] = useState({
    options: { ...initialOptions },
    series: [],
    cameraList: [],
    selectedCam: null,
  });

  const [DwellHourlyDateWise, setDwellHourlyDateWise] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Date", offsetY: -10 },
      },
      yaxis: { title: { text: "Seconds" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      colors: [
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
        "#008ffb",
        "#00d9e9",
        "#ff9f00",
      ],
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val + " sec";
          },
        },
      },
     },
    series: [],
    cameraList: [],
    selectedCam: null,
  });

  const [UniqueGraph, setUniqueGraph] = useState({
    options: {
      chart: {
        id: "basic-bar",
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        contextmenu: { enabled: false },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: { horizontal: false },
      },
      xaxis: {
        categories: [],
        title: { text: "Date", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: 0,
      },
      
      // colors: [
      //   "#feb019",
      //   "#ff4560",
      //   "#775dd0",
      //   "#008ffb",
      //   "#00d9e9",
      //   "#ff9f00",
      // ],
      // tooltip: {
      //   enabled: true,
      //   y: {
      //     formatter: function (val) {
      //       return val + " sec";
      //     },
      //   },
      // },
    },
    series: [],
  });

  const [Engagersweeklychart, setEngagersweeklychart] = useState({
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        title: { text: "Date ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } ,
      // labels: {
      //   formatter: function (val) {
      //     return val.toFixed() // Formats Y-axis labels to two decimal places
      //   },
      // },
      
    },
      title: {
        text: "Engaged",
        align: "center",
        margin: 10,
        style: {
          fontSize: '16px',
          fontFamily:"Helvetica"
        }
      },
    },
    series:[]
  });
 
  const [Bouncedweeklychart, setBouncedweeklychart] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: [],
        title: { text: "Date ", offsetY: -10 },
      },
      yaxis: { title: { text: "Count" } },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },

      colors: ["#00e396"],
      title: {
        text: "Bounced",
        align: "center",
        margin: 10,
        style: {
          fontSize: '16px',
          fontFamily:"Helvetica"
        }
      },
      
    },
    series: [
      {
        name: "Bounced",
        data: [],
        // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
  });

  const [chartDatapieGender, setChartDatapieGender] = useState({
    options: {
      labels: ["Male", "Female"],
      tooltip: {
        enabled: true, // Enable tooltip
        formatter: function (val) {
          return `${val}%`; // Customize the tooltip to display percentage
        },
      },
      
    },

    series: [],
    maleCount:0,
    femaleCount:0
  });
  const [chartDatapieAge, setChartDatapieAge] = useState({
    options: {
      labels: [],
      colors: [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#255aee",
        "#4eed9e",
        "#078f4b",
        "#6088d6",
        "#FFC107",
      ],
    },
    series: [],
  });

  const [pickhoursweeklyData, setpickhoursweeklydata] = useState({
    options: {
      chart: {
        height: 350,
        type: "area",
        color: "#64e38a",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // curve: "straight",
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },

      yaxis: {
        min: 0,
        max: 24,
        tickAmount: 12,
        title: { text: "Hour" },
        labels: {
          formatter: function (value) {
            return Math.round(value); // Convert the y-axis values to decimal format
          },
        },
      },
    },
    series: [],
  });
  const [graphLoading,setGraphLoading]=useState(false)

  const [
    FootfallDirectionHourlyController,
    setFootfallDirectionHourlyController,
  ] = useState(FootfallDirectionHourlyDetails);

  const [ingressloading, setingressloading] = useState(true);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        contextmenu: {
          enabled: false,
        },
        zoom: {
          enabled: false, // Ensure zoom is disabled
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: "category", // Change type to category for categorical data
        categories: [], // Initialize categories as empty array
        title: {
          offsetY: -10,
        },
      },
      yaxis: {
        type: "count",
        title: {
          text: "count",
          offsetY: -10,
        },
        labels: {
          formatter: function (value) {
            return parseInt(value); // Convert the y-axis values to decimal format
          },
        },
      },
      colors: ["#00e396", "#008ffb"],
      dataLabels: {
        enabled: false,
        offsetY: -15,
        style: {
          colors: ["#615494", "#f27777"],
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
            offsetY: -20,
            width: 1,
            style: {
              fontSize: "12px",
              colors: [" #000"],
              offsetY: -20,
            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          formatter: function () {
            return 25;
          },
          offsetY: -20,
        },
      },
    },
    series: [
      {
        name: "Ingress",
        data: [],
      },
      {
        name: "Egress",
        data: [],
      },
    ],
  });
  const [chartDataDate, setChartDataDate] = useState({
    options: {
      chart: {
        id: "basic-bar",
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        contextmenu: {
          enabled: false,
        },
        zoom: {
          enabled: false, // Ensure zoom is disabled
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: "category", // Change type to category for categorical data
        categories: [], // Initialize categories as empty array
        title: {
          offsetY: -10,
        },
      },
      yaxis: {
        type: "count",
        title: {
          text: "count",
          offsetY: -10,
        },
        labels: {
          formatter: function (value) {
            return parseInt(value); // Convert the y-axis values to decimal format
          },
        },
      },
      colors: ["#00e396", "#008ffb"],
      dataLabels: {
        enabled: false,
        offsetY: -15,
        style: {
          colors: ["#615494", "#f27777"],
        },
      },
      stroke: {
        width: 1,
      },
      pattern: {
        strokeWidth: 0.1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
            offsetY: -20,
            width: 1,
            style: {
              fontSize: "12px",
              colors: [" #000"],
              offsetY: -20,
            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          formatter: function () {
            return 25;
          },
          offsetY: -20,
        },
      },
    },
    series: [
      {
        name: "Ingress",
        data: [],
      },
      {
        name: "Egress",
        data: [],
      },
    ],
  });

  //main filters
  const [mainFilter, setMainFilter] = useState({
    selectFilter: 2,
    startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  });

  const onMainFilterChange = (val) => {
    if (val === 1) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val === 2) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val === 3) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().subtract(15, "day").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val === 4) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      }));
    } else if (val === 5) {
      setMainFilter((prevState) => ({
        ...prevState,
        selectFilter: val,
      }));
    }
  };
  const onMainFilterDateChange = (date) => {
    if (date === null || date === "") {
      setMainFilter((prevState) => ({
        ...prevState,
      }));
    } else {
      setMainFilter((prevState) => ({
        ...prevState,

        startDate: dayjs(date).format("YYYY-MM-DD"),
        endDate: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  };
  const onMainRangeChange = (date) => {
    if (date === null || date === "") {
      setMainFilter((prevState) => ({
        ...prevState,
      }));
    } else {
      setMainFilter((prevState) => ({
        ...prevState,

        startDate: dayjs(date[0]).format("YYYY-MM-DD"),
        endDate: dayjs(date[1]).format("YYYY-MM-DD"),
      }));
    }
  };

 

  useEffect(() => {
    EngagedAndBounced();
    handleDateRangeChangeforPeakHours();
    AgeBasedMaleFemaleCount();
    genderGraph();
  }, [mainFilter.selectFilter, mainFilter.startDate, mainFilter.endDate]);
  useEffect(() => {
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, IngressEgress: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, DwellFootfall: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, EngagedBounced: true }));
    }, 1000);

    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, AgeGender: true }));
    }, 1000);
    setTimeout(() => {
      setRenderCompController((prev) => ({ ...prev, PeakHours: true }));
    }, 1000);
  }, []);

  //-----------------------------------------

  //-----------------------------------------
  const DwellHourComp = () => {
    return (
      <Card
        title={
          <>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Title
                  level={5}
                  type="secondary"
                  style={{ fontFamily: "Helvetica" }}
                >
                  {mainFilter.selectFilter === 1
                    ? "Dwell time per hour"
                    : mainFilter.selectFilter === 2
                    ? "Weekly Dwell time"
                    : mainFilter.selectFilter === 3
                    ? "Last 15 Days Dwell time"
                    : mainFilter.selectFilter === 4
                    ? "Monthly Dwell time"
                    : "Specific Date range Dwell time"}
                </Title>
              </Col>
              <Col>
                <Row align="center" gutter={[10, 0]}>
                  <Flex align="center">
                    <Col>
                      <Text type="secondary">
                        Date:{" "}
                        {mainFilter.selectFilter === 1
                          ? dayjs(mainFilter.startDate).format("DD-MM-YYYY")
                          : dayjs(mainFilter.startDate).format("DD-MM-YYYY") +
                            " - " +
                            dayjs(mainFilter.endDate).format("DD-MM-YYYY")}
                      </Text>
                    </Col>
                    <Col>
                      {mainFilter.selectFilter === 1 ? (
                        <Select
                          onChange={OnChangeCameraSelect}
                          placeholder={<Text type="secondary">Camera</Text>}
                          value={DwellHourly.selectedCam}
                          style={{ width: 120 }}
                        >
                          {DwellHourly.cameraList.map((item) => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          onChange={OnChangeCameraSelect}
                          placeholder={<Text type="secondary">Camera</Text>}
                          value={DwellHourlyDateWise.selectedCam}
                          style={{ width: 120 }}
                        >
                          {DwellHourlyDateWise.cameraList.map((item) => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Col>
                  </Flex>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Skeleton loading={graphLoading}>
          <Chart
            options={
              mainFilter.selectFilter === 1
                ? DwellHourly.options
                : DwellHourlyDateWise.options
            }
            series={
              mainFilter.selectFilter === 1
                ? DwellHourly.series
                : DwellHourlyDateWise.series
            }
            type="bar"
            height={"200%"}
          />
        </Skeleton>
      </Card>
    );
  };

  //----------------------------------------------
  const initialDate = dayjs().startOf('month').format("YYYY-MM-DD");
  const [spiderMonth,setSpiderMonth]=useState(initialDate)

  const OnSpiderMonthChange=(date)=>{
    if (date) {
      // Format the selected month to 'YYYY-MM'
      // const formattedDate = date.startOf('month').format('YYYY-MM');
      
      setSpiderMonth(date.format("YYYY-MM-DD"));
    } else {
      setSpiderMonth(initialDate);
    }
  }

  useEffect(() => {
    axiosClient
      .get(`/livereport/spidergraphdata?month=${spiderMonth}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          const responseData = response.data.data.newspider;

          const weeks = {};
          responseData.forEach((item) => {
            if (!weeks[item.weekwise]) {
              weeks[item.weekwise] = {
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
                Sunday: 0,
              };
            }
            weeks[item.weekwise][item.week] = item.totalingress;
          });

          const series = Object.keys(weeks).map((week, index) => ({
            name: `Week ${index + 1}`,
            data: [
              weeks[week].Monday,
              weeks[week].Tuesday,
              weeks[week].Wednesday,
              weeks[week].Thursday,
              weeks[week].Friday,
              weeks[week].Saturday,
              weeks[week].Sunday,
            ],
          }));

          setChartFootfallAnalysis((prevState) => ({
            ...prevState,
            series: series,
          }));
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
  }, [spiderMonth]);


 useEffect(() => {
    //fetchDatewiseData()

    if (mainFilter.selectFilter !== 1) {
      fetchDatewiseData();
    } else {
      fetchHourlyData();
    }
  }, [mainFilter.startDate, mainFilter.endDate, DwellHourly.selectedCam]);

  const fetchDatewiseData = async () => {
    setGraphLoading(true);
    try {
      const response = await axiosClient.get(
        `/dwelltime/getDwellTimeDatewiseData?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camId=${DwellHourly.selectedCam}`
      );
      if (response.data.issuccess === true) {
        const data = response.data.data.dwellData;
        const CameraList = response.data.data.cameraData || [];

        updateCameraList(CameraList);

        if (data.length > 0) {
          const zoneNames = extractZoneNames1(data).sort();
          const { hours, series } = transformData1(data, zoneNames);

          setDwellHourlyDateWise((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.options.xaxis,
                categories: hours,
              },
            },
            series: series,
            selectedCam: response.data.data.selectedCameraDetails.cameraId,
          }));
        } else {
          setDwellHourlyDateWise((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.options.xaxis,
                categories: [],
              },
            },
            series: [],
          }));
        }
        setGraphLoading(false);
      } else {
        setGraphLoading(false);
      }
    } catch (error) {
      setGraphLoading(false);
    }
  };

  const fetchHourlyData = async () => {
    setGraphLoading(true);
    try {
      const response = await axiosClient.get(
        `/dwelltime/getDwellTimeHourData?date=${mainFilter.startDate}&camId=${DwellHourly.selectedCam}`
      );
      if (response.data.issuccess === true) {
        const data = response.data.data.dwellData;
        const CameraList = response.data.data.cameraData || [];

        updateCameraList(CameraList);

        if (data.length > 0) {
          const zoneNames = extractZoneNames(data).sort();
          const { hours, series } = transformData(data, zoneNames);

          setDwellHourly((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.options.xaxis,
                categories: hours,
              },
            },
            series: series,
            selectedCam: response.data.data.selectedCameraDetails.cameraId,
          }));
        } else {
          setDwellHourly((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.options.xaxis,
                categories: [],
              },
            },
            series: [],
          }));
        }
        setGraphLoading(false);
      } else {
        setGraphLoading(false);
      }
    } catch (error) {
      setGraphLoading(false);
    }
  };

  // useEffect(() => {

  //   fetchDatewiseData();
  //   // if (mainFilter.startDate && mainFilter.endDate) {

  //   // }
  // },[mainFilter.startDate, mainFilter.endDate]);

  const updateCameraList = (CameraList) => {
    if (CameraList) {
      const newCamList = CameraList.map((item) => ({
        value: item.cameraId,
        label: item.cameraName,
      }));
      setDwellHourly((prev) => ({
        ...prev,
        cameraList: [...newCamList],
      }));
      setDwellHourlyDateWise((prev) => ({
        ...prev,
        cameraList: [...newCamList],
      }));
    }
  };

  const transformData1 = (data, zoneNames) => {
    const hours = _.map(data, "createdDate");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };

  const extractZoneNames1 = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };

  //------------------------------------------------
  const transformData = (data, zoneNames) => {
    const hours = _.map(data, "hour");
    const series = _.map(zoneNames, (zone) => {
      const DwellTime_avg = _.map(data, zone + ".average_dwell_time");
      const Final_Dwell = DwellTime_avg.map((item) => Math.round(item));
      return {
        name: zone,
        data: Final_Dwell,
      };
    });

    return { hours, series };
  };
  //---------------------------

  const extractZoneNames = (data) => {
    return _(data)
      .flatMap(_.keys)
      .filter((key) => key.startsWith("Area"))
      .uniq()
      .value();
  };
 

  //---------------------------------
  const OnChangeCameraSelect = (cam) => {
    setDwellHourly((prev) => ({
      ...prev,
      selectedCam: cam,
    }));
  };
  //weekly
  const EngageAndBounce = () => {
    return (
      <Col
        xs={{ span: 24 / 1, offset: 0 }}
        sm={{ span: 24 / 1, offset: 0 }}
        md={{ span: 24 / 1, offset: 0 }}
        lg={{ span: 24 / 1, offset: 0 }}
        xl={{ span: 24 / 1, offset: 0 }}
      >
        <Card
          style={{
            userSelect: "none",
            boxShadow: "none",
          }}
          title={
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Title
                    level={5}
                    type="secondary"
                    style={{ fontFamily: "Helvetica" }}
                  >
                    {mainFilter.selectFilter === 1
                      ? "Engaged and Bounced"
                      : mainFilter.selectFilter === 2
                      ? "Weekly Engaged and Bounced"
                      : mainFilter.selectFilter === 3
                      ? "Last 15 Days Engaged and Bounced"
                      : mainFilter.selectFilter === 4
                      ? "Monthly Engaged and Bounced"
                      : "Specific Date range Engaged and Bounced"}
                  </Title>
                </Col>

                <Col>
                  <Flex gap={10} align="center">
                    {/* {WeeklyBouncedController.search && (
                      <RangePicker
                        onChange={handleDateRangeChangeforBounce}
                        disabledDate={(current) => {
                          return current && current > moment().endOf("day");
                        }}
                        allowClear
                      />
                    )}
                    <Dropdown
                      menu={{
                        items: menuforBounced,
                        onClick: handleMenuClickWeeklyBounced,
                      }}
                      placement="bottomRight"
                    >
                      <Button>
                        <Flex gap={10} align="center">
                          Filters
                          <LuFilter />
                        </Flex>
                      </Button>
                    </Dropdown> */}
                    <Text type="secondary">
                      Date:{" "}
                      {mainFilter.selectFilter === 1
                        ? dayjs(mainFilter.startDate).format("DD-MM-YYYY")
                        : dayjs(mainFilter.startDate).format("DD-MM-YYYY") +
                          " - " +
                          dayjs(mainFilter.endDate).format("DD-MM-YYYY")}
                    </Text>
                  </Flex>
                </Col>
              </Row>
            </>
          }
        >
          <Row  justify={"space-between"} align={"middle"}>
            <Col
              xs={{ span: 23 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 12 / 1, offset: 0 }}
              lg={{ span: 12/1 , offset: 0 }}
              xl={{ span: 12/1, offset: 0 }}
            >
              
                <Chart
                  type="bar"
                  options={Engagersweeklychart.options}
                  series={Engagersweeklychart.series}
                  height="200%"
                />
            
            </Col>
            
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 12 / 1, offset: 0 }}
              lg={{ span: 12/1 , offset: 0 }}
              xl={{ span: 12/1, offset: 0 }}
            >
              
                <Chart
                  
                  type="bar"
                  options={Bouncedweeklychart.options}
                  series={Bouncedweeklychart.series}
              
                  height="200%"
                />
             
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  //-------------------------

  const EngagedAndBounced = () => {
    axiosClient
      .get(
        `dwelltime/getbounceandengagedforspecifieddates?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
      )
      .then((response) => {
        const apiData = response.data.data;
        const categories = apiData.map((item) => item.createdDate);
        const seriesData = apiData.map((item) => item.totalbounce);


        const BouncedData = apiData.map((item) => item.totalengaged);
        setEngagersweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
          
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
          series: [{ name: "Engagers", data: seriesData }],
        }));
        setBouncedweeklychart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            
            xaxis: { ...prevState.options.xaxis, categories: categories },
          },
          series: [
            {
              ...prevState.options.series,
              name: "Bounced",
              data: BouncedData,
            },
          ],
        }));
      })
      .catch((error) => {
      });

  };

  const genderGraph = () => {
    axiosClient
      .get(
        `/livereport/getageandgender?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camid=`
      )
      .then((response) => {
        //setLoading(false);
        if (response.data.issuccess === true) {
          const responseData = response.data.data;
          
          // Handle null or zero values for gender percentages
          const malePercentage = responseData.male_percentage || 0;
          const femalePercentage = responseData.female_percentage || 0;
          const maleCount = responseData.male_count || 0;
          const femaleCount = responseData.female_count || 0;

          setChartDatapieGender((prevState) => ({
            ...prevState,
            series: [malePercentage, femalePercentage],
            maleCount: maleCount,
            femaleCount: femaleCount,
          }));

          // Handle zero values for age distribution
          const ageLabels = responseData.age_distribution.map(
            (group) => group.age
          );
          const ageCounts = responseData.age_distribution.map(
            (group) => group.count
          );
          setChartDatapieAge({
            ...chartDatapieAge,
            options: { ...chartDatapieAge.options, labels: ageLabels },
            series: ageCounts,
          });
        }
      })
      .catch((err) => {
        //setLoading(false);
        if (err.response && err.response.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.message);
        }
      });
  };
  const hasNonZeroValues =
    chartDatapieGender.series.reduce((acc, curr) => acc + curr, 0) > 0;
  const hasNonZeroValues2 =
    chartDatapieAge.series.reduce((acc, curr) => acc + curr, 0) > 0;

  const handleDateRangeChangeforPeakHours = () => {
    axiosClient
 // .get(
      //   `livereport/getpeakhourforspecifieddates?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
      // )
      .get(
        `store/getstorepeakhourforspecifieddates?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
      )
      .then((response) => {
        const apiData = response.data.data;

        const categories = apiData.map((item) => item.date);

        const seriesData = apiData.map((item) =>
          item.peakHour === null || item.peakHour === undefined
            ? 0
            : item.peakHour
        );

        // const yAxisLabels = apiData.map((item) => {
        //   if (typeof item.peakHour === "string") {
        //     return item.peakHour;
        //   } else {
        //     return "";
        //   }
        // });

        setpickhoursweeklydata((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,

            xaxis: { categories: categories, title: { text: "Date" } },
            // yaxis: { categories: yAxisLabels },
          },
          series: [
            {
              name: "Peak hour",
              data: seriesData,
            },
          ],
        }));
      })
      .catch((error) => {
      });
  };

  //-----------heatmap
  const [HeatmapController, setHeatmapController] = useState({
    loading: false,
    cameralist: [],
  });
  //footfall analysis

 
  useEffect(() => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      loading: true,
    }));
    axiosClient
      .get(
        `/livereport/getfootfalldirectiondaywise?date=${dayjs(
          FootfallDirectionHourlyController.selectedDate
        ).format("YYYY-MM-DD")}&camId=${
          FootfallDirectionHourlyController.selectedcam
        }`
      )

      .then((response) => {
        if (response.data.issuccess === true) {
          const selectedcamDetails = response.data.data;
          const CameraList = response.data.data.cameraData;
          if (
            selectedcamDetails.selectedCameraData !== null &&
            selectedcamDetails.selectedCameraData !== undefined &&
            selectedcamDetails.selectedCameraData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              selectedcam: selectedcamDetails.selectedCameraData.cameraId,

              selectedcamName: selectedcamDetails.selectedCameraData.cameraName,
            }));
          }

          if (
            selectedcamDetails.directionData !== null &&
            selectedcamDetails.directionData !== undefined &&
            selectedcamDetails.directionData !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionData: selectedcamDetails.directionData,
            }));
          }
          if (
            selectedcamDetails.directionImage !== null &&
            selectedcamDetails.directionImage !== undefined
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              DirectionImage: selectedcamDetails.directionImage,
            }));
          }

          if (
            selectedcamDetails.hour !== null &&
            selectedcamDetails.hour !== undefined &&
            selectedcamDetails.hour !== ""
          ) {
            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              Hour: selectedcamDetails.hour,
            }));
          }
          if (CameraList !== null && CameraList !== undefined) {
            const newCamList = CameraList.map((item) => ({
              value: item.cameraid,
              label: item.cameraname,
            }));

            setFootfallDirectionHourlyController((prev) => ({
              ...prev,
              camlist: [...newCamList],
            }));
          }
        } else {
        }
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        setFootfallDirectionHourlyController((prev) => ({
          ...prev,
          loading: false,
        }));
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, [
    FootfallDirectionHourlyController.selectedcam,
    FootfallDirectionHourlyController.selectedDate,
    FootfallDirectionHourlyController.Hour,
  ]);
  const HandleSelectCameraDirection = (id) => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      selectedcam: id,
    }));
  };
  const onChangeTimeDirection = (date) => {
    if (date) {
      setFootfallDirectionHourlyController((prev) => ({
        ...prev,
        selectedDate: dayjs(date).format("YYYY-MM-DD"),
      }));
    } else {
      setFootfallDirectionHourlyController((prev) => ({
        ...prev,
        selectedDate: "",
      }));
    }
  };

  const handleHourChangeFootfalldirection = (time, hour) => {
    setFootfallDirectionHourlyController((prev) => ({
      ...prev,
      Hour: hour,
    }));
  };
  const disabledTime = () => {
    return {
      disabledHours: () => {
        const currentHour =
          moment().format("YYYY-MM-DD") ===
          FootfallDirectionHourlyController.selectedDate
            ? moment().hours()
            : 24;
        return [...Array(24).keys()].slice(currentHour);
      },
    };
  };

  //Dwell time data
  useEffect(() => {
    setHeatmapController((prev) => ({ ...prev, loading: true }));

    axiosClient
      .get("/dwelltime/getDwellTimeDetails?id=")
      .then((response) => {
        if (response.data.issuccess === true) {
          setHeatmapController((prev) => ({
            ...prev,
            cameralist: response.data.data,
          }));
          setHeatmapController((prev) => ({ ...prev, loading: false }));
        }
      })
      .catch((err) => {
        setHeatmapController((prev) => ({ ...prev, loading: false }));
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }, []);

  const [PasserTableValue, setPasserTableValue] = useState({
    table: [],
    selecteddate: dayjs().subtract(1, "day"),
  });
  const [PasserTableHour, setPasserTableHour] = useState({
    table: [],
    selecteddate: dayjs().subtract(1, "day"),
  });

  // const [PeakDayCount, setPeakDayCount] = useState({
  //   cameraList: [],
  //   TotalCount: 0,
  //   selectedCamId: 0,
  //   selectedDate: "",
  //   startDate: dayjs().subtract(8, "day").format("YYYY-MM-DD"),
  //   endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  // });

  useEffect(() => {
    getPasserByCountHour();
    getPasserByCount();
    // PeakDay();
  }, [mainFilter.startDate, mainFilter.endDate]);

  // function PeakDay() {
  //   axiosClient
  //     .get(
  //       `/livereport/getpeakday?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}&cameraId=${PeakDayCount.selectedCamId}`
  //     )
  //     .then((response) => {
  //       if (response.data.issuccess === true) {
  //         setPeakDayCount((prevState) => ({
  //           ...prevState,
  //           TotalCount: dayjs(response.data.data).format("DD-MM-YYYY"),
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.status === 0) {
  //         message.error("Server error");
  //       } else {
  //         message.error(err);
  //       }
  //     });
  // }

  function getPasserByCount() {
    axiosClient
      .get(
        `/livereport/getprocesspasserbycount?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const TableData = response.data.data.data.sort(
            (a, b) => dayjs(b.date) - dayjs(a.date)
          );

          setPasserTableValue({
            table: TableData,
          });
          const data = response.data.data.data;
          const categories = data.map((item) => item.date);
          const ingressData = data.map((item) => parseInt(item.sumofingress));
          const egressData = data.map((item) => parseInt(item.sumofegress));

          setChartDataDate((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: {
                title: { text: "Date" },
                categories: categories,
              },
            },
            series: [
              {
                name: "Ingress",
                data: ingressData,
              },
              {
                name: "Egress",
                data: egressData,
              },
            ],
          }));
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }

  function getPasserByCountHour() {
    axiosClient
      .get(`/livereport/getpasserbycount?camId=&date=${mainFilter.startDate}`)
      .then((response) => {
        if (response.data.issuccess === true) {
          const updatedData = response.data.data.data
            .filter((item) => item.hour >= 7)
            .sort((a, b) => b.hour - a.hour) // Sort by hour in descending order
            .map((item) => ({
              hour: moment(item.hour, "HH").format("h A"),
              passerby: item.passerby || 0, // Set passerby to 0 if null, undefined, or empty
              ingress: item.ingress || 0, // Set ingress to 0 if null, undefined, or empty
            }));
          setPasserTableHour((prev) => ({
            ...prev,
            table: updatedData,
          }));
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.msg);
        }
      });
  }

  // const onPasserDateChange = (date) => {
  //   if (date) {
  //     setPasserTableValue((prev) => ({
  //       ...prev,
  //       selecteddate: dayjs(date).format("YYYY-MM-DD"),
  //     }));
  //   } else {
  //     setPasserTableValue((prev) => ({
  //       ...prev,
  //       selecteddate: "",
  //     }));
  //   }
  // };

  //passer table column
  const PasserColumn = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => (
        <span style={{ color: "black" }}>
          {dayjs(text).format("DD-MM-YYYY")}
        </span>
      ),
      align: "center",
    },
    {
      title: "Passerby",
      dataIndex: "sumpasserby",
      key: "sumpasserby",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Ingress",
      dataIndex: "sumofingress",
      key: "sumofingress",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Egress",
      dataIndex: "sumofegress",
      key: "sumofegress",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
  ];
  const PasserColumnHour = [
    {
      title: "Hour",
      dataIndex: "hour",
      key: "hour",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Passerby",
      dataIndex: "passerby",
      key: "passerby",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
    {
      title: "Ingress",
      dataIndex: "ingress",
      key: "ingress",
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
      align: "center",
    },
  ];

  const paginationConfig = {
    pageSize: 4,
  };

  //gender age graph male female count based on age
  const [AgeMaleFemaleCount, setAgeMaleFemaleCount] = useState({
    graph: {
      series: [
        {
          name: "Male",
          data: [],
        },
        {
          name: "Female",
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `${val} Count`,
          },
        },
      },
    },
    selecteddate: "",
  });

  useEffect(() => {
    AgeBasedMaleFemaleCount(AgeMaleFemaleCount);
  }, [AgeMaleFemaleCount.selecteddate]);

  const AgeBasedMaleFemaleCount = () => {
    axiosClient
      .get(
        `/livereport/getageandgender?date=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camid=`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          const responseData = response.data.data.gender_based_age_distribution;
          const maleData = responseData.map((item) => item.maleCount);
          const femaleData = responseData.map((item) => item.femaleCount);
          const categories = responseData.map((item) => item.ageRange);

          setAgeMaleFemaleCount((prevState) => ({
            ...prevState,
            graph: {
              ...prevState.graph,
              series: [
                {
                  name: "Male",
                  data: maleData,
                },
                {
                  name: "Female",
                  data: femaleData,
                },
              ],
              options: {
                ...prevState.graph.options,
                xaxis: {
                  categories: categories,
                },
              },
            },
          }));
        }
      })
      .catch((err) => {
        //setLoading(false);
        if (err.response && err.response.status === 0) {
          message.error("Server error");
        } else {
          message.error(err.message);
        }
      });
  };

  //PDF
  // const generatePDF = async () => {
  //   if (containerRef.current && targetRef.current) {
  //     setDownloadDashboard(true);
  //     try {
  //       const pdfExportComponent = containerRef.current;
  //       await pdfExportComponent.save();
  //       setDownloadDashboard(false);
  //     } catch (error) {
  //     }
  //   }
  // };

 //Ingress and engress graph
 useEffect(() => {
  axiosClient
    // .get(
    //   `/livereport/getingressegress?date=` +
    //     mainFilter.startDate +
    //     `&camid=` +
    //     ""
    // )
    .get(
      `store/getstoreanalyticsdetails?date=` +
        mainFilter.startDate +
        `&enddate=` +
        mainFilter.endDate
    )
    .then((response) => {
      if (response.data.issuccess === true) {
        setingressloading(false);

        const list_hour = map(response.data.data.total_records_hour, "hour");

        const list_ingress = map(
          response.data.data.total_records_hour,
          "totalingress"
        );
        const list_egress = map(
          response.data.data.total_records_hour,
          "totalegress"
        );
        setChartData((prev) => ({
          ...prev,
          options: {
            ...prev.options,
            xaxis: {
              title: { text: "Hour" },
              categories: list_hour,
            },
          },
          series: [
            {
              name: "Ingress",
              data: list_ingress,
            },
            {
              name: "Egress",
              data: list_egress,
            },
          ],
        }));
      }
    })
    .catch((err) => {
      if (err.status === 0) {
        message.error("Network error");
      } else {
        message.error(err.message);
      }
    });
}, [mainFilter.startDate, mainFilter.endDate]);


  useEffect(() => {
   UniqueFootFallGraph();
  }, [mainFilter.startDate, mainFilter.endDate]);

  const UniqueFootFallGraph = () => {
    axiosClient
      .get(
        `/livereport/getuniquecountgraph?startDate=${mainFilter.startDate}&endDate=${mainFilter.endDate}&camId=`
      )
      .then((response) => {
        if (response.data.issuccess === true) {
          // const data = response.data.data.totalCountArray;
          // const dates = data.map((item) => item.date);
          // const totals = data.map((item) => item.total);
          let data = response.data.data.totalCountArray;

          if(data !== undefined)
          {
 
            data = data.map(item => ({
              ...item,
              total: item.total
            }));
    
            const dates = data.map(item => item.date);
            const totals = data.map(item => item.total);
            setUniqueGraph((prevState) => ({
              ...prevState,
              options: {
                ...prevState.options,
                xaxis: {
                  ...prevState.options.xaxis,
                  categories: dates,
                },
              },
              series: [
                {
                  ...prevState.series[0],
                  name: "Count",
                  data: totals,
                },
              ],
            }));
          }        
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          message.error("Network error");
        } else {
          message.error(err.message);
        }
      });
  };

  return (
    <div className="weeklystore" style={{ margin: "1rem" }}>
      <Row
        justify={"end"}
        gutter={[30, 30]}
        style={{ margin: "15px", width: "100%" }}
      >
        <Flex style={{ height: "100%", width: "100%" }} align="center" gap={50}>
          <Flex
            align="center"
            gap={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Title level={5} style={{ fontFamily: "Helvetica" }}>
              Filter:
            </Title>

            <Select
              style={{ width: "10rem" }}
              defaultValue={2}
              onChange={onMainFilterChange}
            >
              <Option value={2}>Weekly</Option>
              <Option value={3}>Last 15 days</Option>
              <Option value={4}>Monthly</Option>
              <Option value={1}>Single Day</Option>
              <Option value={5}>Select date</Option>
            </Select>
          </Flex>

          {mainFilter.selectFilter === 5 ? (
            <RangePicker
              onChange={onMainRangeChange}
              disabledDate={(current) => {
                // Disable today and future dates
                const isFutureOrToday =
                  current >= dayjs().subtract(1, "day").endOf("day");
                // Disable dates older than 45 days from yesterday
                const isBefore45DaysAgo =
                  current < dayjs().subtract(45, "day").startOf("day");
                return isFutureOrToday || isBefore45DaysAgo;
              }}
            />
          ) : (
            ""
          )}
          {mainFilter.selectFilter === 1 ? (
            <DatePicker
              //style={{ width: "6rem" }}
              format={"DD-MM-YYYY"}
              className="new_card_date"
              onChange={onMainFilterDateChange}
              disabledDate={(current) =>
                current >= dayjs().subtract(1, "day").endOf("day")
              }
              value={dayjs(mainFilter.startDate)}
            />
          ) : (
            ""
          )}

          {/* <Button
              title="Download"
              type="default"
              htmlType="submit"
              loading={DownloadDashboard}
              onClick={generatePDF}
            >
              Download
            </Button> */}
        </Flex>
      </Row>
      <PDFExport
        margin={20}
        ref={containerRef}
        paperSize="auto"
        fileName={`footfall-report-${moment().format(
          "YYYY-MM-DD-HH-mm-ss"
        )}.pdf`}
        title="Footfall"
      >
        <div ref={targetRef}>
          {RenderCompController.DwellFootfall && (
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Row gutter={[20, 30]}>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 22, offset: 0 }}
                  md={{ span: 22, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  //style={{backgroundColor:"red"}}
                >
                  <Row gutter={[20, 20]}>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                    >
                      <Row gutter={[20, 20]}>
                        <Col
                         xs={{ span: 24, offset: 0 }}
                         sm={{ span: 12, offset: 0 }}
                         md={{ span: 12, offset: 0 }}
                         lg={{ span: 16, offset: 0 }}
                         xl={{ span: 16, offset: 0 }}
                          style={{}}
                        >
                          <Card
                            loading={ingressloading}
                            // hoverable

                            style={{
                              cursor: "pointer",
                              userSelect: "none",
                              boxShadow: "none",
                            }}
                            title={
                              <Row justify={"space-between"} align={"middle"}>
                                <Col>
                                  <Row gutter={5} align={"middle"}>
                                    <Col>
                                      <Title
                                        level={5}
                                        type="secondary"
                                        style={{ fontFamily: "Helvetica" }}
                                      >
                                        {mainFilter.selectFilter === 1
                                          ? "Ingress and Egress Counts per Hour"
                                          : mainFilter.selectFilter === 2
                                          ? "Weekly Ingress and Egress Counts"
                                          : mainFilter.selectFilter === 3
                                          ? "Last 15 Days Ingress and Egress Counts"
                                          : mainFilter.selectFilter === 4
                                          ? "Monthly Ingress and Egress Counts"
                                          : "Specific Date range Ingress and Egress Counts"}
                                      </Title>
                                    </Col>
                                    {/* <Col>
                    {HandleDateForIngressEgress.SelectedDate ? (
                      <>
                        <Tag color="green">
                          {
                            HandleDateForIngressEgress.SelectedDate
                          }
                        </Tag>
                      </>
                    ) : (
                      <LiveTag />
                    )}
                  </Col> */}
                                  </Row>
                                </Col>
                                <Col>
                                  <Text type="secondary">
                                    Date:{" "}
                                    {mainFilter.selectFilter === 1
                                      ? dayjs(mainFilter.startDate).format(
                                          "DD-MM-YYYY"
                                        )
                                      : dayjs(mainFilter.startDate).format(
                                          "DD-MM-YYYY"
                                        ) +
                                        " - " +
                                        dayjs(mainFilter.endDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </Text>
                                </Col>
                              </Row>
                            }
                          >
                            {chartData.series && chartData.series.length > 0 ? (
                              <div className="app">
                                <div className="row">
                                  <div className="mixed-chart">
                                    <Chart
                                      options={
                                        mainFilter.selectFilter === 1
                                          ? chartData.options
                                          : chartDataDate.options
                                      }
                                      series={
                                        mainFilter.selectFilter === 1
                                          ? chartData.series
                                          : chartDataDate.series
                                      }
                                      type="area"
                                      height={295}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "20px",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: "16px",
                                    color: "gray",
                                  }}
                                >
                                  No Data Available
                                </Text>
                              </div>
                            )}
                          </Card>
                        </Col>

                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          xl={{ span: 8, offset: 0 }}
                        >
                          <Card
                            style={{ height: 415 }}
                            //styles={{ body: { minHeight: "100%" } }}
                            title={
                              <>
                                <Flex
                                  gap={5}
                                  align="center"
                                  justify="space-between"
                                >
                                  <Title
                                    level={5}
                                    type="secondary"
                                    style={{ fontFamily: "Helvetica" }}
                                  >
                                    Passerby Count table
                                  </Title>
                                  <Col>
                                    <Text type="secondary">
                                      Date:{" "}
                                      {mainFilter.selectFilter === 1
                                        ? dayjs(mainFilter.startDate).format(
                                            "DD-MM-YYYY"
                                          )
                                        : dayjs(mainFilter.startDate).format(
                                            "DD-MM-YYYY"
                                          ) +
                                          " - " +
                                          dayjs(mainFilter.endDate).format(
                                            "DD-MM-YYYY"
                                          )}
                                    </Text>
                                  </Col>
                                </Flex>
                              </>
                            }
                          >
                            <Table
                              dataSource={
                                mainFilter.selectFilter === 1
                                  ? PasserTableHour.table
                                  : PasserTableValue.table
                              }
                              columns={
                                mainFilter.selectFilter === 1
                                  ? PasserColumnHour
                                  : PasserColumn
                              }
                              rowKey="hour"
                              pagination={paginationConfig}
                            />
                          </Card>
                        </Col>
                        
                      </Row>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                    >
                      <Row gutter={[20, 30]}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <Card
                            title={
                              <>
                                <Flex
                                  gap={5}
                                  align="center"
                                  justify="space-between"
                                >
                                  <Title
                                    level={5}
                                    type="secondary"
                                    style={{ fontFamily: "Helvetica" }}
                                  >
                                    Unique Footfall
                                  </Title>
                                  <Col>
                                    <Text type="secondary">
                                      Date:{" "}
                                      {mainFilter.selectFilter === 1
                                        ? dayjs(mainFilter.startDate).format(
                                            "DD-MM-YYYY"
                                          )
                                        : dayjs(mainFilter.startDate).format(
                                            "DD-MM-YYYY"
                                          ) +
                                          " - " +
                                          dayjs(mainFilter.endDate).format(
                                            "DD-MM-YYYY"
                                          )}
                                    </Text>
                                  </Col>
                                </Flex>
                              </>
                            }
                          >
                            <ReactApexChart
                              //width={"100%"}
                              options={UniqueGraph.options}
                              series={UniqueGraph.series}
                              type="bar"
                              height={"200%"}
                            />
                          </Card>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                        >
                          <DwellHourComp />
                        </Col>
                      </Row>

                      {/* //DwellHourComp */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
          {RenderCompController.EngagedBounced && (
            <Col
              xs={{ span: 24 / 1, offset: 0 }}
              sm={{ span: 24 / 1, offset: 0 }}
              md={{ span: 24 / 1, offset: 0 }}
              lg={{ span: 24 / 1, offset: 0 }}
              xl={{ span: 24 / 1, offset: 0 }}
            >
              <Row style={{ width: "100%", marginTop: "1rem" }}>
                <EngageAndBounce />
              </Row>
            </Col>
          )}

          {/* ---------- */}

          {/* {RenderCompController.AgeGender && ( */}
          <Col
            xs={{ span: 24 / 1, offset: 0 }}
            sm={{ span: 24 / 1, offset: 0 }}
            md={{ span: 24 / 1, offset: 0 }}
            lg={{ span: 24 / 1, offset: 0 }}
            xl={{ span: 24 / 1, offset: 0 }}
          >
            <Card
              style={{
                // borderRadius: "15px",
                // height: "360px",
                userSelect: "none",
                boxShadow: "none",
                marginTop: "1rem",
              }}
              title={
                <>
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Title
                        level={5}
                        type="secondary"
                        style={{ fontFamily: "Helvetica" }}
                      >
                        Total detected Gender Distribution in %
                      </Title>
                    </Col>
                    <Col>
                      <Text type="secondary">
                        Date:{" "}
                        {mainFilter.selectFilter === 1
                          ? dayjs(mainFilter.startDate).format("DD-MM-YYYY")
                          : dayjs(mainFilter.startDate).format("DD-MM-YYYY") +
                            " - " +
                            dayjs(mainFilter.endDate).format("DD-MM-YYYY")}
                      </Text>
                    </Col>
                    <Col>
                      <Title
                        level={5}
                        type="secondary"
                        style={{ fontFamily: "Helvetica" }}
                      >
                        Total detected Age Distribution in %
                      </Title>
                    </Col>
                  </Row>
                </>
              }
            >
              <Row
                style={{ height: "100%" }}
                justify={"space-between"}
                align={"middle"}
              >
                <Col
                  xs={{ span: 23 / 1, offset: 0 }}
                  sm={{ span: 23 / 1, offset: 0 }}
                  md={{ span: 11 / 1, offset: 0 }}
                  lg={{ span: 11 / 1, offset: 0 }}
                  xl={{ span: 11, offset: 0 }}
                >
                
                {hasNonZeroValues ? (
                    <Row>
                      <div
                        style={{
                          width: "15%",
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "start",
                          flexDirection: "column",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Helvetica",
                            fontWeight: "bold",
                          }}
                        >
                          Total:{" "}
                          {chartDatapieGender.maleCount +
                            chartDatapieGender.femaleCount}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Helvetica",
                            fontWeight: "bold",
                            color: "#007acc",
                          }}
                        >
                          Male: {chartDatapieGender.maleCount}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Helvetica",
                            fontWeight: "bold",
                            color: "#00b374",
                          }}
                        >
                          Female: {chartDatapieGender.femaleCount}
                        </Text>
                      </div>
                      <div style={{ width: "85%" }}>
                        <Chart
                          options={chartDatapieGender.options}
                          series={chartDatapieGender.series}
                          type="donut"
                          height="250px"
                        />
                      </div>
                    </Row>
                  ) : (
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100%",
                        padding: "30px",
                      }}
                    >
                      <Empty />
                    </Col>
                  )}
                  
                </Col>
                <Divider type="vertical" style={{ height: "250px" }} />
                <Col
                  xs={{ span: 23 / 1, offset: 0 }}
                  sm={{ span: 23 / 1, offset: 0 }}
                  md={{ span: 11 / 1, offset: 0 }}
                  lg={{ span: 11 / 1, offset: 0 }}
                  xl={{ span: 11, offset: 0 }}
                  style={{ height: "100%" }}
                >
                  <div style={{ height: "250px" }}>
                    {hasNonZeroValues2 ? (
                      <Chart
                        options={chartDatapieAge.options}
                        series={chartDatapieAge.series}
                        type="donut"
                        height="100%"
                      />
                    ) : (
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "100%",
                          padding: "30px",
                        }}
                      >
                        <Empty />
                      </Col>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* -------------------------------------------- */}

          {RenderCompController.PeakHours && (
            <div>
              <Col
                xs={{ span: 24 / 1, offset: 0 }}
                sm={{ span: 24 / 1, offset: 0 }}
                md={{ span: 24 / 1, offset: 0 }}
                lg={{ span: 24 / 1, offset: 0 }}
                xl={{ span: 24 / 1, offset: 0 }}
              >
                <Row gutter={[20, 30]}>
                  <Col
                    xs={{ span: 23 / 1, offset: 0 }}
                    sm={{ span: 20 / 1, offset: 0 }}
                    md={{ span: 18 / 1, offset: 0 }}
                    lg={{ span: 12 / 1, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                  >
                    <Card
                      style={{
                        // borderRadius: "15px",
                        // height: "360px",
                        userSelect: "none",
                        boxShadow: "none",
                        marginTop: "1rem",
                      }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"middle"}>
                            <Col>
                              <Title
                                level={5}
                                type="secondary"
                                style={{ fontFamily: "Helvetica" }}
                              >
                                Total detected gender age distribution in count
                              </Title>
                            </Col>
                            <Col>
                              <Text type="secondary">
                                Date:{" "}
                                {mainFilter.selectFilter === 1
                                  ? dayjs(mainFilter.startDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : dayjs(mainFilter.startDate).format(
                                      "DD-MM-YYYY"
                                    ) +
                                    " - " +
                                    dayjs(mainFilter.endDate).format(
                                      "DD-MM-YYYY"
                                    )}
                              </Text>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <div style={{ height: "250px" }}>
                        <ReactApexChart
                          options={AgeMaleFemaleCount.graph.options}
                          series={AgeMaleFemaleCount.graph.series}
                          type="bar"
                          height={250}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 23 / 1, offset: 0 }}
                    sm={{ span: 20 / 1, offset: 0 }}
                    md={{ span: 18 / 1, offset: 0 }}
                    lg={{ span: 12 / 1, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                  >
                    <Card
                      style={{
                        // borderRadius: "15px",
                        // height: "360px",
                        userSelect: "none",
                        boxShadow: "none",
                        marginTop: "1rem",
                      }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"middle"}>
                            <Col>
                              <Title
                                level={5}
                                type="secondary"
                                style={{ fontFamily: "Helvetica" }}
                              >
                                {mainFilter.selectFilter === 1
                                  ? "Peak Hour"
                                  : mainFilter.selectFilter === 2
                                  ? "Weekly Peak Hour"
                                  : mainFilter.selectFilter === 3
                                  ? "Last 15 Days Peak Hour"
                                  : mainFilter.selectFilter === 4
                                  ? "Monthly Peak Hour"
                                  : "Specific Date range Peak Hour"}
                              </Title>
                            </Col>
                            <Col>
                              <Flex gap={10}>
                                <Text type="secondary">
                                  Date:{" "}
                                  {mainFilter.selectFilter === 1
                                    ? dayjs(mainFilter.startDate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : dayjs(mainFilter.startDate).format(
                                        "DD-MM-YYYY"
                                      ) +
                                      " - " +
                                      dayjs(mainFilter.endDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                </Text>
                              </Flex>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <div style={{ height: "250px" }}>
                        <Chart
                          options={pickhoursweeklyData.options}
                          series={pickhoursweeklyData.series}
                          type="area"
                          height="100%"
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Divider style={{ margin: "1rem 0rem" }}></Divider>
              <Col
                xs={{ span: 24 / 1, offset: 0 }}
                sm={{ span: 24 / 1, offset: 0 }}
                md={{ span: 24 / 1, offset: 0 }}
                lg={{ span: 24 / 1, offset: 0 }}
                xl={{ span: 24 / 1, offset: 0 }}
                style={{ marginTop: "1rem" }}
              >
                <Row gutter={[20, 30]}>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                  >
                    <Card
                      style={{ height: "33rem" }}
                      //bodyStyle={{ minHeight: "33rem",maxHeight:"33rem" }}
                      title={
                        <Title
                          level={5}
                          type="secondary"
                          style={{ fontFamily: "Helvetica" }}
                        >
                          Dwell Time Heatmap
                        </Title>
                      }
                    >
                      <Row>
                        <Col span={24}>
                          <div>

                          {/* <Image
                                      src={heat}
                                      style={{
                                        height: "26rem",
                                        objectFit: "cover",
                                      }}
                                    /> */}
                            {HeatmapController.cameralist &&
                            HeatmapController.cameralist.length > 0 ? (
                              <Carousel showThumbs={false}>
                                {HeatmapController.cameralist.map((item) => (
                                  <Skeleton
                                    loading={HeatmapController.loading}
                                    active
                                    round
                                    key={item.cameraName}
                                  >
                                    <Image
                                      src={`data:image/png;base64,${item.heatMapBase64}`}
                                      style={{
                                        height: "26rem",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Text className="label-heatmap">
                                        Avg: {item.avg} sec
                                        <br />
                                        Camera: {item.cameraName}
                                      </Text>
                                    </div>
                                  </Skeleton>
                                ))}
                              </Carousel>
                            ) : (
                              <Col
                                xs={{ span: 24, offset: 0 }}
                                sm={{ span: 24, offset: 0 }}
                                md={{ span: 24, offset: 0 }}
                                lg={{ span: 24, offset: 0 }}
                                xl={{ span: 24, offset: 0 }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  minHeight: "100%",
                                  padding: "30px",
                                }}
                              >
                                <Empty />
                              </Col>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                  >
                    <Card
                      style={{ height: "33rem" }}
                      //styles={{ body: { minHeight: "100%" } }}
                      title={
                        <>
                          <Row
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "1rem 0rem",
                            }}
                          >
                            <Col span={6}>
                              <Flex gap={5}>
                                <Title
                                  level={5}
                                  type="secondary"
                                  style={{ fontFamily: "Helvetica" }}
                                >
                                  Footfall Direction <GrDirections />
                                </Title>
                              </Flex>

                              <Tag>
                                {FootfallDirectionHourlyController.selectedcamName ? (
                                  FootfallDirectionHourlyController.selectedcamName +
                                  " - "
                                ) : (
                                  <></>
                                )}
                                {FootfallDirectionHourlyController.selectedDate ? (
                                  FootfallDirectionHourlyController.selectedDate +
                                  " - "
                                ) : (
                                  <></>
                                )}
                                {FootfallDirectionHourlyController.Hour ? (
                                  FootfallDirectionHourlyController.Hour + " hr"
                                ) : (
                                  <></>
                                )}
                              </Tag>
                            </Col>
                            <Col>
                              <Flex gap={5}>
                                <Select
                                  placeholder={
                                    <Text type="secondary">camera</Text>
                                  }
                                  onChange={HandleSelectCameraDirection}
                                  style={{
                                    width: 120,
                                  }}
                                  options={
                                    FootfallDirectionHourlyController.camlist
                                  }
                                />

                                <Col
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                  }}
                                >
                                  <Col>
                                    <DatePicker
                                      style={{ width: "120px" }}
                                      format={"DD-MM-YYYY"}
                                      placeholder="Date"
                                      onChange={onChangeTimeDirection}
                                      disabledDate={(current) =>
                                        current >=
                                        dayjs().subtract(1, "day").endOf("day")
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <TimePicker
                                      style={{ width: "120px" }}
                                      placeholder="Hour"
                                      disabledTime={disabledTime}
                                      format={"HH"}
                                      onChange={
                                        handleHourChangeFootfalldirection
                                      }
                                    />
                                  </Col>
                                </Col>
                              </Flex>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <div style={{ maxHeight: "250px" }}>
                      {/* <Image
                              src={
                                dire
                              }
                              style={{
                                maxHeight: "370px",
                                minHeight: "370px",
                              }}
                            ></Image> */}
                        {FootfallDirectionHourlyController.DirectionImage &&
                        FootfallDirectionHourlyController.DirectionImage ? (
                          <Skeleton
                            style={{
                              maxHeight: "250px",
                              minHeight: "250px",
                            }}
                            loading={FootfallDirectionHourlyController.loading}
                            active
                            round
                          >
                            <Image
                              src={
                                "data:image/png;base64," +
                                FootfallDirectionHourlyController.DirectionImage
                              }
                              style={{
                                maxHeight: "370px",
                                minHeight: "370px",
                              }}
                            ></Image>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {FootfallDirectionHourlyController.DirectionData !==
                              "No data" ? (
                                // FootfallDirectionHourlyController.DirectionData.map(
                                //   (item) => (
                                //     <Text className="label-Direction">
                                //       {item.directionname} :{" "}
                                //       {item.count}
                                //     </Text>
                                //   )
                                // )
                                <></>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Skeleton>
                        ) : (
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 24, offset: 0 }}
                            xl={{ span: 23, offset: 0 }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // marginTop: "60px",
                            }}
                          >
                            <Empty />
                          </Col>
                        )}
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    xl={{ span: 8, offset: 0 }}
                  >
                    <Card
                      style={{ height: "33rem" }}
                      title={
                        <>
                          <Row justify={"space-between"} align={"bottom"}>
                            <Col>
                              <Title level={5}>Footfall Analysis</Title>
                            </Col>
                            <Col>
                              <div style={{ display: "inline-flex" }}>
                                <div>
                                  <DatePicker
                                   onChange={OnSpiderMonthChange}
                                   
                                  // format={"MM-YYYY"}
                                    picker="month"
                                   // disabledDate={disabledFutureMonths}
                                    allowClear={true}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      {chartFootfallAnalysis.series.length > 0 ? (
                        <ReactApexChart
                          options={chartFootfallAnalysis.options}
                          series={chartFootfallAnalysis.series}
                          type="radar"
                          height={420}
                        />
                      ) : (
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100%",
                            padding: "30px",
                          }}
                        >
                          <Empty />
                        </Col>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </div>
          )}
        </div>
      </PDFExport>
    </div>
  );
};

export default Weekly;
